import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-co-creation',
  templateUrl: './co-creation.component.html',
  styleUrls: ['./co-creation.component.css']
})
export class CoCreationComponent implements OnInit {
  imageSrc = '../../../../assets/templates/home/choice_driver_mv01.gif';

  constructor() { }

  ngOnInit(): void {
  }


  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }


  onClick(name) {
    this.imageSrc = '../../../../assets/templates/home/'+name
  }

  career() {
    window.location.href = "/career";
  }
  aboutUs() {
    window.location.href = "/about-us";
  }
  products() {
    window.location.href = "/products";
  }
  daicel_group() {
    window.location.href = "/daicel-group";
  }
  csr_page() {
    window.location.href = "/csr";
  }
  news_events() {
    window.location.href = "/news-events";
  }
  contact_us() {
    window.location.href = "/contact-us";
  }
  th() {
    window.location.href = "/th/products";
  }
  home() {
    window.location.href = "/";
  }



}
