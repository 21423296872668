<title>
    Daicel Thailand | CSR</title>
    <header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
      <div class="header_inner clearfix">
          <div class="header_top_bottom_holder">
              <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
                  <div class="container">
                      <div class="container_inner clearfix">
                          <div class="header_inner_left">
                              <div class="mobile_menu_button">
                                  <span>
                                          <i class="fa fa-bars"></i> </span>
                              </div>
                              <div class="logo_wrapper">
                                  <div class="q_logo">
                                      <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                          <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="light" src="../assets/templates/home/DSST-Logo-300x70.png"
                                              alt="Logo" style="height: 100%;"> <img itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="sticky" src="../assets/templates/home/DSST-Logo-300x70.png"
                                              alt="Logo" style="height: 100%;"> <img itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> </a>
                                  </div>
                              </div>
                              <div class="header_fixed_right_area">
                                  <div class="textwidget custom-html-widget">
                                      <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                                  <span class="empty_space_image"></span>
                                          </span>
                                      </div>

                                      <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                                            <div class="icon_holder " style="">
                                                <img itemprop="image" style="
                                                margin-top: 7px;
                                                margin-left: 5px;
                                            "
                                                    src="../assets/templates/home/pr.png" alt="">
                                            </div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                  <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                  font-weight: 400!important;
                                                  font-family: 'Open Sans', sans-serif!important;
                                                  "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                    font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                    ">  241 Moo 4, 304 Ind Park<br>
                                                      Prachinburi 25140 Thailand <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block; margin-right: 15px;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                              margin-top: 7px;
                                              margin-left: 5px;
                                          "
                                                    src="../assets/templates/home/sb.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                  <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                  font-weight: 400!important;
                                                  font-family: 'Open Sans', sans-serif!important;
                                                  ">
                                                      +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                    font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                    ">  76 Moo.1, A.Phra Phutthabat<br>
                                                     Saraburi 18120 Thailand <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                              margin-top: 7px;
                                              margin-left: 5px;
                                          "
                                                    src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                  <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                  font-weight: 400!important;
                                                  font-family: 'Open Sans', sans-serif!important;
                                                  "> daicel-dsst.com<br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                    font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                    ">  Monday - Friday 08h - 17h<br>Closed on Weekends


                                                     <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>


                                  </div>
                              </div>
                          </div>
                          <div class="header_menu_bottom">
                              <div class="header_menu_bottom_inner">
                                  <div class="main_menu_header_inner_right_holder">
                                      <nav class="main_menu drop_down">
                                          <ul id="menu-main-menu" class="clearfix">
                                              <li id="nav-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="aboutUs()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">About
                                                              Us<span class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="products()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">Products<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="daicel_group()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">Daicel
                                                              Group<span class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="csr_page()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">CSR<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="news_events()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">News<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="career()" class=""><i
                                                                      class="menu_icon blank fa"></i><span style="
                                                                      ">Career
                                                                      <span class="underline_dash"></span></span><span
                                                                      class="plus"></span></a></li>
                                              <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="contact_us()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">Contact
                                                              Us<span class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                                  <a title="English" class=""><i
                                                              class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                                                                  src="../assets/templates/home/en.png" alt="en"
                                                                  title="English"><span
                                                                  class="wpml-ls-native" style="
                                                                  ">English</span><span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span><span class="qode-featured-icon "
                                                              aria-hidden="true"></span></a>
                                                  <div class="second" style="height: 0px;">
                                                      <div class="inner">
                                                          <ul>
                                                              <li id="nav-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                                  <a title="ไทย" (click)="th()" class=""><i
                                                                              class="menu_icon blank fa"></i><span><img
                                                                                  class="wpml-ls-flag"
                                                                                  src="../assets/templates/home/th.png"
                                                                                  alt="th" title="ไทย"><span
                                                                                  class="wpml-ls-native">ไทย</span></span><span
                                                                              class="plus"></span><span
                                                                              class="qode-featured-icon "
                                                                              aria-hidden="true"></span></a></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                              </li>
                                          </ul>
                                      </nav>
                                      <div class="header_inner_right">
                                          <div class="side_menu_button_wrapper right">
                                              <div class="header_bottom_right_widget_holder">
                                                  <div class="widget_text header_bottom_widget widget_custom_html">
                                                      <div class="textwidget custom-html-widget">
                                                          <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">
                                                              <div class=" full_section_inner clearfix">
                                                                  <div class="wpb_column vc_column_container vc_col-sm-12">
                                                                      <div class="vc_column-inner ">
                                                                          <div class="wpb_wrapper">


                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="side_menu_button">
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <nav class="mobile_menu">
                              <ul id="menu-main-menu-1" class="">
                                  <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="aboutUs()" class=""><span>About
                                                  Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="products()" class=""><span>Products</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="daicel_group()" class=""><span>Daicel
                                                  Group</span></a><span class="mobile_arrow"><i
                                                  class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="csr_page()" class=""><span>CSR</span></a><span class="mobile_arrow"><i
                                                  class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="news_events()" class=""><span>News</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="career()" class=""><span>Career</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                          class="fa fa-angle-down"></i></span></li>

                                  <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="contact_us()" class=""><span>Contact
                                                  Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                      <a title="English" class=""><span><img class="wpml-ls-flag"
                                                      src="../assets/templates/home/en.png" alt="en" title="English"><span
                                                      class="wpml-ls-native">English</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span>
                                      <ul class="sub_menu">
                                          <li id="mobile-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                              <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                              src="../assets/templates/home/th.png" alt="th" title="ไทย"><span
                                                              class="wpml-ls-native">ไทย</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                          class="fa fa-angle-down"></i></span></li>
                                      </ul>
                                  </li>
                              </ul>
                          </nav>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  </header>
<a id="back_to_top" href="#" class="off">
    <span class="fa-stack">
        <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>



<div class="content content_top_margin_none">
    <div class="content_inner  ">
        <div class="title_outer title_without_animation" data-height="250">
            <div class="title title_size_small  position_left  has_fixed_background " style="background-size:1920px auto;background-image:url(../../../../assets/templates/home/BG-E-1920x600-B60.jpg);height:250px;">
                <div class="image not_responsive"><img itemprop="image" src="../../../../assets/templates/home/BG-E-1920x600-B60.jpg" alt="&nbsp;" /> </div>
                <div class="title_holder" style="padding-top:0;height:250px;">
                    <div class="container">
                        <div class="container_inner clearfix">
                            <div class="title_subtitle_holder">
                                <div class="title_subtitle_holder_inner">
                                    <h1><span>CSR</span></h1>

                                    <div class="breadcrumb" style="background-color: transparent;">
                                        <div class="breadcrumbs">
                                            <div itemprop="breadcrumb"><a (click)="home()">Home</a><span class="delimiter">&nbsp;>&nbsp;</span>
                                                <span class="current">CSR</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="full_width">
            <div class="full_width_inner">
                <div class="vc_row wpb_row section vc_row-fluid  grid_section" style='background-image:url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg); text-align:center;'>
                    <br><br><br><br>
                    <div class=" section_inner clearfix">
                        <div class='section_inner_margin clearfix'>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner vc_custom_1507478863624">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <img style="width:120px ;" src="../../../../assets/templates/home/DSST_Statement1.png" alt="">
                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div>


                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h2>CSR</h2>

                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style="height: 66px"><span class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div>


                                        <div class='latest_post_holder boxes four_columns five_rows'>
                                            <ul>

                                                <li class="clearfix" *ngFor="let item of mDataArray ; let i = index">
                                                    <div class="boxes_image">
                                                        <a itemprop="url">

                                                            <img style="height: 180px; width: 100%;" [src]="img[i]" class="attachment-latest_post_boxes size-latest_post_boxes wp-post-image" alt="" /></a>
                                                    </div>
                                                    <div class="latest_post" style="min-height: 250px;" style="padding: 15px 15px 5px !important">
                                                        <div class="latest_post_text">
                                                            <div class="latest_post_inner" style="min-height: 10px;">
                                                                <div class="latest_post_text_inner">
                                                                    <h5 style="overflow: hidden;
                                                                    text-overflow: ellipsis;
                                                                    display: -webkit-box;
                                                                    line-height: 25px;
                                                                    max-height: 100px;
                                                                    -webkit-line-clamp: 2; /* number of lines to show */
                                                                    -webkit-box-orient: vertical;
                                                                    font-family: Sarabun;
                                                                    height: 50px;
                                                                    font-size: 16px;
                                                                    font-weight: 700;


                                                                    " itemprop="name" class="latest_post_title entry_title">
                                                                        <a itemprop="url" [queryParams]="{
                                                                            id: item.event_id


                                                                          }" routerLinkActive="active" routerLink="/preview">{{item.event_name_en}}</a>

                                                                    </h5>

                                                                    <p itemprop="description" class="excerpt" style="
                                                                   line-height:1.5em;
                                                              height:4.5em;
                                                              overflow:hidden;


                                                                        " [innerHTML]="item.content_detail_en">
                                                                    </p>

                                                                </div>
                                                            </div>
                                                            <span class="post_infos">
                                                                <span class="date_hour_holder">
                                                                    <span itemprop="dateCreated"
                                                                    class="qode-bct-post-date entry_date updated" style="font-size: 12px;
                                                                    font-weight: 400;
                                                                color: grey;">
                                                                        {{item.published_date | date: 'd MMM y' }}
                                                                        <meta itemprop="interactionCount"
                                                                            content="UserComments: 0" />

                                                                    </span>
                                                            </span>
                                                            <div class="btn-group" mdbDropdown>
                                                                <button mdbDropdownToggle type="button" mdbBtn color="link" class="dropdown-toggle waves-light" mdbWavesEffect style="color:#949494;font-size: 12px;
                                                                      font-weight: 400;
                                                           ">
                                                                      <i class="fa fa-square"></i> Share
                                                                    </button>

                                                                <div class="dropdown-menu dropdown-primary" style="font-size: 14px;transform: translate3d(0,0px,0);;">
                                                                    <a class="dropdown-item" href="https://www.facebook.com/sharer.php?u={{link}}preview?id={{item.event_id}}" target="_blank"><i class="fa fa-facebook"></i>  Facebook</a>
                                                                    <a class="dropdown-item" href="https://twitter.com/share?url={{link}}preview?id={{item.event_id}}" target="_blank"><i class="fa fa-twitter"></i> Twitter</a>
                                                                    <a class="dropdown-item" href="https://plus.google.com/share?url={{link}}preview?id={{item.event_id}}" target="_blank"><i class="fa fa-google-plus"></i> Google +</a>

                                                                </div>
                                                            </div>


                                                            </span>

                                                        </div>
                                                    </div>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br><br><br>
                    </div>
                </div>


            </div>
        </div>



    </div>
</div>


<app-footer></app-footer>
