<title>Daicel Thailand | Admin</title>

<app-header-admin></app-header-admin>

<div class="container-fluid">
    <br />
    <section class="mb-5">
        <!-- Card -->

        <div class="card card-cascade narrower">
            <!-- Section: Table -->

            <section>
                <div class="card card-cascade narrower z-depth-0">
                    <div class="view view-cascade gradient-card-header blue-gradient narrower py-2 mx-6 mb-2 d-flex justify-content-between align-items-center">
                        <div class="text-center"></div>
                        <h1 class="white-text mx-1" style="    font-weight: 500;
            font-size: 25px;font-family: sarabun;">
                            <b> {{ content  | uppercase}}</b>
                        </h1>

                        <div>
                            <button [routerLink]="['/post']" type="button" class="btn btn-outline-white btn-sm" style="font-size: 14px;">
                <i class="fa fa-home"></i> Back to ALL CONTENT
              
              </button>
                        </div>
                    </div>

                    <div class="px-4">
                        <div class="table" style="font-size: 14px !important;">
                            <!--Table-->

                            <div class="row" style="padding-left: 50%;">
                                <div class="col">
                                    <form>
                                        <!-- Grid row -->
                                        <div class="form-row align-items-center">
                                            <!-- Grid column -->
                                            <div class="col-auto">
                                                <!-- Material input -->
                                                <label class="sr-only" for="inlineFormInputGroupMD">Publish Date</label
                        >
                        <div class="md-form input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text md-addon"
                              >Publish Date :&nbsp;</span
                            >
                          </div>

                          <input
                            *ngIf="c % 2 == 1"
                            mdbInput
                            type="date"
                            (change)="searchDB($event)"
                            class="form-control pl-0 rounded-0"
                            id="inlineFormInputGroupMD"
                            value=""
                          />

                          <input
                            *ngIf="c % 2 == 0"
                            mdbInput
                            type="date"
                            (change)="searchDB($event)"
                            class="form-control pl-0 rounded-0"
                            id="inlineFormInputGroupMD"
                          />
                          <button
                            style="
                              color: red;
                              background-color: Transparent;
                              background-repeat: no-repeat;
                              border: none;
                              cursor: pointer;
                              overflow: hidden;
                              outline: none;
                            "
                            (click)="clear()"
                          >
                            <i class="fas fa-backspace"></i>
                          </button>
                        </div>
                      </div>
                      <!-- Grid column -->
                      <!-- Grid column -->
                      <div class="col-auto">
                        <!-- Material input -->
                        <label class="sr-only" for="inlineFormInputGroupMD"
                          >Search</label
                        >
                        <div class="md-form input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text md-addon"
                              ><i class="fa fa-search" aria-hidden="true"></i>
                              :&nbsp;</span
                            >
                          </div>
                          <input
                            (keyup)="onSearch($event.target.value)"
                            mdbInput
                            type="text"
                            class="form-control pl-0 rounded-0"
                            id="inlineFormInputGroupMD"
                            placeholder="Search by Title "
                          />
                        </div>
                      </div>
                      <!-- Grid column -->
                    </div>
                    <!-- Grid row -->
                  </form>
                </div>
              </div>

              <table class="table table-hover mb-0">
                <!-- Table head -->
                <thead>
                  <tr>
                    <th style="text-align: center;width: 5%;font-weight: 700;
                    font-size: 16px;font-family:sarabun;">NO.</th>
                    <th style="text-align: center;width: 25%;font-weight: 700;
                    font-size: 16px;font-family:sarabun;
                    ">TITLE TH</th>
                    <th style="text-align: center;width: 25%;font-weight: 700;
                    font-size: 16px;font-family:sarabun;">TITLE EN</th>
                   
                   <th style="text-align: center;width: 20%;font-weight: 700;
                   font-size: 16px;font-family:sarabun;">LAST MODIFIED</th>
                    <th style="text-align: center;width: 10%;font-weight: 700;
                    font-size: 16px;font-family:sarabun;">PUBLISH DATE</th>
                     <th style="text-align: center;width: 10%;font-weight: 700;
                     font-size: 16px;font-family:sarabun;width: 180px !important;">SELECT</th>
                  </tr>
                </thead>
                <!-- Table head -->

                <!-- Table body -->
                <tbody>
                  <tr *ngFor="let item of mDataArray; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td style="text-align: left;">{{ item.event_name_th }}</td>
                    <td style="text-align: left;">{{ item.event_name_en }}</td>
              
                    <td>{{ item.post_date | date: "dd/MM/yyyy" }}</td>
                    <td>{{ item.published_date | date: "dd/MM/yyyy" }}</td>
                    <td style="text-align: center;">
                      <button
                        *ngIf="item.active == 2"
                        type="button"
                        class="btn btn-outline-success btn-rounded btn-sm px-2"
                      >
                        <i class="fas fa-check"></i>
                      </button>

                      <button
                        *ngIf="item.active == 1"
                        type="button"
                        class="btn btn-outline-primary btn-rounded btn-sm px-2"
                        (click)="editItem(item.event_id, type)"
                        type="button"
                      >
                        Choose
                      </button>
                    </td>
                  </tr>
                </tbody>
                <!-- Table body -->
                <br />
              </table>
              <!-- Table -->
            </div>

            <hr class="my-0" />
          </div>
        </div>
      </section>
      <!--Section: Table-->
    </div>
    <!-- Card -->
  </section>
  <!-- Section: Main panel -->
</div>