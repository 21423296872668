import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { setTheme } from "ngx-bootstrap/utils";
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { ContentService } from "src/app/services/content.service";
import { contentModel } from "src/app/models/content";
import { NgForm } from "@angular/forms";
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { ImagesService } from "src/app/services/images.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { formatDate } from "@angular/common";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Router } from "@angular/router";
import { LocalStorageService } from "../local-storage.service";
type objField = { name: string; age: string; sex: string };
@Component({
  selector: "app-post",
  templateUrl: "./post.component.html",
  styleUrls: ["./post.component.css"],
})
export class PostComponent implements OnInit {
  modalRef?: BsModalRef;
  model = "some text";

  valid1 = false;
  valid2 = false;
  valid3 = false;

  config = {
    animated: true,
    // backdrop: "static",
    keyboard: false,
    class: "modal-lg",
  };
  node_static_url = `${environment.backendUrl}`;
  public contentValid: boolean | null;
  public content: contentModel;
  public content1: contentModel[];
  isSubmitted = false;
  @ViewChild("contentForm") public contentForm: NgForm;
  @ViewChild("editorTh") editorTh;
  @ViewChild("editorEn") editorEn;

  description: string = "";
  description1: string = "";
  descriptionEdit: string = "";
  description1Edit: string = "";

  constructor(
    private modalService: BsModalService,
    private cd: ChangeDetectorRef,
    private contentService: ContentService,
    private imagesService: ImagesService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public storage: LocalStorageService
  ) {
    setTheme("bs4");
  }

  fields = {
    event_name_en: "",
    published_date: "",
    com_news: "",
    csr: "",
    events: "",
  };
  filter1 = {};
  filter = {};

  updateFilters() {
    Object.keys(this.fields).forEach((key) =>
      this.fields[key] === "" ? delete this.fields[key] : key
    );
    this.filter = Object.assign({}, this.fields);
  }

  ngOnInit(): void {
    this.content = {};
    // this.content1 = {};
    this.contentValid = true;
    this.feedData();
  }

  stud = [
    {
      name: "All",
      id: 0,
    },
    {
      name: "Company News",
      id: 1,
    },
    {
      name: "CSR",
      id: 2,
    },
    {
      name: "Events",
      id: 3,
    },
  ];
  selected: any = 0;

  valueChange(event) {
    this.fields.com_news = "";
    this.fields.csr = "";
    this.fields.events = "";
    if (this.selected == 0) {
      this.fields.com_news = "";
      this.fields.csr = "";
      this.fields.events = "";
    }
    if (this.selected == 1) {
      this.fields.com_news = "1";
    }
    if (this.selected == 2) {
      this.fields.csr = "1";
    }
    if (this.selected == 3) {
      this.fields.events = "1";
    }
    this.updateFilters();
  }
  showImage(url: string) {
    Swal.fire({
      imageUrl: url,
    });
  }
  async feedData() {
    this.content1 = [];
    let res = await this.contentService.find().toPromise();
    this.splitData(res);
  }

  async splitData(res: any) {
    this.content1 = [];
    for (let i = 0; i < res.length; i++) {
      let res1 = await this.imagesService
        .findByIdHeader(res[i]["event_id"] + "")
        .toPromise();
      const obj = JSON.parse(JSON.stringify(res1));

      this.content1.push({
        active: res[i].active,
        com_news: res[i].com_news,
        event_name_en: res[i].event_name_en,
        event_name_th: res[i].event_name_th,
        event_datetime: res[i].event_datetime,
        event_id: res[i].event_id,
        events: res[i].events,
        csr: res[i].csr,
        published_date: res[i].published_date,
        post_date: res[i].post_date,
        img_header: `${environment.backendUrl}` + obj.img_header,
      });
    }
    console.log(this.content1);
    // this.pagedItems = []
    // this.pagedItems = this.content1?.slice(0,10);
    // this.cd.detectChanges();
  }

  pagedItems = [];

  itemsPerPage = 10;
  currentPage = 1;

  setPage(page: number) {
    this.currentPage = page;
    this.cd.detectChanges();
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.pagedItems = this.content1?.slice(startItem, endItem); //Retrieve items for page
    this.cd.detectChanges();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  async edit(template: TemplateRef<any>, id: any) {
    this.content = await this.contentService.findById(id + "").toPromise();

    this.description = this.content.content_detail_th;
    this.description1 = this.content.content_detail_en;

    if (this.content.com_news == 1) {
      this.content.type = 0;
    }
    if (this.content.csr == 1) {
      this.content.type = 1;
    }
    if (this.content.events == 1) {
      this.content.type = 2;
    }

    console.log(this.content);
    const v = await this.imagesService.findByIdHeader(id + "").toPromise();
    const obj = JSON.parse(JSON.stringify(v));
    this.filename = obj.img_header;
    this.imageSrc = this.node_static_url + obj.img_header;

    const allImg = await this.imagesService.findByIdAll(id + "").toPromise();
    const obj1 = JSON.parse(JSON.stringify(allImg));
    this.setfileuploads(obj1.img_all);

    this.modalRef = this.modalService.show(template, this.config);
  }

  setStyle(style: string) {
    let bool = document.execCommand(style, false, null);
  }

  onChange(e) {
    this.descriptionEdit = e.target.innerHTML;
  }
  onChange1(e) {
    this.description1Edit = e.target.innerHTML;
  }

  filename = "Choose file";
  imgname: string = "";
  size: string = "";
  type: string = "";
  imageSrc: string = "";
  async fileHeader(event) {
    const file = event.target.files[0];
    if (file) {
      const image = {
        name: "",
        type: "",
        size: "",
        imgname: "",
        url: "",
      };

      //Upload IMG Baqckend
      const formData = new FormData();
      formData.append("image", file);
      //Insert File
      const a = await this.imagesService.addImg(formData).toPromise();
      this.filename = a["message"];
      this.imgname = a["imgname"];
      this.size = a["size"];
      this.type = a["type"];

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.imageSrc = event.target.result;
      };
    }
  }

  async copy(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, copy it!",
    }).then(async (result) => {
      if (result.value) {
        let content = await this.contentService.findById(id).toPromise();
        let data = {
          event_name_th: content.event_name_th,
          event_name_en: content.event_name_en + " - Copy",
          content_detail_th: content.content_detail_th,
          content_detail_en: content.content_detail_en,
          event_datetime: formatDate(new Date(), "yyyy-MM-dd", "en"),
          published_date: formatDate(new Date(), "2500-01-01", "en"),
          post_by: "admin",
          csr: "0",
          com_news: "0",
          events: "0",
          active: 1,
        };

        await this.contentService.addContent(data).toPromise();
        Swal.fire({
          title: "Copy successfully",
          text: "Click close button to back to the Post page",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Close",
          confirmButtonColor: "#3085d6",
        }).then((result) => {
          this.feedData();
        });
      }
    });
  }

  async setfileuploads(event) {
    this.images = [];
    this.allfiles = [];
    for (let i = 0; i < event.length; i++) {
      const image = {
        name: "",
        type: "",
        size: "",
        imgname: "",
        url: "",
        path: "",
      };
      image.name = event[i];
      image.url = this.node_static_url + event[i];
      image.path = event[i];
      this.images.push(image);
      this.allfiles.push(image);
    }
  }

  async deleteItem(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        await this.contentService.delete(id).toPromise();

        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Close",
          confirmButtonColor: "#3085d6",
        }).then((result) => {
          this.updateMainContent();
          this.feedData();
        });
      }
    });
  }

  imagePreview = "";
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  rawFileArray = [];
  base64ImgArray = [];

  images: any = [];
  allfiles: any = [];

  async fileuploads(event) {
    const files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const image = {
          name: "",
          type: "",
          size: "",
          imgname: "",
          url: "",
        };
        this.allfiles.push(files[i]);

        //Upload IMG Baqckend
        const formData = new FormData();
        formData.append("image", files[i]);
        //Insert File
        const a = await this.imagesService.addImg(formData).toPromise();
        // Show IMG
        image.name = a.message;
        image.imgname = a.imgname;
        image.type = files[i].type;
        image.size = files[i].size;
        const reader = new FileReader();

        reader.onload = (filedata) => {
          image.url = reader.result + "";
          this.images.push(image);
        };
        reader.readAsDataURL(files[i]);
      }
    }
    event.srcElement.value = null;
  }

  async deleteImage(image: any) {
    const index = this.images.indexOf(image);
    this.images.splice(index, 1);
    this.allfiles.splice(index, 1);
    await this.imagesService.delete(image.name).toPromise();
  }

  mDataArray: any[] = [];
  all_id = [];
  async updateMainContent() {
    let type = ["com_news", "csr", "events"];
    let formData = new FormData();
    type.forEach(async (element) => {
      this.mDataArray = await this.contentService
        .getContentByType(element)
        .toPromise();

      this.all_id = [];
      this.mDataArray.forEach(async (element1) => {
        this.all_id.push(element1["event_id"]);
        formData.append("id", element1["event_id"] + "");
        formData.append("type", element);
        formData.append("active", "1");
        await this.contentService.updateContent(formData).toPromise();
      });
      await this.contentService.autoMainContent().toPromise();
    });
  }

  cancel() {
    this.modalRef?.hide();
    this.description = "";
    this.description1 = "";
    this.imageSrc = "";
    this.content = {};
    this.images = [];
    this.allfiles = [];
    this.valid1 = false;
    this.valid2 = false;
    this.valid3 = false;
  }
  async onClickSignOut() {
    // await this.rest.deleteCoockie()
    await this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  checkRadio() {
    this.valid1 = false;
  }
  checkTitleTh() {
    if (this.content.event_name_th.length > 0) {
      this.valid2 = false;
    } else {
      this.valid2 = true;
    }
  }
  checkTitleEn() {
    if (this.content.event_name_en.length > 0) {
      this.valid3 = false;
    } else {
      this.valid3 = true;
    }
  }

  async submitForm() {
    this.contentValid = true;
    if (this.content.type == null) {
      this.valid1 = true;
      this.contentValid = false;
    }
    if (this.content.event_name_th == null) {
      this.valid2 = true;
      this.contentValid = false;
    }
    if (this.content.event_name_en == null) {
      this.valid3 = true;
      this.contentValid = false;
    }
    if (!this.contentValid) {
      Swal.fire({
        title: "Warning!",
        text: "Data is not correct. Please re-check again.",
        icon: "warning",
        confirmButtonColor: "#20a8d8",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      return;
    } else {
      Swal.fire({
        title: "Do you confirm?",
        text: "Please check data, then click 'Confirm'.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, confirm it!",
      }).then(async (result) => {
        if (result.value) {
          let csr = 0;
          let com_news = 0;
          let events = 0;
          if (this.content.type == "0") {
            com_news = 1;
          }
          if (this.content.type == "1") {
            csr = 1;
          }
          if (this.content.type == "2") {
            events = 1;
          }

          let data = {
            event_name_th: this.content.event_name_th,
            event_name_en: this.content.event_name_en,
            content_detail_th: this.descriptionEdit,
            content_detail_en: this.description1Edit,
            event_datetime: formatDate(new Date(), "yyyy-MM-dd", "en"),
            published_date: formatDate(new Date(), "yyyy-MM-dd", "en"),
            post_by: "admin",
            csr: csr,
            com_news: com_news,
            events: events,
            active: 1,
          };

          let formData = new FormData();
          formData.append("id", this.content.event_id + "");
          formData.append("event_name_th", this.content.event_name_th);
          formData.append("event_name_en", this.content.event_name_en);
          formData.append("content_detail_th", this.descriptionEdit);
          formData.append("content_detail_en", this.description1Edit);
          formData.append(
            "post_date",
            formatDate(new Date(), "yyyy-MM-dd", "en")
          );
          formData.append("post_by", "admin");
          formData.append(
            "published_date",
            formatDate(new Date(), "yyyy-MM-dd", "en")
          );
          formData.append("csr", csr + "");
          formData.append("com_news", com_news + "");
          formData.append("events", events + "");
          console.log(this.content);
          let a: any;
          if (this.content["event_id"] > 0) {
            a = await this.contentService.updateContent(formData).toPromise();
            if (this.size == "") {
            } else {
              let imgHead = new FormData();
              imgHead.append("event_id", this.content["event_id"] + "");
              imgHead.append("image_storage", this.filename);
              imgHead.append("image_name", this.imgname);
              imgHead.append("image_size", this.size);
              imgHead.append("image_type", this.type);
              imgHead.append("image_header", "1");
              await this.imagesService.addImgdb(imgHead).toPromise();
            }
            for (var i = 0; i < this.images.length; i++) {
              if (this.images[i].size == "") {
              } else {
                let imgHead = new FormData();
                imgHead.append("event_id", this.content["event_id"] + "");
                imgHead.append("image_storage", this.images[i].name);
                imgHead.append("image_name", this.images[i].imgname);
                imgHead.append("image_size", this.images[i].size);
                imgHead.append("image_type", this.images[i].type);
                await this.imagesService.addImgdb(imgHead).toPromise();
              }
            }
          } else {
            a = await this.contentService.addContent(data).toPromise();
            let id = a.message;
            let imgHead = new FormData();
            imgHead.append("event_id", id);
            imgHead.append("image_storage", this.filename);
            imgHead.append("image_name", this.imgname);
            imgHead.append("image_size", this.size);
            imgHead.append("image_type", this.type);
            imgHead.append("image_header", "1");
            await this.imagesService.addImgdb(imgHead).toPromise();

            for (var i = 0; i < this.images.length; i++) {
              let imgHead = new FormData();
              imgHead.append("event_id", id);
              imgHead.append("image_storage", this.images[i].name);
              imgHead.append("image_name", this.images[i].imgname);
              imgHead.append("image_size", this.images[i].size);
              imgHead.append("image_type", this.images[i].type);
              await this.imagesService.addImgdb(imgHead).toPromise();
            }
          }

          Swal.fire({
            title: "Completed!",
            text: "Transaction is success.",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Close",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            this.updateMainContent();
            this.feedData();
            this.cancel();
          });
        }
      });
    }
  }

  imgHeader: string;
  async previewEN() {
    let imgName = [];
    for (var i = 0; i < this.images.length; i++) {
      imgName.push(this.node_static_url + this.images[i].name);
    }

    let comNews = "false";
    let csr = "false";
    let event = "false";
    if (this.content.type == "0") {
      comNews = "true";
    }
    if (this.content.type == "1") {
      csr = "true";
    }
    if (this.content.type == "2") {
      event = "true";
    }
    this.imgHeader = this.filename;
    this.storage.set("user_object", {
      language: "EN",
      title: this.content.event_name_en,
      titleTh: this.content.event_name_th,
      detailTh: this.description,
      detail: this.description1,
      comNews: comNews,
      csr: csr,
      event: event,
      imgHeader: this.filename,
      imgName: imgName,
      dateActivity: formatDate(new Date(), "yyyy-MM-dd", "en"),
      datePublish: formatDate(new Date(), "yyyy-MM-dd", "en"),
    });
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/preview-save`])
    );

    window.open(url, "_blank");
  }
  async previewTH() {
    let imgName = [];
    for (var i = 0; i < this.images.length; i++) {
      imgName.push(this.node_static_url + this.images[i].name);
    }

    let comNews = "false";
    let csr = "false";
    let event = "false";
    if (this.content.type == "0") {
      comNews = "true";
    }
    if (this.content.type == "1") {
      csr = "true";
    }
    if (this.content.type == "2") {
      event = "true";
    }
    this.imgHeader = this.filename;
    this.storage.set("user_object", {
      title: this.content.event_name_en,
      titleTh: this.content.event_name_th,
      detailTh: this.description,
      detail: this.description1,
      comNews: comNews,
      csr: csr,
      event: event,
      imgHeader: this.filename,
      imgName: imgName,
      dateActivity: formatDate(new Date(), "yyyy-MM-dd", "en"),
      datePublish: formatDate(new Date(), "yyyy-MM-dd", "en"),
    });
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/th/preview-save`])
    );

    window.open(url, "_blank");
  }
}
