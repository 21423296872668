<div class="wrapper fadeInDown">
    <div id="formContent">

        <br>
        <div class="fadeIn first">
            <img src="../assets/templates/home/DSST-Logo-300x70.png" id="icon" alt="User Icon" />
        </div>

        <!-- <h2 class="active"> ADMIN LOGIN</h2> -->
        <br>
        <form id="login-form" (ngSubmit)="loginForm.form.valid && loginSubmit()" #loginForm="ngForm" novalidate>
            <input type="text" name="username" class="fadeIn second" placeholder="Username" autocomplete="username" [(ngModel)]="login.username" #username="ngModel" [ngClass]="{ 'is-invalid': (username.errors && !loginValid) || (username.errors && username.touched) }"
                required>


            <input type="password" name="password" class="fadeIn third" placeholder="Password" autocomplete="current-password" [(ngModel)]="login.password" #password="ngModel" [ngClass]="{ 'is-invalid': (password.errors && !loginValid) || (password.errors && password.touched) }"
                required>

            <br>
            <input type="submit" class="fadeIn fourth" form="login-form" [disabled]="loginForm.form.invalid" value="LOGIN">

        </form>


        <div id="formFooter">

        </div>

    </div>
</div>
