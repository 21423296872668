<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark white lighten-1" [containerInside]="false">

  <!-- Navbar brand -->
  <mdb-navbar-brand>
      <a (click)="home()" class="navbar-brand">
          <img src="../assets/templates/home/DSST-Logo-300x70.png" height="70px" width="200px">
      </a>
  </mdb-navbar-brand>


  <!-- Collapsible content -->
  <links>
      <ul class="navbar-nav ml-auto nav-flex-icons">

          <li class="nav-item avatar dropdown" dropdown>
              <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect style="color: black;font-size:18px">
                  <img src="../../../assets/image/admin.png" alt="" class="img-fluid rounded-circle z-depth-0" width="50px" height="150px"> {{name.username}}
              </a>
              <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu" style="width: 350px;">

                  <!--Card-->
                  <mdb-card cascade="true">

                      <!--Card image-->
                      <!-- <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                          <mdb-card-img src="../../../assets/image/head.jpg"></mdb-card-img>
                          <a>
                              <div class="mask rgba-white-slight"></div>
                          </a>
                      </div> -->
                      <!--/Card image-->

                      <mdb-card-body cascade="true" class="text-center">
                          <h4 class="card-title">
                              <strong> {{name.username}}  </strong>
                          </h4>

                          <mdb-card-text>
                              Common DSST
                          </mdb-card-text>

                          <a mdbBtn color="green" class="waves-light" mdbWavesEffect (click)="onClickSignOut()">logout</a>


                      </mdb-card-body>
                      <!--/.Card content-->

                  </mdb-card>
                  <!--/.Card-->

                  <!-- <a class="dropdown-item waves-light" mdbWavesEffect href="#">My account</a>
                          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Log out</a> -->
              </div>
          </li>
      </ul>
  </links>
  <!-- Collapsible content -->



</mdb-navbar>


