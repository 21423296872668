import { Component, OnInit } from '@angular/core';
import Swiper from 'swiper';
@Component({
  selector: 'app-slide-footer',
  templateUrl: './slide-footer.component.html',
  styleUrls: ['./slide-footer.component.css']
})
export class SlideFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
  imageObject = [{
    image: '../../../../assets/templates/home/HONDA-D-323x165.png',
    thumbImage: '../../../../assets/templates/home/HONDA-D-323x165.png',

}, {
    image: '../../../../assets/templates/home/LEXUS-G-323x165.png',
    thumbImage: '../../../../assets/templates/home/LEXUS-G-323x165.png'
}, {
    image: '../../../../assets/templates/home/MAZDA-H-323x165.png',
    thumbImage: '../../../../assets/templates/home/MAZDA-H-323x165.png',

},{
    image: '../../../../assets/templates/home/MITSUBISHI-I-323x165.png',
    thumbImage: '../../../../assets/templates/home/MITSUBISHI-I-323x165.png',

}, {
    image: '../../../../assets/templates/home/NISSAN-J-323x165.png',
    thumbImage: '../../../../assets/templates/home/NISSAN-J-323x165.png'
}, {
    image: '../../../../assets/templates/home/SUBARU-L-323x165.png',
    thumbImage: '../../../../assets/templates/home/SUBARU-L-323x165.png',

},
{
  image: '../../../../assets/templates/home/SUZUKI-M-323x165.png',
  thumbImage: '../../../../assets/templates/home/SUZUKI-M-323x165.png',

},
{
  image: '../../../../assets/templates/home/Audi-A-323x165.png',
  thumbImage: '../../../../assets/templates/home/Audi-A-323x165.png',

},

{
  image: '../../../../assets/templates/home/BUICK-B-323x165.png',
  thumbImage: '../../../../assets/templates/home/BUICK-B-323x165.png',

},
{
  image: '../../../../assets/templates/home/CHECROLET-C-323x165.png',
  thumbImage: '../../../../assets/templates/home/CHECROLET-C-323x165.png',

},

{
  image: '../../../../assets/templates/home/CITROEN-D-323x165.png',
  thumbImage: '../../../../assets/templates/home/CITROEN-D-323x165.png',

},

{
  image: '../../../../assets/templates/home/DAEWOO-A-323x165.png',
  thumbImage: '../../../../assets/templates/home/DAEWOO-A-323x165.png',

},

{
  image: '../../../../assets/templates/home/HYUNDAI-B-323x165.png',
  thumbImage: '../../../../assets/templates/home/HYUNDAI-B-323x165.png',

},

{
  image: '../../../../assets/templates/home/KIA-MOTORS-C-323x165.png',
  thumbImage: '../../../../assets/templates/home/KIA-MOTORS-C-323x165.png',

},

{
  image: '../../../../assets/templates/home/SSANG-YOUG-D-323x165.png',
  thumbImage: '../../../../assets/templates/home/SSANG-YOUG-D-323x165.png',

},

{
  image: '../../../../assets/templates/home/FORD-E-323x165.png',
  thumbImage: '../../../../assets/templates/home/FORD-E-323x165.png',

},

{
  image: '../../../../assets/templates/home/GM-F-323x165.png',
  thumbImage: '../../../../assets/templates/home/GM-F-323x165.png',

},

{
  image: '../../../../assets/templates/home/LOTUS-G-323x165.png',
  thumbImage: '../../../../assets/templates/home/LOTUS-G-323x165.png',

},

{
  image: '../../../../assets/templates/home/PEUGEOT-H-232x165.png',
  thumbImage: '../../../../assets/templates/home/PEUGEOT-H-232x165.png',

},
{
  image: '../../../../assets/templates/home/SKODA-I-323x165.png',
  thumbImage: '../../../../assets/templates/home/SKODA-I-323x165.png',

},
{
  image: '../../../../assets/templates/home/TEALA-J-323x165.png',
  thumbImage: '../../../../assets/templates/home/TEALA-J-323x165.png',

},
{
  image: '../../../../assets/templates/home/VOLKSWAGEN-K-323x165.png',
  thumbImage: '../../../../assets/templates/home/VOLKSWAGEN-K-323x165.png',

},
{
  image: '../../../../assets/templates/home/BAIC-GROUP-A-323x165.png',
  thumbImage: '../../../../assets/templates/home/BAIC-GROUP-A-323x165.png',

},
{
  image: '../../../../assets/templates/home/CHANGAN-B-323x165.png',
  thumbImage: '../../../../assets/templates/home/CHANGAN-B-323x165.png',

},
{
  image: '../../../../assets/templates/home/CHERY-C-323x165.png',
  thumbImage: '../../../../assets/templates/home/CHERY-C-323x165.png',

},
{
  image: '../../../../assets/templates/home/DFM-D-323x165.png',
  thumbImage: '../../../../assets/templates/home/DFM-D-323x165.png',

},
{
  image: '../../../../assets/templates/home/FAM-E-323x165.png',
  thumbImage: '../../../../assets/templates/home/FAM-E-323x165.png',

},
{
  image: '../../../../assets/templates/home/FOTON-F-323x165.png',
  thumbImage: '../../../../assets/templates/home/FOTON-F-323x165.png',

},

{
  image: '../../../../assets/templates/home/GEELY-G-323x165.png',
  thumbImage: '../../../../assets/templates/home/GEELY-G-323x165.png',

},

{
  image: '../../../../assets/templates/home/GREAT-WALL-H-323x165.png',
  thumbImage: '../../../../assets/templates/home/GREAT-WALL-H-323x165.png',

},

{
  image: '../../../../assets/templates/home/HAIMA-I-232x165.png',
  thumbImage: '../../../../assets/templates/home/HAIMA-I-232x165.png',

},

{
  image: '../../../../assets/templates/home/JAC-J-232x165.png',
  thumbImage: '../../../../assets/templates/home/JAC-J-232x165.png',

},

{
  image: '../../../../assets/templates/home/JINBEI-K-323x165.png',
  thumbImage: '../../../../assets/templates/home/JINBEI-K-323x165.png',

},

{
  image: '../../../../assets/templates/home/LIFAN-L-323x165.png',
  thumbImage: '../../../../assets/templates/home/LIFAN-L-323x165.png',

},

{
  image: '../../../../assets/templates/home/LUXGEN-M-323x165.png',
  thumbImage: '../../../../assets/templates/home/LUXGEN-M-323x165.png',

},

{
  image: '../../../../assets/templates/home/ROEWE-N-323x165.png',
  thumbImage: '../../../../assets/templates/home/ROEWE-N-323x165.png',

},

{
  image: '../../../../assets/templates/home/SAIC-O-323x165.png',
  thumbImage: '../../../../assets/templates/home/SAIC-O-323x165.png',

},

{
  image: '../../../../assets/templates/home/ZOTYE-P-323x165.png',
  thumbImage: '../../../../assets/templates/home/ZOTYE-P-323x165.png',

},

{
  image: '../../../../assets/templates/home/ACURA-A-323x165.png',
  thumbImage: '../../../../assets/templates/home/ACURA-A-323x165.png',

},
{
  image: '../../../../assets/templates/home/DAIHATSU-B-323x165.png',
  thumbImage: '../../../../assets/templates/home/DAIHATSU-B-323x165.png',

},
{
  image: '../../../../assets/templates/home/TOYOTA-N-323x165.png',
  thumbImage: '../../../../assets/templates/home/TOYOTA-N-323x165.png',

},
{
  image: '../../../../assets/templates/home/ISUZU-F-323x165.png',
  thumbImage: '../../../../assets/templates/home/ISUZU-F-323x165.png',

},
{
  image: '../../../../assets/templates/home/FUSO-C-323x165.png',
  thumbImage: '../../../../assets/templates/home/FUSO-C-323x165.png',

},
{
  image: '../../../../assets/templates/home/INFINITI-E-323x165.png',
  thumbImage: '../../../../assets/templates/home/INFINITI-E-323x165.png',

}


];

}
