import { Location } from "@angular/common";
import Swal from "sweetalert2";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ContentService } from "src/app/services/content.service";
import { UserModel } from "../../models/user";
import { contentModel } from "../../models/content";
import { imagesModel } from "../../models/images";
import { ImagesService } from "src/app/services/images.service";
import {
  FormArray,
  AbstractControl,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-career-add",
  templateUrl: "./career-add.component.html",
  styleUrls: ["./career-add.component.css"],
})
export class CareerAddComponent implements OnInit {
  public readonly USERLOGIN: UserModel =
    this.authenticationService.currentUserValue;
  public content: contentModel;

  form: any;
  w1: string = "";
  w2: string = "";
  titleEn: string = "";
  detailEn: string = "";
  titleTh: string = "";
  detailTh: string = "";
  dateActivity: string = "";
  datePublish: string = "";
  comNews: string = "";
  csr: string = "";
  event: string = "";
  submitted = false;
  type = false;
  j1: number = 1;
  j2: number = 1;
  num_position: number = 1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private contentService: ContentService,
    private imagesService: ImagesService,
    private http: HttpClient,
    private location: Location,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      id: new FormControl("", Validators.required),
      titleTh: new FormControl("", Validators.required),
      titleEn: new FormControl("", Validators.required),
      detailTh: new FormControl("", Validators.required),
      detailEn: new FormControl("", Validators.required),
      dateActivity: new FormControl("", Validators.required),
      datePublish: new FormControl("", Validators.required),
      num_position: new FormControl("", Validators.required),
      selectedLocation: new FormControl("Prachinburi", Validators.required),
    });
  }

  async ngOnInit() {}

  cities = [
    { id: 1, name: "Prachinburi" },
    { id: 2, name: "Saraburi" },
  ];

  words2 = [
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
  ];

  words1 = [
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
  ];

  async previewEN() {
    let active = 0;
    if (this.template == true) {
      active = 3;
    } else if (this.template == false) {
      active = 4;
    } else {
      active = 4;
    }
    this.titleEn = this.form.value.titleEn;
    this.titleTh = this.form.value.titleTh;
    this.num_position = this.form.value.num_position;
    this.setValue();

    localStorage.setItem("titleEn", this.titleEn);
    localStorage.setItem("titleTh", this.titleTh);
    localStorage.setItem("detailEn", this.detailEn);
    localStorage.setItem("detailTh", this.detailTh);

    window.open(
      "preview-career?type=" +
        active +
        "&num=" +
        this.num_position +
        "&location=" +
        this.selectedLocation,
      "_blank" // <- This is what makes it open in a new window.
    );
  }

  setValue() {
    this.detailEn = null;
    this.detailTh = null;
    this.j1 = 1;
    this.j2 = 1;

    if (this.form.value.selectedLocation.name != undefined) {
      this.selectedLocation = this.form.value.selectedLocation.name;
    } else if (this.form.value.selectedLocation.name == undefined) {
      this.selectedLocation = "Prachinburi";
    }

    for (let i = 0; i < this.words1.length; i++) {
      if (this.words1[i]["values"] != "") {
        if (this.words1[i]["values"] != " ") {
          this.detailEn =
            this.detailEn + this.j1 + ". " + this.words1[i]["values"] + "<br>";
          this.j1 += 1;
        }
      }
    }
    for (let i = 0; i < this.words2.length; i++) {
      if (this.words2[i]["values"] != "") {
        if (this.words2[i]["values"] != " ") {
          this.detailTh =
            this.detailTh + this.j2 + ". " + this.words2[i]["values"] + "<br>";
          this.j2 += 1;
        }
      }
    }
  }

  template: any;
  active: any;
  sumJ1: string;
  async save_post() {
    this.setValue();
    if (
      this.form.value.titleTh != "" &&
      this.num_position != 0 &&
      this.form.value.titleEn != "" &&
      this.detailTh != "" &&
      this.detailEn != ""
    ) {
      if (this.template == true) {
        this.active = 3;
      } else if (this.template == false) {
        this.active = 4;
      } else {
        this.active = 4;
      }

      let data = {
        event_name_th: this.form.value.titleTh + "<br>" + this.num_position + "<br>" +this.selectedLocation,
        event_name_en: this.form.value.titleEn,
        content_detail_th: this.detailTh,
        content_detail_en: this.detailEn,
        event_datetime: formatDate(new Date(), "yyyy-MM-dd", "en"),
        published_date: formatDate(new Date(), "yyyy-MM-dd", "en"),
        post_by: "admin",
        csr: "0",
        com_news: "0",
        events: "0",
        active: this.active,
      };

      await this.contentService.addContent(data).toPromise();
      Swal.fire({
        title: "Create successfully",
        text: "Click close button to back to the Post page",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Close",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        this.location.back();
      });
    }

    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
  }

  get f() {
    return this.form.controls;
  }

  selectedLocation = "";
  async save() {
    this.setValue();
    if (
      this.form.value.titleTh != "" &&
      this.num_position != 0 &&
      this.form.value.titleEn != "" &&
      this.detailTh != "" &&
      this.detailEn != ""
    ) {
      if (this.template == true) {
        this.active = 3;
      } else if (this.template == false) {
        this.active = 4;
      } else {
        this.active = 4;
      }
      let data = {
        event_name_th: this.form.value.titleTh + "<br>" + this.num_position + "<br>" +this.selectedLocation,
        event_name_en: this.form.value.titleEn,
        content_detail_th: this.detailTh,
        content_detail_en: this.detailEn,
        event_datetime: formatDate(new Date(), "yyyy-MM-dd", "en"),
        published_date: formatDate(new Date(), "2500-01-01", "en"),
        post_by: "admin",
        csr: "0",
        com_news: "0",
        events: "0",
        active: this.active,
      };
      await this.contentService.addContent(data).toPromise();
      Swal.fire({
        title: "Create successfully",
        text: "Click close button to back to the Post page",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Close",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        this.location.back();
      });
    }
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
  }
}
