import { Component, OnInit, AfterViewInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { ContentService } from "src/app/services/content.service";
import { ImagesService } from "src/app/services/images.service";
@Component({
  selector: "app-home-page-en",
  templateUrl: "./home-page-en.component.html",
  styleUrls: ["./home-page-en.component.scss"],
})
export class HomePageEnComponent implements OnInit {
  baseUrl: string = environment.baseUrl;
  node_static_url: string = environment.backendUrl;
  com_id;
  com_news;
  com_news_detail;
  com_img;
  com_date;
  csr_id;
  csr;
  csr_img;
  csr_detail;
  csr_date;
  events;
  events_img;
  events_id;
  events_detail;
  events_date;
  mobile = false;
  lastest: any[] = [];
  img: any[] = [];
  show = false;
  constructor(
    private rest: ContentService,
    private imageService: ImagesService,
    private router: Router
  ) {}

  async ngOnInit() {
    if (window.screen.width < 640) {
      // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    this.main();
    this.last();
  }

  async main() {
    const res1 = await this.rest.getContentLastest().toPromise();
    let data = [];
    data.push(
      res1
        .filter((item) => item.com_news === 1)
        .sort((a, b) => b.published_date - a.published_date)[0]
    );
    data.push(
      res1
        .filter((item) => item.csr === 1)
        .sort((a, b) => b.published_date - a.published_date)[0]
    );
    data.push(
      res1
        .filter((item) => item.events === 1)
        .sort((a, b) => b.published_date - a.published_date)[0]
    );

    for (let i = 0; i < data.length; i++) {
      console.log(data[i]);
      if (data[i].com_news == 1) {
        this.com_id = data[i].event_id;
        this.com_news = data[i].event_name_en;
        this.com_news_detail = data[i].content_detail_en;
        this.com_date = data[i].post_date;
        const v = await this.imageService
          .findByIdHeader(this.com_id)
          .toPromise();
        this.com_img = this.node_static_url + v["img_header"];
      }
      if (data[i].csr == 1) {
        this.csr_id = data[i].event_id;
        this.csr = data[i].event_name_en;
        this.csr_detail = data[i].content_detail_en;
        this.csr_date = data[i].post_date;
        const v = await this.imageService
          .findByIdHeader(this.csr_id)
          .toPromise();
        this.csr_img = this.node_static_url + v["img_header"];
      }
      if (data[i].events == 1) {
        this.events_id = data[i].event_id;
        this.events = data[i].event_name_en;
        this.events_detail = data[i].content_detail_en;
        this.events_date = data[i].post_date;
        const v = await this.imageService
          .findByIdHeader(this.events_id)
          .toPromise();
        this.events_img = this.node_static_url + v["img_header"];
      }
    }

    // console.log(data);
    // const res = await this.rest.getMainContentByType().toPromise();
    // for (let index = 0; index < res.length; index++) {
    //   const element = res[index];
    //   const values = Object.keys(element).map((key) => element[key]);
    //   const commaJoinedValues = values;
    //   if (index == 0) {
    //     this.com_id = commaJoinedValues[0].event_id;
    //     this.com_news = commaJoinedValues[0].event_name_en;
    //     this.com_news_detail = commaJoinedValues[0].content_detail_en;
    //     this.com_date = commaJoinedValues[0].post_date;
    //     const v = await this.imageService
    //       .findByIdHeader(this.com_id)
    //       .toPromise();
    //     this.com_img = this.node_static_url + v["img_header"];
    //   }
    //   if (index == 1) {
    //     this.csr_id = commaJoinedValues[0].event_id;
    //     this.csr = commaJoinedValues[0].event_name_en;
    //     this.csr_detail = commaJoinedValues[0].content_detail_en;
    //     this.csr_date = commaJoinedValues[0].post_date;
    //     const v = await this.imageService
    //       .findByIdHeader(this.csr_id)
    //       .toPromise();
    //     this.csr_img = this.node_static_url + v["img_header"];
    //   }
    //   if (index == 2) {
    //     this.events_id = commaJoinedValues[0].event_id;
    //     this.events = commaJoinedValues[0].event_name_en;
    //     this.events_detail = commaJoinedValues[0].content_detail_en;
    //     this.events_date = commaJoinedValues[0].post_date;
    //     const v = await this.imageService
    //       .findByIdHeader(this.events_id)
    //       .toPromise();
    //     this.events_img = this.node_static_url + v["img_header"];
    //   }
    // }
  }

  async last() {
    const last = await this.rest.getContentLastest().toPromise();
    this.lastest = last;

    for (let index = 0; index < last.length; index++) {
      const id = JSON.stringify(last[index].event_id);
      const v = await this.imageService.findByIdHeader(id).toPromise();
      this.img.push(this.node_static_url + v["img_header"]);
    }
  }
  aboutUs() {
    window.location.href = "/about-us";
  }
  products() {
    window.location.href = "/products";
  }
  daicel_group() {
    window.location.href = "/daicel-group";
  }
  csr_page() {
    window.location.href = "/csr";
  }
  news_events() {
    window.location.href = "/news-events";
  }
  contact_us() {
    window.location.href = "/contact-us";
  }
  career() {
    window.location.href = "/career";
  }
  th() {
    window.location.href = "/th";
  }
  home() {
    window.location.href = "/";
  }
  preview(data) {
    window.location.href = "/preview?id=" + data;
  }
}
