import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';
import { ImagesService } from 'src/app/services/images.service';

@Component({
  selector: 'app-csr',
  templateUrl: './csr.component.html',
  styleUrls: ['./csr.component.css']
})
export class CsrComponent implements OnInit {

  constructor(private rest: ContentService, private imageService: ImagesService, private router: Router) {

  }

  link =  environment.path
  mDataArray: any[] = [];
  img: any[] = [];
  baseUrl : string = environment.baseUrl;
  node_static_url: string  = environment.backendUrl;
  imgName = [];
  titleEn: string = '';
  detailEn: string = '';
  titleTh: string = '';
  detailTh: string = '';
  dateActivity: string = '';
  datePublish: string = '';


  async ngOnInit() {
    this.rest.set_reload()
    this.mDataArray = await this.rest.getCSR().toPromise();
    for (let index = 0; index < this.mDataArray.length; index++) {
      const id = this.mDataArray[index].event_id
      const v = await this.imageService.findByIdHeader(id).toPromise();
      this.img.push(this.node_static_url  + v['img_header'])
    
    }

   
  }

  career() {
    window.location.href = '/career';
   
  }
  aboutUs() {
    window.location.href='/about-us';  
   
  }
  products(){
    window.location.href='/products';  
   
  }
  daicel_group(){
    window.location.href='/daicel-group';  
   
  }
  csr_page(){
    window.location.href='/csr';  
   
  }
  news_events(){
    window.location.href='/news-events';  
   
  }
  contact_us(){
    window.location.href='/contact-us';
   

  }
  th(){
    window.location.href='/th/csr';
   

  }
  home () {
    window.location.href='/';
   
  }



}
