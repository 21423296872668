import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css"],
})
export class ProductsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  career() {
    window.location.href = "/career";
  }
  aboutUs() {
    window.location.href = "/about-us";
  }
  products() {
    window.location.href = "/products";
  }
  daicel_group() {
    window.location.href = "/daicel-group";
  }
  csr_page() {
    window.location.href = "/csr";
  }
  news_events() {
    window.location.href = "/news-events";
  }
  contact_us() {
    window.location.href = "/contact-us";
  }
  th() {
    window.location.href = "/th/products";
  }
  home() {
    window.location.href = "/";
  }
  inflator() {
    window.location.href = "/products/inflator";
  }
  pgg() {
    window.location.href = "/products/pgg";
  }
  co_creation() {
    window.location.href = "/products/co-creation";
  }
  pyro_fuse() {
    window.location.href = "/products/pyrofuse";
  }

  out1() {
    window.location.href = "/products/pgg";
  }
  out2() {
    window.location.href = "/products/co-creation";
  }
  out3() {
    window.location.href = "/products/pyrofuse";
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  
}
