import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pyro-fuse-th',
  templateUrl: './pyro-fuse-th.component.html',
  styleUrls: ['./pyro-fuse-th.component.css']
})
export class PyroFuseThComponent implements OnInit {
  onstructor() { }

  ngOnInit(): void {
  }


  career() {
    window.location.href = "/th/career";
  }
  aboutUs() {
    window.location.href = "/th/about-us";
  }
  products() {
    window.location.href = "/th/products";
  }
  daicel_group() {
    window.location.href = "/th/daicel-group";
  }
  csr_page() {
    window.location.href = "/th/csr";
  }
  news_events() {
    window.location.href = "/th/news-events";
  }
  contact_us() {
    window.location.href = "/th/contact-us";
  }
  th() {
    window.location.href = "/products";
  }
  home() {
    window.location.href = "/th";
  }

}
