import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preloading',
  templateUrl: './preloading.component.html',
  styleUrls: ['./preloading.component.css']
})
export class PreloadingComponent implements OnInit {

  constructor() { }
  public showContent: boolean = false;
  ngOnInit(): void {
    if(!this.showContent){
      setTimeout(() => (this.showContent = true), 2000);
    }
    
  }

}
