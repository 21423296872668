<title>
  Daicel Thailand | Products</title>
  <header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
    <div class="header_inner clearfix">
        <div class="header_top_bottom_holder">
            <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
                <div class="container">
                    <div class="container_inner clearfix">
                        <div class="header_inner_left">
                            <div class="mobile_menu_button">
                                <span>
                                    <i class="fa fa-bars"></i> </span>
                            </div>
                            <div class="logo_wrapper">
                                <div class="q_logo">
                                    <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                        <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="light" src="../assets/templates/home/DSST-Logo-300x70.png"
                                            alt="Logo" style="height: 100%;"> <img itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="sticky" src="../assets/templates/home/DSST-Logo-300x70.png"
                                            alt="Logo" style="height: 100%;"> <img itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> </a>
                                </div>
                            </div>
                            <div class="header_fixed_right_area">
                              <div class="textwidget custom-html-widget">
                                  <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                      </span>
                                  </div>

                                  <span style="display: inline-block;">
                                    <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                                        <div class="icon_holder " style="">
                                            <img itemprop="image" style="
                                            margin-top: 7px;
                                            margin-left: 5px;
                                        "
                                                src="../assets/templates/home/pr.png" alt="">
                                        </div>
                                        <div class="icon_text_holder" style="">
                                            <div class="icon_text_inner" style="">
                                              <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;
                                              font-family: 'Open Sans', sans-serif!important;
                                              "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                                </h6>
                                                <p style="color: #949494;line-height: 18px!important;
                                              font-weight: 400!important;
                                                ">  241 หมู่ 4, เขตอุตสาหกรรม 304 <br>
                                                  ปราจีนบุรี 25140 ประเทศไทย <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>

                                <span style="display: inline-block; margin-right: 15px;">
                                    <div class="q_icon_with_title medium custom_icon_image ">
                                        <div class="icon_holder " style=" "><img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                                src="../assets/templates/home/sb.png" alt=""></div>
                                        <div class="icon_text_holder" style="">
                                            <div class="icon_text_inner" style="">
                                              <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;               font-family: 'Open Sans', sans-serif!important;

                                              ">
                                                  +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                                </h6>
                                                <p style="color: #949494;line-height: 18px!important;
                                              font-weight: 400!important;
                                                ">  76 หมู่ 1, อำเภอพระพุทธบาท<br>
                                                สระบุรี 18120 ประเทศไทย <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>

                                <span style="display: inline-block;">
                                    <div class="q_icon_with_title medium custom_icon_image ">
                                        <div class="icon_holder " style=" "><img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                                src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                        <div class="icon_text_holder" style="">
                                            <div class="icon_text_inner" style="">
                                              <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;

                                              "> daicel-dsst.com<br>

                                                </h6>
                                                <p style="color: #949494;line-height: 18px!important;
                                                font-weight: 400!important;
                                                ">  วันจันทร์ - วันศุกร์ 08h - 17h<br>หยุดวันเสาร์ - อาทิตย์


                                                 <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>


                              </div>
                          </div>
                        </div>
                        <div class="header_menu_bottom">
                            <div class="header_menu_bottom_inner">
                                <div class="main_menu_header_inner_right_holder">
                                    <nav class="main_menu drop_down">
                                        <ul id="menu-main-menu" class="clearfix">
                                            <li id="nav-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="aboutUs()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>เกี่ยวกับเรา
                                                        <span class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="products()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>ผลิตภัณฑ์<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="daicel_group()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>ไดเซลกรุ๊ป<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="csr_page()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>กิจกรรม CSR<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="news_events()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>ข่าวสารและกิจกรรม<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>

                                            <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="career()" class=""><i
                                                                class="menu_icon blank fa"></i><span class='font'>ร่วมงานกับเรา<span
                                                                    class="underline_dash"></span></span><span
                                                                class="plus"></span></a></li>
                                            <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">

                                                <a (click)="contact_us()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>ติดต่อเรา
                                                        <span class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                                <a title="English"  class=""><i
                                                        class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                                                            src="../assets/templates/home/th.png" alt="en"
                                                            title="ไทย"><span class="wpml-ls-native" class='font'>ไทย</span><span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span><span class="qode-featured-icon "
                                                        aria-hidden="true"></span></a>
                                                <div class="second" style="height: 0px;">
                                                    <div class="inner">
                                                        <ul>
                                                            <li id="nav-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                                <a title="ไทย" (click)="th()" class=""><i
                                                                        class="menu_icon blank fa"></i><span><img
                                                                            class="wpml-ls-flag"
                                                                            src="../assets/templates/home/en.png"
                                                                            alt="th" title="English"><span
                                                                            class="wpml-ls-native">English</span></span><span
                                                                        class="plus"></span><span
                                                                        class="qode-featured-icon "
                                                                        aria-hidden="true"></span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div class="header_inner_right">
                                        <div class="side_menu_button_wrapper right">
                                            <div class="header_bottom_right_widget_holder">
                                                <div class="widget_text header_bottom_widget widget_custom_html">
                                                    <div class="textwidget custom-html-widget">
                                                        <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="side_menu_button">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav class="mobile_menu">
                            <ul id="menu-main-menu-1" class="">
                                <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="aboutUs()" class=""><span class='font'>เกี่ยวกับเรา
                                        </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="products()" class=""><span class='font'>ผลิตภัณฑ์</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="daicel_group()" class=""><span class='font'>ไดเซลกรุ๊ป
                                        </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="csr_page()" class=""><span class='font'>กิจกรรม CSR</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="news_events()" class=""><span class='font'>ข่าวสารและกิจกรรม</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>

                                <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="career()" class=""><span class='font'>ร่วมงานกับเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                    class="fa fa-angle-down"></i></span></li>

                                <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="contact_us()" class=""><span class='font'>ติดต่อเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                    <a title="English"  class=""><span><img class="wpml-ls-flag"
                                                src="../assets/templates/home/th.png" alt="en" title="ไทย"><span
                                                class="wpml-ls-native font" >ไทย</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span>
                                    <ul class="sub_menu">
                                        <li id="mobile-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                            <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                        src="../assets/templates/home/en.png" alt="th"
                                                        title="English"><span
                                                        class="wpml-ls-native">English</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                    class="fa fa-angle-down"></i></span></li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
    </div>

  </header>
<a id="back_to_top" href="#" class="off">
  <span class="fa-stack">
    <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>
<div class="content content_top_margin_none">
  <div class="content_inner  ">
    <div class="title_outer title_without_animation" data-height="250">
      <div class="title title_size_small  position_left  has_fixed_background "
        style="background-size:1920px auto;background-image:url(../../../../assets/templates/home/co-creation_bg.png);height:250px;">
        <div class="image not_responsive"><img itemprop="image"
            src="../../../../assets/templates/home/co-creation_bg.png" alt="&nbsp;" /> </div>
        <div class="title_holder" style="padding-top:0;height:250px;">
          <div class="container">
            <div class="container_inner clearfix">
              <div class="title_subtitle_holder">
                <div class="title_subtitle_holder_inner">
                  <h1><span>Co-creation</span></h1>

                  <div class="breadcrumb" style="background-color: transparent;">
                    <div class="breadcrumbs">
                      <div itemprop="breadcrumb" class="breadcrumbs_inner"><a (click)="home()">Home</a><span
                          class="delimiter">&nbsp;>&nbsp; Products </span> &nbsp;>&nbsp; <span
                          class="current">Co-creation</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="full_width">
      <app-choose-product-th></app-choose-product-th>
      <div class="full_width_inner">
        <div class="full_width_inner">

          <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1510505660155 grid_section"
            style=' text-align:center;'>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner vc_custom_1518015324755">
                    <div class="wpb_wrapper">
                      <br> <br> <br> <br><br>
                      <div class="wpb_text_column wpb_content_element ">
                        <div class="wpb_wrapper">
                          <h2 style="text-align: center;"><span style="color: #009be6;">ONE TIME ENERGY</span></h2>
                          <h4 style="text-align: center;"><span>A one-shot <br>
                              energetic force generated <br>
                              instantly, reliably, and safely.</span></h4>
                        </div>
                      </div>

                      <div class="vc_empty_space" style="height: 80px"><span class="vc_empty_space_inner">
                          <span class="empty_space_image"></span>
                        </span>
                      </div>

                      <div class="vc_row wpb_row section vc_row-fluid vc_inner " style=' text-align:left;'>
                        <div class=" full_section_inner clearfix">

                          <div class="wpb_column vc_column_container vc_col-sm-2">

                          </div>

                          <div class="wpb_column vc_column_container vc_col-sm-8">
                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">


                                    <h3 style="text-align: center;">
                                      <strong>Why choose DAICEL?</strong>
                                    </h3>
                                    <br>
                                    <p>
                                      <b>1.</b> Instantaneous activation. (MAX 1 msec) <br>
                                      <b>2.</b>Versatile energy output control from miniscule to massive level.<br>
                                      <b>3.</b> Ensured activation under harsh environments without electricity. (polar
                                      area, deep sea, outer space)<br>
                                      <b>4.</b> Zero malfunctions under 1 billion pieces tested proves our outstanding
                                      reliability.

                                    </p>
                                    <br>

                                    <h6 style="text-align: center">We have broadened our expertise to various dangerous
                                      fields to assure protection under any “emergency”. <br>(Lifesaving, Medical,
                                      Disaster, Security, Mobility, Construction, Aerospace)
                                    </h6>


                                    <div class="vc_empty_space" style="height: 80px"><span class="vc_empty_space_inner">
                                        <span class="empty_space_image"></span>
                                      </span>
                                    </div>

                                    <h3 style="text-align: center;">
                                      <strong>We look forward to serve as your reliable partner <br> to “Co-create” a
                                        brand-new value together.</strong>
                                    </h3>
                                    <div class="vc_empty_space" style="height: 30px"><span class="vc_empty_space_inner">
                                        <span class="empty_space_image"></span>
                                      </span>
                                    </div>
                                    <img style="width: 90%" src="../../../../assets/templates/home/intro_im01.svg">




                                  </div>
                                </div>


                                <br>


                              </div>
                            </div>
                            <br><br>

                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-2">

                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





          <section data-speed="1" class="parallax_section_holder  "
            style=" height:px; background-image:url('../../../../assets/templates/home/Products-Sample-A90-1920x1080.jpg');">
            <br><br><br>
            <div class="parallax_content_full_width center">
              <div class='parallax_section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner vc_custom_1518015406018">
                    <div class="wpb_wrapper">
                      <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner">
                          <span class="empty_space_image"></span>
                        </span>
                      </div>


                      <div class="wpb_text_column wpb_content_element ">
                        <div class="wpb_wrapper">
                          <!-- <h2><span style="color: #009be6;">Product lineup</span></h2> -->

                        </div>
                      </div>
                      <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner">
                          <span class="empty_space_image"></span>
                        </span>
                      </div>

                      <div class="vc_row wpb_row section vc_row-fluid vc_inner  grid_section"
                        style=' text-align:center'>
                        <div class=" section_inner clearfix">
                          <div class='section_inner_margin clearfix'>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                              <div class="vc_column-inner ">
                                <div class="wpb_wrapper">

                                  <div class="row">
                                    <div class="col">
                                      <img src="../../../../assets/templates/home/co-creation-01.png"
                                      alt="qode-np-item" />
                                      <h4 class="qode-np-title" style="color: #000000">
                                        Pyro-Closer</h4>
                                      <br>
                                      <a  (click)="scroll(Connect)" target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color: white;">Connect</span><span
                                          class="qode-button-v2-icon-holder"
                                          style="font-size: 16px;color: white;"><span aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>


                                    </div>
                                    <div class="col">
  <img src="../../../../assets/templates/home/co-creation-02.png"
                                      alt="qode-np-item" />
                                      <h4 class="qode-np-title" style="color: #000000">
                                        Puncher</h4>
                                      <br>
                                      <a (click)="scroll(Punch)"  target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color: white;">Punch</span><span
                                          class="qode-button-v2-icon-holder"
                                          style="font-size: 16px;color: white;"><span aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                    </div>
                                    <div class="col">
                                      <img src="../../../../assets/templates/home/co-creation-03.png"
                                      alt="qode-np-item" />
                                      <h4 class="qode-np-title" style="color: #000000">
                                        Pin Pusher</h4>
                                      <br>
                                      <a  (click)="scroll(Push)"  target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color: white;">Push</span><span
                                          class="qode-button-v2-icon-holder"
                                          style="font-size: 16px;color: white;"><span aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                    </div>
                                    <div class="col">
                                      <img src="../../../../assets/templates/home/co-creation-04.png"
                                      alt="qode-np-item" />
                                      <h4 class="qode-np-title" style="color: #000000">
                                        Pin Puller</h4>
                                      <br>
                                      <a   (click)="scroll(Push)"  target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color: white;">Pull</span><span
                                          class="qode-button-v2-icon-holder"
                                          style="font-size: 16px;color: white;"><span aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                    </div>
                                    <div class="col">
                                      <img src="../../../../assets/templates/home/co-creation-05.png"
                                      alt="qode-np-item" />
                                      <h4 class="qode-np-title" style="color: #000000">
                                        Wire Cutter</h4>
                                      <br>
                                      <a  (click)="scroll(Cut)"  target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color: white;">Cut</span><span
                                          class="qode-button-v2-icon-holder"
                                          style="font-size: 16px;color: white;"><span aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                    </div>

                                  </div>


                                  <div class="vc_empty_space" style="height: 50px"><span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>



                                </div>
                                <br><br><br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>



          <div #Connect  class="vc_row wpb_row section vc_row-fluid  vc_custom_1518014984044 grid_section"
            style=' text-align:left; background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>
            <br><br><br><br>

            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <h6 style="text-align: center;">Connect
                </h6>

              </div>
            </div>
            <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                <span class="empty_space_image"></span>
              </span>
            </div>


            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <h2 style="text-align: center;">Pyro-Closer</h2>

                <div class="wpb_single_image wpb_content_element vc_align_center">
                  <div class="wpb_wrapper">

                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                      <img width="200" height="145" src="../../../../assets/templates/home/co-creation-line1.png"
                        class="vc_single_image-img attachment-full" alt=""
                        srcset="../../../../assets/templates/home/co-creation-line1.png 600w,
                                            ../../../../assets/templates/home/co-creation-line1.png 300w" sizes="(max-width: 600px) 100vw, 600px" />
                    </div>
                  </div>
                </div>



              </div>
            </div>

            <br><br>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518015575529"
                        style=' text-align:left;'>
                        <div class=" full_section_inner clearfix">
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>



                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3 style="text-align:center ;"><strong>Application</strong></h3>

                                  </div>
                                </div>
                                <br>
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h6 style="text-align:left;"><i class="fa  fa-circle" style="font-size: 5px"></i>
                                      Circuit malfunctions

                                      <br><i class="fa  fa-circle" style="font-size: 5px"></i> Accidents
                                      <br><i class="fa  fa-circle" style="font-size: 5px"></i> Overcharging
                                    </h6>
                                  </div>
                                </div>

                                <br>
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <img style="width: 80%;" src="../../../../assets/templates/home/howto_im01.png"
                                      alt="">

                                  </div>
                                </div>
                                <br>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h5>
                                      <i class="fa  fa-circle" style="font-size: 5px"></i> Pyro-Closer
                                    </h5>

                                    <p>
                                      Intentional short-circuit to release accumulated electricity.



                                    </p>
                                    <br>
                                    <h5>
                                      <i class="fa  fa-circle" style="font-size: 5px"></i> Pyro-Fuse
                                    </h5>

                                    <p> Intentional electrical shutdown.</p>
                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>



                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3 style="text-align:center ;"><strong>Feature</strong></h3>

                                  </div>
                                </div>
                                <br>
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h6 style="text-align:left ;"><i class="fa  fa-circle" style="font-size: 8px"></i>
                                      Physically connect bus bars to instantly create a new circuit. <br>
                                      <i class="fa  fa-circle" style="font-size: 8px"></i> Intentionally cause a short
                                      circuit to release electricity, <br> &nbsp; &nbsp; applicable to other multiple
                                      devices.
                                    </h6>
                                  </div>
                                </div>

                                <br>
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h5 style="text-align:center ;"><strong>Mechanism
                                      </strong></h5>

                                  </div>
                                </div>




                                <div class="wpb_single_image wpb_content_element vc_align_center">
                                  <div class="wpb_wrapper">

                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                      <img style="width: 60%;" src="../../../../assets/templates/home/feature_mv01.gif"
                                        alt="">
                                    </div>
                                  </div>
                                </div>



                                <br>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">

                                    <p>
                                      <b>1.</b> An electric signal is transmitted when malfunction is detected.<br>
                                      <b>2.</b> “One-Time Energy” is activated.<br>
                                      <b>3.</b> The piston is pushed out to shunt the bus bar.

                                    </p>

                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>

                        </div>
                      </div>


                      <br><br><br><br><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div   class="vc_row wpb_row section vc_row-fluid  vc_custom_1510596881665 grid_section"
            style='background-color:#ffffff; text-align:right;'>
            <br>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div
                        class='q_elements_holder two_columns eh_two_columns_66_33 responsive_mode_from_768 alignment_one_column_center'>
                        <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                          <div class='q_elements_item_inner'>
                            <div class='q_elements_item_content q_elements_holder_custom_904884'>
                              <style type="text/css" data-type="q_elements_custom_padding" scoped>
                                @media only screen and (min-width: 600px) and (max-width: 768px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (min-width: 480px) and (max-width: 600px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (max-width: 480px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }
                              </style>
                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h3>HAVE QUESTIONS ABOUT OUR PRODUCTS?</h3>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                          <div class='q_elements_item_inner'>
                            <div class='q_elements_item_content q_elements_holder_custom_972935'>
                              <style type="text/css" data-type="q_elements_custom_padding" scoped>
                                @media only screen and (min-width: 600px) and (max-width: 768px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (min-width: 480px) and (max-width: 600px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (max-width: 480px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }
                              </style>

                              <a href="mailto:sales.dsst@dsst.daicel.com" target="_self"
                                style="font-size: 13px"
                                class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                <span class="qode-btn-text">Get In
                                  Touch</span><span class="qode-button-v2-icon-holder" style="font-size: 21px"><span
                                    aria-hidden="true"
                                    class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
          </div>



          <div #Punch class="vc_row wpb_row section vc_row-fluid  vc_custom_1518014984044 grid_section"
            style=' text-align:left; background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>
            <br><br><br><br>

            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <h6 style="text-align: center;">Punch
                </h6>

              </div>
            </div>
            <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                <span class="empty_space_image"></span>
              </span>
            </div>


            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <h2 style="text-align: center;">Puncher</h2>

                <div class="wpb_single_image wpb_content_element vc_align_center">
                  <div class="wpb_wrapper">

                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                      <img width="200" height="145" src="../../../../assets/templates/home/co-creation-02.png"
                        class="vc_single_image-img attachment-full" alt="" srcset="../../../../assets/templates/home/co-creation-02.png 600w,
                                        ../../../../assets/templates/home/co-creation-02.png 300w"
                        sizes="(max-width: 600px) 100vw, 600px" />
                    </div>
                  </div>
                </div>



              </div>
            </div>

            <br>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518015575529"
                        style=' text-align:left;'>
                        <div class=" full_section_inner clearfix">
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner "  style="width: 95%;">
                              <div class="wpb_wrapper">
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>



                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3 style="text-align:center ;"><strong>Application</strong></h3>

                                  </div>
                                </div>
                                <br>
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h5>Life Jacket</h5>

                                    <h6 style="text-align:left;"><i class="fa  fa-circle" style="font-size: 5px"></i>
                                      Prevent elderly injuries.

                                      <br><i class="fa  fa-circle" style="font-size: 5px"></i> Relieve damage when
                                      fallen from heights.
                                      <br><i class="fa  fa-circle" style="font-size: 5px"></i> Absorb impact when thrown
                                      off a bicycle or motorized recreational vehicle.
                                    </h6>
                                  </div>
                                </div>

                                <br>
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">


                                    <div class="row">
                                      <div class="col">
                                        <img src="../../../../assets/templates/home/howto_punch1.png" alt="">
                                        <br>
                                        <p style="font-weight: bold;text-align: center;">Injury</p>
                                      </div>
                                      <div class="col">
                                        <img src="../../../../assets/templates/home/howto_punch2.png" alt="">
                                        <br>
                                        <p style="font-weight: bold;text-align: center;">Heights</p>
                                      </div>
                                      <div class="col">
                                        <img src="../../../../assets/templates/home/howto_punch3.png" alt="">
                                        <br>
                                        <p style="font-weight: bold;text-align: center;">Impact</p>
                                      </div>

                                    </div>


                                  </div>
                                </div>
                                <br>
                                <br>
                                <br>
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h5>Fire Extinguishment</h5>

                                    <h6 style="text-align:left;"><i class="fa  fa-circle" style="font-size: 5px"></i>
                                      The sensor detects the fire and auto-extinguish.

                                    </h6><br>
                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                      <div class="wpb_wrapper">

                                        <div class="vc_single_image-wrapper   vc_box_border_grey">
                                          <img style="width: 80%;" src="../../../../assets/templates/home/howto_im04.png" alt="">
                                        </div>
                                      </div>
                                    </div>




                                  </div>
                                </div>

                                <br>




                              </div>
                            </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner "  style="width: 95%;">
                              <div class="wpb_wrapper">
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>



                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3 style="text-align:center ;"><strong>Feature</strong></h3>

                                  </div>
                                </div>
                                <br>
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h5>Life Jacket</h5>

                                    <h6 style="text-align:left ;"><i class="fa  fa-circle" style="font-size: 8px"></i>
                                      The sensor detects then sends a signal upon accident, and instantly inflates the
                                      life jacket. (within 2 msecs)
                                      <br>
                                      <i class="fa  fa-circle" style="font-size: 8px"></i> Lightweight, compact, and
                                      portable.
                                    </h6>

                                    <br><br>
                                    <h5>Fire Extinguishment</h5>

                                    <h6 style="text-align:left ;"><i class="fa  fa-circle" style="font-size: 8px"></i>
                                      The sensor detects then sends a signal upon fire.</h6>


                                  </div>
                                </div>

                                <br>
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h5 style="text-align:center ;"><strong>Mechanism
                                      </strong></h5>

                                  </div>
                                </div>




                                <div class="wpb_single_image wpb_content_element vc_align_center">
                                  <div class="wpb_wrapper">

                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                      <img style="width: 60%;" src="../../../../assets/templates/home/feature_mv02.gif"
                                        alt="">
                                    </div>
                                  </div>
                                </div>



                                <br>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">

                                    <p>
                                      <b>1.</b> “One-Time Energy” is activated to generate energy.<br>
                                      <b>2.</b> The generated energy pushes out the “Pin Pusher”.<br>
                                      <b>3.</b> The pushed-out pin pierces the cylinder, releasing the gas.

                                    </p>

                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>

                        </div>
                      </div>


                      <br><br><br><br><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div   class="vc_row wpb_row section vc_row-fluid  vc_custom_1510596881665 grid_section"
            style='background-color:#ffffff; text-align:right;'>
            <br>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div
                        class='q_elements_holder two_columns eh_two_columns_66_33 responsive_mode_from_768 alignment_one_column_center'>
                        <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                          <div class='q_elements_item_inner'>
                            <div class='q_elements_item_content q_elements_holder_custom_904884'>
                              <style type="text/css" data-type="q_elements_custom_padding" scoped>
                                @media only screen and (min-width: 600px) and (max-width: 768px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (min-width: 480px) and (max-width: 600px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (max-width: 480px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }
                              </style>
                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h3>HAVE QUESTIONS ABOUT OUR PRODUCTS?</h3>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                          <div class='q_elements_item_inner'>
                            <div class='q_elements_item_content q_elements_holder_custom_972935'>
                              <style type="text/css" data-type="q_elements_custom_padding" scoped>
                                @media only screen and (min-width: 600px) and (max-width: 768px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (min-width: 480px) and (max-width: 600px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (max-width: 480px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }
                              </style>

                              <a href="mailto:sales.dsst@dsst.daicel.com" target="_self"
                                style="font-size: 13px"
                                class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                <span class="qode-btn-text">Get In
                                  Touch</span><span class="qode-button-v2-icon-holder" style="font-size: 21px"><span
                                    aria-hidden="true"
                                    class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
          </div>



          <div  #Push class="vc_row wpb_row section vc_row-fluid  vc_custom_1518014984044 grid_section"
            style=' text-align:left; background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>
            <br><br><br><br>


<div class="row">
  <div class="col">

    <div class="wpb_text_column wpb_content_element ">
      <div class="wpb_wrapper">
        <div class="wpb_single_image wpb_content_element vc_align_center">
          <div class="wpb_wrapper">

            <div class="vc_single_image-wrapper   vc_box_border_grey" style="float: right;">
              <h6 style="text-align: center;">Punch</h6>
              <h2 style="text-align: center;">Pin Pusher</h2>

              <img width="200" height="145" src="../../../../assets/templates/home/co-creation-03.png"
                class="vc_single_image-img attachment-full" alt="" srcset="../../../../assets/templates/home/co-creation-03.png 600w,
                                ../../../../assets/templates/home/co-creation-03 300w"
                sizes="(max-width: 600px) 100vw, 600px" />
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
  <div class="col">

    <div class="wpb_text_column wpb_content_element ">
      <div class="wpb_wrapper">
        <div class="wpb_single_image wpb_content_element vc_align_center">
          <div class="wpb_wrapper">

            <div class="vc_single_image-wrapper   vc_box_border_grey" style="float:left;">
              <h6 style="text-align: center;">Pull</h6>
              <h2 style="text-align: center;">Pin Puller</h2>

              <img width="200" height="145" src="../../../../assets/templates/home/co-creation-04.png"
                class="vc_single_image-img attachment-full" alt="" srcset="../../../../assets/templates/home/co-creation-04.png 600w,
                                ../../../../assets/templates/home/co-creation-04.png 300w"
                sizes="(max-width: 600px) 100vw, 600px" />
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
</div>



            <br>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518015575529"
                        style=' text-align:left;'>
                        <div class=" full_section_inner clearfix">
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner "  style="width: 95%;">
                              <div class="wpb_wrapper">
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>



                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3 style="text-align:center ;"><strong>Application</strong></h3>

                                  </div>
                                </div>
                                <br>
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h6 style="text-align:left;"><i class="fa  fa-circle" style="font-size: 5px"></i>
                                      It can be used in fields where urgency and reliability are required.
                                    </h6>
                                  </div>
                                </div>


                                <div class="vc_empty_space" style="height: 50px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <img  style="width: 90%;" src="../../../../assets/templates/home/howto_puncher1.jpg" alt="">

                                    <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>

                                    <div class="row">
                                      <div class="col">
                                        <img style="width: 80%;" src="../../../../assets/templates/home/howto_puncher2.png" alt="">
                                        <br>
                                        <p style="font-weight: bold;text-align: center;">Block</p>
                                      </div>
                                      <div class="col">
                                        <img style="width: 80%;" src="../../../../assets/templates/home/howto_puncher3.png" alt="">
                                        <br>
                                        <p style="font-weight: bold;text-align: center;">Pierce</p>
                                      </div>

                                    </div>
                                    <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>

                                    <div class="row">
                                      <div class="col">
                                        <img style="width: 80%;" src="../../../../assets/templates/home/howto_puncher4.png" alt="">
                                        <br>
                                        <p style="font-weight: bold;text-align: center;">Break</p>
                                      </div>
                                      <div class="col">
                                        <img style="width: 80%;" src="../../../../assets/templates/home/howto_puncher5.png" alt="">
                                        <br>
                                        <p style="font-weight: bold;text-align: center;">Pull</p>
                                      </div>

                                    </div>


                                  </div>
                                </div>
                                <br>
                                <br>





                              </div>
                            </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner "  style="width: 95%;">
                              <div class="wpb_wrapper">
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>



                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3 style="text-align:center ;"><strong>Feature</strong></h3>

                                  </div>
                                </div>
                                <br>
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">

                                    <h6 style="text-align:left ;"><i class="fa  fa-circle" style="font-size: 8px"></i>
                                      Generates instant high energy source (pressure).
                                      <br>
                                      <i class="fa  fa-circle" style="font-size: 8px"></i> Safe, remote control using electric signal.
                                      <br>
                                      <i class="fa  fa-circle" style="font-size: 8px"></i> Available for various applications.
                                    </h6>


                                  </div>
                                </div>

                                <br>
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h5 style="text-align:center ;"><strong>Mechanism
                                      </strong></h5>

                                  </div>
                                </div>




                                <div class="wpb_single_image wpb_content_element vc_align_center">
                                  <div class="wpb_wrapper">

                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                      <img style="width: 60%;" src="../../../../assets/templates/home/puncher_6.png"
                                        alt="">
                                    </div>
                                  </div>
                                </div>



                                <br>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">

                                    <p>
                                      <b>1.</b>“One-Time Energy” is activated to generate energy.<br>
                                      <b>2.</b> The generated energy pushes/pulls out the pin.<br>

                                    </p>

                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>

                        </div>
                      </div>


                      <br><br><br><br><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1510596881665 grid_section"
            style='background-color:#ffffff; text-align:right;'>
            <br>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div
                        class='q_elements_holder two_columns eh_two_columns_66_33 responsive_mode_from_768 alignment_one_column_center'>
                        <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                          <div class='q_elements_item_inner'>
                            <div class='q_elements_item_content q_elements_holder_custom_904884'>
                              <style type="text/css" data-type="q_elements_custom_padding" scoped>
                                @media only screen and (min-width: 600px) and (max-width: 768px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (min-width: 480px) and (max-width: 600px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (max-width: 480px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }
                              </style>
                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h3>HAVE QUESTIONS ABOUT OUR PRODUCTS?</h3>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                          <div class='q_elements_item_inner'>
                            <div class='q_elements_item_content q_elements_holder_custom_972935'>
                              <style type="text/css" data-type="q_elements_custom_padding" scoped>
                                @media only screen and (min-width: 600px) and (max-width: 768px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (min-width: 480px) and (max-width: 600px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (max-width: 480px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }
                              </style>

                              <a href="mailto:sales.dsst@dsst.daicel.com" target="_self"
                                style="font-size: 13px"
                                class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                <span class="qode-btn-text">Get In
                                  Touch</span><span class="qode-button-v2-icon-holder" style="font-size: 21px"><span
                                    aria-hidden="true"
                                    class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
          </div>




          <div   #Cut  class="vc_row wpb_row section vc_row-fluid  vc_custom_1518014984044 grid_section"
            style=' text-align:left; background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>
            <br><br><br><br>

            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <h6 style="text-align: center;">Cut
                </h6>

              </div>
            </div>
            <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                <span class="empty_space_image"></span>
              </span>
            </div>


            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <h2 style="text-align: center;">Wire Cutter</h2>

                <div class="wpb_single_image wpb_content_element vc_align_center">
                  <div class="wpb_wrapper">

                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                      <img width="200" height="145" src="../../../../assets/templates/home/co-creation-05.png"
                        class="vc_single_image-img attachment-full" alt="" srcset="../../../../assets/templates/home/co-creation-02.png 600w,
                                        ../../../../assets/templates/home/co-creation-02.png 300w"
                        sizes="(max-width: 600px) 100vw, 600px" />
                    </div>
                  </div>
                </div>



              </div>
            </div>
<br>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518015575529"
                        style=' text-align:left;'>
                        <div class=" full_section_inner clearfix">
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner "  style="width: 95%;">
                              <div class="wpb_wrapper">
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>



                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3 style="text-align:center ;"><strong>Application</strong></h3>

                                  </div>
                                </div>
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">

                                    <h6 style="text-align:left;">
                                      <br><i class="fa  fa-circle" style="font-size: 5px"></i> Detach satellites.
                                      <br><i class="fa  fa-circle" style="font-size: 5px"></i> Release anchor lines.
                                      <br><i class="fa  fa-circle" style="font-size: 5px"></i> Disconnect tether straps.
                                    </h6>
                                  </div>
                                </div>

                                <br>
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">


                                    <img  style="width: 80%"  src="../../../../assets/templates/home/howto_cutter01.jpg" alt="">
                                    <br>
                                    <p style="font-weight: bold;text-align: center;width: 80%">Mars Exploration Device

                                    </p>


<br>
<img style="width: 80%" src="../../../../assets/templates/home/howto_cutter02.jpg" alt="">
<br>
<p style="font-weight: bold;text-align: center;width: 80%">Mooring Apparatus

</p>



                                  </div>
                                </div>
                                <br>
                                <br>


                              </div>
                            </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner "  style="width: 95%;">
                              <div class="wpb_wrapper">
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>



                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3 style="text-align:center ;"><strong>Feature</strong></h3>

                                  </div>
                                </div>
                                <br>
                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h6 style="text-align:left ;"><i class="fa  fa-circle" style="font-size: 8px"></i>
                                      Available for various applications.<br>
                                      &nbsp; &nbsp;&nbsp; &nbsp;Examples; fiber ropes, super fibers, steel wires, etc.
                                      <br>
                                      <i class="fa  fa-circle" style="font-size: 8px"></i> Instant detachment and release. (under 10msec)
                                    </h6>


                                  </div>
                                </div>

                                <br>
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h5 style="text-align:center ;"><strong>Mechanism
                                      </strong></h5>

                                  </div>
                                </div>




                                <div class="wpb_single_image wpb_content_element vc_align_center">
                                  <div class="wpb_wrapper">

                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                      <img style="width: 60%;" src="../../../../assets/templates/home/howto_cutter03.gif"
                                        alt="">
                                    </div>
                                  </div>
                                </div>



                                <br>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">

                                    <p>
                                      <b>1.</b> “One-Time Energy” is activated to generate energy.<br>
                                      <b>2.</b> The generated energy pushes out the “Pin Pusher”.<br>
                                      <b>3.</b> The pushed-out pin pierces the cylinder, releasing the gas.

                                    </p>

                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>

                        </div>
                      </div>


                      <br><br><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1510596881665 grid_section"
            style='background-color:#ffffff; text-align:right;'>
            <br>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <div
                        class='q_elements_holder two_columns eh_two_columns_66_33 responsive_mode_from_768 alignment_one_column_center'>
                        <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                          <div class='q_elements_item_inner'>
                            <div class='q_elements_item_content q_elements_holder_custom_904884'>
                              <style type="text/css" data-type="q_elements_custom_padding" scoped>
                                @media only screen and (min-width: 600px) and (max-width: 768px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (min-width: 480px) and (max-width: 600px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (max-width: 480px) {
                                  .q_elements_item_content.q_elements_holder_custom_904884 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }
                              </style>
                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h3>HAVE QUESTIONS ABOUT OUR PRODUCTS?</h3>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                          <div class='q_elements_item_inner'>
                            <div class='q_elements_item_content q_elements_holder_custom_972935'>
                              <style type="text/css" data-type="q_elements_custom_padding" scoped>
                                @media only screen and (min-width: 600px) and (max-width: 768px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (min-width: 480px) and (max-width: 600px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }

                                @media only screen and (max-width: 480px) {
                                  .q_elements_item_content.q_elements_holder_custom_972935 {
                                    padding: 0 0 21px 0 !important;
                                  }
                                }
                              </style>

                              <a href="mailto:sales.dsst@dsst.daicel.com" target="_self"
                                style="font-size: 13px"
                                class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                <span class="qode-btn-text">Get In
                                  Touch</span><span class="qode-button-v2-icon-holder" style="font-size: 21px"><span
                                    aria-hidden="true"
                                    class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
          </div>



        </div>
      </div>

    </div>
  </div>



<app-footer></app-footer>
