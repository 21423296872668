import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ContentService } from 'src/app/services/content.service';
import { LocalStorageService } from '../ir/local-storage.service';

@Component({
  selector: 'app-preview-admin-th',
  templateUrl: './preview-admin-th.component.html',
  styleUrls: ['./preview-admin-th.component.css']
})
export class PreviewAdminThComponent implements OnInit {
  data: [];
  imgHeader: string = '';
  imgAll: [];
  imgAll1: Array<object> = [];
  imgAll2: Array<object> = [];
  title: string = '';
  detail: string = '';
  cnum = 0;
  dateActivity: string = '';
  datePublish: string = '';
  comNews: string = '';
  csr: string = '';
  event: string = '';
  type: string = '';
  recent: [];
  post_by: string = '';
  node_static_url = environment.backendUrl;
  year: string[] = [];
  startMonth: number[] = [];
  endMonth: number[] = [];
  month: string[] = [];
  mlength: number[] = [];
  content: object = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private rest: ContentService,
    public storage: LocalStorageService,
  ) { }

  async ngOnInit() {
    this.content = {}
    this.content = this.storage.get("user_object");


    this.route.queryParams.subscribe((params) => {
      this.type = params.type

      this.comNews = params.comNews
      this.csr = params.csr
      this.event = params.event
      this.dateActivity = params.dateActivity
      this.datePublish = params.datePublish
      this.imgHeader = params.imgHeader
      this.imgAll = params.imgAll
      this.post_by = params.post_by
      this.setTag()
      this.setData()
    })
    this.title = localStorage.getItem('titleTh')
    this.detail = localStorage.getItem('detailTh')

    const start = await this.rest.getStartYear().toPromise();
    const end = await this.rest.getEndYear().toPromise();
    var yearAll = parseInt(end[0]) - parseInt(start[0]) + 1;

    var m: string[]
    m = await this.rest.getMonth().toPromise();
    for (let i = 0; i < yearAll; i++) {
      this.year.push((parseInt(start[0]) + i).toString());
    }

    // m.reverse();

    var allMonth = []
    for (let i = 0; i < m.length; i++) {

      var spl = m[i].toString().split(",")
      this.mlength.push(spl.length);

      for (let j = 0; j < spl.length; j++) {
        allMonth.push(spl[j] )

      }
      allMonth.sort(function(a, b) {
        return a - b;
      });

      for (let j = 0; j < allMonth.length; j++) {
          if (allMonth[j] == '1') {
          this.month.push('มกราคม');
        }
        if (allMonth[j] == '2') {
          this.month.push('กุมภาพันธ์');
        }
        if (allMonth[j] == '3') {
          this.month.push('มีนาคม');
        }
        if (allMonth[j] == '4') {
          this.month.push('เมษายน');
        }
        if (allMonth[j] == '5') {
          this.month.push('พฤษภาคม');
        }
        if (allMonth[j] == '6') {
          this.month.push('มิถุนายน');
        }
        if (allMonth[j] == '7') {
          this.month.push('กรกฎาคม');
        }
        if (allMonth[j] == '8') {
          this.month.push('สิงหาคม');
        }
        if (allMonth[j] == '9') {
          this.month.push('กันยายน');
        }
        if (allMonth[j] == '10') {
          this.month.push('ตุลาคม');
        }
        if (allMonth[j] == '11') {
          this.month.push('พฤศจิกายน');
        }
        if (allMonth[j] == '12') {
          this.month.push('ธันวาคม');
        }
        if (allMonth[j] == '') {
          this.month.push('no');

        }
      }

    }
    var stMonth = 0;
    for (let i = 0; i < m.length; i++) {

      if (i == 0) {
        this.startMonth.push(0)
        this.endMonth.push(this.mlength[i])
      }
      else {
        this.startMonth.push(this.endMonth[i - 1])
        this.endMonth.push(this.endMonth[i - 1] + this.mlength[i])
      }
    }

    // this.title = params.titleEn
    // this.detail = res.content_detail_en
    // this.datePublish = res.published_date
    // const v = await this.rest.getImageById(this.id).toPromise();
    // this.imgHeader = this.node_static_url + "images/" + v.img_header
    // this.comNews = res.com_news
    // this.csr = res.csr
    // this.event = res.events
    // this.imgAll = v.img_all

    this.recent = await this.rest.getRecent().toPromise();


  }

  setData() {

    let devide = Math.ceil(this.imgAll.length / 2)
    let col1 = devide;
    let col2 = this.imgAll.length - devide;

    for (let i = 0; i < this.imgAll.length; i++) {

      if (this.imgAll[i] == '.') {
        this.cnum = this.cnum + 1
      }

    }

    for (var i = 0; i < col1; i++) {
      this.imgAll1.push(this.imgAll[i])
    }
    for (var i = col1; i < (col1 + col2); i++) {
      this.imgAll2.push(this.imgAll[i])
    }


  }
  setTag() {
    if (this.comNews == 'true') {
      this.comNews = "NEWS "
    }
    if (this.csr == 'true') {
      this.csr = "CSR "
    }
    if (this.event == 'true') {
      this.event = "EVENT "
    }
    if (this.comNews == 'false') {
      this.comNews = ""
    }
    if (this.csr == 'false') {
      this.csr = ""
    }
    if (this.event == 'false') {
      this.event = ""
    }


  }
  aboutUs() {
    window.location.href = '/th/about-us';
  }
  products() {
    window.location.href = '/th/products';
  }
  daicel_group() {
    window.location.href = '/th/daicel-group';
  }
  csr_page() {
    window.location.href = '/th/csr';
  }
  news_events() {
    window.location.href = '/th/news-events';
  }
  contact_us() {
    window.location.href = '/th/contact-us';

  }
  career() {
    window.location.href = '/career';

  }

  home() {
    window.location.href = '/th/home';
  }

  preview(data) {

    window.location.href = '/th/preview?id=' + data;
  }

  async onSearch(keyword) {

    window.location.href = '/th/search?keyword=' + keyword;
  }

  go(j, item) {

    window.location.href = '/th/year?month=' + j + '&year=' + item

  }

  th() {
    window.location.href = "/preview-save";

  }




}
