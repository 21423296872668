<title>Daicel Thailand | Terms Of Use</title>
<header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
  <div class="header_inner clearfix">
      <div class="header_top_bottom_holder">
          <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
              <div class="container">
                  <div class="container_inner clearfix">
                      <div class="header_inner_left">
                          <div class="mobile_menu_button">
                              <span>
                                  <i class="fa fa-bars"></i> </span>
                          </div>
                          <div class="logo_wrapper">
                              <div class="q_logo">
                                  <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                      <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="light" src="../assets/templates/home/DSST-Logo-300x70.png"
                                          alt="Logo" style="height: 100%;"> <img itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="sticky" src="../assets/templates/home/DSST-Logo-300x70.png"
                                          alt="Logo" style="height: 100%;"> <img itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> </a>
                              </div>
                          </div>
                          <div class="header_fixed_right_area">
                            <div class="textwidget custom-html-widget">
                                <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                            <span class="empty_space_image"></span>
                                    </span>
                                </div>

                                <span style="display: inline-block;">
                                  <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                                      <div class="icon_holder " style="">
                                          <img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                              src="../assets/templates/home/pr.png" alt="">
                                      </div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;
                                            font-family: 'Open Sans', sans-serif!important;
                                            "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              ">  241 หมู่ 4, เขตอุตสาหกรรม 304 <br>
                                                ปราจีนบุรี 25140 ประเทศไทย <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>

                              <span style="display: inline-block; margin-right: 15px;">
                                  <div class="q_icon_with_title medium custom_icon_image ">
                                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    "
                                              src="../assets/templates/home/sb.png" alt=""></div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;               font-family: 'Open Sans', sans-serif!important;

                                            ">
                                                +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              ">  76 หมู่ 1, อำเภอพระพุทธบาท<br>
                                              สระบุรี 18120 ประเทศไทย <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>

                              <span style="display: inline-block;">
                                  <div class="q_icon_with_title medium custom_icon_image ">
                                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    "
                                              src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;

                                            "> daicel-dsst.com<br>

                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                              font-weight: 400!important;
                                              ">  วันจันทร์ - วันศุกร์ 08h - 17h<br>หยุดวันเสาร์ - อาทิตย์


                                               <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>


                            </div>
                        </div>
                      </div>
                      <div class="header_menu_bottom">
                          <div class="header_menu_bottom_inner">
                              <div class="main_menu_header_inner_right_holder">
                                  <nav class="main_menu drop_down">
                                      <ul id="menu-main-menu" class="clearfix">
                                          <li id="nav-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="aboutUs()" class=""><i
                                                      class="menu_icon blank fa"></i><span class='font'>เกี่ยวกับเรา
                                                      <span class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="products()" class=""><i
                                                      class="menu_icon blank fa"></i><span class='font'>ผลิตภัณฑ์<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="daicel_group()" class=""><i
                                                      class="menu_icon blank fa"></i><span class='font'>ไดเซลกรุ๊ป<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="csr_page()" class=""><i
                                                      class="menu_icon blank fa"></i><span class='font'>กิจกรรม CSR<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="news_events()" class=""><i
                                                      class="menu_icon blank fa"></i><span class='font'>ข่าวสารและกิจกรรม<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>

                                          <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="career()" class=""><i
                                                              class="menu_icon blank fa"></i><span class='font'>ร่วมงานกับเรา<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                          <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">

                                              <a (click)="contact_us()" class=""><i
                                                      class="menu_icon blank fa"></i><span class='font'>ติดต่อเรา
                                                      <span class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                              <a title="English" class=""><i
                                                      class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                                                          src="../assets/templates/home/th.png" alt="en"
                                                          title="ไทย"><span class="wpml-ls-native" class='font'>ไทย</span><span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span><span class="qode-featured-icon "
                                                      aria-hidden="true"></span></a>
                                              <div class="second" style="height: 0px;">
                                                  <div class="inner">
                                                      <ul>
                                                          <li id="nav-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                              <a title="ไทย" (click)="th()" class=""><i
                                                                      class="menu_icon blank fa"></i><span><img
                                                                          class="wpml-ls-flag"
                                                                          src="../assets/templates/home/en.png"
                                                                          alt="th" title="English"><span
                                                                          class="wpml-ls-native">English</span></span><span
                                                                      class="plus"></span><span
                                                                      class="qode-featured-icon "
                                                                      aria-hidden="true"></span></a></li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </li>
                                      </ul>
                                  </nav>
                                  <div class="header_inner_right">
                                      <div class="side_menu_button_wrapper right">
                                          <div class="header_bottom_right_widget_holder">
                                              <div class="widget_text header_bottom_widget widget_custom_html">
                                                  <div class="textwidget custom-html-widget">
                                                      <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="side_menu_button">
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <nav class="mobile_menu">
                          <ul id="menu-main-menu-1" class="">
                              <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="aboutUs()" class=""><span class='font'>เกี่ยวกับเรา
                                      </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="products()" class=""><span class='font'>ผลิตภัณฑ์</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="daicel_group()" class=""><span class='font'>ไดเซลกรุ๊ป
                                      </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="csr_page()" class=""><span class='font'>กิจกรรม CSR</span></a><span class="mobile_arrow"><i
                                          class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="news_events()" class=""><span class='font'>ข่าวสารและกิจกรรม</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>

                              <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="career()" class=""><span class='font'>ร่วมงานกับเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>

                              <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="contact_us()" class=""><span class='font'>ติดต่อเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                  <a title="English" class=""><span><img class="wpml-ls-flag"
                                              src="../assets/templates/home/th.png" alt="en" title="ไทย"><span
                                              class="wpml-ls-native font" >ไทย</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span>
                                  <ul class="sub_menu">
                                      <li id="mobile-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                          <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                      src="../assets/templates/home/en.png" alt="th"
                                                      title="English"><span
                                                      class="wpml-ls-native">English</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  </ul>
                              </li>
                          </ul>
                      </nav>

                  </div>
              </div>
          </div>
      </div>
  </div>

</header>
<a id="back_to_top" href="#" class="off">
    <span class="fa-stack">
        <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>
<div class="content content_top_margin_none">
  <div class="content_inner  ">
      <div class="title_outer title_without_animation" data-height="250">
          <div class="title title_size_small  position_left  has_fixed_background " style="background-size: 1920px; background-image: url(../../../../assets/templates/home/BG-E-1920x600-B60.jpg); height: 250px; background-position: center -52.5px;">
              <div class="image not_responsive"><img itemprop="image" src="../../../../assets/templates/home/BG-E-1920x600-B60.jpg" alt=" "> </div>
              <div class="title_holder" style="padding-top:0;height:250px;">
                  <div class="container">
                      <div class="container_inner clearfix">
                          <div class="title_subtitle_holder">
                              <div class="title_subtitle_holder_inner">
                                  <h1><span>Terms of Use</span></h1>

                                  <div class="breadcrumb" style="background-color: transparent;">
                                      <div class="breadcrumbs">
                                          <div itemprop="breadcrumb" class="breadcrumbs_inner"><a routerLink="/home">Home</a><span class="delimiter">&nbsp;&gt;&nbsp;</span><span class="current">Terms
                                                  of Use</span></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="container">
        <div class="container_inner default_template_holder clearfix page_container_inner">
          <div class="vc_row wpb_row section vc_row-fluid " style=" padding-bottom:128px; text-align:left;">
            <div class=" full_section_inner clearfix">
              <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper">
                    <div class="wpb_text_column wpb_content_element ">
                      <div class="wpb_wrapper">
                        <div class="in_text06">This Website (www.daicel-dsst.com) is controlled and operated by Daicel Safety Systems (Thailand) Co., Ltd. (hereafter "DSST").

                          Please carefully read the following terms of use (hereafter "Terms") when accessing this
                          Website. You can use this Website only, if you accept the Terms without limitation or
                          reservation. If you use this Website, you shall be deemed to have accepted all the Terms.</div>
                        <div></div>
                        <div></div>
                        <h2></h2>
                        <h2>1. Prohibition</h2>
                        <div class="privacy_text">When using this Website, the following actions are prohibited:
                          <br>
                          1. Actions to create damage or disadvantage to Daicel or any third party, or actions to injure
                          the credibility or honor of Daicel or any third party.
                          <br>
                          2.Actions to violate laws, regulations or the like, or actions which have a likelihood thereof;
                          and
                          <br>
                          3.Other than the aforementioned matters, actions that Daicel deems inappropriate.
                        </div>
                        <div></div>
                        <h2>2. Copyrights and Trademarks</h2>
                        <div class="privacy_text">The copyrights relating to the Contents of this Website belong to Daicel
                          and other copyright owners. Actions to use, reproduce, modify or any of this Website data are
                          prohibited without authority of Daicel.

                          The trademark, logo and corporate mark used at this Website revert to Daicel or its affiliated
                          company. You cannot use either of them without prior authorization from Daicel.
                        </div>
                        <div></div>
                        <h2>3. Links</h2>
                        <div class="privacy_text">1.This Website has links to or from third party's web sites. Contents of
                          third party's websites are controlled and operated under the responsibility of the relevant
                          third party. Therefore, Daicel shall not be responsible for any damages or troubles arising out
                          of, or resulting from any use of or access to such third party's websites.
                          <br>
                          2.If you wish to have a link to this Website, please obtain prior approval from Daicel.
                        </div>
                        <div></div>
                        <h2>4. Privacy Policy</h2>
                        <div class="in_text">
                          Daicel will treat the personal information received through this Website in compliance with the
                          "
                          <a href="https://www.daicel.com/en/personalinformation/">Daicel Group Policy on the Protection
                            of Personal Information (Privacy Policy)
                          </a>
                          ".


                        </div>
                        <h2>5. Cookie policy</h2>
                        <div class="privacy_text">Please read this Cookie Policy carefully before you start using our
                          website. By continuing to use this website, you agree to our Cookie Policy. If you do not agree
                          to our Cookie Policy, please disable cookies in your browser settings. Note, however, that if
                          you disable cookies, certain functions on this website may not work properly.
                        </div>
                        <h4>
                          Use of cookies on this website
                        </h4>

                        <div class="privacy_text">In order to provide greater user convenience and maintain and improve
                          service quality, this website uses cookies for the purposes stated below, not for the purpose of
                          obtaining personal information of users.
                          <br>
                          <i class="fa fa-circle" aria-hidden="true" style="font-size: 10px"></i> For an optimal display
                          and better services on the website
                          <br>
                          <i class="fa fa-circle" aria-hidden="true" style="font-size: 10px"></i> For statistical research
                          on and analysis of the access to the website (Google Analytics)
                        </div>

                        <h4>
                          Use of Google Analytics
                        </h4>

                        <div class="privacy_text">This website uses Google Analytics, a web analytic service provided by
                          Google LLC, to track the access status on the website. Google Analytics analyzes the access
                          status by using cookies we provide. We receive the analysis results from Google LLC., and grasp
                          the utilization of our website.
                          The information that is collected, recorded and analyzed by Google Analytics does not involve
                          any information to identify specific individuals. The information collected, recorded and
                          analyzed by Google Analytics is managed by Google LLC. in accordance with its privacy policy.
                          Please refer to the terms of service of Google Analytics and the privacy policy of Google, Inc.
                          through the following links.
                          <br>
                          <a href="https://marketingplatform.google.com/about/analytics/terms/us/">Google Analytics Terms
                            of Service </a>
                          <br>
                          <a href="https://policies.google.com/privacy/embedded?hl=en">Privacy Policy of Google LLC.</a>
                          <br>




                        </div>

                        <h4>
                          Managing and clearing cookies
                        </h4>

                        <div class="privacy_text">You can disable cookies by changing browser settings of your computer,
                          tablet or smartphone. You can also delete cookies from your browser.
                          <br>

                        </div>



                        <h4>
                          Inquiries about our Cookie Policy
                        </h4>

                        <div class="privacy_text">If you have any questions or concerns about this Cookie Policy, please contact us using the contact form via the link below.
                          When you disable cookies, you can still use this website but certain functions on the website may not be available.
                          For how to change browser settings, please refer to the website of the relevant browser company.
                          <br>
                          <a href="https://www.daicel.com/en/inquiry/input?inquiry_type=5">Contact us </a>
                          <br>
                        </div>



                        <div></div>
                        <h2>6. Escape Clause</h2>
                        <div class="privacy_text">Daicel has paid special attention to ensure that the information included on this Website will be updated and accurate. However, Daicel does not guarantee or accept any responsibility for the appropriateness and accuracy of the content of this Website.
                          <br>
                          Additionally, please approve of the fact that the content or URL of this Website may be changed, or suspended, or the operation thereof ceased, without prior notice. Regardless of reason, Daicel does not assume responsibility for any damages arising from changes to the content of this Website or from suspension or cessation of the operation.
                        </div>
                        <div></div>
                        <h2>7. Proper Law & Competent Court</h2>
                        <div class="privacy_text">The clauses as to the utilization of our site and requirements for this utilization shall comply with the Japan Law.
                          Also, all disputes associated with the utilization of our site shall be settled in Osaka District Court as the exclusive competent court of the first instance.
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

  </div>
</div>
<app-footer-th></app-footer-th>
