import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pyro-fuse',
  templateUrl: './pyro-fuse.component.html',
  styleUrls: ['./pyro-fuse.component.css']
})
export class PyroFuseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  career() {
    window.location.href = "/career";
  }
  aboutUs() {
    window.location.href = "/about-us";
  }
  products() {
    window.location.href = "/products";
  }
  daicel_group() {
    window.location.href = "/daicel-group";
  }
  csr_page() {
    window.location.href = "/csr";
  }
  news_events() {
    window.location.href = "/news-events";
  }
  contact_us() {
    window.location.href = "/contact-us";
  }
  th() {
    window.location.href = "/th/products";
  }
  home() {
    window.location.href = "/";
  }


}
