import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ContentService } from "src/app/services/content.service";
import { UserModel } from "../../models/user";
import { contentModel } from "../../models/content";

import { formatDate } from "@angular/common";

@Component({
  selector: "app-career-all",
  templateUrl: "./career-all.component.html",
  styleUrls: ["./career-all.component.css"],
})
export class CareerAllComponent implements OnInit {
  public readonly USERLOGIN: UserModel =
    this.authenticationService.currentUserValue;
  public content: contentModel;
  public content1: contentModel;
  public dataCareer = [];

  date_now = formatDate(new Date(), "yyyy-MM-dd", "en");
  name: string = "";
  c = 0;
  allID = [];
  titleEn: string = "";
  detailEn: string = "";
  titleTh: string = "";
  detailTh: string = "";
  words2 = [
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
  ];

  words1 = [
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private contentService: ContentService
  ) {}

  async clear() {
    this.c += 1;
    this.content = await this.contentService.findJob().toPromise();
  }

  async ngOnInit() {
    this.dataCareer = [];
    let show = 0;
    let res = await this.contentService.findJob().toPromise();

    // date_now < item.published_date
    for (let i = 0; i < res.length; i++) {
      if (this.date_now >= res[i].published_date) {
        show = 1;
      } else {
        show = 0;
      }

      this.dataCareer.push({
        active: res[i].active,
        event_name_en: res[i].event_name_en,
        post_date: res[i].post_date,
        published_date: res[i].published_date,
        event_id: res[i].event_id,
        event_name_th: res[i].event_name_th.split("<br>")[2],
        show: show,
      });
    }
    this.dataCareer.sort((a, b) => b.show - a.show);

    console.log(this.dataCareer);

    this.content1 = await this.contentService.findSetting().toPromise();
    this.getValue();
  }

  editItem(id) {
    this.router.navigate(["career/edit/" + id]);
  }

  async deleteItem(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        await this.contentService.delete(id).toPromise();
        this.content = await this.contentService.findJob().toPromise();
      }
    });
  }

  async onSearch(keyword) {
    this.dataCareer = [];
    if (keyword != "") {
      let res = await this.contentService.getContentByJob(keyword).toPromise();

      for (let i = 0; i < res.length; i++) {
        this.dataCareer.push({
          active: res[i].active,
          event_name_en: res[i].event_name_en,
          post_date: res[i].post_date,
          published_date: res[i].published_date,
          event_id: res[i].event_id,
          event_name_th: res[i].event_name_th.split("<br>")[2],
        });
      }
    } else {
      let res = await this.contentService.findJob().toPromise();
      for (let i = 0; i < res.length; i++) {
        this.dataCareer.push({
          active: res[i].active,
          event_name_en: res[i].event_name_en,
          post_date: res[i].post_date,
          published_date: res[i].published_date,
          event_id: res[i].event_id,
          event_name_th: res[i].event_name_th.split("<br>")[2],
        });
      }
    }
  }

  home() {
    window.location.href = "/home";
  }

  setValue() {
    for (let i = 0; i < this.words1.length; i++) {
      if (this.words1[i]["values"] != "") {
        this.detailEn = this.detailEn + this.words1[i]["values"] + "<br>";
      }
    }
    for (let i = 0; i < this.words2.length; i++) {
      if (this.words2[i]["values"] != "") {
        this.detailTh = this.detailTh + this.words2[i]["values"] + "<br>";
      }
    }
  }

  async repost() {
    let name_admin = await this.authenticationService.getCoockie();
    this.name = JSON.parse(name_admin);
    console.log(this.dataCareer);
    // console.log( JSON.stringify(this.dataCareer).split('{"event_id":').length)
    // let num = JSON.stringify(this.content).split('{"event_id":').length;

    console.log(this.name["username"]);
    for (let i = 0; i < this.dataCareer.length; i++) {
      if (this.dataCareer[i]["published_date"] != "2500-01-01") {
        let formData = new FormData();
        formData.append("id", this.dataCareer[i]["event_id"]);
        formData.append("post_by", this.name["username"]);
        formData.append(
          "post_date",
          formatDate(new Date(), "yyyy-MM-dd", "en")
        );
        formData.append(
          "published_date",
          formatDate(new Date(), "yyyy-MM-dd", "en")
        );

        await this.contentService.updateContent(formData).toPromise();
      }
    }

    Swal.fire({
      title: "Re-post successfully",
      text: "Click close button to back to the Post page",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Close",
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      location.reload();
    });
  }

  async repost_1(id) {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("post_by", this.name["username"]);
    formData.append("post_date", formatDate(new Date(), "yyyy-MM-dd", "en"));
    formData.append(
      "published_date",
      formatDate(new Date(), "yyyy-MM-dd", "en")
    );
    await this.contentService.updateContent(formData).toPromise();
    Swal.fire({
      title: "Re-post successfully",
      text: "Click close button to back to the Post page",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Close",
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      location.reload();
    });
  }

  async show(id) {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("post_by", this.name["username"]);
    formData.append("post_date", formatDate(new Date(), "yyyy-MM-dd", "en"));
    formData.append(
      "published_date",
      formatDate(new Date(), "yyyy-MM-dd", "en")
    );
    await this.contentService.updateContent(formData).toPromise();
    Swal.fire({
      title: "Update successfully",
      text: "Click close button to back to the Post page",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Close",
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      location.reload();
    });
  }

  async notshow(id) {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("post_by", this.name["username"]);
    formData.append("post_date", formatDate(new Date(), "yyyy-MM-dd", "en"));
    formData.append(
      "published_date",
      formatDate(new Date(), "2500-01-01", "en")
    );
    await this.contentService.updateContent(formData).toPromise();
    Swal.fire({
      title: "Update successfully",
      text: "Click close button to back to the Post page",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Close",
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      location.reload();
    });
  }

  getValue() {
    this.words2 = [
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
    ];

    this.words1 = [
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
    ];

    let num = this.content1[0]["content_detail_th"].split("<br>").length - 1;
    let num1 = this.content1[0]["content_detail_en"].split("<br>").length - 1;
    for (let i = 0; i < num; i++) {
      this.words2[i]["values"] =
        this.content1[0]["content_detail_th"].split("<br>")[i];
    }
    for (let i = 0; i < num1; i++) {
      this.words1[i]["values"] =
        this.content1[0]["content_detail_en"].split("<br>")[i];
    }
  }

  async save() {
    this.setValue();
    let name_admin = await this.authenticationService.getCoockie();
    this.name = JSON.parse(name_admin);
    let formData = new FormData();
    formData.append("id", this.content1[0]["event_id"]);
    formData.append("event_name_th", "");
    formData.append("event_name_en", "");
    formData.append("content_detail_th", this.detailTh);
    formData.append("content_detail_en", this.detailEn);
    formData.append("post_by", this.name["username"]);
    formData.append("post_date", formatDate(new Date(), "yyyy-MM-dd", "en"));
    formData.append(
      "published_date",
      formatDate(new Date(), "yyyy-MM-dd", "en")
    );
    await this.contentService.updateContent(formData).toPromise();
    Swal.fire({
      title: "Update successfully",
      text: "Click close button to back to the Post page",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Close",
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      location.reload();
    });
  }

  async copy(id) {
    let name_admin = await this.authenticationService.getCoockie();
    this.name = JSON.parse(name_admin);
    let content = await this.contentService.findJobById(id).toPromise();

    let data = {
      event_name_th: content.event_name_th,
      event_name_en: content.event_name_en + " - Copy",
      content_detail_th: content.content_detail_th,
      content_detail_en: content.content_detail_en,
      event_datetime: formatDate(new Date(), "yyyy-MM-dd", "en"),
      published_date: formatDate(new Date(), "2500-01-01", "en"),
      post_by: this.name["username"],
      csr: "0",
      com_news: "0",
      events: "0",
      active: content.active,
    };

    await this.contentService.addContent(data).toPromise();
    Swal.fire({
      title: "Copy successfully",
      text: "Click close button to back to the Post page",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Close",
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      location.reload();
    });
  }
}
