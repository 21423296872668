<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap" rel="stylesheet">
<title>Daicel Thailand | Career</title>
<header class="has_header_fixed_right stick menu_bottom">
  <div class="header_inner clearfix">
      <div class="header_top_bottom_holder">
          <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
              <div class="container">
                  <div class="container_inner clearfix">
                      <div class="header_inner_left">
                          <div class="mobile_menu_button">
                              <span>
                                  <i class="fa fa-bars"></i> </span>
                          </div>
                          <div class="logo_wrapper">
                              <div class="q_logo">
                                  <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                      <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="light" src="../assets/templates/home/DSST-Logo-300x70.png"
                                          alt="Logo" style="height: 100%;"> <img itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="sticky" src="../assets/templates/home/DSST-Logo-300x70.png"
                                          alt="Logo" style="height: 100%;"> <img itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> </a>
                              </div>
                          </div>
                          <div class="header_fixed_right_area">
                            <div class="textwidget custom-html-widget">
                                <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                            <span class="empty_space_image"></span>
                                    </span>
                                </div>

                                <span style="display: inline-block;">
                                  <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                                      <div class="icon_holder " style="">
                                          <img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                              src="../assets/templates/home/pr.png" alt="">
                                      </div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;
                                            font-family: 'Open Sans', sans-serif!important;
                                            "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              ">  241 หมู่ 4, เขตอุตสาหกรรม 304 <br>
                                                ปราจีนบุรี 25140 ประเทศไทย <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>

                              <span style="display: inline-block; margin-right: 15px;">
                                  <div class="q_icon_with_title medium custom_icon_image ">
                                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    "
                                              src="../assets/templates/home/sb.png" alt=""></div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;               font-family: 'Open Sans', sans-serif!important;

                                            ">
                                                +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              ">  76 หมู่ 1, อำเภอพระพุทธบาท<br>
                                              สระบุรี 18120 ประเทศไทย <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>

                              <span style="display: inline-block;">
                                  <div class="q_icon_with_title medium custom_icon_image ">
                                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    "
                                              src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;

                                            "> daicel-dsst.com<br>

                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                              font-weight: 400!important;
                                              ">  วันจันทร์ - วันศุกร์ 08h - 17h<br>หยุดวันเสาร์ - อาทิตย์


                                               <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>


                            </div>
                        </div>
                      </div>
                      <div class="header_menu_bottom">
                          <div class="header_menu_bottom_inner">
                              <div class="main_menu_header_inner_right_holder">
                                  <nav class="main_menu drop_down">
                                      <ul id="menu-main-menu" class="clearfix">
                                          <li id="nav-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="aboutUs()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">เกี่ยวกับเรา
                                                      <span class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="products()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ผลิตภัณฑ์<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="daicel_group()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ไดเซลกรุ๊ป<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="csr_page()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">กิจกรรม CSR<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="news_events()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ข่าวสารและกิจกรรม<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>

                                          <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="career()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ร่วมงานกับเรา<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                          <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">

                                              <a (click)="contact_us()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ติดต่อเรา
                                                      <span class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                              <a title="English"  class=""><i
                                                      class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                                                          src="../assets/templates/home/th.png" alt="en"
                                                          title="ไทย"><span class="wpml-ls-native" style="font-family: 'Sarabun';">ไทย</span><span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span><span class="qode-featured-icon "
                                                      aria-hidden="true"></span></a>
                                              <div class="second" style="height: 0px;">
                                                  <div class="inner">
                                                      <ul>
                                                          <li id="nav-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                              <a title="ไทย" (click)="th()" class=""><i
                                                                      class="menu_icon blank fa"></i><span><img
                                                                          class="wpml-ls-flag"
                                                                          src="../assets/templates/home/en.png"
                                                                          alt="th" title="English"><span
                                                                          class="wpml-ls-native">English</span></span><span
                                                                      class="plus"></span><span
                                                                      class="qode-featured-icon "
                                                                      aria-hidden="true"></span></a></li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </li>
                                      </ul>
                                  </nav>
                                  <div class="header_inner_right">
                                      <div class="side_menu_button_wrapper right">
                                          <div class="header_bottom_right_widget_holder">
                                              <div class="widget_text header_bottom_widget widget_custom_html">
                                                  <div class="textwidget custom-html-widget">
                                                      <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="side_menu_button">
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <nav class="mobile_menu">
                          <ul id="menu-main-menu-1" class="">
                              <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="aboutUs()" class=""><span style="font-family: 'Sarabun';">เกี่ยวกับเรา
                                      </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="products()" class=""><span style="font-family: 'Sarabun';">ผลิตภัณฑ์</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="daicel_group()" class=""><span style="font-family: 'Sarabun';">ไดเซลกรุ๊ป
                                      </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="csr_page()" class=""><span style="font-family: 'Sarabun';">กิจกรรม CSR</span></a><span class="mobile_arrow"><i
                                          class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="news_events()" class=""><span style="font-family: 'Sarabun';">ข่าวสารและกิจกรรม</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>

                              <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="career()" class=""><span style="font-family: 'Sarabun';">ร่วมงานกับเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>

                              <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="contact_us()" class=""><span style="font-family: 'Sarabun';">ติดต่อเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                  <a title="English"  class=""><span><img class="wpml-ls-flag"
                                              src="../assets/templates/home/th.png" alt="en" title="ไทย"><span style="font-family: 'Sarabun';"
                                              class="wpml-ls-native">ไทย</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span>
                                  <ul class="sub_menu">
                                      <li id="mobile-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                          <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                      src="../assets/templates/home/en.png" alt="th"
                                                      title="English"><span
                                                      class="wpml-ls-native">English</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  </ul>
                              </li>
                          </ul>
                      </nav>

                  </div>
              </div>
          </div>
      </div>
  </div>

</header>
<a id="back_to_top" href="#" class="off">
    <span class="fa-stack">
    <i class="qode_icon_font_awesome fa fa-arrow-up"></i>
  </span>
</a>

<section #target style="background-color: white;margin-top: -20px;">
  <div class="box__search-filter">
    <div class="container">
      <form id="search_form" name="search_form" method="post" action="javascript:void(null);"
        enctype="multipart/form-data">
        <input type="hidden" name="search_form_token" id="search_form_token">
        <div class="row" style="margin-top: 20px;">
          <div class="col-lg-3">
            <h2 class="text" class="search-txt">Search</h2>
          </div>

          <div class="col-lg-9">
            <div class="row">
              <div class="col-5">
                <div class="form-group" style="margin-left:10px;margin-top: 4%">
                  <ng-select (change)="onChange($event)" [items]="cities" bindLabel="name" placeholder="Select Location"
                    name="selectedLocation" formControlName="selectedLocation"

                    >
                  </ng-select>



                </div>
              </div>
              <div class="col-6">
                <div class="form-group" style="margin-left:10px;margin-top: 3%">

                  <input (keyup)="filterData($event)" style="    border: none;

                        font-size: 16px !important;
                        background: black;
                        color: white;
                        border-bottom: 1px solid  white;
                        border-radius: 0px;
                      font-weight: 700;

                    " type="text" class="form-control" placeholder="Keyword in Position" id="search_keyword"
                    name="search_keyword" value="">

                </div>
              </div>




              <div class="form-group" *ngIf="!mobile">

                <button class="submit" type="submit" id="btn_submit_search" name="btn_submit_search">Submit</button>
              </div>
            </div>




          </div>

        </div>

      </form>
    </div>
  </div>


  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" *ngIf="mobile">

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef style="text-align:center;"> </th>
      <td mat-cell *matCellDef="let element" style="width: 5%;text-align: left;">
        <div *ngIf="element.active == '3' ">
          <span style="color: red;font-weight: bold; font-style: italic;text-align: left;">Urgent</span>
        </div>
        <p style="font-size: 12px;font-weight: 700;font-family: Montserrat,sans-serif;">
          Position
        </p>
        <p style="margin-top: -10px;">
          <b style="color:#424242">{{element.event_name_en}}</b>
        </p>
        <p style="font-size: 12px;font-weight: 700;font-family: Montserrat,sans-serif;">
          Vacant
        </p>
        <p style="margin-top: -10px;">
          <i *ngFor="let i of Arr(element.vacant).fill(1)" class="fa fa-user" style="padding-right: 5px;color:#666"
            aria-hidden="true"></i>
        </p>
        <p style="font-size: 12px;font-weight: 700;font-family: Montserrat,sans-serif;">
          Location
        </p>
        <p style="margin-top: -10px;">
          <b style="color:#424242">{{element.location}} </b>
        </p>
        <p style="font-size: 12px;font-weight: 700;font-family: Montserrat,sans-serif;">
          Post Date
        </p>
        <p style="margin-top: -10px;color:#424242">
      <b> {{element.published_date | date: "dd MMM
        yyyy" }}</b>
        </p><br>
      </td>
    </ng-container>



    <ng-container matColumnDef="expandedDetail" style="border-bottom: 1px solid red;">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

          <div class="example-element-description" style="width: 100%;padding:0px">


            <div class="tab-content">
              <div class="row">
                <h4 style="padding-left: 15px;text-align:left ;" *ngIf="element.location == 'Prachinburi'">
                  Daicel Safety Systems (Thailand) Co., Ltd. (Head Office : Prachinburi)
                </h4>
                <h4 style="padding-left: 15px;text-align:left ;" *ngIf="element.location == 'Saraburi'">
                  Daicel Safety Systems (Thailand) Co., Ltd. (Branch Office : Saraburi)
                </h4>
              </div>

              <div class="row">
                <div class="col left-border">
                  <p style="color: #666;text-align: left;font-weight: 700;">
                    Job Description :
                  </p>
                  <p style="color: black;    padding-top: 5px;text-align: left;
      font-size: 14px;" [innerHTML]="element.content_detail_en">
                  </p>



                </div>
                <div class="col ">
                  <p style="color: #666;text-align: left;font-weight: 700;">
                    Qualifications :
                  </p>
                  <p style="color: black;    padding-top: 5px;text-align: left;
      font-size: 14px;" [innerHTML]="element.content_detail_th">
                  </p>



                </div>
              </div>

              <div class="grid-container">
                <div class="grid-item"> <a (click)="editItem(element.event_id)" class="btn " style="background-color: #4747b5;display: block;
                  text-align: center;font-size: 12px;

color: white;
font-weight: bold;"><i class="fas fa-info-circle"></i> DETAIL</a></div>
                <div class="grid-item">
                  <a (click)="link(element.event_name_th)" class="btn " style="color: white;display: block;
            text-align: center;
          background-color: #ed1b2f;font-size: 12px;
                  font-weight: bold;"><i class="fas fa-edit"></i> APPLY NOW</a>

                </div>

              </div>



            </div>


          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumnsMobile;let row" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" *ngIf="!mobile">

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef style="text-align:center;"> </th>
      <td mat-cell *matCellDef="let element" style="width: 5%;">
        <div *ngIf="element.active == '3' ">
          <span style="color: red;font-weight: bold; font-style: italic;">Urgent</span>
        </div>
      </td>
    </ng-container>


    <ng-container matColumnDef="event_name_en" style=" ">

      <th mat-header-cell *matHeaderCellDef style="text-align:left;padding-left: 20px;font-family: Montserrat,sans-serif;"> POSITION</th>
      <td mat-cell *matCellDef="let element"
        style=" padding: 20px 0 20px 20px;text-align: left;color:#424242;width: 40%;"><b> {{element.event_name_en}}</b>
      </td>
    </ng-container>

    <ng-container matColumnDef="vacant">
      <th mat-header-cell *matHeaderCellDef style="text-align:center;font-family: Montserrat,sans-serif;"> VACANT </th>
      <td mat-cell *matCellDef="let element" style="width: 10%; ">
        <i *ngFor="let i of Arr(element.vacant).fill(1)" class="fa fa-user" style="padding-right: 5px;color:#666"
          aria-hidden="true"></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef style="text-align:center;font-family: Montserrat,sans-serif;"> LOCATION </th>
      <td mat-cell *matCellDef="let element" style="text-align: left;width: 10%;"><i class="fa fa-map-marker"
          aria-hidden="true" style="color:red;font-size: 22px;"></i> <b
          style="padding-left: 5px;color:#424242">{{element.location}} </b> </td>
    </ng-container>

    <ng-container matColumnDef="published_date" style="">
      <th mat-header-cell *matHeaderCellDef style="text-align:center;font-family: Montserrat,sans-serif;"> POST DATE</th>
      <td mat-cell *matCellDef="let element" style="color:#424242;width: 10%; ">{{element.published_date | date: "dd MMM
        yyyy" }} </td>
    </ng-container>



    <ng-container matColumnDef="expandedDetail" style="border-bottom: 1px solid red;">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

          <div class="example-element-description" style="width: 100%;">


            <div class="tab-content">
              <div class="row">
                <h4 style="padding-left: 15px;text-align:left ;" *ngIf="element.location == 'Prachinburi'">
                  Daicel Safety Systems (Thailand) Co., Ltd. (Head Office : Prachinburi)
                </h4>
                <h4 style="padding-left: 15px;text-align:left ;" *ngIf="element.location == 'Saraburi'">
                  Daicel Safety Systems (Thailand) Co., Ltd. (Branch Office : Saraburi)
                </h4>
              </div>

              <div class="row">
                <div class="col left-border">
                  <p style="color: #666;text-align: left;font-weight: 700;">
                    Job Description :
                  </p>
                  <p style="color: black;    padding-top: 5px;text-align: left;
      font-size: 16px;" [innerHTML]="element.content_detail_en">
                  </p>



                </div>
                <div class="col ">
                  <p style="color: #666;text-align: left;font-weight: 700;">
                    Qualifications :
                  </p>
                  <p style="color: black;    padding-top: 5px;text-align: left;
      font-size: 16px;" [innerHTML]="element.content_detail_th">
                  </p>



                </div>
              </div>
              <div class="row" style="margin-bottom: 20px;margin-top: 20px;">
                <div class="col">

                </div>
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <a (click)="editItem(element.event_id)" class="btn " style="background-color: #4747b5;display: block;
                              text-align: center;
                              height: 48px;
                              border-radius: 48px;
          color: white;
          font-weight: bold;"><i class="fas fa-info-circle" style="margin-top: 8px;"></i> DETAIL</a>
                    </div>
                    <div class="col">
                      <a (click)="link(element.event_name_th)" class="btn " style="
          color: white;display: block;
    text-align: center;
    height: 48px;
    border-radius: 48px;background-color: #ed1b2f;
          font-weight: bold;"><i class="fas fa-edit" style="margin-top: 8px;"></i> APPLY NOW</a>
                    </div>
                  </div>

                </div>

              </div>
            </div>


          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;let row" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>






</section>
<section style=" background-color: white;height: 40vh; ">

</section>

<app-footer-th></app-footer-th>
