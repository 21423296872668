import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { contentModel } from 'src/app/models/content';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-career-th-detail',
  templateUrl: './career-th-detail.component.html',
  styleUrls: ['./career-th-detail.component.css']
})
export class CareerThDetailComponent implements OnInit {
  public content: contentModel;
  public content1: contentModel;
  vacant :number;
  Arr = Array;
  mobile = false;
  id = 0;
  location : string;
  ckContent  : contentModel;
  date_now  =  formatDate(new Date(), 'yyyy-MM-dd', 'en')
  constructor(   private route: ActivatedRoute, private contentService: ContentService) {

   }

   async ngOnInit() {
    if (window.screen.width < 640) { // 768px portrait
      this.mobile = true;

    }
    else {
      this.mobile = false;
    }

    this.route.params.subscribe(async params => {
      this.id = params["id"]
    })
    this.ckContent = await this.contentService.findJobById(this.id + '').toPromise();
    if(this.ckContent==null){
      window.location.href = '/career';
    }
    else if( Date.parse(this.date_now) < Date.parse(this.ckContent.published_date)  ){
      window.location.href = '/career';
    }
    else{
      this.content = this.ckContent
      this.vacant = parseInt((this.content['event_name_th']).split('<br>')[1])
      this.location = (this.content['event_name_th']).split('<br>')[2]
      this.content1 = await this.contentService.findSetting().toPromise();
    }

  }

  link(url) {
    let arrayUrl = url.split('<br>');
    window.open(arrayUrl[0], "_blank");
  }

  aboutUs() {
    window.location.href = '/th/about-us';

  }
  products() {
    window.location.href = '/th/products';

  }
  daicel_group() {
    window.location.href = '/th/daicel-group';

  }
  csr_page() {
    window.location.href = '/th/csr';

  }
  news_events() {
    window.location.href = '/th/news-events';

  }
  contact_us() {
    window.location.href = '/th/contact-us';


  }
  career() {
    window.location.href = '/th/career';


  }
  home() {
    window.location.href = '/th';


  }
  th() {
    window.location.href = "/career/detail/" + this.id

  }


}
