<title>Daicel Thailand | เกี่ยวกับเรา</title>
<header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
    <div class="header_inner clearfix">
        <div class="header_top_bottom_holder">
            <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
                <div class="container">
                    <div class="container_inner clearfix">
                        <div class="header_inner_left">
                            <div class="mobile_menu_button">
                                <span>
                                    <i class="fa fa-bars"></i> </span>
                            </div>
                            <div class="logo_wrapper">
                                <div class="q_logo">
                                    <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                        <img itemprop="image" class="normal"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="light"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="dark"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="sticky"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="mobile"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> </a>
                                </div>
                            </div>
                            <div class="header_fixed_right_area">
                                <div class="textwidget custom-html-widget">
                                    <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                            <span class="empty_space_image"></span>
                                        </span>
                                    </div>

                                    <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image "
                                            style="margin-right: 5px;">
                                            <div class="icon_holder " style="">
                                                <img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      " src="../assets/templates/home/pr.png" alt="">
                                            </div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                    <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;
                                            font-family: 'Open Sans', sans-serif!important;
                                            "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              "> 241 หมู่ 4, เขตอุตสาหกรรม 304 <br>
                                                        ปราจีนบุรี 25140 ประเทศไทย <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block; margin-right: 15px;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    " src="../assets/templates/home/sb.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                    <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;               font-family: 'Open Sans', sans-serif!important;

                                            ">
                                                        +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              "> 76 หมู่ 1, อำเภอพระพุทธบาท<br>
                                                        สระบุรี 18120 ประเทศไทย <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    " src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                    <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;

                                            "> daicel-dsst.com<br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                              font-weight: 400!important;
                                              "> วันจันทร์ - วันศุกร์ 08h - 17h<br>หยุดวันเสาร์ - อาทิตย์


                                                        <br>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>


                                </div>
                            </div>
                        </div>
                        <div class="header_menu_bottom">
                            <div class="header_menu_bottom_inner">
                                <div class="main_menu_header_inner_right_holder">
                                    <nav class="main_menu drop_down">
                                        <ul id="menu-main-menu" class="clearfix">
                                            <li id="nav-menu-item-1532"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="aboutUs()" class=""><i class="menu_icon blank fa"></i><span
                                                        class='font'>เกี่ยวกับเรา
                                                        <span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1530"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="products()" class=""><i class="menu_icon blank fa"></i><span
                                                        class='font'>ผลิตภัณฑ์<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1526"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="daicel_group()" class=""><i
                                                        class="menu_icon blank fa"></i><span
                                                        class='font'>ไดเซลกรุ๊ป<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1527"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="csr_page()" class=""><i class="menu_icon blank fa"></i><span
                                                        class='font'>กิจกรรม CSR<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1529"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="news_events()" class=""><i
                                                        class="menu_icon blank fa"></i><span
                                                        class='font'>ข่าวสารและกิจกรรม<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>

                                            <li id="nav-menu-item-1529"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="career()" class=""><i class="menu_icon blank fa"></i><span
                                                        class='font'>ร่วมงานกับเรา<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1531"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">

                                                <a (click)="contact_us()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>ติดต่อเรา
                                                        <span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-wpml-ls-12-en"
                                                class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                                <a title="English" class=""><i class="menu_icon blank fa"></i><span><img
                                                            class="wpml-ls-flag" src="../assets/templates/home/th.png"
                                                            alt="en" title="ไทย"><span class="wpml-ls-native"
                                                            class='font'>ไทย</span><span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span><span class="qode-featured-icon "
                                                        aria-hidden="true"></span></a>
                                                <div class="second" style="height: 0px;">
                                                    <div class="inner">
                                                        <ul>
                                                            <li id="nav-menu-item-wpml-ls-12-th"
                                                                class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                                <a title="ไทย" (click)="th()" class=""><i
                                                                        class="menu_icon blank fa"></i><span><img
                                                                            class="wpml-ls-flag"
                                                                            src="../assets/templates/home/en.png"
                                                                            alt="th" title="English"><span
                                                                            class="wpml-ls-native">English</span></span><span
                                                                        class="plus"></span><span
                                                                        class="qode-featured-icon "
                                                                        aria-hidden="true"></span></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div class="header_inner_right">
                                        <div class="side_menu_button_wrapper right">
                                            <div class="header_bottom_right_widget_holder">
                                                <div class="widget_text header_bottom_widget widget_custom_html">
                                                    <div class="textwidget custom-html-widget">
                                                        <div class="vc_row wpb_row section vc_row-fluid "
                                                            style=" text-align:left;">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="side_menu_button">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav class="mobile_menu">
                            <ul id="menu-main-menu-1" class="">
                                <li id="mobile-menu-item-1532"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="aboutUs()" class=""><span class='font'>เกี่ยวกับเรา
                                        </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1530"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="products()" class=""><span class='font'>ผลิตภัณฑ์</span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1526"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="daicel_group()" class=""><span class='font'>ไดเซลกรุ๊ป
                                        </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1527"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="csr_page()" class=""><span class='font'>กิจกรรม CSR</span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1529"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="news_events()" class=""><span
                                            class='font'>ข่าวสารและกิจกรรม</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>

                                <li id="mobile-menu-item-1529"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="career()" class=""><span class='font'>ร่วมงานกับเรา</span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>

                                <li id="mobile-menu-item-1531"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="contact_us()" class=""><span class='font'>ติดต่อเรา</span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-wpml-ls-12-en"
                                    class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                    <a title="English" class=""><span><img class="wpml-ls-flag"
                                                src="../assets/templates/home/th.png" alt="en" title="ไทย"><span
                                                class="wpml-ls-native font">ไทย</span></span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span>
                                    <ul class="sub_menu">
                                        <li id="mobile-menu-item-wpml-ls-12-th"
                                            class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                            <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                        src="../assets/templates/home/en.png" alt="th"
                                                        title="English"><span
                                                        class="wpml-ls-native">English</span></span></a><span
                                                class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                    class="fa fa-angle-down"></i></span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
    </div>

</header>
<a id="back_to_top" href="#" class="off">
    <span class="fa-stack">
        <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>

<div class="content content_top_margin_none">
    <div class="content_inner  ">
        <div class="title_outer title_without_animation" data-height="250">
            <div class="title title_size_small  position_left  has_fixed_background "
                style="background-size:1920px auto;background-image:url(../../../../assets/templates/home/BG-E-1920x600-B60.jpg);height:250px;">
                <div class="image not_responsive"><img itemprop="image"
                        src="../../../../assets/templates/home/BG-E-1920x600-B60.jpg" alt="&nbsp;" /> </div>
                <div class="title_holder" style="padding-top:0;height:250px;">
                    <div class="container">
                        <div class="container_inner clearfix">
                            <div class="title_subtitle_holder">
                                <div class="title_subtitle_holder_inner">
                                    <h1><span>เกี่ยวกับเรา</span></h1>

                                    <div class="breadcrumb" style="background-color: transparent;">
                                        <div class="breadcrumbs">
                                            <div itemprop="breadcrumb"><a (click)="home()">Home</a><span
                                                    class="delimiter">&nbsp;>&nbsp;</span><span
                                                    class="current">เกี่ยวกับเรา
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="full_width">
            <div class="full_width_inner">
                <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518017477874 grid_section"
                    style=' text-align:left;background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>
                    <div class=" section_inner clearfix">
                        <div class='section_inner_margin clearfix'>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                            <div class="wpb_wrapper">
                                                <br><br><br><br><br>
                                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                    <img style="width:600 ;"
                                                        src="../../../../assets/templates/home/DSST-Logo-bg.png" alt="">


                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 32px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <p style="text-align: center;">ไดเซล คอร์เปอร์เรชั่น
                                                        เป็นผู้ผลิตรายใหญ่ของระบบความปลอดภัย (Inflator and Pretensioner
                                                        Gas Generator (PGG)) และส่วนประกอบที่เกี่ยวข้องเช่น
                                                        ตัวเริ่มปฎิกิริยาและระบบหล่อเย็นในโลก
                                                    </p>

                                                </div>
                                            </div>
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <p style="text-align: center;">DSST
                                                        ได้รับการจัดตั้งขึ้นเป็นครั้งที่สองของฐานธุรกิจในต่างประเทศ
                                                        สำหรับ Inflator ในปี 2545 และ Initiator ในปี 2548
                                                    </p>
                                                    <p>เราได้ขยายเขตการค้าไปเกือบทั้งทั่วเอเชีย ยกเว้นประเทศจีน
                                                        ไต้หวันและเกาหลี ในเครือของ Daicel group.</p>

                                                </div>
                                            </div>
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <p style="text-align: center;">บริษัท ของเรา มีคติพจน์ที่ว่า <span
                                                            style="color: #0071be;"><strong>“เราคือผู้ช่วยชีวิต”</strong></span>
                                                        บุคลากรทุกคนของบริษัทกำลังพยายามที่จะจัดหาผลิตภัณฑ์ที่มีการแข่งขัน
                                                        ด้วยความปลอดภัยและคุณภาพระดับสูงสุดในตลาด
                                                    </p>
                                                    <p style="text-align: center;">
                                                        เราถือเป็นเกียรติอย่างมากในการก่อตั้งธุรกิจในประเทศไทยและได้รับประสบการณ์การสนับสนุนจากผู้ที่มีทักษะผีมือ
                                                        และกระตุ้นผู้คน
                                                        ในฐานะที่เราบรรลุถึงการเติบโตและการพัฒนาอย่าต่อเนื่องผ่านผลิตภัณฑ์ที่เป็นมิตรต่อสิ่งแวดล้อมของเรา
                                                    </p>

                                                </div>
                                            </div>
                                            <br>
                                            <div
                                                class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1510369127181">
                                                <div class="wpb_wrapper">

                                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                        <img width="1920" height="789"
                                                            src="../../../../assets/templates/home/About-Aerial-A-1920x780.jpg"
                                                            class="vc_single_image-img attachment-full" alt=""
                                                            srcset="../../../../assets/templates/home/About-Aerial-A-1920x780.jpg 1920w,
                                                    ../../../../assets/templates/home/About-Aerial-A-1920x780-300x123.jpg 300w,
                                                    ../../../../assets/templates/home/About-Aerial-A-1920x780-768x316.jpg 768w,
                                                    ../../../../assets/templates/home/About-Aerial-A-1920x780-1024x421.jpg 1024w"
                                                            sizes="(max-width: 1920px) 100vw, 1920px" />
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="vc_empty_space" style="height: 64px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                style=' text-align:left;'>
                                                <div class=" full_section_inner clearfix">
                                                    <div class="wpb_column vc_column_container vc_col-sm-4">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h6 style="text-align: center;">
                                                                            ประธานของ DSST </h6>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 7px">
                                                                    <span class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h2 style="text-align: center;">
                                                                            สาห์นจากประธาน</h2>

                                                                    </div>
                                                                </div>
                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <p>บริษัท ไดเซล เซฟตี้ ซีสเต็มส์ (ประเทศไทย)
                                                                            จำกัด (DSST) ได้ก่อตั้งขึ้นด้วยความมุ่งมั่น
                                                                            ที่จะเป็นผู้นำการผลิตสุดยอดของเทคโนโลยีเพื่อมวลมนุษยชาติ
                                                                            และเป็นผู้แทนจำหน่ายผลิตภัณฑ์ตัวกำเนิดก๊าซสำหรับถุงลมนิรภัยและตัวกำเนิดก๊าซสำหรับเครื่องรั้งเข็มขัดนิรภัย
                                                                            รวมถึงอุปกรณ์ที่เกี่ยวข้อง (Initiator,
                                                                            Coolant &amp; Gas Generant)
                                                                            สำหรับระบบความปลอดภัยในรถยนต์
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 7px">
                                                                    <span class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <p>ด้วยคำขวัญว่า <span
                                                                                style="color: #0071be;"><strong>“เราคือผู้ช่วยชีวิต”</strong></span>
                                                                            ทีมงานของบริษัทได้พยายามสร้างสรรค์ผลิตภัณฑ์ที่เป็นสุดยอดของผลิตภัณฑ์คุณภาพในเรื่องความปลอดภัยเหนือคู่แข่งในอุตสาหกรรม
                                                                            ซึ่งบริษัทถือเป็นเกียรติ
                                                                            และด้วยความยินดีเป็นอย่างยิ่งที่ได้มาดำเนินธุรกิจในประเทศไทย
                                                                            และด้วยประสบการณ์ความช่วยเหลือ
                                                                            ความเอื้ออาทรจากทุกท่าน
                                                                            จึงกลายมาเป็นสิ่งเสริมสร้างศักยภาพ
                                                                            และแรงใจให้กับบริษัทในการดำเนินกิจการให้ประสบความสำเร็จเจริญก้าวหน้า
                                                                            และพัฒนาผลิตภัณฑ์ที่รักษาความปลอดภัยให้กับชีวิต
                                                                            โดยไม่ก่อให้เกิดมลภาวะ
                                                                            และเป็นมิตรกับสิ่งแวดล้อมตลอดไป </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="wpb_column vc_column_container vc_col-sm-8">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="vc_empty_space" style="height: 48px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>

                                                                <div
                                                                    class="wpb_gallery wpb_content_element vc_clearfix">
                                                                    <div class="wpb_wrapper">
                                                                        <div class="wpb_gallery_slides drag_enabled wpb_flexslider flexslider_fade flexslider"
                                                                            data-interval="3" data-flex_fx="fade"
                                                                            data-direction="true" data-drag="true">
                                                                            <ul class="slides">
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic2.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic2.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic2-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic2-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic3.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic3.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic3-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic3-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic4.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic4.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic4-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic4-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic5.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic5.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic5-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic5-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic6.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic6.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic6-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic6-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic7.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic7.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic7-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic7-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br>
                    </div>


                    <section data-speed="1" class="parallax_section_holder  "
                        style=" height:450px; background-image:url('../../../../assets/templates/home/BG-A-1920x1080-B70.jpg');">
                        <div class="parallax_content_full_width center">
                            <div class='parallax_section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="vc_empty_space" style="height: 128px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h2><span style="color: #ffffff;">วิสัยทัศน์</span></h2>

                                                </div>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper" style="padding: 20px;">
                                                    <h2 style="text-align: center;color: #ffffff;">

                                                        <em> “วิสัยทัศน์ของเรา คือ การเป็นผู้ให้บริการนวัตกรรม</em>



                                                    </h2>
                                                    <h2 style="text-align: center;color: #ffffff;margin-top: 10px;">



                                                        <em> ด้านความปลอดภัยที่แข็งแกร่ง น่าเชื่อถือ
                                                            และเป็นที่ยอมรับ”</em>

                                                    </h2>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 128px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1507498988322 grid_section"
                        style='background-image:url(../../../../assets/templates/home/map.jpg); text-align:left;'>
                        <br><br>

                        <div class=" section_inner clearfix">
                            <div class='section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-6">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h6>พันธกิจแห่งความสำเร็จ</h6>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 7px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h2>เราจะบรรลุผลตามวิสัยทัศน์ดังกล่าวได้โดย</h2>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 21px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class=ordered>

                                                <ol>
                                                    <li>พัฒนาและเสริมสร้างสภาพแวดล้อมในการทำงานให้มีความปลอดภัยและคุณภาพยอดเยี่ยม
                                                        ผลผลิตสูง และทำงานอย่างมีความสุข</li>
                                                    <li>จูงใจ และรักษาพนักงานซึ่งมีคุณภาพไว้กับบริษัท</li>
                                                    <li>พัฒนาทักษะ
                                                        และเทคโนโลยีการผลิตเพื่อก้าวไปสู่กระบวนการที่มีมาตรฐาน
                                                    </li>
                                                    <li>พัฒนาและสื่อสารให้ทราบถึงแผนการตำเนินธุรกิจ โดยร่วมกับบริษัท
                                                        ไดเซล คอร์ปอเรชั่น</li>
                                                    <li>ได้รับการรับรองระบบ ISO/T 16949 และ ISO 14001
                                                        เพื่อเสริมสร้างความแข็งแกร่งให้กับธุรกิจหลัก และพัฒนาเครือข่าย
                                                        มุ่งกลุ่มลูกค้าเป้าหมาย เพื่อเพิ่มศักยภาพธุรกิจ
                                                    </li>
                                                </ol>

                                            </div>
                                            <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                style=' text-align:left;'>
                                                <div class=" full_section_inner clearfix">

                                                    <div class="wpb_column vc_column_container vc_col-sm-6">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div
                                                                    class="wpb_single_image wpb_content_element vc_align_center">
                                                                    <div class="wpb_wrapper">

                                                                        <div
                                                                            class="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img width="600" height="600"
                                                                                src="../../../../assets/templates/home/ISO 14001.jpg"
                                                                                class="vc_single_image-img attachment-full"
                                                                                alt=""
                                                                                srcset="../../../../assets/templates/home/ISO 14001.jpg 600w, 
                                                                         ../../../../assets/templates/home/ISO 14001.jpg 150w,
                                                                         ../../../../assets/templates/home/ISO 14001.jpg 300w,
                                                                         ../../../../assets/templates/home/ISO 14001.jpg100w"
                                                                                sizes="(max-width: 600px) 100vw, 600px" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="wpb_column vc_column_container vc_col-sm-6">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div
                                                                    class="wpb_single_image wpb_content_element vc_align_center">
                                                                    <div class="wpb_wrapper">

                                                                        <div
                                                                            class="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img width="600" height="600"
                                                                                src="../../../../assets/templates/home/IATF 16949.jpg"
                                                                                class="vc_single_image-img attachment-full"
                                                                                alt=""
                                                                                srcset="../../../../assets/templates/home/IATF 16949.jpg 600w,
                                                                        ../../../../assets/templates/home/IATF 16949.jpg 150w, 
                                                                        ../../../../assets/templates/home/IATF 16949.jpg 300w, 
                                                                        ../../../../assets/templates/home/IATF 16949.jpg100w"
                                                                                sizes="(max-width: 600px) 100vw, 600px" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="wpb_column vc_column_container vc_col-sm-6">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h6>ค่านิยมองค์กร</h6>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 7px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h2>เราบรรลุถึงปัจจัยสำเร็จต่างๆ ได้โดยผ่านค่านิยมเหล่านี้
                                                    </h2>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 21px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span
                                                        class='progress_title'><span>ความปลอดภัยและคุณภาพสูง</span></span><span
                                                        class='progress_number' style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span class='progress_title'><span>ความพึงพอใจของลูกค้า
                                                        </span></span><span class='progress_number'
                                                        style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span class='progress_title'><span>การพัฒนาอย่างต่อเนื่อง
                                                        </span></span><span class='progress_number'
                                                        style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span
                                                        class='progress_title'><span>ความรับผิดชอบต่อสังคมของธุรกิจ</span></span><span
                                                        class='progress_number' style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span class='progress_title'><span>สร้างพลัง /
                                                            ความรับผิดชอบ</span></span><span class='progress_number'
                                                        style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span
                                                        class='progress_title'><span>การเคารพซึ่งกันและกัน</span></span><span
                                                        class='progress_number' style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br><br>
                    </div>


                    <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518017917058 grid_section"
                        style=' text-align:left; background-color: white; padding: 70px;'>
                        <div class=" section_inner clearfix">
                            <div class='section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                style=' text-align:left;'>
                                                <div class=" full_section_inner clearfix">
                                                    <div class="wpb_column vc_column_container vc_col-sm-4">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h6>Highlight ของการผลิต</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 7px">
                                                                    <span class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h2>ระบบการผลิตแบบยืดหยุ่น</h2>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 21px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <p>ระบบการผลิตแบบยืดหยุ่นและระบบการจัดการ
                                                                            ได้ถูกจัดตั้งขึ้นมาเพื่อให้สอดคล้องกับระบบการจัดหาเฉพาะของอุตสาหกรรมและตอบสนองกับความต้องการที่หลากหลายของลูกค้า
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 32px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h2>เพื่อความน่าเชื่อถือสูงสุด</h2>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 21px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <p>การทดสอบความน่าเชื่อถือภายใต้สภาวะที่รุนแรงทำเพื่อยืนยันความน่าเชื่อถือสูงสุดสำหรับ
                                                                            Inflators
                                                                            เราพร้อมบริการลูกค้าผ่านระบบของเราตั้งแต่การออกแบบจนไปถึงการประมวลผล
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="wpb_column vc_column_container vc_col-sm-8">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div
                                                                    class="wpb_gallery wpb_content_element vc_clearfix">
                                                                    <div class="wpb_wrapper">
                                                                        <div class="wpb_gallery_slides drag_enabled wpb_flexslider flexslider_fade flexslider"
                                                                            data-interval="3" data-flex_fx="fade"
                                                                            data-direction="true" data-drag="true">
                                                                            <ul class="slides">
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/6A-856x373.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/6A-856x373.jpg 856w,
                                                                                    ../../../../assets/templates/home/6A-856x373-300x131.jpg 300w,
                                                                                    ../../../../assets/templates/home/6A-856x373-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/5.2-856x373.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/5.2-856x373.jpg 856w,
                                                                                    ../../../../assets/templates/home/5.2-856x373-300x131.jpg 300w,
                                                                                    ../../../../assets/templates/home/5.2-856x373-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <section data-speed="1" class="parallax_section_holder  "
                        style=" height:450px; background-image:url('../../../../assets/templates/home/BG-A-1920x1080-B70.jpg');">
                        <div class="parallax_content_full_width center">
                            <div class='parallax_section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="vc_empty_space" style="height: 128px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class="vc_row wpb_row section vc_row-fluid vc_inner  grid_section"
                                                style=' text-align:center;'>
                                                <div class=" section_inner clearfix">
                                                    <div class='section_inner_margin clearfix'>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="q_counter_holder  center" style=""><span
                                                                            class="counter  zero"
                                                                            style="color: #ffffff;font-size: 72px;">2002</span><span
                                                                            class="separator small" style=""></span>
                                                                        <p class="counter_text"
                                                                            style="font-size: 24px;text-transform: capitalize;color: #ffffff;">
                                                                            DSST ได้ถูกก่อตั้ง
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="q_counter_holder  center" style=""><span
                                                                            class="counter  zero"
                                                                            style="color: #ffffff;font-size: 72px;">1300</span><span
                                                                            class="separator small" style=""></span>
                                                                        <p class="counter_text"
                                                                            style="font-size: 24px;text-transform: capitalize;color: #ffffff;">
                                                                            จำนวนพนักงาน DSST</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 128px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>





                    <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518014855017 grid_section" style=' text-align:left;background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;
'>
                        <div class=" section_inner clearfix">
                            <div class='section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner vc_custom_1507483902096">
                                        <div class="wpb_wrapper">
                                            <div class="vc_empty_space" style="height: 72px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h6 style="text-align: center;">DSST</h6>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 7px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h2 style="text-align: center;">
                                                        เหตุการณ์สำคัญในประวัติศาสตร์</h2>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 16px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class="qode-workflow  qode-workflow-animate">
                                                <span class="main-line" style="background-color:#009be6;"></span>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2001-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2001-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2001-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2001</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">มิถุนายน :
                                                                เข้ามาในประเทศไทยเป็นครั้งแรกเพื่อค้นหาโอกาสทางธุรกิจสำหรับอุปกรณ์ด้านความปลอดภัย
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2002-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2002-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2002-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2002</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">พฤษภาคม : เริ่มลงทุนในธุรกิจ Inflator,
                                                                กันยายน : ได้ก่อตั้งสำนักงาน Daicel Safety Systems
                                                                (Thailand) Co., Ltd. (DSST) ที่จังหวัดกรุงเทพมหานคร,
                                                                พฤศจิกายน : ประกอบพิธีวางศิลาฤกษ์ที่จังหวัดปราจีนบุรี
                                                                301 เขตอุตสาหกรรม
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2003-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2003-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2003-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2003</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">พฤษภาคม : DSST
                                                                ย้ายจากจังหวัดกรุงเทพมหานครไปยัง 304 เขตอุตสาหกรรม,
                                                                กันยายน : การทดสอบการดำเนินงานกระบวนการของบริษัท
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2004-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2004-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2004-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2004</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">มีนาคม : เริ่มดำเนินการค้าขายของ Inflator
                                                                สายการผลิตที่ 1 (DSST), พฤษภาคม : เริ่มธุรกิจ Initiator,
                                                                กันยายน : เริ่มดำเนินการค้าขายของ Inflator สายการผลิตที่
                                                                2 (DSST)
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2005-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2005-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2005-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2005</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">มกราคม : DSST ได้รับการรับรองมาตรฐานด้วย ISO
                                                                9001, เมษายน : DSST ได้รับการรับรองมาตรฐานด้วย ISO/TS
                                                                16949, สิงหาคม : Daicel Safety Technologies (Thailand)
                                                                Co., Ltd. (DSTT) ได้เริ่มก่อตั้งธุรกิจ Initiator
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2006-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2006-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2006-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2006</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">พฤษภาคม : เริ่มดำเนินการค้าขายของ Initiator
                                                                (DSTT), มิถุนายน : เริ่มธุรกิจ Coolant (DSST), ตุลาคม :
                                                                เริ่มธุรกิจ Inflator เพิ่มขึ้นมาอีก 2 สายการผลิต(DSST),
                                                                ธันวาคม : DSTT ได้รับการรับรองมาตรฐานด้วย
                                                                ISO 9001:2000
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2007-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2007-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2007-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2007</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">มีนาคม : DSST และ DSTT ได้รับการรับรองจาก
                                                                ISO 14001</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2009-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2009-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2009-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2009</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">กรกฎาคม : DSTT เริ่มดำเนินธุรกิจ PGG,
                                                                พฤษภาคม : DSTT ได้รับการรับรองมาตรฐาน ISO/TS 16949 :
                                                                2002</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2012-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2012-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2012-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2012</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">มิถุนายน : เริ่มดำเนินการค้าขายของ Inflator
                                                                สายการผลิตที่ 5 (DSST), พฤษภาคม : DSTT
                                                                เริ่มดำเนินธุรกิจของ Initiator รุ่นใหม่ (DG2)
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2013-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2013-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2013-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2013</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">มิถุนายน : เริ่มดำเนินการค้าขายของ Inflator
                                                                สายการผลิตที่ 6 (DSST), กรกฎาคม : DSTT
                                                                เริ่มการผสมผสานการดำเนินการครั้งที่ 3, ธันวาคม : DSST
                                                                เริ่มดำเนินการค้าขายของ Inflator สายการผลิตที่ 7 (DSST)
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2014-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2014-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2014-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2014</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">สิงหาคม : DSST ได้ทำการขยายโรงงาน Inflator
                                                                เพื่อสายการผลิตที่เพิ่มมากขึ้น</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2016-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2016-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2016-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2016</h3>
                                                            <h6 class="qode-workflow-subtitle">เหตุการณ์สำคัญ
                                                            </h6>
                                                            <p class="text">กุมภาพันธ์ : DSST เริ่มดำเนินการค้าขายของ
                                                                Inflator สายการผลิตที่ 9 (DSST), มีนาคม : DSST
                                                                เริ่มธุรกิจ Gas Generant, กรกฎาคม : DSST
                                                                ได้เริ่มสร้างโรงงานที่ 2 สำหรับ Inflator, สิงหาคม : DSST
                                                                เริ่มดำเนินการค้าขายของ
                                                                Inflator สายการผลิตที่ 8
                                                                ในฐานะการผลิตสินค้ามาตรฐานระดับโลก(DSST)
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br><br><br>
                    </div>



                </div>
            </div>

        </div>
    </div>
    <div style="background-color: white;">
        <app-slide-footer></app-slide-footer>
    </div>


    <app-footer-th></app-footer-th>