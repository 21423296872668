<title>Daicel Thailand | ติดต่อเรา</title>
<header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
  <div class="header_inner clearfix">
      <div class="header_top_bottom_holder">
          <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
              <div class="container">
                  <div class="container_inner clearfix">
                      <div class="header_inner_left">
                          <div class="mobile_menu_button">
                              <span>
                                  <i class="fa fa-bars"></i> </span>
                          </div>
                          <div class="logo_wrapper">
                              <div class="q_logo">
                                  <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                      <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="light" src="../assets/templates/home/DSST-Logo-300x70.png"
                                          alt="Logo" style="height: 100%;"> <img itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="sticky" src="../assets/templates/home/DSST-Logo-300x70.png"
                                          alt="Logo" style="height: 100%;"> <img itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> </a>
                              </div>
                          </div>
                          <div class="header_fixed_right_area">
                            <div class="textwidget custom-html-widget">
                                <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                            <span class="empty_space_image"></span>
                                    </span>
                                </div>

                                <span style="display: inline-block;">
                                  <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                                      <div class="icon_holder " style="">
                                          <img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                              src="../assets/templates/home/pr.png" alt="">
                                      </div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;
                                            font-family: 'Open Sans', sans-serif!important;
                                            "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              ">  241 หมู่ 4, เขตอุตสาหกรรม 304 <br>
                                                ปราจีนบุรี 25140 ประเทศไทย <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>

                              <span style="display: inline-block; margin-right: 15px;">
                                  <div class="q_icon_with_title medium custom_icon_image ">
                                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    "
                                              src="../assets/templates/home/sb.png" alt=""></div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;               font-family: 'Open Sans', sans-serif!important;

                                            ">
                                                +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              ">  76 หมู่ 1, อำเภอพระพุทธบาท<br>
                                              สระบุรี 18120 ประเทศไทย <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>

                              <span style="display: inline-block;">
                                  <div class="q_icon_with_title medium custom_icon_image ">
                                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    "
                                              src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;

                                            "> daicel-dsst.com<br>

                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                              font-weight: 400!important;
                                              ">  วันจันทร์ - วันศุกร์ 08h - 17h<br>หยุดวันเสาร์ - อาทิตย์


                                               <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>


                            </div>
                        </div>
                      </div>
                      <div class="header_menu_bottom">
                          <div class="header_menu_bottom_inner">
                              <div class="main_menu_header_inner_right_holder">
                                  <nav class="main_menu drop_down">
                                      <ul id="menu-main-menu" class="clearfix">
                                          <li id="nav-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="aboutUs()" class="" style="font-family: Sarabun;"><i
                                                      class="menu_icon blank fa"></i><span class='font'>เกี่ยวกับเรา
                                                      <span class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="products()" class="" style="font-family: Sarabun;"><i
                                                      class="menu_icon blank fa"></i><span class='font'>ผลิตภัณฑ์<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="daicel_group()" class="" style="font-family: Sarabun;"><i
                                                      class="menu_icon blank fa"></i><span class='font'>ไดเซลกรุ๊ป<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="csr_page()" class="" style="font-family: Sarabun;"><i
                                                      class="menu_icon blank fa"></i><span class='font'>กิจกรรม CSR<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="news_events()" class="" style="font-family: Sarabun;"><i
                                                      class="menu_icon blank fa"></i><span class='font'>ข่าวสารและกิจกรรม<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>

                                          <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="career()" class="" style="font-family: Sarabun;"><i
                                                              class="menu_icon blank fa"></i><span class='font'>ร่วมงานกับเรา<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                          <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">

                                              <a (click)="contact_us()" class="" style="font-family: Sarabun;"><i
                                                      class="menu_icon blank fa"></i><span class='font'>ติดต่อเรา
                                                      <span class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                              <a title="English"  class=""><i
                                                      class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                                                          src="../assets/templates/home/th.png" alt="en"
                                                          title="ไทย"><span class="wpml-ls-native" class='font'>ไทย</span><span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span><span class="qode-featured-icon "
                                                      aria-hidden="true"></span></a>
                                              <div class="second" style="height: 0px;">
                                                  <div class="inner">
                                                      <ul>
                                                          <li id="nav-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                              <a title="ไทย" (click)="th()" class=""><i
                                                                      class="menu_icon blank fa"></i><span><img
                                                                          class="wpml-ls-flag"
                                                                          src="../assets/templates/home/en.png"
                                                                          alt="th" title="English"><span
                                                                          class="wpml-ls-native">English</span></span><span
                                                                      class="plus"></span><span
                                                                      class="qode-featured-icon "
                                                                      aria-hidden="true"></span></a></li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </li>
                                      </ul>
                                  </nav>
                                  <div class="header_inner_right">
                                      <div class="side_menu_button_wrapper right">
                                          <div class="header_bottom_right_widget_holder">
                                              <div class="widget_text header_bottom_widget widget_custom_html">
                                                  <div class="textwidget custom-html-widget">
                                                      <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="side_menu_button">
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <nav class="mobile_menu">
                          <ul id="menu-main-menu-1" class="">
                              <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="aboutUs()" class=""><span class='font'>เกี่ยวกับเรา
                                      </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="products()" class=""><span class='font'>ผลิตภัณฑ์</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="daicel_group()" class=""><span class='font'>ไดเซลกรุ๊ป
                                      </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="csr_page()" class=""><span class='font'>กิจกรรม CSR</span></a><span class="mobile_arrow"><i
                                          class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="news_events()" class=""><span class='font'>ข่าวสารและกิจกรรม</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>

                              <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="career()" class=""><span class='font'>ร่วมงานกับเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>

                              <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="contact_us()" class=""><span class='font'>ติดต่อเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                  <a title="English"  class=""><span><img class="wpml-ls-flag"
                                              src="../assets/templates/home/th.png" alt="en" title="ไทย"><span
                                              class="wpml-ls-native font" >ไทย</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span>
                                  <ul class="sub_menu">
                                      <li id="mobile-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                          <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                      src="../assets/templates/home/en.png" alt="th"
                                                      title="English"><span
                                                      class="wpml-ls-native">English</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  </ul>
                              </li>
                          </ul>
                      </nav>

                  </div>
              </div>
          </div>
      </div>
  </div>

</header>
<a id="back_to_top" href="#" class="off">
    <span class="fa-stack">
        <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>

<div class="content content_top_margin_none">
  <div class="content_inner  ">

      <div class="title_outer title_without_animation" data-height="250">
          <div class="title title_size_small  position_left  has_fixed_background " style="background-size:1920px auto;background-image:url(../../../../assets/templates/home/BG-E-1920x600-B60.jpg);height:250px;">
              <div class="image not_responsive"><img itemprop="image" src="../../../../assets/templates/home/BG-E-1920x600-B60.jpg" alt="&nbsp;" /> </div>
              <div class="title_holder" style="padding-top:0;height:250px;">
                  <div class="container">
                      <div class="container_inner clearfix">
                          <div class="title_subtitle_holder">
                              <div class="title_subtitle_holder_inner">
                                  <h1><span>ติดต่อเรา</span></h1>

                                  <div class="breadcrumb" style="background-color: transparent;">
                                      <div class="breadcrumbs">
                                          <div itemprop="breadcrumb"><a (click)="home()">Home</a><span class="delimiter">&nbsp;>&nbsp;</span>
                                              <span class="current" style="font-family: Sarabun;">ติดต่อเรา</span>
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="full_width">
          <div class="full_width_inner">
              <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1535930757612 grid_section" style=' text-align:center;'>
                  <br><br><br>
                  <div class=" section_inner clearfix">
                      <div class='section_inner_margin clearfix'>
                          <div class="wpb_column vc_column_container vc_col-sm-2">
                              <div class="vc_column-inner ">
                                  <div class="wpb_wrapper"></div>
                              </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-8">
                              <div class="vc_column-inner ">
                                  <div class="wpb_wrapper">
                                      <div class="wpb_single_image wpb_content_element vc_align_center">
                                          <div class="wpb_wrapper">

                                              <div class="vc_single_image-wrapper vc_box_border_grey">
                                                  <img width="80%" height="146" src="../../../../assets/templates/home/DSST-Logo-500x146.png" class="vc_single_image-img attachment-full" alt="" srcset="../../../../assets/templates/home/DSST-Logo-500x146.png 500w,
                                                      ../../../../assets/templates/home/DSST-Logo-500x146-300x88.png 300w" sizes="(max-width: 500px) 100vw, 500px" /></div>
                                          </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 20px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                          </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                              <p>บริษัท ไดเซล เซฟตี้ ซีสเต็มส์ (ประเทศไทย) จํากัด (DSST)  ได้รับการจัดตั้งขึ้นโดยมีจุดมุ่งหมายในการเป็นผู้ผลิตชั้นนำและบริษัท จำหน่ายเครื่องเติมอากาศสำหรับระบบถุงลมนิรภัย เครื่องกำเนิดไฟฟ้า แก๊สตัวเติมอากาศ (PGG) สำหรับเข็มขัดนิรภัยและชิ้นส่วนที่เกี่ยวข้อง Initiators and Coolants) สำหรับระบบความปลอดภัยของรถยนต์</p>

                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-2">
                              <div class="vc_column-inner ">
                                  <div class="wpb_wrapper"></div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <br><br><br>
              <tabset [justified]="true">
                <tab heading="Head Office" id="tab1">
              <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1535930426802 grid_section" style=' text-align:center;'>
                  <br><br><br>
                  <div class=" section_inner clearfix">
                      <div class='section_inner_margin clearfix'>

                        <div>

                              <div class="wpb_column vc_column_container vc_col-sm-4">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">

                                      <span data-type="normal" data-hover-icon-color="#2e82ea" class="qode_icon_shortcode  q_font_awsome_icon fa-4x  " style=" "><span
                                                aria-hidden="true"
                                                class="qode_icon_font_elegant icon_pin qode_icon_element"
                                                style="font-size: 72px;color: #0071be;"></span></span>
                                        <div class="vc_empty_space" style="height: 20px"><span class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div>


                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h4>ที่อยู่</h4>

                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style="height: 14px"><span class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div>


                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <p>  241 หมู่ 4 เขตอุตสาหกรรม 304 ตำบลท่าตูม<br />อำเภอศรีมหาโพธิ จังหวัดปราจีนบุรี 25140 ประเทศไทย</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-4">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                      <span data-type="normal" data-hover-icon-color="#2e82ea" class="qode_icon_shortcode  q_font_awsome_icon fa-4x  " style=" "><span
                                                aria-hidden="true"
                                                class="qode_icon_font_elegant icon_phone qode_icon_element"
                                                style="font-size: 72px;color: #0071be;"></span></span>
                                        <div class="vc_empty_space" style="height: 20px"><span class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div>


                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h4>ติดต่อ</h4>

                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style="height: 14px"><span class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div>


                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h5>โทรศัพท์: <span style="color: #0071be;">+66 37 270
                                                        900</span><br /> แฟกซ์: <span style="color: #0071be;">+66 37 274 225</span>
                                                </h5>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-4">
                              <div class="vc_column-inner ">
                                  <div class="wpb_wrapper">
                                    <span data-type="normal" data-hover-icon-color="#2e82ea" class="qode_icon_shortcode  q_font_awsome_icon fa-4x  " style=" display: flex;
                                    align-items: center;
                                    justify-content: center; "><span
                                            aria-hidden="true"
                                            style="font-size: 72px;color: #0071be;"><i class="fa fa-envelope" aria-hidden="true"></i></span></span>
                                      <div class="vc_empty_space" style="height: 20px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                          </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                              <h4>อีเมล</h4>

                                          </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 14px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                          </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                            <a href="mailto:dsstannouncement@dsst.daicel.com">
                                              <h5 style="text-align: center;"> <span style="color: #0071be;">ส่งข้อความหาเรา</span>
                                              </h5>
                                          </a>

                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>


                        </div>




                      </div>
                  </div>
              </div>

              <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left ">
                <br><br><br>
                <div class=" full_section_inner clearfix ">
                    <div class="wpb_column vc_column_container vc_col-sm-12 ">
                        <div class="vc_column-inner ">
                            <div class="wpb_wrapper ">
                                <!--Google map-->
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15489.47792720639!2d101.58893466382193!3d13.936592306640046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311cf6c90ccece47%3A0xfc4244989fbbc79a!2sDaicel%20Safety%20Systems%20(Thailand)%20Co.%2C%20Ltd.!5e0!3m2!1sen!2sth!4v1586963983350!5m2!1sen!2sth "
                                    width="100% " height="600 " frameborder="0 " style="border:0; " allowfullscreen=" " aria-hidden="false " tabindex="0 "></iframe>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            </tab>
            <tab heading="Branch Office">

              <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1535930426802 grid_section" style=' text-align:center;'>
                <br><br><br>
                <div class=" section_inner clearfix">
                    <div class='section_inner_margin clearfix'>

                      <div>

                            <div class="wpb_column vc_column_container vc_col-sm-4">
                              <div class="vc_column-inner ">
                                  <div class="wpb_wrapper">

                                    <span data-type="normal" data-hover-icon-color="#2e82ea" class="qode_icon_shortcode  q_font_awsome_icon fa-4x  " style=" "><span
                                              aria-hidden="true"
                                              class="qode_icon_font_elegant icon_pin qode_icon_element"
                                              style="font-size: 72px;color: #0071be;"></span></span>
                                      <div class="vc_empty_space" style="height: 20px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                          </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                              <h4>Address</h4>

                                          </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 14px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                          </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                              <p>76 หมู่ 1 ตำบลพุคำจาน<br />อำเภอพระพุทธบาท จังหวัด สระบุรี 18120 ประเทศไทย</p>

                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-4">
                              <div class="vc_column-inner ">
                                  <div class="wpb_wrapper">
                                    <span data-type="normal" data-hover-icon-color="#2e82ea" class="qode_icon_shortcode  q_font_awsome_icon fa-4x  " style=" "><span
                                              aria-hidden="true"
                                              class="qode_icon_font_elegant icon_phone qode_icon_element"
                                              style="font-size: 72px;color: #0071be;"></span></span>
                                      <div class="vc_empty_space" style="height: 20px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                          </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                              <h4>ติดต่อ</h4>

                                          </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 14px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                          </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                              <h5>โทรศัพท์: <span style="color: #0071be;">+66 36 236 318</span><br /> แฟกซ์: <span style="color: #0071be;">+66 36 236 289</span>
                                              </h5>

                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-4">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                  <span data-type="normal" data-hover-icon-color="#2e82ea" class="qode_icon_shortcode  q_font_awsome_icon fa-4x  " style=" display: flex;
                                  align-items: center;
                                  justify-content: center; "><span
                                          aria-hidden="true"
                                          style="font-size: 72px;color: #0071be;"><i class="fa fa-envelope" aria-hidden="true"></i></span></span>
                                    <div class="vc_empty_space" style="height: 20px"><span class="vc_empty_space_inner">
                                            <span class="empty_space_image"></span>
                                        </span>
                                    </div>


                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                            <h4>อีเมล</h4>

                                        </div>
                                    </div>
                                    <div class="vc_empty_space" style="height: 14px"><span class="vc_empty_space_inner">
                                            <span class="empty_space_image"></span>
                                        </span>
                                    </div>


                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <a href="mailto:dsstannouncement@dsst.daicel.com">
                                            <h5 style="text-align: center;"> <span style="color: #0071be;">ส่งข้อความหาเรา </span>
                                            </h5>
                                        </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                      </div>




                    </div>
                </div>
            </div>

            <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left ">
              <br><br><br>
              <div class=" full_section_inner clearfix ">
                  <div class="wpb_column vc_column_container vc_col-sm-12 ">
                      <div class="vc_column-inner ">
                          <div class="wpb_wrapper ">
                              <!--Google map-->
                              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3857.8269064726583!2d100.84689801432215!3d14.778777776648651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311e09d44ba88091%3A0x707de3aef7f8edb6!2sSpecial%20Devices%20(Thailand)%20Co.%20Ltd.!5e0!3m2!1sen!2sth!4v1655781077896!5m2!1sen!2sth" width="100%" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                          </div>
                      </div>
                  </div>
              </div>
          </div>


            </tab>

          </tabset>




          </div>
      </div>

  </div>
</div>
<div style="background-color: white;">
    <app-slide-footer></app-slide-footer>
</div>


<app-footer-th></app-footer-th>
