<title>Daicel Thailand | Admin</title>

<app-header-admin></app-header-admin>

<br />
<!--/.Navbar-->
<div class="container-fluid">
  <section class="mb-5">
    <!-- Card -->

    <div class="card card-cascade narrower">
      <!-- Section: Table -->

      <section>
        <div class="card card-cascade narrower z-depth-0">
          <div
            class="view view-cascade gradient-card-header blue-gradient narrower"
          >
            <div class="row" style="padding: 10px">
              <div class="col-4"></div>
              <div class="col-4">
                <h1
                  class="white-text mx-1"
                  style="
                    font-weight: 500;
                    font-size: 25px;
                    font-family: sarabun;
                  "
                >
                  <b> ALL CAREER</b>
                </h1>
              </div>
              <div class="col-4">
                <div class="row">
                  <button
                    (click)="repost()"
                    type="button"
                    class="btn btn-outline-white btn-sm"
                    style="font-size: 14px"
                  >
                    <i class="fa fa-refresh" aria-hidden="true"></i> Re-Post All
                    Career
                  </button>

                  <button
                    [routerLink]="['/career/create']"
                    type="button"
                    type="button"
                    class="btn btn-white btn-sm"
                    style="
                      color: #274c87;
                      font-size: 14px;
                      font-weight: 700;
                      font-family: sarabun;
                    "
                  >
                    <i class="fa fa-plus"></i> Add New Career
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="px-4">
            <div class="table" style="font-size: 14px !important">
              <!--Table-->

              <div class="row" style="padding-left: 50%">
                <div class="col">
                  <form>
                    <!-- Grid row -->
                    <div class="form-row align-items-center">
                      <div class="col">
                        <!-- Material input -->
                        <label class="sr-only" for="inlineFormInputGroupMD"
                          >Search</label
                        >
                        <div class="md-form input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text md-addon"
                              ><i class="fa fa-search" aria-hidden="true"></i>
                              :&nbsp;</span
                            >
                          </div>
                          <input
                            (keyup)="onSearch($event.target.value)"
                            mdbInput
                            type="text"
                            class="form-control pl-0 rounded-0"
                            id="inlineFormInputGroupMD"
                            placeholder="Search position"
                          />
                        </div>
                      </div>
                      <!-- Grid column -->
                    </div>
                    <!-- Grid row -->
                  </form>
                </div>
              </div>

              <table class="table table-hover mb-0">
                <!-- Table head -->
                <thead>
                  <tr>
                    <th
                      style="
                        text-align: left;
                        width: 5%;
                        font-weight: 700;
                        font-size: 18px;
                        font-family: sarabun;
                      "
                    >
                      NO.
                    </th>
                    <th
                      style="
                        text-align: left;
                        width: 5%;
                        font-weight: 700;
                        font-size: 18px;
                        font-family: sarabun;
                      "
                    ></th>
                    <th
                      style="
                        text-align: left;
                        width: 30%;
                        font-weight: 700;
                        font-size: 18px;
                        font-family: sarabun;
                      "
                    >
                      POSITION
                    </th>
                    <th
                      style="
                        text-align: center;
                        width: 15%;
                        font-weight: 700;
                        font-size: 18px;
                        font-family: sarabun;
                      "
                    >
                      LAST MODIFIED
                    </th>
                    <th
                      style="
                        text-align: center;
                        width: 15%;
                        font-weight: 700;
                        font-size: 18px;
                        font-family: sarabun;
                      "
                    >
                      STATUS
                    </th>
                    <th
                      style="
                        text-align: center;
                        width: 10%;
                        font-weight: 700;
                        font-size: 18px;
                        font-family: sarabun;
                      "
                    >
                      LOCATION
                    </th>
                    <th
                      style="
                        width: 180px;
                        text-align: center;
                        font-weight: 700;
                        font-size: 18px;
                        font-family: sarabun;
                      "
                    >
                      ACTION
                    </th>
                  </tr>
                </thead>
                <!-- Table head -->

                <!-- Table body -->
                <tbody>
                  <tr *ngFor="let item of dataCareer; let i = index">
                    <td style="text-align: left">{{ i + 1 }}</td>
                    <td style="text-align: left">
                      <div *ngIf="item.active == '3'">
                        <div class="" style="color: red; font-weight: bold">
                          <span>Urgent</span>
                        </div>
                      </div>
                    </td>

                    <td style="text-align: left">{{ item.event_name_en }}</td>

                    <!-- <td>{{ item.post_by }}</td> -->
                    <td>{{ item.post_date | date : "dd/MM/yyyy" }}</td>
                    <td>
                      <button
                        style="width: 90px"
                        *ngIf="date_now >= item.published_date"
                        type="button"
                        (click)="notshow(item.event_id)"
                        class="btn btn-outline-primary btn-rounded btn-sm px-2"
                      >
                        <i class="fa fa-eye" aria-hidden="true"></i> Post
                      </button>

                      <button
                        style="width: 90px"
                        *ngIf="date_now < item.published_date"
                        type="button"
                        (click)="show(item.event_id)"
                        class="btn btn-outline-grey btn-rounded btn-sm px-2"
                      >
                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        Unposted
                      </button>
                    </td>
                    <td>
                      <p>{{ item.event_name_th }}</p>
                    </td>

                    <td style="text-align: center">
                      <button
                        type="button"
                        (click)="copy(item.event_id)"
                        class="btn btn-outline-purple btn-rounded btn-sm px-2"
                      >
                        <i class="fa fa-copy" aria-hidden="true"></i> Copy
                      </button>

                      <button
                        type="button"
                        (click)="repost_1(item.event_id)"
                        class="btn btn-outline-primary btn-rounded btn-sm px-2"
                      >
                        <i class="fa fa-refresh" aria-hidden="true"></i> Re-post
                      </button>
                      <button
                        type="button"
                        (click)="editItem(item.event_id)"
                        class="btn btn-outline-amber btn-rounded btn-sm px-2"
                      >
                        <i class="fas fa-pencil-alt mt-0"></i> Edit
                      </button>
                      <button
                        type="button"
                        (click)="deleteItem(item.event_id)"
                        class="btn btn-outline-red btn-rounded btn-sm px-2"
                      >
                        <i class="fas fa-eraser mt-0"></i> Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
                <!-- Table body -->
                <br />
              </table>
              <!-- Table -->
            </div>

            <hr class="my-0" />
          </div>
        </div>
      </section>
      <!--Section: Table-->
    </div>
    <!-- Card -->
  </section>
  <!-- Section: Main panel -->

  <section class="mb-5">
    <!-- Card -->

    <div class="card card-cascade narrower">
      <!-- Section: Table -->

      <section>
        <div class="card card-cascade narrower z-depth-0">
          <div
            class="view view-cascade gradient-card-header blue-gradient narrower py-2 mx-6 mb-2 d-flex justify-content-between align-items-center"
          >
            <div class="text-center"></div>
            <h1
              class="white-text mx-1"
              style="
                font-size: 25px !important;
                font-weight: 500;
                font-family: sarabun;
              "
            >
              <b s> BENEFITS / CONTACTS</b>
            </h1>
            <div style="margin-right: 20px">
              <button
                (click)="save()"
                type="button"
                class="btn btn-white btn-sm"
                style="
                  color: #274c87;
                  font-size: 14px;
                  font-weight: 700;
                  font-family: sarabun;
                "
              >
                <i class="fa fa-save"></i> Save
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <div class="card-title">
                    <p
                      style="
                        color: black;
                        font-size: 20px !important;
                        font-family: sarabun;
                        font-weight: 700;
                        text-align: center;
                      "
                    >
                      BENEFITS
                    </p>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <form #formRef="ngForm">
                    <table mdbTable striped="true">
                      <tbody>
                        <tr
                          mdbTableCol
                          *ngFor="let word2 of words1; let in = index"
                        >
                          <td style="width: 100vh">
                            <input
                              type="text"
                              [(ngModel)]="words1[in].values"
                              name="name{{ in }}"
                              class="form-control"
                              #name="ngModel"
                              required
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card" style="font-size: 14px !important">
                <div class="card-header">
                  <p
                    style="
                      color: black;
                      font-size: 20px !important;
                      font-family: sarabun;
                      font-weight: 700;
                      text-align: center;
                    "
                  >
                    CONTACTS
                  </p>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <form #formRef="ngForm">
                      <table mdbTable striped="true">
                        <tbody>
                          <tr
                            mdbTableCol
                            *ngFor="let word2 of words2; let in = index"
                          >
                            <td style="width: 100vh">
                              <input
                                type="text"
                                [(ngModel)]="words2[in].values"
                                name="name{{ in }}"
                                class="form-control"
                                #name="ngModel"
                                required
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--Section: Table-->
    </div>
    <!-- Card -->
  </section>
</div>

<br /><br /><br /><br /><br />
<br /><br /><br /><br /><br />
