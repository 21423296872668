import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-product-th',
  templateUrl: './choose-product-th.component.html',
  styleUrls: ['./choose-product-th.component.scss']
})
export class ChooseProductThComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scrolled = 0;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event) {
    const numb = window.scrollY;
    if (numb >= 50) {
      this.scrolled = 1;
    } else {
      this.scrolled = 0;
    }
  }

}
