<title>
    Daicel Thailand | {{ content.event_name_en }}</title>
    <header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
      <div class="header_inner clearfix">
          <div class="header_top_bottom_holder">
              <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
                  <div class="container">
                      <div class="container_inner clearfix">
                          <div class="header_inner_left">
                              <div class="mobile_menu_button">
                                  <span>
                                          <i class="fa fa-bars"></i> </span>
                              </div>
                              <div class="logo_wrapper">
                                  <div class="q_logo">
                                      <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                          <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="light" src="../assets/templates/home/DSST-Logo-300x70.png"
                                              alt="Logo" style="height: 100%;"> <img itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="sticky" src="../assets/templates/home/DSST-Logo-300x70.png"
                                              alt="Logo" style="height: 100%;"> <img itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> </a>
                                  </div>
                              </div>
                              <div class="header_fixed_right_area">
                                  <div class="textwidget custom-html-widget">
                                      <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                                  <span class="empty_space_image"></span>
                                          </span>
                                      </div>

                                      <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                                            <div class="icon_holder " style="">
                                                <img itemprop="image" style="
                                                margin-top: 7px;
                                                margin-left: 5px;
                                            "
                                                    src="../assets/templates/home/pr.png" alt="">
                                            </div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                  <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                  font-weight: 400!important;
                                                  font-family: 'Open Sans', sans-serif!important;
                                                  "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                    font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                    ">  241 Moo 4, 304 Ind Park<br>
                                                      Prachinburi 25140 Thailand <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block; margin-right: 15px;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                              margin-top: 7px;
                                              margin-left: 5px;
                                          "
                                                    src="../assets/templates/home/sb.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                  <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                  font-weight: 400!important;
                                                  font-family: 'Open Sans', sans-serif!important;
                                                  ">
                                                      +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                    font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                    ">  76 Moo.1, A.Phra Phutthabat<br>
                                                     Saraburi 18120 Thailand <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                              margin-top: 7px;
                                              margin-left: 5px;
                                          "
                                                    src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                  <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                  font-weight: 400!important;
                                                  font-family: 'Open Sans', sans-serif!important;
                                                  "> daicel-dsst.com<br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                    font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                    ">  Monday - Friday 08h - 17h<br>Closed on Weekends


                                                     <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>


                                  </div>
                              </div>
                          </div>
                          <div class="header_menu_bottom">
                              <div class="header_menu_bottom_inner">
                                  <div class="main_menu_header_inner_right_holder">
                                      <nav class="main_menu drop_down">
                                          <ul id="menu-main-menu" class="clearfix">
                                              <li id="nav-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="aboutUs()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">About
                                                              Us<span class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="products()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">Products<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="daicel_group()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">Daicel
                                                              Group<span class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="csr_page()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">CSR<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="news_events()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">News<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="career()" class=""><i
                                                                      class="menu_icon blank fa"></i><span style="
                                                                      ">Career
                                                                      <span class="underline_dash"></span></span><span
                                                                      class="plus"></span></a></li>
                                              <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                  <a (click)="contact_us()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="
                                                              ">Contact
                                                              Us<span class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                              <li id="nav-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                                  <a title="English" class=""><i
                                                              class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                                                                  src="../assets/templates/home/en.png" alt="en"
                                                                  title="English"><span
                                                                  class="wpml-ls-native" style="
                                                                  ">English</span><span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span><span class="qode-featured-icon "
                                                              aria-hidden="true"></span></a>
                                                  <div class="second" style="height: 0px;">
                                                      <div class="inner">
                                                          <ul>
                                                              <li id="nav-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                                  <a title="ไทย" (click)="th()" class=""><i
                                                                              class="menu_icon blank fa"></i><span><img
                                                                                  class="wpml-ls-flag"
                                                                                  src="../assets/templates/home/th.png"
                                                                                  alt="th" title="ไทย"><span
                                                                                  class="wpml-ls-native">ไทย</span></span><span
                                                                              class="plus"></span><span
                                                                              class="qode-featured-icon "
                                                                              aria-hidden="true"></span></a></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                              </li>
                                          </ul>
                                      </nav>
                                      <div class="header_inner_right">
                                          <div class="side_menu_button_wrapper right">
                                              <div class="header_bottom_right_widget_holder">
                                                  <div class="widget_text header_bottom_widget widget_custom_html">
                                                      <div class="textwidget custom-html-widget">
                                                          <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">
                                                              <div class=" full_section_inner clearfix">
                                                                  <div class="wpb_column vc_column_container vc_col-sm-12">
                                                                      <div class="vc_column-inner ">
                                                                          <div class="wpb_wrapper">


                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="side_menu_button">
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <nav class="mobile_menu">
                              <ul id="menu-main-menu-1" class="">
                                  <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="aboutUs()" class=""><span>About
                                                  Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="products()" class=""><span>Products</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="daicel_group()" class=""><span>Daicel
                                                  Group</span></a><span class="mobile_arrow"><i
                                                  class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="csr_page()" class=""><span>CSR</span></a><span class="mobile_arrow"><i
                                                  class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="news_events()" class=""><span>News</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="career()" class=""><span>Career</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                          class="fa fa-angle-down"></i></span></li>

                                  <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="contact_us()" class=""><span>Contact
                                                  Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  <li id="mobile-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                      <a title="English" class=""><span><img class="wpml-ls-flag"
                                                      src="../assets/templates/home/en.png" alt="en" title="English"><span
                                                      class="wpml-ls-native">English</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span>
                                      <ul class="sub_menu">
                                          <li id="mobile-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                              <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                              src="../assets/templates/home/th.png" alt="th" title="ไทย"><span
                                                              class="wpml-ls-native">ไทย</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                          class="fa fa-angle-down"></i></span></li>
                                      </ul>
                                  </li>
                              </ul>
                          </nav>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  </header>
<a id="back_to_top" href="#" class="off">
    <span class="fa-stack">
        <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>
<div class="content content_top_margin_none" style="min-height: 183px; padding-top: 0px;background-color: white;">
    <div class="content_inner  ">
        <div class="title_outer title_without_animation" data-height="250">


            <div class="title title_size_small  position_left  has_fixed_background " style="background-size:1920px auto;background-image:url(../../../assets/templates/home/BG-A-1920x1080-B70-edit.jpg);height:250px;">
                <div class="image not_responsive"><img itemprop="image" src="../../../assets/templates/home/BG-E-1920x600-B60.jpg" alt="&nbsp;" /> </div>
                <div class="title_holder" style="padding-top:0;height:250px;">
                    <div class="container">
                        <div class="container_inner clearfix">
                            <div class="title_subtitle_holder">
                                <div class="title_subtitle_holder_inner">
                                    <h1><span></span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container" style="background-color: white">
            <div class="container_inner default_template_holder;" style="background-color: white">
                <div class="container" style="background-color: white">
                    <div class="row">
                        <div class="col-sm">
                            <div class="column_inner">
                                <div class="blog_single blog_holder">
                                    <article id="post-7249" class="post-7249 post type-post status-publish format-standard has-post-thumbnail hentry category-activities-th category-events-th category-news-th">
                                        <div class="post_content_holder">
                                            <div class="post_text">
                                                <div class="post_text_inner" style="padding-top: 20px;">

                                                    <div class="section1">
                                                        <div class="col1 grid_1_of_3" style="background-color: white;">
                                                            <h5 style="color:#3e9cdd;font-family: 'sarabun';font-size:20px;font-weight: 700;">
                                                                <i class="fa fa-briefcase" aria-hidden="true"></i> {{content.event_name_en}}
                                                            </h5>

                                                            <div class="row">
                                                                <div *ngIf="content.active == 3 " style="font-weight: 700;
                                                    color: red;padding-left: 15px;
                                                   ">Urgent</div>

                                                            </div>

                                                            <h6 style="color:black;">
                                                                Post Date : <i class="fa fa-clock-o" aria-hidden="true"></i> {{ content.published_date | date: "dd MMM yyyy" }}
                                                            </h6>

                                                            <h6 style="color:black;">
                                                                Vacant : <i *ngFor="let i of Arr(vacant).fill(1)" class="fa fa-user" style="padding-right: 5px;color:black;" aria-hidden="true"></i>

                                                            </h6>
                                                            <h6 style="color:black;">
                                                              Location : {{ location }}

                                                          </h6>
                                                        </div>



                                                    </div>




                                                    <br>





                                                    <div class="row" style="margin-left: 20px;">



                                                    </div>



                                                    <style type="text/css">
                                                        #gallery-1 {
                                                            margin: auto;
                                                        }

                                                        #gallery-1 .gallery-item {
                                                            float: left;
                                                            margin-top: 10px;
                                                            text-align: center;
                                                            width: 33%;
                                                        }

                                                        #gallery-1 img {
                                                            border: 2px solid #cfcfcf;
                                                        }

                                                        #gallery-1 .gallery-caption {
                                                            margin-left: 0;
                                                        }
                                                        /* see gallery_shortcode() in wp-includes/media.php */
                                                    </style>



                                                </div>
                                            </div>
                                        </div>

                                    </article>
                                </div>

                            </div>
                        </div>

                    </div>

                    <hr style="boarder: 1px solid #3e9cdd; border-radius: 2px;" />

                    <div class="row" *ngIf="!mobile">
                        <div class="col">
                            <div class="post_text_inner">
                                <h5 style="color:#3e9cdd;font-size: 18px;font-family: sarabun;    font-weight: 700;">Job Description
                                </h5>

                                <h6 [innerHTML]="content.content_detail_en" style="font-size: 16px;line-height: 18pt ;color:black;padding-top: 16px;font-family:sarabun">
                                </h6>

                                <style type="text/css">
                                    #gallery-1 {
                                        margin: auto;
                                    }

                                    #gallery-1 .gallery-item {
                                        float: left;
                                        margin-top: 10px;
                                        text-align: center;
                                        width: 33%;
                                    }

                                    #gallery-1 img {
                                        border: 2px solid #cfcfcf;
                                    }

                                    #gallery-1 .gallery-caption {
                                        margin-left: 0;
                                    }
                                    /* see gallery_shortcode() in wp-includes/media.php */
                                </style>


                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col">

                            <div class="post_text_inner">
                                <h5 style="color:#3e9cdd;font-size: 18px;font-family: sarabun;    font-weight: 700;">
                                    Qualifications</h5>

                                <h6 [innerHTML]="content.content_detail_th" style="line-height: 18pt;color:black;font-size:16px;padding-top: 16px;font-family:sarabun">
                                </h6>


                                <style type="text/css">
                                    #gallery-1 {
                                        margin: auto;
                                    }

                                    #gallery-1 .gallery-item {
                                        float: left;
                                        margin-top: 10px;
                                        text-align: center;
                                        width: 33%;
                                    }

                                    #gallery-1 img {
                                        border: 2px solid #cfcfcf;
                                    }

                                    #gallery-1 .gallery-caption {
                                        margin-left: 0;
                                    }
                                    /* see gallery_shortcode() in wp-includes/media.php */
                                </style>


                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="mobile">
                        <div class="post_text_inner">
                            <h5 style="color:#3e9cdd;font-size: 18px;font-family: sarabun;    font-weight: 700;">Job Description
                            </h5>

                            <h6 [innerHTML]="content.content_detail_en" style="font-size: 16px;line-height: 18pt ;color:black;padding-top: 16px;font-family:sarabun">
                            </h6>

                            <style type="text/css">
                                #gallery-1 {
                                    margin: auto;
                                }

                                #gallery-1 .gallery-item {
                                    float: left;
                                    margin-top: 10px;
                                    text-align: center;
                                    width: 33%;
                                }

                                #gallery-1 img {
                                    border: 2px solid #cfcfcf;
                                }

                                #gallery-1 .gallery-caption {
                                    margin-left: 0;
                                }
                                /* see gallery_shortcode() in wp-includes/media.php */
                            </style>


                            <p>&nbsp;</p>
                        </div>


                        <div class="post_text_inner">
                            <h5 style="color:#3e9cdd;font-size: 18px;font-family: sarabun;    font-weight: 700;">
                                Qualifications</h5>

                            <h6 [innerHTML]="content.content_detail_th" style="line-height: 18pt;color:black;font-size:16px;padding-top: 16px;font-family:sarabun">
                            </h6>


                            <style type="text/css">
                                #gallery-1 {
                                    margin: auto;
                                }

                                #gallery-1 .gallery-item {
                                    float: left;
                                    margin-top: 10px;
                                    text-align: center;
                                    width: 33%;
                                }

                                #gallery-1 img {
                                    border: 2px solid #cfcfcf;
                                }

                                #gallery-1 .gallery-caption {
                                    margin-left: 0;
                                }
                                /* see gallery_shortcode() in wp-includes/media.php */
                            </style>


                            <p>&nbsp;</p>
                        </div>

                    </div>





                    <div id="gallery-1" class="gallery galleryid-7249 gallery-columns-3 gallery-size-large">

                        <h5 style="margin-top: 10px; margin-bottom: 10px;color:#3e9cdd;font-size: 18px;    font-family: 'Sarabun';    font-weight: 700;">
                            Benefits</h5>
                        <h6 style="line-height: 18pt;color:black;font-family: sarabun;" [innerHTML]="content1[0]['content_detail_en']"> </h6>
                        <br>

                        <h5 style="margin-top: 10px; margin-bottom: 10px;color:#3e9cdd;font-size: 18px  ;  font-weight: 700; font-family: 'Sarabun';  ">
                            Contacts</h5>
                        <h6 style="line-height: 18pt;color:black;font-family: sarabun;" [innerHTML]="content1[0]['content_detail_th']">

                        </h6>
                        <br>

                        <h5 style="margin-top: 10px; margin-bottom: 10px;color:#3e9cdd;font-size: 18px;    font-weight: 700;   font-family: 'Sarabun';">
                            JOIN US</h5>
                        <h6 style="font-size: 16px;font-family: sarabun;">
                            * * All Positions need good command of English. Japanese speaking will be a plus.* *
                        </h6>

                        <li style="font-size: 16px;font-family: sarabun;color:black">
                            สมัครผ่าน website (Click Apply Now)

                        </li>
                        <li style="font-size: 16px;font-family: sarabun;color:black">
                            ส่งประวัติส่วนตัว (Resume) ภาษาอังกฤษมาที่ E-mail : recruit@dsst.daicel.com

                        </li>

                        <h6 style="font-size: 16px;font-family: sarabun;">
                            * *Interested candidates please send full-detail resume in English with photo, current and expected compensation package to:* *
                        </h6>

                        <br>
                        <div style=" display: flex;
                justify-content: center;">
                            <a (click)="link(content.event_name_th)" class="btn btn-primary" style=" color:white">Apply
                                Now</a>
                        </div>
                        <br><br><br>
                    </div>


                </div>



            </div>
        </div>
        <br><br>
        <br><br>
