// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// const URL = `http://159.228.251.234:1353/`;
// const URL = `http://203.113.96.243:4646/`;
// const URL = `http://localhost:8080/`;
const URL = `https://www.daicel-dsst.com/`;
const URL_PATH = `https://www.daicel-dsst.com/`;

export const environment = {

  production: false,
  backendUrl: `${URL}`,
  baseUrl: "",
  path: `${URL_PATH}`,
  URL_LOGIN: `${URL}api/user/login/`,
  URL_POST: `${URL}api/post/`,
  URL_IMG: `${URL}api/images/`,
  URL_MAIL: `${URL}api/mail/`,

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
