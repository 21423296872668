import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IrRoutingModule } from './ir-routing.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PostComponent } from './post/post.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { MyFilterPipe } from './my-filter.pipe';

@NgModule({
  declarations: [PostComponent,MyFilterPipe],
  imports: [
    CommonModule,
    IrRoutingModule,
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,


  ]
})
export class IrModule { }
