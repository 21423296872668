<title>Daicel Thailand | ผลิตภัณฑ์</title>
<header class="has_header_fixed_right stick menu_bottom">
  <div class="header_inner clearfix">
      <div class="header_top_bottom_holder">
          <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
              <div class="container">
                  <div class="container_inner clearfix">
                      <div class="header_inner_left">
                          <div class="mobile_menu_button">
                              <span>
                                  <i class="fa fa-bars"></i> </span>
                          </div>
                          <div class="logo_wrapper">
                              <div class="q_logo">
                                  <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                      <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="light" src="../assets/templates/home/DSST-Logo-300x70.png"
                                          alt="Logo" style="height: 100%;"> <img itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="sticky" src="../assets/templates/home/DSST-Logo-300x70.png"
                                          alt="Logo" style="height: 100%;"> <img itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> </a>
                              </div>
                          </div>
                          <div class="header_fixed_right_area">
                            <div class="textwidget custom-html-widget">
                                <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                            <span class="empty_space_image"></span>
                                    </span>
                                </div>

                                <span style="display: inline-block;">
                                  <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                                      <div class="icon_holder " style="">
                                          <img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                              src="../assets/templates/home/pr.png" alt="">
                                      </div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;
                                            font-family: 'Open Sans', sans-serif!important;
                                            "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              ">  241 หมู่ 4, เขตอุตสาหกรรม 304 <br>
                                                ปราจีนบุรี 25140 ประเทศไทย <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>

                              <span style="display: inline-block; margin-right: 15px;">
                                  <div class="q_icon_with_title medium custom_icon_image ">
                                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    "
                                              src="../assets/templates/home/sb.png" alt=""></div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;               font-family: 'Open Sans', sans-serif!important;

                                            ">
                                                +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                            font-weight: 400!important;
                                              ">  76 หมู่ 1, อำเภอพระพุทธบาท<br>
                                              สระบุรี 18120 ประเทศไทย <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>

                              <span style="display: inline-block;">
                                  <div class="q_icon_with_title medium custom_icon_image ">
                                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                        margin-top: 7px;
                                        margin-left: 5px;
                                    "
                                              src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                      <div class="icon_text_holder" style="">
                                          <div class="icon_text_inner" style="">
                                            <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                            font-weight: 400!important;

                                            "> daicel-dsst.com<br>

                                              </h6>
                                              <p style="color: #949494;line-height: 18px!important;
                                              font-weight: 400!important;
                                              ">  วันจันทร์ - วันศุกร์ 08h - 17h<br>หยุดวันเสาร์ - อาทิตย์


                                               <br> </p>
                                          </div>
                                      </div>
                                  </div>
                              </span>


                            </div>
                        </div>
                      </div>
                      <div class="header_menu_bottom">
                          <div class="header_menu_bottom_inner">
                              <div class="main_menu_header_inner_right_holder">
                                  <nav class="main_menu drop_down">
                                      <ul id="menu-main-menu" class="clearfix">
                                          <li id="nav-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="aboutUs()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">เกี่ยวกับเรา
                                                      <span class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="products()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ผลิตภัณฑ์<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="daicel_group()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ไดเซลกรุ๊ป<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="csr_page()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">กิจกรรม CSR<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="news_events()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ข่าวสารและกิจกรรม<span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>

                                          <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                              <a (click)="career()" class=""><i
                                                              class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ร่วมงานกับเรา<span
                                                                  class="underline_dash"></span></span><span
                                                              class="plus"></span></a></li>
                                          <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">

                                              <a (click)="contact_us()" class=""><i
                                                      class="menu_icon blank fa"></i><span style="font-family: 'Sarabun';">ติดต่อเรา
                                                      <span class="underline_dash"></span></span><span
                                                      class="plus"></span></a></li>
                                          <li id="nav-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                              <a title="English"  class=""><i
                                                      class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                                                          src="../assets/templates/home/th.png" alt="en"
                                                          title="ไทย"><span class="wpml-ls-native" style="font-family: 'Sarabun';">ไทย</span><span
                                                          class="underline_dash"></span></span><span
                                                      class="plus"></span><span class="qode-featured-icon "
                                                      aria-hidden="true"></span></a>
                                              <div class="second" style="height: 0px;">
                                                  <div class="inner">
                                                      <ul>
                                                          <li id="nav-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                              <a title="ไทย" (click)="th()" class=""><i
                                                                      class="menu_icon blank fa"></i><span><img
                                                                          class="wpml-ls-flag"
                                                                          src="../assets/templates/home/en.png"
                                                                          alt="th" title="English"><span
                                                                          class="wpml-ls-native">English</span></span><span
                                                                      class="plus"></span><span
                                                                      class="qode-featured-icon "
                                                                      aria-hidden="true"></span></a></li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </li>
                                      </ul>
                                  </nav>
                                  <div class="header_inner_right">
                                      <div class="side_menu_button_wrapper right">
                                          <div class="header_bottom_right_widget_holder">
                                              <div class="widget_text header_bottom_widget widget_custom_html">
                                                  <div class="textwidget custom-html-widget">
                                                      <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="side_menu_button">
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <nav class="mobile_menu">
                          <ul id="menu-main-menu-1" class="">
                              <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="aboutUs()" class=""><span style="font-family: 'Sarabun';">เกี่ยวกับเรา
                                      </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="products()" class=""><span style="font-family: 'Sarabun';">ผลิตภัณฑ์</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="daicel_group()" class=""><span style="font-family: 'Sarabun';">ไดเซลกรุ๊ป
                                      </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="csr_page()" class=""><span style="font-family: 'Sarabun';">กิจกรรม CSR</span></a><span class="mobile_arrow"><i
                                          class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="news_events()" class=""><span style="font-family: 'Sarabun';">ข่าวสารและกิจกรรม</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>

                              <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="career()" class=""><span style="font-family: 'Sarabun';">ร่วมงานกับเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>

                              <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="contact_us()" class=""><span style="font-family: 'Sarabun';">ติดต่อเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span></li>
                              <li id="mobile-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                  <a title="English"  class=""><span><img class="wpml-ls-flag"
                                              src="../assets/templates/home/th.png" alt="en" title="ไทย"><span style="font-family: 'Sarabun';"
                                              class="wpml-ls-native">ไทย</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                          class="fa fa-angle-down"></i></span>
                                  <ul class="sub_menu">
                                      <li id="mobile-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                          <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                      src="../assets/templates/home/en.png" alt="th"
                                                      title="English"><span
                                                      class="wpml-ls-native">English</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                  class="fa fa-angle-down"></i></span></li>
                                  </ul>
                              </li>
                          </ul>
                      </nav>

                  </div>
              </div>
          </div>
      </div>
  </div>

</header>
<a id="back_to_top" href="#" class="off">
    <span class="fa-stack">
        <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>
<div class="content content_top_margin_none">
  <div class="content_inner  ">
    <div class="title_outer title_without_animation" data-height="250">
      <div class="title title_size_small  position_left  has_fixed_background "
        style="background-size:1920px auto;background-image:url(../../../../assets/templates/home/Carhuman-1920x600B.jpg);height:250px;">
        <div class="image not_responsive"><img itemprop="image"
            src="../../../../assets/templates/home/Carhuman-1920x600B.jpg" alt="&nbsp;" /> </div>
        <div class="title_holder" style="padding-top:0;height:250px;">
          <div class="container">
            <div class="container_inner clearfix">
              <div class="title_subtitle_holder">
                <div class="title_subtitle_holder_inner">
                  <h1><span style="font-family: 'Sarabun';">ผลิตภัณฑ์</span></h1>

                  <div class="breadcrumb" style="background-color: transparent;">
                    <div class="breadcrumbs">
                      <div itemprop="breadcrumb" class="breadcrumbs_inner"><a (click)="home()">Home</a><span
                          class="delimiter">&nbsp;>&nbsp;</span><span class="current" style="font-family: 'Sarabun';">ผลิตภัณฑ์</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full_width">
      <div class="full_width_inner">
        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1510505660155 grid_section"
          style=' text-align:center;'>
          <div class=" section_inner clearfix">
            <div class='section_inner_margin clearfix'>
              <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner vc_custom_1518015324755">
                  <div class="wpb_wrapper">
                    <br> <br> <br> <br><br>




                    <!-- <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h2 style="text-align: center;"><span style="color: #009be6;">ONE TIME ENERGY</span></h2>

                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div> -->


                    <div class="wpb_single_image wpb_content_element vc_align_center">
                      <div class="wpb_wrapper">

                        <div class="vc_single_image-wrapper   vc_box_border_grey">
                          <img width="200" height="145" src="../../../../assets/templates/home/daisi.png"
                            class="vc_single_image-img attachment-full" alt=""
                            srcset="../../../../assets/templates/home/daisi.png 600w,
                                                    ../../../../assets/templates/home/0.001We-Save-Lives-600x145-300x73.png 300w"
                            sizes="(max-width: 600px) 100vw, 600px" />
                        </div>
                      </div>
                    </div>
                    <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner">
                        <span class="empty_space_image"></span>
                      </span>
                    </div>

                    <div class="vc_row wpb_row section vc_row-fluid vc_inner " style=' text-align:left;'>
                      <div class=" full_section_inner clearfix" style="margin-bottom: 80px">
                        <div class="wpb_column vc_column_container vc_col-sm-4">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h6 style="text-align: left;">DAISI will always be there to save you.
                                  </h6>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 7px">
                                <span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <p>“One Time Energy”.
                                    A one-shot energetic force generated instantly,
                                    reliably, and safely.

                                  </p>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 7px">
                                <span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>

<br>
                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h3 style="text-align: left;">
                                    <strong>What’s DAISI ?</strong>
                                  </h3>
                                  <p>
                                    <b>DAISI = DAICEL Saving Innovation</b><br>
                                    We continue our evolution to protect
                                    what we hold dear, across the world.
                                  </p>

                                </div>
                              </div>
                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <p>Gas generation through combustion of propellant.
                                  </p>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 7px">
                                <span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>



                              <div class="vc_empty_space" style="height: 7px">
                                <span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <br>
                              <a  (click)="scroll(Daici)"   target="_self" style="font-size: 12px"
                                class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                <span class="qode-btn-text" style="color: white;">Find Out
                                  More</span><span class="qode-button-v2-icon-holder"
                                  style="font-size: 16px;color: white;"><span aria-hidden="true"
                                    class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                              </a>
                              <br><br>

                            </div>
                          </div>
                          <br><br><br><br>

                        </div>
                        <div class="wpb_column vc_column_container vc_col-sm-8">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="wpb_single_image wpb_content_element vc_align_center">
                                <div class="wpb_wrapper">

                                  <div class="vc_single_image-wrapper   vc_box_border_grey">
                                    <video loop muted autoplay oncanplay="this.play()"
                                      onloadedmetadata="this.muted = true" id="myVideo" style="width:100%">
                                      <source src="../../../../assets/templates/home/daisi01_en.mp4" type="video/mp4">
                                    </video>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <section data-speed="1" class="parallax_section_holder  "
          style=" height:px; background-image:url('../../../../assets/templates/home/Products-Sample-A90-1920x1080.jpg');">
          <br><br><br>
          <div class="parallax_content_full_width center">
            <div class='parallax_section_inner_margin clearfix'>
              <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner vc_custom_1518015406018">
                  <div class="wpb_wrapper">
                    <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner">
                        <span class="empty_space_image"></span>
                      </span>
                    </div>


                    <div class="wpb_text_column wpb_content_element ">
                      <div class="wpb_wrapper">
                        <h2><span style="color: #009be6;">Products</span></h2>

                      </div>
                    </div>
                    <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner">
                        <span class="empty_space_image"></span>
                      </span>
                    </div>

                    <div class="vc_row wpb_row section vc_row-fluid vc_inner  grid_section" style=' text-align:center'>
                      <div class=" section_inner clearfix">
                        <div class='section_inner_margin clearfix'>
                          <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div
                                  class="qode-numbered-process-holder qode-numbered-process-holder-items-four qodef-np-padding-small qode-np-line-dashed">
                                  <div class="qode-numbered-process-inner">
                                    <div class="qode-np-item">
                                      <div class="qode-np-item-image-holder">
                                        <div class="qode-np-item-image-inner" style="border-color: #009be6">
                                          <div class="qode-np-item-image-table">
                                            <div class="qode-np-item-image-table-cell">
                                              <img src="../../../../assets/templates/home/product1.png"
                                                alt="qode-np-item" />
                                            </div>
                                          </div>
                                          <span class="qode-np-item-number"
                                            style="color: #009be6; background-color: #ffffff">1</span>
                                        </div>
                                        <span class="qode-np-line"></span>
                                      </div>
                                      <h4 class="qode-np-title" style="color: #000000">
                                        Co-creation </h4>

                                      <br>
                                      <p style="text-align: left;height: 100px;">
                                        Cutting-edge devices that apply our "ONE TIME ENERGY" technology.
                                      </p>

                                      <a (click)=" co_creation()" target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color: white;">Find Out
                                          More</span><span class="qode-button-v2-icon-holder"
                                          style="font-size: 16px;color: white;"><span aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>
<br><br>
                                    </div>
                                    <div class="qode-np-item">
                                      <div class="qode-np-item-image-holder">
                                        <div class="qode-np-item-image-inner" style="border-color: #009be6">
                                          <div class="qode-np-item-image-table">
                                            <div class="qode-np-item-image-table-cell">
                                              <img src="../../../../assets/templates/home/product_inflator.png"
                                                alt="qode-np-item" />
                                            </div>
                                          </div>
                                          <span class="qode-np-item-number"
                                            style="color: #009be6; background-color: #ffffff">2</span>
                                        </div>
                                        <span class="qode-np-line"></span>
                                      </div>
                                      <h4 class="qode-np-title" style="color: #000000">
                                        Inflator </h4>

                                      <br>
                                      <p style="text-align: left;height: 100px;">
                                        Essential component to ensure driver & passengers' safety.
                                      </p>

                                      <a (click)="inflator()" target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color: white;">Find Out
                                          More</span><span class="qode-button-v2-icon-holder"
                                          style="font-size: 16px;color: white;"><span aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>
                                      <br><br>

                                    </div>
                                    <div class="qode-np-item">
                                      <div class="qode-np-item-image-holder">
                                        <div class="qode-np-item-image-inner" style="border-color: #009be6">
                                          <div class="qode-np-item-image-table">
                                            <div class="qode-np-item-image-table-cell">
                                              <img src="../../../../assets/templates/home/product_pgg.png"
                                                alt="qode-np-item" />
                                            </div>
                                          </div>
                                          <span class="qode-np-item-number"f
                                            style="color: #009be6; background-color: #ffffff">3</span>
                                        </div>
                                        <span class="qode-np-line"></span>
                                      </div>
                                      <h4 class="qode-np-title" style="color: #000000">
                                        PGG</h4>
                                      <br>
                                      <p style="text-align: left;height: 100px;">
                                        Safety device stored inside the seat belt retractor to protect the occupant
                                        during a collision.
                                      </p>

                                      <a (click)=" pgg()" target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color: white;">Find Out
                                          More</span><span class="qode-button-v2-icon-holder"
                                          style="font-size: 16px;color: white;"><span aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                      <br><br>

                                    </div>
                                    <div class="qode-np-item">
                                      <div class="qode-np-item-image-holder">
                                        <div class="qode-np-item-image-inner" style="border-color: #009be6">
                                          <div class="qode-np-item-image-table">
                                            <div class="qode-np-item-image-table-cell">
                                              <img src="../../../../assets/templates/home/product_pyro-fuse.png"
                                                alt="qode-np-item" />
                                            </div>
                                          </div>
                                          <span class="qode-np-item-number"
                                            style="color: #009be6; background-color: #ffffff">4</span>
                                        </div>
                                        <span class="qode-np-line"></span>
                                      </div>
                                      <h4 class="qode-np-title" style="color: #000000">
                                        Pyro-fuse </h4>
                                      <br>
                                      <p style="text-align: left;height: 100px;">
                                        Instant electrical disconnector to prevent current flow from EV battery after a
                                        collision.
                                      </p>

                                      <a (click)=" pyro_fuse()" target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color: white;">Find Out
                                          More</span><span class="qode-button-v2-icon-holder"
                                          style="font-size: 16px;color: white;"><span aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                      <br><br><br><br>

                                    </div>
                                  </div>
                                </div>
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>



                              </div>
                              <br><br><br>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <div #Daici class="vc_row wpb_row section vc_row-fluid  vc_custom_1518014984044 grid_section"
          style=' text-align:left; background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>
          <br><br><br><br>

          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <h6 style="text-align: center;">DAISI will always be there to save you.
              </h6>

            </div>
          </div>
          <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
              <span class="empty_space_image"></span>
            </span>
          </div>


          <div class="wpb_text_column wpb_content_element ">
            <div class="wpb_wrapper">
              <h2 style="text-align: center;">DAISI </h2>

            </div>
          </div>

          <br><br>
          <div class=" section_inner clearfix">
            <div class='section_inner_margin clearfix'>
              <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper">
                    <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518015575529"
                      style=' text-align:left;'>
                      <div class=" full_section_inner clearfix">
                        <div class="wpb_column vc_column_container vc_col-sm-4">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h6>Technology</h6>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 7px">
                                <span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h3><strong>The fundamentals of DAISI </strong></h3>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <p>Be fully prepared for the unexpected.
                                    Be sure to activate no matter what.
                                    The most extreme environments kept advancing our DAISI’s technology.</p>

                                </div>
                              </div>
                              <br>

                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <div class="row">
                                    <div class="col-2">
                                      <img src="../../../../assets/templates/home/daisi_02.svg" alt="">
                                    </div>
                                    <div class="col">
                                      <p><i>"We fully control the pressure, timelength, and precision, owing to the
                                        combinations of three energy sources of short/medium/long distances."</i></p>

                                    </div>
                                  </div>

                                </div>
                              </div>




                            </div>
                          </div>
                        </div>
                        <div class="wpb_column vc_column_container vc_col-sm-8">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="wpb_single_image wpb_content_element vc_align_center">
                                <div class="wpb_wrapper">

                                  <div class="vc_single_image-wrapper   vc_box_border_grey">
                                    <img width="600" height="373" src="../../../../assets/templates/home/daisi_01.png"
                                      class="vc_single_image-img attachment-full" alt=""
                                      srcset="../../../../assets/templates/home/daisi_01.png 856w,
                                                                                        ../../../../assets/templates/home/Product-Edit01-856x373-B-300x131.png 300w,
                                                                                        ../../../../assets/templates/home/Product-Edit01-856x373-B-768x335.png 768w"
                                      sizes="(max-width: 856px) 100vw, 856px" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518015575529"
                      style=' text-align:left;margin-top: 100px;'>
                      <div class=" full_section_inner clearfix">
                        <div class="wpb_column vc_column_container vc_col-sm-4">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h6>DAISI Application</h6>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 7px">
                                <span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h3><strong>Actranza™ lab.</strong></h3>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <p>With the precise control of pressure and speed,
                                    we create a new additional value to the conventional syringes.</p>

                                </div>
                              </div>
                              <br>
                              <a href="https://www.daicel.com/safety/daisi/actranza_pr/en/" target="_self" style="font-size: 12px" class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                <span class="qode-btn-text" style="color: white;">Details</span><span class="qode-button-v2-icon-holder" style="font-size: 16px;color: white;"><span
                                            aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                            </a>
                            <br><br>


                            </div>
                          </div>
                        </div>
                        <div class="wpb_column vc_column_container vc_col-sm-8">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="wpb_single_image wpb_content_element vc_align_center">
                                <div class="wpb_wrapper">

                                  <div class="vc_single_image-wrapper   vc_box_border_grey">
                                    <video  controls  id="myVideo" style="width: 95%;"  poster="../../../../assets/templates/home/daisi_poster_02.jpg" >
                                      <source src="../../../../assets/templates/home/Actranza_Lab.mp4" type="video/mp4">
                                  </video>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518015575529"
                      style=' text-align:left;margin-top: 120px;'>
                      <div class=" full_section_inner clearfix">
                        <div class="wpb_column vc_column_container vc_col-sm-4">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h6>DAISI Application</h6>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 7px">
                                <span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h3><strong>Volt Breaker</strong></h3>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <p>We prevent electric accidents of EVs with the instantaneous shutdown of high-voltage electric currents.</p>

                                </div>
                              </div>

                              <br>
                              <a  (click)=" pyro_fuse() "   target="_self" style="font-size: 12px" class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                <span class="qode-btn-text" style="color: white;">Details</span><span class="qode-button-v2-icon-holder" style="font-size: 16px;color: white;"><span
                                            aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                            </a>
                            <br><br>

                            </div>
                          </div>
                        </div>
                        <div class="wpb_column vc_column_container vc_col-sm-8">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="wpb_single_image wpb_content_element vc_align_center">
                                <div class="wpb_wrapper">

                                  <div class="vc_single_image-wrapper   vc_box_border_grey">
                                    <video  controls  id="myVideo" style="width: 95%"  poster="../../../../assets/templates/home/daisi_poster_03.jpg" >
                                      <source src="../../../../assets/templates/home/VOLT BREAKER by DAISI.mp4" type="video/mp4">
                                  </video>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518015575529"
                    style=' text-align:left;margin-top: 120px;'>
                    <div class=" full_section_inner clearfix">
                      <div class="wpb_column vc_column_container vc_col-sm-4">
                        <div class="vc_column-inner ">
                          <div class="wpb_wrapper">
                            <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <h6>DAISI Application</h6>

                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 7px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <h3><strong>Airbag / Seatbelt</strong></h3>

                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <p>Our legacy of protecting lives with zero past malfunctions represent our reliability and performance.</p>

                              </div>
                            </div>


                            <br>
                            <a  (click)="inflator() "  target="_self" style="font-size: 12px" class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                              <span class="qode-btn-text" style="color: white;">Details</span><span class="qode-button-v2-icon-holder" style="font-size: 16px;color: white;"><span
                                          aria-hidden="true"
                                          class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                          </a>

                          <br><br>

                          </div>
                        </div>
                      </div>
                      <div class="wpb_column vc_column_container vc_col-sm-8">
                        <div class="vc_column-inner ">
                          <div class="wpb_wrapper">
                            <div class="wpb_single_image wpb_content_element vc_align_center">
                              <div class="wpb_wrapper">

                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                  <video  controls  id="myVideo" style="width: 95%"  poster="../../../../assets/templates/home/daisi_poster_04.jpg" >
                                    <source src="../../../../assets/templates/home/Airbags and Seatbelts by DAISI.mp4" type="video/mp4">
                                </video>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br><br><br><br><br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1510511606615 grid_section" style='background-image:url(../../../../assets/templates/home/Products-Sample-A90-1920x1080.jpg); text-align:center;'>
          <br><br><br>
          <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                  <div class="wpb_column vc_column_container vc_col-sm-12">
                      <div class="vc_column-inner ">
                          <div class="wpb_wrapper">
                              <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                      <h6 style="text-align: center;">Potential</h6>

                                  </div>
                              </div>
                              <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                  </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                      <h2 style="text-align: center;">What DAISI could achieve
                                      </h2>
                                      <p>DAISI’s possibilities give birth to
                                        the new aspects of future happiness.</p>

                                  </div>
                              </div>
                              <div class="vc_empty_space" style="height: 30px"><span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                  </span>
                              </div>

                              <div class="vc_row wpb_row section vc_row-fluid vc_inner " style=' text-align:left;'>
                                  <div class=" full_section_inner clearfix">
                                      <div class="wpb_column vc_column_container vc_col-sm-3">
                                          <div class="vc_column-inner ">
                                              <div class="wpb_wrapper">
                                                  <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <div class="wpb_wrapper">

                                                          <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                              <img width="150" height="150" src="../../../../assets/templates/home/no.1.png" class="vc_single_image-img attachment-thumbnail" alt="" srcset="../../../../assets/templates/home/../../../../assets/templates/home/no.1.png 150w,
                                                              ../../../../assets/templates/home/no.1.png 300w,
                                                              ../../../../assets/templates/home/no.1.png 768w,
                                                              ../../../../assets/templates/home/no.1.png 1024w,
                                                              ../../../../assets/templates/home/no.1.png 1200w,
                                                              ../../../../assets/templates/home/no.1.png100w" sizes="(max-width: 150px) 100vw, 150px" />
                                                          </div>
                                                      </div>
                                                  </div>


                                                  <div class="vc_empty_space" style="height: 7px">
                                                      <span class="vc_empty_space_inner">
                                                          <span class="empty_space_image"></span>
                                                      </span>
                                                  </div>


                                                  <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                          <p >
                                                            Secure taste and safety for food by sterilizing heat-resistant viruses with pressure
                                                          </p>

                                                      </div>
                                                  </div>
                                                  <div class="vc_empty_space" style="height: 7px">
                                                      <span class="vc_empty_space_inner">
                                                          <span class="empty_space_image"></span>
                                                      </span>
                                                  </div>

                                              </div>
                                          </div>
                                      </div>
                                      <div class="wpb_column vc_column_container vc_col-sm-3">
                                          <div class="vc_column-inner ">
                                              <div class="wpb_wrapper">
                                                  <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <div class="wpb_wrapper">

                                                          <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                              <img width="150" height="150" src="../../../../assets/templates/home/no.2.png" class="vc_single_image-img attachment-thumbnail" alt="" srcset="../../../../assets/templates/home/no.2.png 150w,
                                                              ../../../../assets/templates/home/no.2.png 300w,
                                                              ../../../../assets/templates/home/no.2.png 768w,
                                                              ../../../../assets/templates/home/no.2.png 1024w,
                                                              ../../../../assets/templates/home/no.2.png 1200w,
                                                              ../../../../assets/templates/home/no.2.png 100w" sizes="(max-width: 150px) 100vw, 150px" />
                                                          </div>
                                                      </div>
                                                  </div>


                                                  <div class="vc_empty_space" style="height: 7px">
                                                      <span class="vc_empty_space_inner">
                                                          <span class="empty_space_image"></span>
                                                      </span>
                                                  </div>


                                                  <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                          <p >
                                                            Provide wearable airbags to save lives from injuries and fallen impact
                                                          </p>

                                                      </div>
                                                  </div>
                                                  <div class="vc_empty_space" style="height: 7px">
                                                      <span class="vc_empty_space_inner">
                                                          <span class="empty_space_image"></span>
                                                      </span>
                                                  </div>

                                              </div>
                                          </div>
                                      </div>
                                      <div class="wpb_column vc_column_container vc_col-sm-3">
                                          <div class="vc_column-inner ">
                                              <div class="wpb_wrapper">
                                                  <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <div class="wpb_wrapper">

                                                          <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                              <img width="150" height="150" src="../../../../assets/templates/home/no.3.png" class="vc_single_image-img attachment-thumbnail" alt="" srcset="../../../../assets/templates/home/no.3.png 150w,
                                                              ../../../../assets/templates/home/no.3.png 300w,
                                                              ../../../../assets/templates/home/no.3.png 768w,
                                                              ../../../../assets/templates/home/no.3.png 1024w,
                                                              ../../../../assets/templates/home/no.3.png 1200w,
                                                              ../../../../assets/templates/home/no.3.png 100w" sizes="(max-width: 150px) 100vw, 150px" />
                                                          </div>
                                                      </div>
                                                  </div>

                                                  <div class="vc_empty_space" style="height: 7px">
                                                      <span class="vc_empty_space_inner">
                                                          <span class="empty_space_image"></span>
                                                      </span>
                                                  </div>


                                                  <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                          <p>
                                                            Protect people in unattended facilities (drones, autonomous driving, automated factories)
                                                          </p>

                                                      </div>
                                                  </div>
                                                  <div class="vc_empty_space" style="height: 7px">
                                                      <span class="vc_empty_space_inner">
                                                          <span class="empty_space_image"></span>
                                                      </span>
                                                  </div>

                                              </div>
                                          </div>
                                      </div>
                                      <div class="wpb_column vc_column_container vc_col-sm-3">
                                        <div class="vc_column-inner ">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_single_image wpb_content_element vc_align_center">
                                                    <div class="wpb_wrapper">

                                                        <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                            <img width="150" height="150" src="../../../../assets/templates/home/no.4.png" class="vc_single_image-img attachment-thumbnail" alt="" srcset="../../../../assets/templates/home/no.4.png 150w,
                                                            ../../../../assets/templates/home/no.4.png 300w,
                                                            ../../../../assets/templates/home/no.4.png 768w,
                                                            ../../../../assets/templates/home/no.4.png 1024w,
                                                            ../../../../assets/templates/home/no.4.png 1200w,
                                                            ../../../../assets/templates/home/no.4.png 100w" sizes="(max-width: 150px) 100vw, 150px" />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="vc_empty_space" style="height: 7px">
                                                    <span class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span>
                                                </div>


                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <p >
                                                          Create safe and nutrient-rich fodder from food waste
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 7px">
                                                    <span class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                  </div>

                              </div>

                              <div class="vc_empty_space" style="height: 30px"><span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                            </span>
                        </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <br><br><br>
      </div>

      <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1510596881665 grid_section"
      style='background-color:#ffffff; text-align:right;'>
      <br>
      <div class=" section_inner clearfix">
        <div class='section_inner_margin clearfix'>
          <div class="wpb_column vc_column_container vc_col-sm-12">
            <div class="vc_column-inner ">
              <div class="wpb_wrapper">
                <div
                  class='q_elements_holder two_columns eh_two_columns_66_33 responsive_mode_from_768 alignment_one_column_center'>
                  <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                    <div class='q_elements_item_inner'>
                      <div class='q_elements_item_content q_elements_holder_custom_904884'>
                        <style type="text/css" data-type="q_elements_custom_padding" scoped>
                          @media only screen and (min-width: 600px) and (max-width: 768px) {
                            .q_elements_item_content.q_elements_holder_custom_904884 {
                              padding: 0 0 21px 0 !important;
                            }
                          }

                          @media only screen and (min-width: 480px) and (max-width: 600px) {
                            .q_elements_item_content.q_elements_holder_custom_904884 {
                              padding: 0 0 21px 0 !important;
                            }
                          }

                          @media only screen and (max-width: 480px) {
                            .q_elements_item_content.q_elements_holder_custom_904884 {
                              padding: 0 0 21px 0 !important;
                            }
                          }
                        </style>
                        <div class="wpb_text_column wpb_content_element ">
                          <div class="wpb_wrapper">
                            <h3>HAVE QUESTIONS ABOUT OUR PRODUCTS?</h3>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                    <div class='q_elements_item_inner'>
                      <div class='q_elements_item_content q_elements_holder_custom_972935'>
                        <style type="text/css" data-type="q_elements_custom_padding" scoped>
                          @media only screen and (min-width: 600px) and (max-width: 768px) {
                            .q_elements_item_content.q_elements_holder_custom_972935 {
                              padding: 0 0 21px 0 !important;
                            }
                          }

                          @media only screen and (min-width: 480px) and (max-width: 600px) {
                            .q_elements_item_content.q_elements_holder_custom_972935 {
                              padding: 0 0 21px 0 !important;
                            }
                          }

                          @media only screen and (max-width: 480px) {
                            .q_elements_item_content.q_elements_holder_custom_972935 {
                              padding: 0 0 21px 0 !important;
                            }
                          }
                        </style>

                        <a  href="mailto:sales.dsst@dsst.daicel.com" target="_self"
                          style="font-size: 13px"
                          class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                          <span class="qode-btn-text">Get In
                            Touch</span><span class="qode-button-v2-icon-holder" style="font-size: 21px"><span
                              aria-hidden="true"
                              class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>



      </div>
    </div>

  </div>
</div>

<app-footer-th></app-footer-th>
