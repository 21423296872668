import { Injectable } from '@angular/core';
import { UserModel } from '../models/user';
import { contentModel } from '../models/content';
import { imagesModel } from '../models/images';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  public readonly USERLOGIN: UserModel = this.authenticationService.currentUserValue;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  findByIdAll = (id?: string) => {
    return this.http.get<any>(`${environment.URL_IMG}all/${id}`);
  };
  findByIdHeader = (id?: string) => {
    return this.http.get<any>(`${environment.URL_IMG}header/${id}`,{headers: this.headers});
  };

  delete = (id: string) => {
    return this.http.delete<any>(`${environment.URL_IMG}${id}`);
  };
  
  addImg(img) {
    return this.http.post<any>(`${environment.URL_IMG}img`,img);
  };
  addImgdb(data){    
    return this.http.post<any>(`${environment.URL_IMG}db`, data);
  }




};



