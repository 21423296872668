<header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
  <div class="header_inner clearfix">
    <div class="header_top_bottom_holder">
      <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
        <div class="container">
          <div class="container_inner clearfix">
            <div class="header_inner_left">
              <a style="height: 60px; visibility: visible;">
                <img src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                  style="height: 50px;margin-top: 5px;"> </a>

              <div class="header_fixed_right_area">
                <div class="textwidget custom-html-widget">
                  <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                      <span class="empty_space_image"></span>
                    </span>
                  </div>



                  <span style="display: inline-block;" style="margin-top: 10%;">
                    <div class="q_icon_with_title medium custom_icon_image ">
                      <a class=""><i class="fa fa-sign-out" aria-hidden="true"></i><span style="
                                        ">LOGOUT<span class="underline_dash"></span></span><span
                          class="plus"></span></a>


                    </div>
                  </span>


                </div>
              </div>
            </div>
            <div class="header_menu_bottom">
              <div class="header_menu_bottom_inner">
                <div class="main_menu_header_inner_right_holder">
                  <nav class="main_menu drop_down">
                    <ul id="menu-main-menu" class="clearfix">
                      <li id="nav-menu-item-1532"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a class=""><i class="menu_icon blank fa"></i><span style="
                                                          ">All career<span class="underline_dash"></span></span><span
                            class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-1530"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a class=""  (click)="openModal(benefits)" ><i class="menu_icon blank fa"></i><span style="
                                                                        ">Benefits<span
                              class="underline_dash"></span></span><span class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-1530"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a class=""  (click)="openModal(contacts)"><i class="menu_icon blank fa"></i><span style="
                                                                                      ">Contacts<span
                              class="underline_dash"></span></span><span class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-1530"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a class=""  (click)="openModal(newCareer)"><span>Add new career<span class="underline_dash"></span></span><span
                            class="plus"></span></a>
                      </li>


                    </ul>
                  </nav>
                  <div class="header_inner_right">
                    <div class="side_menu_button_wrapper right">
                      <div class="header_bottom_right_widget_holder">
                        <div class="widget_text header_bottom_widget widget_custom_html">
                          <div class="textwidget custom-html-widget">
                            <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">
                              <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">


                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="side_menu_button">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</header>




<body>

  <div class="overlay"></div>
  <!-- ======== sidebar-nav end =========== -->

  <!-- ======== main-wrapper start =========== -->
  <main class="main-wrapper" style="margin-left: 0;">


    <!-- ========== section start ========== -->
    <section class="section">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <div class="title">
              <h2>All Career</h2>
            </div>
          </div>

          <div class="col">
            <span style="float: right;">Recruit /
              <a href="#0"> All career</a>
            </span>



          </div>



        </div>


        <div class="row" style="margin-top: -30px;">

          <!-- End Col -->
          <div class="col-lg-12">
            <div class="card-style mb-30">
              <div class="
                  title
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                ">
                <div class="left">
                  <button type="button" class="btn btn-primary btn-sm" style="font-size: 14px;">
                    <i class="fa fa-refresh" aria-hidden="true"></i> Re-Post All Career
                  </button>

                </div>
                <div class="right">
                  <label class="sr-only" for="inlineFormInputGroupMD">Search</label>
                  <div class="md-form input-group ">
                    <div class="input-group-prepend">
                      <span class="input-group-text md-addon"><i class="fa fa-search" aria-hidden="true"></i>
                        :&nbsp;</span>
                    </div>
                    <input mdbInput type="text" class="form-control pl-0 rounded-0" id="inlineFormInputGroupMD"
                      style="width: 480px;" placeholder="Search position ..." />
                  </div>





                  <!-- end select -->
                </div>
              </div>
              <!-- End Title -->
              <div class="table">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 5%;">
                        <h6 class="text-sm text-medium">
                          #
                        </h6>
                      </th>
                      <th style="width: 5%;">
                        <h6 class="text-sm text-medium">

                        </h6>
                      </th>

                      <th style="width:45%;">
                        <h6 class="text-sm text-medium">
                          Position
                        </h6>
                      </th>
                      <th style="width: 10%;">
                        <h6 class="text-sm text-medium">
                          Last modified
                        </h6>
                      </th>
                      <th style="width: 10%;text-align:center ;">
                        <h6 class="text-sm text-medium">
                          Status
                        </h6>
                      </th>
                      <th style="width: 15%;">
                        <h6 style="text-align: center;" class="text-sm text-medium">
                          Location
                        </h6>
                      </th>
                      <th style="width: 25%;">
                        <h6 class="text-sm text-medium">
                          Actions
                        </h6>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of career; let i = index">
                      <td style="text-align: left;"> {{ i + 1 }}</td>
                      <td style="text-align: left;">
                        <div *ngIf="item.active == '3' ">
                          <p class="" style="color:red;font-weight: bold;"><span>Urgent</span>
                          </p>
                        </div>
                      </td>

                      <td style="text-align: left;"> <p>{{ item.event_name_en }}</p></td>

                      <!-- <td>{{ item.post_by }}</td> -->
                      <td><p>{{ item.post_date | date: "dd/MM/yyyy" }}</p></td>
                      <td>
                        <span style="width: 110px;text-align: center;" *ngIf="(date_now) < (item.published_date )" class="status-btn close-btn"><i class="fa fa-eye-slash" aria-hidden="true"></i> Unposted </span>
                        <span  style="width: 110px;text-align: center;"  *ngIf="(date_now) >= (item.published_date )"  class="status-btn success-btn"><i class="fa fa-eye" aria-hidden="true"></i> Post</span>
                      </td>
                      <td>
                     <p style="text-align: center;">Prachinburi</p>


                      </td>


                      <td >

                        <div class="dropdown">
                          <button type="button" class="btn btn-outline-warning" mdbWavesEffect><i class="fa fa-cog" aria-hidden="true" ></i></button>
                          <div class="dropdown-content">
                            <a class="dropdown-item" href="#" style="font-size: 14px;" ><i class="fas fa-pencil-alt mt-0" style="color: orange;" ></i> Edit</a>
                            <a class="dropdown-item" href="#"  style="font-size: 14px;">  <i class="fa fa-copy" aria-hidden="true" style="color: purple;"></i> Copy</a>
                            <a class="dropdown-item" href="#"  style="font-size: 14px;"> <i class="fa fa-refresh" aria-hidden="true" style="color:blue"></i> Re-post</a>
                            <div class="divider dropdown-divider"></div>
                            <a class="dropdown-item" href="#"  style="font-size: 14px;"><i class="fas fa-eraser mt-0" style="color: red;"></i> Delete</a>
                          </div>
                        </div>






                      </td>
                    </tr>


                    <br><br><br>

                  </tbody>
                </table>
                <!-- End Table -->
              </div>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
      <!-- end container -->
    </section>
    <!-- ========== section end ========== -->

    <!-- ========== footer start =========== -->
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 order-last order-md-first">
            <p class="text-sm" style="text-align: center;">
              Designed and Developed by

            </p>
          </div>
          <!-- end col-->

        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </footer>
    <!-- ========== footer end =========== -->
  </main>
  <!-- ======== main-wrapper end =========== -->

</body>




<ng-template #benefits>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Benefits</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true" style="font-size:25px ;">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-lg">
    <div  #editor class="editor" [(innerHtml)]="description" contenteditable style="    height: auto;
    min-height: 250px;"  >
    </div>
  </div>
  <div class="modal-footer">
    <button   (click)="modalRef.hide()"  type="button" class="btn btn-secondary button-close" style="color: white;"><i class="fa fa-times" aria-hidden="true"></i>
      Close</button>
      <button  (click)="saveBenefits()"   type="button" class="btn btn-light button-footer" style="    background: #4a6cf7;
      color: #fff;"><i class="fa fa-refresh" aria-hidden="true"></i>
      Update
    </button>
  </div>
</ng-template>

<ng-template #contacts>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Contacts</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-lg">
    This is a modal.
  </div>
</ng-template>


<ng-template #newCareer>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add New Career</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-lg">
    This is a modal.
  </div>
</ng-template>



<div #editor class="editor" [(innerHtml)]="description" (input)="onChange()" contenteditable></div>
