<title>
    Daicel Thailand | Daicel Group</title>
<header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
    <div class="header_inner clearfix">
        <div class="header_top_bottom_holder">
            <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
                <div class="container">
                    <div class="container_inner clearfix">
                        <div class="header_inner_left">
                            <div class="mobile_menu_button">
                                <span>
                                    <i class="fa fa-bars"></i> </span>
                            </div>
                            <div class="logo_wrapper">
                                <div class="q_logo">
                                    <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                        <img itemprop="image" class="normal"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="light"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="dark"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="sticky"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="mobile"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> </a>
                                </div>
                            </div>
                            <div class="header_fixed_right_area">
                                <div class="textwidget custom-html-widget">
                                    <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                            <span class="empty_space_image"></span>
                                        </span>
                                    </div>

                                    <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image "
                                            style="margin-right: 5px;">
                                            <div class="icon_holder " style="">
                                                <img itemprop="image" style="
                                                margin-top: 7px;
                                                margin-left: 5px;
                                            " src="../assets/templates/home/pr.png" alt="">
                                            </div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                    <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                  font-weight: 400!important;
                                                  font-family: 'Open Sans', sans-serif!important;
                                                  "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                    font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                    "> 241 Moo 4, 304 Ind Park<br>
                                                        Prachinburi 25140 Thailand <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block; margin-right: 15px;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                              margin-top: 7px;
                                              margin-left: 5px;
                                          " src="../assets/templates/home/sb.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                    <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                  font-weight: 400!important;
                                                  font-family: 'Open Sans', sans-serif!important;
                                                  ">
                                                        +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                    font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                    "> 76 Moo.1, A.Phra Phutthabat<br>
                                                        Saraburi 18120 Thailand <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                              margin-top: 7px;
                                              margin-left: 5px;
                                          " src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                    <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                  font-weight: 400!important;
                                                  font-family: 'Open Sans', sans-serif!important;
                                                  "> daicel-dsst.com<br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                    font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                    "> Monday - Friday 08h - 17h<br>Closed on Weekends


                                                        <br>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>


                                </div>
                            </div>
                        </div>
                        <div class="header_menu_bottom">
                            <div class="header_menu_bottom_inner">
                                <div class="main_menu_header_inner_right_holder">
                                    <nav class="main_menu drop_down">
                                        <ul id="menu-main-menu" class="clearfix">
                                            <li id="nav-menu-item-1532"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="aboutUs()" class=""><i class="menu_icon blank fa"></i><span
                                                        style="
                                                              ">About
                                                        Us<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1530"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="products()" class=""><i class="menu_icon blank fa"></i><span
                                                        style="
                                                              ">Products<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1526"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="daicel_group()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                              ">Daicel
                                                        Group<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1527"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="csr_page()" class=""><i class="menu_icon blank fa"></i><span
                                                        style="
                                                              ">CSR<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1529"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="news_events()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                              ">News<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1531"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="career()" class=""><i class="menu_icon blank fa"></i><span
                                                        style="
                                                                      ">Career
                                                        <span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1531"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="contact_us()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                              ">Contact
                                                        Us<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-wpml-ls-12-en"
                                                class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                                <a title="English" class=""><i class="menu_icon blank fa"></i><span><img
                                                            class="wpml-ls-flag" src="../assets/templates/home/en.png"
                                                            alt="en" title="English"><span class="wpml-ls-native" style="
                                                                  ">English</span><span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span><span class="qode-featured-icon "
                                                        aria-hidden="true"></span></a>
                                                <div class="second" style="height: 0px;">
                                                    <div class="inner">
                                                        <ul>
                                                            <li id="nav-menu-item-wpml-ls-12-th"
                                                                class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                                <a title="ไทย" (click)="th()" class=""><i
                                                                        class="menu_icon blank fa"></i><span><img
                                                                            class="wpml-ls-flag"
                                                                            src="../assets/templates/home/th.png"
                                                                            alt="th" title="ไทย"><span
                                                                            class="wpml-ls-native">ไทย</span></span><span
                                                                        class="plus"></span><span
                                                                        class="qode-featured-icon "
                                                                        aria-hidden="true"></span></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div class="header_inner_right">
                                        <div class="side_menu_button_wrapper right">
                                            <div class="header_bottom_right_widget_holder">
                                                <div class="widget_text header_bottom_widget widget_custom_html">
                                                    <div class="textwidget custom-html-widget">
                                                        <div class="vc_row wpb_row section vc_row-fluid "
                                                            style=" text-align:left;">
                                                            <div class=" full_section_inner clearfix">
                                                                <div
                                                                    class="wpb_column vc_column_container vc_col-sm-12">
                                                                    <div class="vc_column-inner ">
                                                                        <div class="wpb_wrapper">


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="side_menu_button">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav class="mobile_menu">
                            <ul id="menu-main-menu-1" class="">
                                <li id="mobile-menu-item-1532"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="aboutUs()" class=""><span>About
                                            Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1530"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="products()" class=""><span>Products</span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1526"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="daicel_group()" class=""><span>Daicel
                                            Group</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1527"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="csr_page()" class=""><span>CSR</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1529"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="news_events()" class=""><span>News</span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1529"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="career()" class=""><span>Career</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>

                                <li id="mobile-menu-item-1531"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="contact_us()" class=""><span>Contact
                                            Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-wpml-ls-12-en"
                                    class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                    <a title="English" class=""><span><img class="wpml-ls-flag"
                                                src="../assets/templates/home/en.png" alt="en" title="English"><span
                                                class="wpml-ls-native">English</span></span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span>
                                    <ul class="sub_menu">
                                        <li id="mobile-menu-item-wpml-ls-12-th"
                                            class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                            <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                        src="../assets/templates/home/th.png" alt="th" title="ไทย"><span
                                                        class="wpml-ls-native">ไทย</span></span></a><span
                                                class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                    class="fa fa-angle-down"></i></span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>

</header>
<a id="back_to_top" href="#" class="off">
    <span class="fa-stack">
        <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>



<div class="content content_top_margin_none">
    <div class="content_inner  ">
        <div class="title_outer title_without_animation" data-height="250">
            <div class="title title_size_small  position_left  has_fixed_background "
                style="background-size:1920px auto;background-image:url(../../../../assets/templates/home/BG-Global-Network-No-Text-1920x600.jpg);height:250px;">
                <div class="image not_responsive"><img itemprop="image"
                        src="../../../../assets/templates/home/BG-Global-Network-No-Text-1920x600.jpg" alt="&nbsp;" />
                </div>
                <div class="title_holder" style="padding-top:0;height:250px;">
                    <div class="container">
                        <div class="container_inner clearfix">
                            <div class="title_subtitle_holder">
                                <div class="title_subtitle_holder_inner">
                                    <h1><span>Daicel Group</span></h1>

                                    <div class="breadcrumb" style="background-color: transparent;">
                                        <div class="breadcrumbs">
                                            <div itemprop="breadcrumb"><a (click)="home()">Home</a><span
                                                    class="delimiter">&nbsp;>&nbsp;</span><span class="current">Daicel
                                                    Group</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="full_width">
            <div class="full_width_inner">
                <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1510505660155 grid_section"
                    style=' text-align:center;'>
                    <br><br><br><br><br>

                    <div class=" section_inner clearfix">
                        <div class='section_inner_margin clearfix'>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h5 style="text-align: center;">We Belong to</h5>

                                            </div>
                                        </div>
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                            <div class="wpb_wrapper">

                                                <a href="https://www.daicel.com/en/" target="_blank">
                                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                        <img width="300" height="70"
                                                            src="../../../../assets/templates/home/logo-Daicel-Group-300x70.png"
                                                            class="vc_single_image-img attachment-full" alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style="height: 7px"><span
                                                class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div>


                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h6 style="text-align: center;">Global Network</h6>

                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style="height: 7px"><span
                                                class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div>


                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h2 style="text-align: center;">Daicel Safety Systems Companies
                                                </h2>

                                            </div>
                                        </div>
                                        <div class="vc_empty_space" style="height: 7px"><span
                                                class="vc_empty_space_inner">
                                                <span class="empty_space_image"></span>
                                            </span>
                                        </div>


                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <p style="text-align: center;">Seven Production Sites In Six Countries
                                                    Manufacture And Sell Our Products To Customers Around The World.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br><br>
                </div>

                <div class="vc_row wpb_row section vc_row-fluid " style=' text-align:center;'>

                    <div class=" full_section_inner clearfix">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                        <div class="wpb_wrapper">

                                            <div class="vc_single_image-wrapper   vc_box_border_grey"><img width="1920"
                                                    height="550"
                                                    src="../../../../assets/templates/home/Global-Network-Text-1920x550.jpg"
                                                    class="vc_single_image-img attachment-full" alt=""
                                                    sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518016787536 grid_section"
                    style=' text-align:left;background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>

                    <br><br>
                    <div class=" section_inner clearfix">
                        <div class='section_inner_margin clearfix'>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_row wpb_row section vc_row-fluid vc_inner  grid_section"
                                            style=' text-align:center;'>
                                            <div class=" section_inner clearfix">
                                                <div class='section_inner_margin clearfix'>
                                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h6>Global Network</h6>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 7px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h2>Japan &amp; Asia</h2>

                                                                    </div>
                                                                </div>
                                                                <br>
                                                                <div class="vc_empty_space" style="height: 21px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1510507380341"
                                            style=' text-align:left;'>

                                            <div class=" full_section_inner clearfix">
                                                <div class="wpb_column vc_column_container vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="vc_empty_space" style="height: 32px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h6>DSS (Japan)</h6>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 7px">
                                                                <span class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h3><strong>1. Daicel Safety Systems
                                                                            Inc.</strong></h3>

                                                                </div>
                                                            </div>
                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <p>Manufacturing and sales of automobile airbag
                                                                        inflators, initiators and PGGs.
                                                                    </p>
                                                                    <p><strong>Address:</strong> 805 Umaba, Ibogawa-cho,
                                                                        Tatsuno-chi, Hyogo 671-1681, Japan</p>
                                                                    <p><strong>Website: <a
                                                                                href="http://www.daicel.com/msd/en/"
                                                                                target="_blank"
                                                                                rel="noopener">www.daicel.com/msd/en/</a></strong>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="wpb_column vc_column_container vc_col-sm-8">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div
                                                                class="wpb_single_image wpb_content_element vc_align_center  element_from_right">
                                                                <div class="wpb_wrapper">

                                                                    <div
                                                                        class="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width="1920" height="1080"
                                                                            src="../../../../assets/templates/home/DSS Japan.jfif"
                                                                            class="vc_single_image-img attachment-full"
                                                                            alt=""
                                                                            sizes="(max-width: 1920px) 100vw, 1920px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1510507380341"
                                            style=' text-align:left;'>
                                            <br><br><br>
                                            <div class=" full_section_inner clearfix">
                                                <div class="wpb_column vc_column_container vc_col-sm-8">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div
                                                                class="wpb_single_image wpb_content_element vc_align_center  element_from_left">
                                                                <div class="wpb_wrapper">

                                                                    <div
                                                                        class="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width="1920" height="1080"
                                                                            src="../../../../assets/templates/home/DSSTThailand-DSTTThailand-1920x1080.jpg"
                                                                            class="vc_single_image-img attachment-full"
                                                                            alt=""
                                                                            sizes="(max-width: 1920px) 100vw, 1920px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="wpb_column vc_column_container vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="vc_empty_space" style="height: 32px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h6>DSST (Thailand) </h6>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 7px">
                                                                <span class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h3><strong>2. Daicel Safety Systems
                                                                            (Thailand) Co., Ltd.</strong>
                                                                    </h3>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 21px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <p>Manufacturing and sales of automobile airbag
                                                                        inflators and PGGs </p>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 7px">
                                                                <span class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>




                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">

                                                                    <p><strong>Head Office :</strong> 241 Moo.4, 304
                                                                        Industrial Park, T.Thatoom, A.Srimahaphote,
                                                                        Prachinburi 25140 Thailand
                                                                    </p>
                                                                    <p><strong>Branch Office :</strong> 76 Moo.1, T.Phu
                                                                        Kham Chan, A.Phra Phutthabat, Saraburi 18120
                                                                        Thailand
                                                                    </p>
                                                                    <p><strong>Website: <a (click)="home()"
                                                                                target="_blank"
                                                                                rel="noopener">www.daicel-dsst.com</a></strong>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1510507380341"
                                            style=' text-align:left;'>
                                            <br><br><br>
                                            <div class=" full_section_inner clearfix">
                                                <div class="wpb_column vc_column_container vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="vc_empty_space" style="height: 32px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h6>DSSC (China)</h6>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 7px">
                                                                <span class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h3><strong>3. Daicel Safety Systems
                                                                            (Jiangsu) Co., Ltd.</strong>
                                                                    </h3>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 21px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <p>Manufacturing and sales of automobile airbag
                                                                        initiators, gas generants.
                                                                    </p>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 7px">
                                                                <span class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h3><strong>4. Daicel Safety
                                                                            Technologies (Jiangsu) Co.,
                                                                            Ltd.</strong></h3>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 21px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <p>Manufacture and sales of initiators.
                                                                    </p>
                                                                    <p><strong>Address:</strong> Chenshan, Houxiang
                                                                        Town, Danyang City, Jiangsu 212312 China</p>
                                                                    <p><strong>Website: &#8211;</strong></p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="wpb_column vc_column_container vc_col-sm-8">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div
                                                                class="wpb_single_image wpb_content_element vc_align_center  element_from_right">
                                                                <div class="wpb_wrapper">

                                                                    <div
                                                                        class="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width="1920" height="1080"
                                                                            src="../../../../assets/templates/home/DSSCChina-1920x1080.jpg"
                                                                            class="vc_single_image-img attachment-full"
                                                                            alt=""
                                                                            srcset="../../../../assets/templates/home/DSSCChina-1920x1080.jpg 1920w,
                                                                        ../../../../assets/templates/home/DSSCChina-1920x1080-300x169.jpg 300w,
                                                                        ../../../../assets/templates/home/DSSCChina-1920x1080-768x432.jpg 768w,
                                                                        ../../../../assets/templates/home/DSSCChina-1920x1080-1024x576.jpg 1024w"
                                                                            sizes="(max-width: 1920px) 100vw, 1920px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1510507380341"
                                            style=' text-align:left;'>
                                            <br><br><br>
                                            <div class=" full_section_inner clearfix">
                                                <div class="wpb_column vc_column_container vc_col-sm-8">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div
                                                                class="wpb_single_image wpb_content_element vc_align_center  element_from_left">
                                                                <div class="wpb_wrapper">

                                                                    <div
                                                                        class="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width="1920" height="1080"
                                                                            src="../../../../assets/templates/home/DSSI.png"
                                                                            class="vc_single_image-img attachment-full"
                                                                            alt=""
                                                                            sizes="(max-width: 1920px) 100vw, 1920px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="wpb_column vc_column_container vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="vc_empty_space" style="height: 32px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h6>DSSI (India)</h6>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 7px">
                                                                <span class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h3><strong>5. Daicel Safety Systems India Pvt. Ltd.
                                                                        </strong></h3>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 21px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <p>Manufacture and sales of Inflator.
                                                                    </p>
                                                                    <p><strong>Address:</strong> Chennai, India.</p>
                                                                    <p><strong>Website: &#8211;</strong></p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br><br>
                </div>

                <section data-speed="1" class="parallax_section_holder  "
                    style=" height:px; background-image:url('../../../../assets/templates/home/GLOBAL-NETWORK-1920x1080-A.jpg');">
                    <br><br><br><br><br>
                    <div class="parallax_content_full_width center">
                        <div class='parallax_section_inner_margin clearfix'>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner vc_custom_1518017122465">
                                    <div class="wpb_wrapper">
                                        <div class="vc_row wpb_row section vc_row-fluid vc_inner  grid_section"
                                            style=' text-align:center;'>
                                            <div class=" section_inner clearfix">
                                                <div class='section_inner_margin clearfix'>
                                                    <div class="wpb_column vc_column_container vc_col-sm-4">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="q_counter_holder  center" style=""><span
                                                                        class="counter  zero"
                                                                        style="color: #ffffff;font-size: 72px;">7</span><span
                                                                        class="separator small" style=""></span>
                                                                    <p class="counter_text"
                                                                        style="font-size: 24px;text-transform: capitalize;color: #ffffff;">
                                                                        Production Sites</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="wpb_column vc_column_container vc_col-sm-4">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="q_counter_holder  center" style=""><span
                                                                        class="counter  zero"
                                                                        style="color: #ffffff;font-size: 72px;">6</span><span
                                                                        class="separator small" style=""></span>
                                                                    <p class="counter_text"
                                                                        style="font-size: 24px;text-transform: capitalize;color: #ffffff;">
                                                                        Countries</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="wpb_column vc_column_container vc_col-sm-4">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="q_counter_holder  center" style=""><span
                                                                        class="counter  zero"
                                                                        style="color: #ffffff;font-size: 72px;">3</span><span
                                                                        class="separator small" style=""></span>
                                                                    <p class="counter_text"
                                                                        style="font-size: 24px;text-transform: capitalize;color: #ffffff;">
                                                                        Continents</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br><br><br><br>
                </section>

                <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518016846915 grid_section"
                    style=' text-align:left;background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>
                    <br><br><br>
                    <div class=" section_inner clearfix">
                        <div class='section_inner_margin clearfix'>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_row wpb_row section vc_row-fluid vc_inner  grid_section"
                                            style=' text-align:center;'>
                                            <div class=" section_inner clearfix">
                                                <div class='section_inner_margin clearfix'>
                                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h6>Global Network</h6>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 7px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>

                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h2>Europe &amp; North America</h2>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 21px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>
                                                                <br>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1510507380341"
                                            style=' text-align:left;'>
                                            <br><br>
                                            <div class=" full_section_inner clearfix">
                                                <div class="wpb_column vc_column_container vc_col-sm-8">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div
                                                                class="wpb_single_image wpb_content_element vc_align_center  element_from_left">
                                                                <div class="wpb_wrapper">

                                                                    <div
                                                                        class="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width="1920" height="1080"
                                                                            src="../../../../assets/templates/home/DSSEPoland-1920x1080.jpg"
                                                                            class="vc_single_image-img attachment-full"
                                                                            alt=""
                                                                            srcset="../../../../assets/templates/home/DSSEPoland-1920x1080.jpg 1920w,
                                                                                ../../../../assets/templates/home/DSSEPoland-1920x1080-300x169.jpg 300w,
                                                                                ../../../../assets/templates/home/DSSEPoland-1920x1080-768x432.jpg 768w,
                                                                                ../../../../assets/templates/home/DSSEPoland-1920x1080-1024x576.jpg 1024w"
                                                                            sizes="(max-width: 1920px) 100vw, 1920px" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="wpb_column vc_column_container vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="vc_empty_space" style="height: 32px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h6>DSSE (Poland)</h6>

                                                                </div>
                                                            </div>
                                                            <div class="vc_empty_space" style="height: 7px">
                                                                <span class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h3><strong>6. Daicel Safety Systems
                                                                            Europe Sp. Z o.o.</strong></h3>

                                                                </div>
                                                            </div>

                                                            <div class="vc_empty_space" style="height: 21px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>
                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <p>Manufacturing and sales of automobile airbag
                                                                        inflators.</p>
                                                                    <p><strong>Address:</strong> 6 Strefowa Str. 58-130
                                                                        Zarow Poland</p>
                                                                    <p><strong>Website: <a
                                                                                href="http://www.daicelsse.com/homepage/"
                                                                                target="_blank"
                                                                                rel="noopener">www.daicelsse.com</a></strong>
                                                                    </p>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1510507380341"
                                            style=' text-align:left;'>
                                            <br><br>
                                            <div class=" full_section_inner clearfix">
                                                <div class="wpb_column vc_column_container vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="vc_empty_space" style="height: 32px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>

                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h6>DSSA (U.S.A.)</h6>

                                                                </div>
                                                            </div>


                                                            <div class="vc_empty_space" style="height: 7px">
                                                                <span class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <h3><strong>7. Daicel Safety Systems
                                                                            Americas, Inc.</strong></h3>

                                                                </div>
                                                            </div>



                                                            <div class="vc_empty_space" style="height: 21px"><span
                                                                    class="vc_empty_space_inner">
                                                                    <span class="empty_space_image"></span>
                                                                </span>
                                                            </div>


                                                            <div class="wpb_text_column wpb_content_element ">
                                                                <div class="wpb_wrapper">
                                                                    <p>Manufacturing and sales of gas generators for seat belt pretensioners as well as inflators and initiatos for automotive air bags</p>
                                                                    <p><strong>Address: </strong>4558 E Virginia St, Mesa, AZ 85215, U.S.A.</p>
                                                                    <p><strong>Website: <a
                                                                                href="http://www.daicelssa.com/"
                                                                                target="_blank"
                                                                                rel="noopener">www.daicelssa.com</a></strong>
                                                                    </p>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="wpb_column vc_column_container vc_col-sm-8">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div
                                                                class="wpb_single_image wpb_content_element vc_align_center  element_from_right">
                                                                <div class="wpb_wrapper">

                                                                    <div
                                                                        class="vc_single_image-wrapper   vc_box_border_grey">
                                                                        <img width="1920" height="1080"
                                                                            src="../../../../assets/templates/home/DSSA-1920x1080.jpg"
                                                                            class="vc_single_image-img attachment-full"
                                                                            alt=""
                                                                            srcset="../../../../assets/templates/home/DSSA-1920x1080.jpg 1920w,
                                                                                ../../../../assets/templates/home/DSSA-1920x1080-300x169.jpg 300w,
                                                                                ../../../../assets/templates/home/DSSA-1920x1080-768x432.jpg 768w,
                                                                                ../../../../assets/templates/home/DSSA-1920x1080-1024x576.jpg 1024w"
                                                                            sizes="(max-width: 1920px) 100vw, 1920px" />


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br><br>
                </div>


            </div>
        </div>

    </div>
</div>
<div style="background-color: white;">
    <app-slide-footer></app-slide-footer>
</div>

<app-footer></app-footer>