import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Location, formatDate } from '@angular/common';
import { ContentService } from 'src/app/services/content.service';
import { ThrowStmt } from '@angular/compiler';
import { DomSanitizer } from '@angular/platform-browser';
import { contentModel } from 'src/app/models/content';
import { UserModel } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-preview-career',
  templateUrl: './preview-career.component.html',
  styleUrls: ['./preview-career.component.css']
})
export class PreviewCareerComponent implements OnInit {
  public content1: contentModel;
  public readonly USERLOGIN: UserModel = this.authenticationService.currentUserValue;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private rest: ContentService, private sanitizer: DomSanitizer,
    private contentService: ContentService,
    private authenticationService: AuthenticationService
  ) { }

  type: string;
  title: string;
  detail1: string;
  detail2: string;
  date: string;

  link1: string;
  data1;
  data2;
  num: number;
  mobile = false;
  event_id: string;
  Arr = Array;
  data : any;
  id : any;
  location: any;
  async ngOnInit(){
    if (window.screen.width < 640) { // 768px portrait
      this.mobile = true;

    }
    else {
      this.mobile = false;
    }

    this.route.queryParams.subscribe((params) => {
    this.type = params.type
    this.num = parseInt(params.num)
    this.event_id = params.id
    this.location = params.location
    })

    this.title = localStorage.getItem('titleEn')
    this.detail1 =localStorage.getItem('detailTh')
    this.detail2 = localStorage.getItem('detailEn')
    this.link1 =localStorage.getItem('titleTh')


    this.date = formatDate(new Date(), 'dd MMMM yyyy', 'en')
    this.data1 = this.sanitizer.bypassSecurityTrustHtml(this.detail1);
    this.data2 = this.sanitizer.bypassSecurityTrustHtml(this.detail2);
    this.content1 = await this.contentService.findSetting().toPromise();

  }

  link() {
    window.open(this.link1, "_blank");
  }

  aboutUs() {
    window.location.href = 'about-us';
  }
  products() {
    window.location.href = 'products';
  }
  daicel_group() {
    window.location.href = 'daicel-group';
  }
  csr_page() {
    window.location.href = 'csr';
  }
  news_events() {
    window.location.href = 'news-events';
  }
  contact_us() {
    window.location.href = 'contact-us';
  }


  home() {
    window.location.href = 'home';
  }

  career() {
    window.location.href = 'career';
  }


  async save_post() {
    if(this.event_id != undefined){
        let formData = new FormData();
        formData.append("id", this.event_id);
        formData.append("event_name_th", this.link1 + '<br>' + this.num+ '<br>'+this.location );
        formData.append('event_name_en', this.title)
        formData.append('content_detail_th', this.detail1)
        formData.append('content_detail_en', this.detail2)
        formData.append('active', this.type + '')
        formData.append('post_by', 'admin')
        formData.append('post_date', formatDate(new Date(), 'yyyy-MM-dd', 'en'))
        formData.append('published_date', formatDate(new Date(), 'yyyy-MM-dd', 'en'))

        await this.contentService.updateContent(formData).toPromise()

    }
    else{
      if(this.type == 'undefined') {
        this.type = '4'
      }
      let data = {
        "event_name_th":  this.link1 + '<br>' + this.num+ '<br>'+this.location,
        'event_name_en': this.title,
        'content_detail_th': this.detail1,
        'content_detail_en': this.detail2,
        'event_datetime': formatDate(new Date(), 'yyyy-MM-dd', 'en'),
        'published_date': formatDate(new Date(), 'yyyy-MM-dd', 'en'),
        'post_by':'admin',
        'csr': '0',
        'com_news': '0',
        'events': '0',
        'active': this.type

      }
      await this.contentService.addContent(data).toPromise()
    }

    Swal.fire({
      title: 'Create successfully',
      text: 'Click close button to back to the Post page',
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: "Close",
      confirmButtonColor: "#3085d6",
    }).then(result => {
      window.location.href = 'career-all'
    });




  }


  async save() {
    if(this.event_id != undefined){

      let formData = new FormData();
      formData.append("id", this.event_id);
      formData.append("event_name_th", this.link1 + '<br>' + this.num+ '<br>'+this.location);
      formData.append('event_name_en', this.title)
      formData.append('content_detail_th', this.detail1)
      formData.append('content_detail_en', this.detail2)
      formData.append('active', this.type + '')
      formData.append('post_by', 'admin')
      formData.append('post_date', formatDate(new Date(), 'yyyy-MM-dd', 'en'))
      formData.append('published_date', formatDate(new Date(), '2500-01-01', 'en'))

      await this.contentService.updateContent(formData).toPromise()
    }
    else{
      if(this.type == 'undefined') {
        this.type = '4'
      }
      let data = {
        "event_name_th": this.link1 + '<br>' + this.num+ '<br>'+this.location,
        'event_name_en': this.title,
        'content_detail_th': this.detail1,
        'content_detail_en': this.detail2,
        'event_datetime': formatDate(new Date(), 'yyyy-MM-dd', 'en'),
        'published_date': formatDate(new Date(), '2500-01-01', 'en'),
        'post_by': 'admin',
        'csr': '0',
        'com_news': '0',
        'events': '0',
        'active': this.type

      }
      await this.contentService.addContent(data).toPromise()
    }

    Swal.fire({
      title: 'Create successfully',
      text: 'Click close button to back to the Post page',
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: "Close",
      confirmButtonColor: "#3085d6",
    }).then(result => {
      window.location.href = 'career-all'
    });





  }








}
