<title>
  Daicel Thailand | We Save Lives</title>
<header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
  <div class="header_inner clearfix">
    <div class="header_top_bottom_holder">
      <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
        <div class="container">
          <div class="container_inner clearfix">
            <div class="header_inner_left">
              <div class="mobile_menu_button">
                <span>
                  <i class="fa fa-bars"></i> </span>
              </div>
              <div class="logo_wrapper">
                <div class="q_logo">
                  <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                    <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                      style="height: 100%;"> <img itemprop="image" class="light"
                      src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img
                      itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                      style="height: 100%;"> <img itemprop="image" class="sticky"
                      src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img
                      itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                      style="height: 100%;"> </a>
                </div>
              </div>
              <div class="header_fixed_right_area">
                <div class="textwidget custom-html-widget">
                  <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                      <span class="empty_space_image"></span>
                    </span>
                  </div>

                  <span style="display: inline-block;">
                    <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                      <div class="icon_holder " style="">
                        <img itemprop="image" style="
                                              margin-top: 7px;
                                              margin-left: 5px;
                                          " src="../assets/templates/home/pr.png" alt="">
                      </div>
                      <div class="icon_text_holder" style="">
                        <div class="icon_text_inner" style="">
                          <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                font-weight: 400!important;
                                                font-family: 'Open Sans', sans-serif!important;
                                                "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                          </h6>
                          <p style="color: #949494;line-height: 18px!important;
                                                  font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                  "> 241 Moo 4, 304 Ind Park<br>
                            Prachinburi 25140 Thailand <br> </p>
                        </div>
                      </div>
                    </div>
                  </span>

                  <span style="display: inline-block; margin-right: 15px;">
                    <div class="q_icon_with_title medium custom_icon_image ">
                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                            margin-top: 7px;
                                            margin-left: 5px;
                                        " src="../assets/templates/home/sb.png" alt=""></div>
                      <div class="icon_text_holder" style="">
                        <div class="icon_text_inner" style="">
                          <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                font-weight: 400!important;
                                                font-family: 'Open Sans', sans-serif!important;
                                                ">
                            +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                          </h6>
                          <p style="color: #949494;line-height: 18px!important;
                                                  font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                  "> 76 Moo.1, A.Phra Phutthabat<br>
                            Saraburi 18120 Thailand <br> </p>
                        </div>
                      </div>
                    </div>
                  </span>

                  <span style="display: inline-block;">
                    <div class="q_icon_with_title medium custom_icon_image ">
                      <div class="icon_holder " style=" "><img itemprop="image" style="
                                            margin-top: 7px;
                                            margin-left: 5px;
                                        " src="../assets/templates/home/header-icon-3.png" alt=""></div>
                      <div class="icon_text_holder" style="">
                        <div class="icon_text_inner" style="">
                          <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                                font-weight: 400!important;
                                                font-family: 'Open Sans', sans-serif!important;
                                                "> daicel-dsst.com<br>

                          </h6>
                          <p style="color: #949494;line-height: 18px!important;
                                                  font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                  "> Monday - Friday 08h - 17h<br>Closed on Weekends


                            <br>
                          </p>
                        </div>
                      </div>
                    </div>
                  </span>


                </div>
              </div>
            </div>
            <div class="header_menu_bottom">
              <div class="header_menu_bottom_inner">
                <div class="main_menu_header_inner_right_holder">
                  <nav class="main_menu drop_down">
                    <ul id="menu-main-menu" class="clearfix">
                      <li id="nav-menu-item-1532"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a (click)="aboutUs()" class=""><i class="menu_icon blank fa"></i><span style="
                                                            ">About
                            Us<span class="underline_dash"></span></span><span class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-1530"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a (click)="products()" class=""><i class="menu_icon blank fa"></i><span style="
                                                            ">Products<span class="underline_dash"></span></span><span
                            class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-1526"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a (click)="daicel_group()" class=""><i class="menu_icon blank fa"></i><span style="
                                                            ">Daicel
                            Group<span class="underline_dash"></span></span><span class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-1527"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a (click)="csr_page()" class=""><i class="menu_icon blank fa"></i><span style="
                                                            ">CSR<span class="underline_dash"></span></span><span
                            class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-1529"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a (click)="news_events()" class=""><i class="menu_icon blank fa"></i><span style="
                                                            ">News<span class="underline_dash"></span></span><span
                            class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-1531"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a (click)="career()" class=""><i class="menu_icon blank fa"></i><span style="
                                                                    ">Career
                            <span class="underline_dash"></span></span><span class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-1531"
                        class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                        <a (click)="contact_us()" class=""><i class="menu_icon blank fa"></i><span style="
                                                            ">Contact
                            Us<span class="underline_dash"></span></span><span class="plus"></span></a>
                      </li>
                      <li id="nav-menu-item-wpml-ls-12-en"
                        class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                        <a title="English" class=""><i class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                              src="../assets/templates/home/en.png" alt="en" title="English"><span
                              class="wpml-ls-native" style="
                                                                ">English</span><span
                              class="underline_dash"></span></span><span class="plus"></span><span
                            class="qode-featured-icon " aria-hidden="true"></span></a>
                        <div class="second" style="height: 0px;">
                          <div class="inner">
                            <ul>
                              <li id="nav-menu-item-wpml-ls-12-th"
                                class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                <a title="ไทย" (click)="th()" class=""><i class="menu_icon blank fa"></i><span><img
                                      class="wpml-ls-flag" src="../assets/templates/home/th.png" alt="th"
                                      title="ไทย"><span class="wpml-ls-native">ไทย</span></span><span
                                    class="plus"></span><span class="qode-featured-icon " aria-hidden="true"></span></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                  <div class="header_inner_right">
                    <div class="side_menu_button_wrapper right">
                      <div class="header_bottom_right_widget_holder">
                        <div class="widget_text header_bottom_widget widget_custom_html">
                          <div class="textwidget custom-html-widget">
                            <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">
                              <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">


                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="side_menu_button">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nav class="mobile_menu">
              <ul id="menu-main-menu-1" class="">
                <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a
                    (click)="aboutUs()" class=""><span>About
                      Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                      class="fa fa-angle-down"></i></span></li>
                <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a
                    (click)="products()" class=""><span>Products</span></a><span class="mobile_arrow"><i
                      class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a
                    (click)="daicel_group()" class=""><span>Daicel
                      Group</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                      class="fa fa-angle-down"></i></span></li>
                <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a
                    (click)="csr_page()" class=""><span>CSR</span></a><span class="mobile_arrow"><i
                      class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a
                    (click)="news_events()" class=""><span>News</span></a><span class="mobile_arrow"><i
                      class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a
                    (click)="career()" class=""><span>Career</span></a><span class="mobile_arrow"><i
                      class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>

                <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a
                    (click)="contact_us()" class=""><span>Contact
                      Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                      class="fa fa-angle-down"></i></span></li>
                <li id="mobile-menu-item-wpml-ls-12-en"
                  class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                  <a title="English" class=""><span><img class="wpml-ls-flag" src="../assets/templates/home/en.png"
                        alt="en" title="English"><span class="wpml-ls-native">English</span></span></a><span
                    class="mobile_arrow"><i class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span>
                  <ul class="sub_menu">
                    <li id="mobile-menu-item-wpml-ls-12-th"
                      class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                      <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                            src="../assets/templates/home/th.png" alt="th" title="ไทย"><span
                            class="wpml-ls-native">ไทย</span></span></a><span class="mobile_arrow"><i
                          class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

</header>
<a id="back_to_top" href="#" class="off">
  <span class="fa-stack">
    <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>


<div class="content content_top_margin_none has_slider" style="min-height: 53px; padding-top: 0px;">
  <div class="content_inner">
    <script>
      var page_scroll_amount_for_sticky = 890;
    </script>

    <div class="q_slider">


      <div class="q_slider_inner">


        <div *ngIf="mobile==false">


          <ul class="slideshow">
            <section>
              <div class="bg-video">
                <div class="blur-edges"></div>
                <video class="bg-video__content" autoplay loop [muted]="'muted'" style="    position: relative;
                min-width: 100%;
                margin-left: 50vw;
                transform: translate(-50%);">
                  <source src="../../../../assets/templates/home/bg.mp4" type="video/mp4">
                  <!-- <img src="../../../../assets/templates/home/bg_cover.jpg" alt="Abstract Background"> -->
                </video>
              </div>
            </section>

            <!--
                        <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" id="video-bg"
                        >
                            <source src="../../../../assets/templates/home/bg.mp4" type="video/mp4">
                        </video> -->

            <li style="color: transparent;">

              <span>
                <br><br><br><br><br><br> <br>


              </span>
            </li>

            <li style="color: transparent;">

              <span>
                <br><br><br><br><br><br> <br>


                <div class="text" style="margin-left: 10%;">

                  <h1 style="
                                text-align: left ;
                                white-space: nowrap; white-space: nowrap;
                                font-size: 69px;
                                line-height: 76px;
                                font-weight: 700;
                                color: rgb(255, 255, 255);
                                font-family: Montserrat;

                                "> DSST <br>We Save Lives</h1>
                  <br>

                  <p style="z-index: 7;
                                white-space: nowrap;
                                font-size: 23px!important;;
                                line-height: 29px;
                                font-weight: 800;
                                color: rgb(255, 255, 255);
                                font-family: 'Open Sans';
                                text-align: left;">
                    Aim for developing topmost safety technology for humanity. </p>
                  <br><br>

                  <button (click)="aboutUs()" style="font-size: 12px;float:left"
                    class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                    <label class="qode-btn-text"> About Us</label>
                    <label class="qode-button-v2-icon-holder" style="font-size: 16px"><label aria-hidden="true"
                        class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></label>
                    </label>
                  </button>

                  <br><br><br><br><br><br><br><br>
                </div>


              </span>
            </li>

            <li style="color: transparent;">

              <span>
                <br><br><br><br><br><br> <br>


              </span>
            </li>


            <li style="color: transparent;"> <span>
                <br><br><br><br><br><br><br>
                <div class="text" style="margin-left: 10%;">

                  <h1 style=" text-align: left ;
                                    white-space: nowrap; white-space: nowrap;
                                    font-size: 69px;
                                    line-height: 76px;
                                    font-weight: 700;
                                    color: rgb(255, 255, 255);
                                    font-family: Montserrat;
                                    ">We only produce <br> High-quality products. </h1>
                  <br>

                  <p style="z-index: 7;
                                    white-space: nowrap;
                                    font-size: 23px!important;;
                                    line-height: 29px;
                                    font-weight: 800;
                                    color: rgb(255, 255, 255);
                                    font-family: 'Open Sans';text-align: left;">
                    Our team members always contribute to people’s safety and lives worldwide. </p>
                  <br><br>
                  <button (click)="products()" style="font-size: 12px;float:left"
                    class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                    <label class="qode-btn-text"> Products</label>
                    <label class="qode-button-v2-icon-holder" style="font-size: 16px"><label aria-hidden="true"
                        class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></label>
                    </label>
                  </button>


                  <br><br><br><br><br><br><br><br>
                </div>


              </span> </li>



          </ul>
          <br><br><br><br><br><br><br><br>

        </div>



        <div *ngIf="mobile==true">
          <ul class="slideshow">
            <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" id="video-bg" style="    position: relative;
            min-width: 100%;
            margin-left: 50vw;
            transform: translate(-50%);">
              <source src="../../../../assets/templates/home/bg.mp4" type="video/mp4">
            </video>

            <li style="color: transparent;">
              <span>
                <br><br><br><br><br><br><br>
                <div class="text" style="margin-left: 30px;">

                  <h1 style=" text-align: left ;
                                            white-space: nowrap;
                                            font-size: 25px;
                                            line-height: 30px;
                                            font-weight: 700;
                                            color: rgb(255, 255, 255);
                                            font-family: Montserrat;
                                            ">DSST</h1>

                  <h1 style=" text-align: left ;
                                        white-space: nowrap;
                                        font-size: 25px;
                                        line-height: 30px;
                                        font-weight: 700;
                                        color: rgb(255, 255, 255);
                                        font-family: Montserrat;
                                        ">We Save Lives</h1>

                  <br>
                  <button (click)="products()" style="font-size: 12px;float:left"
                    class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                    <label class="qode-btn-text">SEE LOGISTICS SERVICES</label>

                  </button>


                  <br><br>


                </div>


              </span>
            </li>



            <li style="color: transparent;"> <span>
                <br><br><br><br><br><br><br>

              </span> </li>

            <li style="color: transparent;"> <span>
                <br><br><br><br><br><br><br>
                <div class="text" style="margin-left: 30px;">

                  <h1 style=" text-align: left ;
                                        white-space: nowrap;
                                        font-size: 25px;
                                        line-height: 30px;
                                        font-weight: 700;
                                        color: rgb(255, 255, 255);
                                        font-family: Montserrat;
                                        ">We only produce </h1>
                  <h1 style=" text-align: left ;
                                           white-space: nowrap;
                                           font-size: 25px;
                                           line-height: 20px;
                                           font-weight: 700;
                                           color: rgb(255, 255, 255);
                                           font-family: Montserrat;
                                           "> High-quality products. </h1>

                  <br>
                  <button (click)="products()" style="font-size: 12px;float:left"
                    class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                    <label class="qode-btn-text"> SEE LOGISTICS SERVICES</label>

                  </button>
                  <br><br>


                </div>

              </span> </li>
            <li style="color: transparent;"> <span>
                <br><br><br><br><br><br><br>

              </span> </li>



          </ul>

          <br><br><br><br>

        </div>








      </div>
    </div>


    <div class="full_width">
      <div class="full_width_inner">


        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
          <div class=" section_inner clearfix">
            <div class="section_inner_margin clearfix">
              <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4 vc_col-has-fill">
                <div class="vc_column-inner vc_custom_1491984129094"
                  style="padding-top: 45px !important;margin-top: -100px !important;">
                  <div class="wpb_wrapper">
                    <div class="q_elements_holder one_column responsive_mode_from_768">
                      <div class="q_elements_item " data-animation="no" style="vertical-align:top;">
                        <div class="q_elements_item_inner">
                          <div class="q_elements_item_content q_elements_holder_custom_605498" style="padding:0 0 0 0">
                            <style type="text/css" data-type="q_elements_custom_padding" scoped="">
                              @media only screen and (min-width: 600px) and (max-width: 768px) {
                                .q_elements_item_content.q_elements_holder_custom_605498 {
                                  padding: 0 0 96px 0 !important;
                                }
                              }

                              @media only screen and (min-width: 480px) and (max-width: 600px) {
                                .q_elements_item_content.q_elements_holder_custom_605498 {
                                  padding: 0 0 96px 0 !important;
                                }
                              }

                              @media only screen and (max-width: 480px) {
                                .q_elements_item_content.q_elements_holder_custom_605498 {
                                  padding: 0 0 96px 0 !important;
                                }
                              }
                            </style>
                            <div class="wpb_single_image wpb_content_element vc_align_left">
                              <div class="wpb_wrapper">

                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                  <img style="
                                                                        width:300px;
                                                                          height:200px;
                                                                          object-fit:cover;
                                                                          " [src]="com_img" alt="daicelthailand"
                                    sizes="(max-width: 1200px) 100vw, 1200px">
                                </div>
                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 24px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <h6 style="font-family: 'Open Sans', sans-serif!important;
                                                                    font-size: 15px!important;
                                                                    font-weight: 400!important;
                                                                    ">Company News</h6>

                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 3px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <h4 style="overflow: hidden;
                                                                    text-overflow: ellipsis;
                                                                    display: -webkit-box;
                                                                    line-height: 25px;
                                                                    max-height: 150px;
                                                                    -webkit-line-clamp: 2;
                                                                    -webkit-box-orient: vertical;
                                                                    height: 50px;font-weight: bold!important;
    font-size: 20px!important;">{{com_news}}</h4>

                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 14px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <p [innerHTML]="com_news_detail" style=" line-height:1.5em;
                                                              height:4.5em;
                                                              overflow:hidden;"></p>


                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 25px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>



                            <a (click)=" news_events()" target="_self" style="font-size: 12px"
                              class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                              <span class="qode-btn-text" style="color: white;">Find Out
                                More</span><span class="qode-button-v2-icon-holder"
                                style="font-size: 16px;color: white;"><span aria-hidden="true"
                                  class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                            </a>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4 vc_col-has-fill">
                <div class="vc_column-inner vc_custom_1491984133924"
                  style="padding-top: 45px !important;margin-top: -100px !important;">
                  <div class="wpb_wrapper">
                    <div class="q_elements_holder one_column responsive_mode_from_768">
                      <div class="q_elements_item " data-animation="no" style="vertical-align:top;">
                        <div class="q_elements_item_inner">
                          <div class="q_elements_item_content q_elements_holder_custom_201166" style="padding:0 0 0 0">
                            <style type="text/css" data-type="q_elements_custom_padding" scoped="">
                              @media only screen and (min-width: 600px) and (max-width: 768px) {
                                .q_elements_item_content.q_elements_holder_custom_201166 {
                                  padding: 0 0 96px 0 !important;
                                }
                              }

                              @media only screen and (min-width: 480px) and (max-width: 600px) {
                                .q_elements_item_content.q_elements_holder_custom_201166 {
                                  padding: 0 0 96px 0 !important;
                                }
                              }

                              @media only screen and (max-width: 480px) {
                                .q_elements_item_content.q_elements_holder_custom_201166 {
                                  padding: 0 0 96px 0 !important;
                                }
                              }
                            </style>
                            <div class="wpb_single_image wpb_content_element vc_align_left">
                              <div class="wpb_wrapper">

                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                  <img style="
                                                                        width:300px;
                                                                          height:200px;
                                                                          object-fit:cover;
                                                                          " [src]="csr_img" alt="daicelthailand"
                                    sizes="(max-width: 1200px) 100vw, 1200px">
                                </div>
                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 24px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <h6 style="font-family: 'Open Sans', sans-serif!important;
                                                                    font-size: 15px!important;
                                                                    font-weight: 400!important;
                                                                    ">Company CSR</h6>

                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 3px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <h4 style="overflow: hidden;
                                                                    text-overflow: ellipsis;
                                                                    display: -webkit-box;
                                                                    line-height: 25px;
                                                                    max-height: 150px;
                                                                    -webkit-line-clamp: 2; /* number of lines to show */
                                                                    -webkit-box-orient: vertical;
                                                                    height: 50px;

    font-weight: bold!important;
    font-size: 20px!important;
                                                                    ">{{csr}}</h4>

                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 14px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <p [innerHTML]="csr_detail" style=" line-height:1.5em;
                                                              height:4.5em;
                                                              overflow:hidden;"></p>


                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 25px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>

                            <a (click)="csr_page()" target="_self" style="font-size: 12px"
                              class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                              <span class="qode-btn-text" style="color: white;">Find Out
                                More</span><span class="qode-button-v2-icon-holder"
                                style="font-size: 16px;color: white;"><span aria-hidden="true"
                                  class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                            </a>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4 vc_col-has-fill">
                <div class="vc_column-inner vc_custom_1492515599080"
                  style="padding-top: 45px !important;margin-top: -100px !important;">
                  <div class="wpb_wrapper">
                    <div class="q_elements_holder one_column responsive_mode_from_768">
                      <div class="q_elements_item " data-animation="no" style="vertical-align:top;">
                        <div class="q_elements_item_inner">
                          <div class="q_elements_item_content q_elements_holder_custom_341490" style="padding:0 0 0 0">
                            <div class="wpb_single_image wpb_content_element vc_align_left">
                              <div class="wpb_wrapper">

                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                  <img style="
                                                                          width:300px;
                                                                            height:200px;
                                                                            object-fit:cover;
                                                                            " [src]="events_img" alt="daicelthailand"
                                    sizes="(max-width: 1200px) 100vw, 1200px">
                                </div>
                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 24px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <h6 style="font-family: 'Open Sans', sans-serif!important;
                                                                    font-size: 15px!important;
                                                                    font-weight: 400!important;">Company Activities
                                </h6>

                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 3px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <h4 style="overflow: hidden;
                                                                    text-overflow: ellipsis;
                                                                    display: -webkit-box;
                                                                    line-height: 25px;
                                                                    max-height: 150px;
                                                                    -webkit-line-clamp: 2; /* number of lines to show */
                                                                    -webkit-box-orient: vertical;
                                                                    height: 50px;font-weight: bold!important;
    font-size: 20px!important;

                                                            ">{{ events}}</h4>

                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 14px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>


                            <div class="wpb_text_column wpb_content_element ">
                              <div class="wpb_wrapper">
                                <p [innerHTML]="events_detail" style=" line-height:1.5em;
                                                              height:4.5em;
                                                              overflow:hidden;"></p>
                              </div>
                            </div>
                            <div class="vc_empty_space" style="height: 25px">
                              <span class="vc_empty_space_inner">
                                <span class="empty_space_image"></span>
                              </span>
                            </div>



                            <a (click)="news_events()" target="_self" style="font-size: 12px"
                              class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                              <span class="qode-btn-text" style="color: white;">Find Out
                                More</span><span class="qode-button-v2-icon-holder"
                                style="font-size: 16px;color: white;"><span aria-hidden="true"
                                  class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                            </a>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
          <div class=" section_inner clearfix">
            <div class="section_inner_margin clearfix">
              <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                <div class="vc_column-inner vc_custom_1473080809720">
                  <div class="wpb_wrapper"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518002993734" style=" text-align:left;">
          <div class=" full_section_inner clearfix">
            <div class="wpb_column vc_column_container vc_col-sm-3">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                      <span class="empty_space_image"></span>
                    </span>
                  </div>

                </div>
              </div>
            </div>
            <div class="wpb_column vc_column_container vc_col-sm-6" style="padding: 15px;">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                      <span class="empty_space_image"></span>
                    </span>
                  </div>

                  <div class="q_icon_with_title very_large normal_icon left_from_title ">
                    <div class="icon_text_holder" style="">
                      <div class="icon_text_inner" style="">
                        <div class="icon_title_holder">
                          <div class="icon_holder q_icon_animation" style=" ">
                            <span data-icon-type="normal" style=""
                              class="qode_iwt_icon_holder q_font_awsome_icon fa-5x  "><i
                                class="qode_icon_font_awesome fa fa-exclamation-triangle qode_iwt_icon_element"
                                style="color: #dd3333;"></i></span>
                          </div>
                          <h2 class="icon_title" style="color: #dd3333;font-weight: 900;">
                            WARNING!
                          </h2>
                        </div>
                        <p style=""></p>
                      </div>
                    </div>
                  </div>
                  <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                      <span class="empty_space_image"></span>
                    </span>
                  </div>


                  <div class="wpb_text_column wpb_content_element ">
                    <div class="wpb_wrapper">
                      <div style="text-align: center;">
                        <p style="text-align: left;

                                                    font-family: 'Open Sans', sans-serif!important;
                                                    font-size: 15px!important;
                                                    font-weight: bold!important;
                                                     "><strong><span style="color: #ff0000;">The
                              Company’s products are
                              directly sold to the airbag module manufacturers
                              only and are not publicly sold to any other
                              persons.&nbsp; Whether it is the case of using old
                              products or buying products that are commonly sold
                              in the market, such products do not meet the safety
                              standards and/or being fake products and may cause
                              damage and loss to life, body, and property. The
                              Company cannot guarantee the quality of those
                              products in all respects and therefore shall not be
                              liable for such damages in all
                              respects.</span></strong></p>
                      </div>
                    </div>
                  </div>
                  <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                      <span class="empty_space_image"></span>
                    </span>
                  </div>

                </div>
              </div>
            </div>
            <div class="wpb_column vc_column_container vc_col-sm-3">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                      <span class="empty_space_image"></span>
                    </span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518002888838 grid_section"
          style=" text-align:left;">
          <div class=" section_inner clearfix">
            <div class="section_inner_margin clearfix">
              <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper">
                    <div class="qode_content_slider  has_control_nav drag_enabled" data-interval="5"
                      data-direction="false" data-control="true" data-pause-on-hover="false" data-drag="true">
                      <div class="flex-viewport" style="overflow: hidden; position: relative;">
                        <div class="qode_content_slider_inner" style="width: 1200%; margin-left: -4400px;">

                          <div class="qode_content_slider_item" data-thumb-alt=""
                            style="width: 1100px; margin-right: 0px; float: left; display: block;">
                            <div class="vc_row wpb_row section vc_row-fluid vc_inner " style=" text-align:left;">
                              <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <h6>DSST Product
                                          </h6>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 7px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <h2>INFLATORS FOR THE DRIVER AIRBAG
                                          </h2>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 21px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <p>This gas-generating system protects the driver in a head-on collision.
                                            <br> It employs the pyrotechnic method.
                                          </p>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 30px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>



                                      <a (click)="products()" target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color:white">Products</span><span
                                          class="qode-button-v2-icon-holder" style="font-size: 16px; color:white"><span
                                            aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                      <div class="vc_empty_space" style="height: 40px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-8">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                      <div class="vc_empty_space" style="height: 7px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_single_image wpb_content_element vc_align_left">
                                        <div class="wpb_wrapper">

                                          <div class="vc_single_image-wrapper   vc_box_border_grey">
                                            <img width="856" height="373"
                                              src="../../../../assets/templates/home/1-856x373.jpg"
                                              class="vc_single_image-img attachment-full" alt=""
                                              srcset="../../../../assets/templates/home/1-856x373.jpg 856w,
                                                                                                ../../../../assets/templates/home/1-856x373-300x131.jpg 300w,
                                                                                                ../../../../assets/templates/home/1-856x373-768x335.jpg 768w"
                                              sizes="(max-width: 856px) 100vw, 856px" draggable="false">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 40px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="qode_content_slider_item" data-thumb-alt=""
                            style="width: 1100px; margin-right: 0px; float: left; display: block;">
                            <div class="vc_row wpb_row section vc_row-fluid vc_inner " style=" text-align:left;">
                              <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <h6>Daicel Group Products
                                          </h6>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 7px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <h2>INFLATORS FOR THE SIDE CURTAIN AIRBAG
                                          </h2>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 21px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <p>This gas-generating system protects the occupant’s head in a side
                                            collision. It employs the pyrotechnic, compressed gas, and hybrid methods.
                                          </p>
                                          <p><strong><span style="color: #00ccff;font-size: 14px!important;">INFLATORS
                                                FOR THE SIDE
                                                AIRBAG</span></strong><br> This gas-generating system protects the
                                            occupant’s chest and abdomen in a side collision. It employs the pyrotechnic
                                            and hybrid
                                            methods.
                                          </p>
                                          <p><strong><span style="color: #00ccff;font-size: 14px!important;">INFLATORS
                                                FOR THE KNEE
                                                AIRBAG</span></strong><br> This gas-generating system protects the
                                            occupant’s legs in a head-on collision. It employs the pyrotechnic and
                                            hybrid methods.
                                          </p>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 30px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>



                                      <a (click)=" products()" target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color:white">Products</span><span
                                          class="qode-button-v2-icon-holder" style="font-size: 16px; color:white"><span
                                            aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                      <div class="vc_empty_space" style="height: 40px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-8">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                      <div class="vc_empty_space" style="height: 7px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_single_image wpb_content_element vc_align_left">
                                        <div class="wpb_wrapper">

                                          <div class="vc_single_image-wrapper   vc_box_border_grey">
                                            <img width="856" height="373"
                                              src="../../../../assets/templates/home/3-856x373.jpg"
                                              class="vc_single_image-img attachment-full" alt=""
                                              srcset="../../../../assets/templates/home/3-856x373.jpg 856w,
                                                                                                ../../../../assets/templates/home/3-856x373-300x131.jpg 300w,
                                                                                                ../../../../assets/templates/home/3-856x373-768x335.jpg 768w"
                                              sizes="(max-width: 856px) 100vw, 856px" draggable="false">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 40px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="qode_content_slider_item" data-thumb-alt=""
                            style="width: 1100px; margin-right: 0px; float: left; display: block;">
                            <div class="vc_row wpb_row section vc_row-fluid vc_inner " style=" text-align:left;">
                              <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <h6>DSST Product
                                          </h6>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 7px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <h2>INFLATORS FOR THE PASSENGER AIRBAG
                                          </h2>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 21px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <p>This gas-generating system protects the front passenger in a head-on
                                            collision. It employs the pyrotechnic and hybrid methods.
                                          </p>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 30px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>



                                      <a (click)=" products()" target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color:white">Products</span><span
                                          class="qode-button-v2-icon-holder" style="font-size: 16px; color:white"><span
                                            aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                      <div class="vc_empty_space" style="height: 40px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-8">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                      <div class="vc_empty_space" style="height: 7px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_single_image wpb_content_element vc_align_left">
                                        <div class="wpb_wrapper">

                                          <div class="vc_single_image-wrapper   vc_box_border_grey">
                                            <img width="856" height="373"
                                              src="../../../../assets/templates/home/2-856x373.jpg"
                                              class="vc_single_image-img attachment-full" alt=""
                                              srcset="../../../../assets/templates/home/2-856x373.jpg 856w,
                                                                                            ../../../../assets/templates/home/2-856x373-300x131.jpg 300w,
                                                                                            ../../../../assets/templates/home/2-856x373-768x335.jpg 768w"
                                              sizes="(max-width: 856px) 100vw, 856px" draggable="false">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 40px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="qode_content_slider_item flex-active-slide" data-thumb-alt=""
                            style="width: 1100px; margin-right: 0px; float: left; display: block;">
                            <div class="vc_row wpb_row section vc_row-fluid vc_inner " style=" text-align:left;">
                              <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-4">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <h6>DSST Product
                                          </h6>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 7px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <h2>GAS GENERATORS FOR SEAT BELT PRETENSIONERS (PGG)
                                          </h2>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 21px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                          <p>This gas generator protects the occupant by retracting the seat belt during
                                            a collision. It employs the pyrotechnic method.
                                          </p>

                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 30px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>



                                      <a (click)="products()" target="_self" style="font-size: 12px"
                                        class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                        <span class="qode-btn-text" style="color:white">Products</span><span
                                          class="qode-button-v2-icon-holder" style="font-size: 16px; color:white"><span
                                            aria-hidden="true"
                                            class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                      </a>

                                      <div class="vc_empty_space" style="height: 40px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-8">
                                  <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                      <div class="vc_empty_space" style="height: 7px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>


                                      <div class="wpb_single_image wpb_content_element vc_align_left">
                                        <div class="wpb_wrapper">

                                          <div class="vc_single_image-wrapper   vc_box_border_grey">
                                            <img width="856" height="373"
                                              src="../../../../assets/templates/home/4-856x373.jpg"
                                              class="vc_single_image-img attachment-full" alt=""
                                              srcset="../../../../assets/templates/home/4-856x373.jpg 856w,
                                                                                            ../../../../assets/templates/home/4-856x373-300x131.jpg 300w,
                                                                                            ../../../../assets/templates/home/4-856x373-768x335.jpg 768w"
                                              sizes="(max-width: 856px) 100vw, 856px" draggable="false">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="vc_empty_space" style="height: 40px"><span
                                          class="vc_empty_space_inner">
                                          <span class="empty_space_image"></span>
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vc_row wpb_row section vc_row-fluid " style=' text-align:left;'>
          <div class=" full_section_inner clearfix">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="qode-elliptical-slider">
                    <div class="qode-elliptical-slider-slides" data-autoplay=no>
                      <div class="qode-elliptical-slide"
                        style="background-image:url(../../../../assets/templates/home/Activities-1920x1080.jpg)">
                        <div class="qode-elliptical-slide-image-holder-wrapper" style="background-color:#2c3137">
                          <span class="qode-elliptical-slide-image-holder">
                            <img src="../../../../assets/templates/home/Activities-1920x1080.jpg"
                              alt="qode-eliptic-slider" />
                          </span>
                        </div>
                        <div class="qode-elliptical-slide-content-holder"
                          style="background: -webkit-linear-gradient(left, #2c3137 50%, transparent 50%);background: linear-gradient(90deg, #2c3137 50%, transparent 50%);">
                          <div class="qode-elliptical-slide-content-holder-inner grid_section">
                            <div class="qode-elliptical-slide-content-wrapper section_inner">
                              <div class="qode-elliptical-slide-wrapper-inner">
                                <div class="qode-elliptical-slide-svg-holder">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="92.296px"
                                    height="485px" viewBox="0 0 92.296 492" enable-background="new 0 0 92.296 492"
                                    preserveAspectRatio="none">
                                    <path style="fill:#2c3137"
                                      d="M91.621,0H0v492h92.296C47.988,426.806,21,340.351,21,245.5C21,151.133,47.716,65.078,91.621,0z" />
                                  </svg>
                                </div>
                                <div class="qode-elliptical-slide-elements-holder">


                                  <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">



                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 7px">
                                    <span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>


                                  <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                      <h2><span style="color: #ffffff;">Company
                                          Activities</span></h2>

                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 24px">
                                    <span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>


                                  <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                      <p><span style="color: #ffffff;">Daicel
                                          Corporation intends to
                                          conduct or participate
                                          in activities that aim
                                          at many areas of
                                          development in order to
                                          become an industrial
                                          leader of safety
                                          technology for
                                          humanity.</span></p>

                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 33px">
                                    <span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>



                                  <a (click)="news_events()" target="_self" style="font-size: 13px"
                                    class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                    <span class="qode-btn-text" style="color: white;">Find Out
                                      More</span><span class="qode-button-v2-icon-holder"
                                      style="font-size: 21px;color: white;"><span aria-hidden="true"
                                        class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                  </a>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="qode-elliptical-slide"
                        style="background-image:url(../../../../assets/templates/home/CSR.jpg)">
                        <div class="qode-elliptical-slide-image-holder-wrapper" style="background-color:#2c3137">
                          <span class="qode-elliptical-slide-image-holder">
                            <img src="../../../../assets/templates/home/CSR.jpg" alt="qode-eliptic-slider" />
                          </span>
                        </div>
                        <div class="qode-elliptical-slide-content-holder"
                          style="background: -webkit-linear-gradient(left, #2c3137 50%, transparent 50%);background: linear-gradient(90deg, #2c3137 50%, transparent 50%);">
                          <div class="qode-elliptical-slide-content-holder-inner grid_section">
                            <div class="qode-elliptical-slide-content-wrapper section_inner">
                              <div class="qode-elliptical-slide-wrapper-inner">
                                <div class="qode-elliptical-slide-svg-holder">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="92.296px"
                                    height="485px" viewBox="0 0 92.296 492" enable-background="new 0 0 92.296 492"
                                    preserveAspectRatio="none">
                                    <path style="fill:#2c3137"
                                      d="M91.621,0H0v492h92.296C47.988,426.806,21,340.351,21,245.5C21,151.133,47.716,65.078,91.621,0z" />
                                  </svg>
                                </div>
                                <div class="qode-elliptical-slide-elements-holder">


                                  <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                      <h6><span style="color: #ffffff;"></span>
                                      </h6>

                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 7px">
                                    <span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>


                                  <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                      <h2><span style="color: #ffffff;">Company
                                          CSR</span></h2>

                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 24px">
                                    <span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>


                                  <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                      <p><span style="color: #ffffff;">Daicel
                                          Corporation gains
                                          acceptance, builds
                                          trust, and acknowledges
                                          people in society an
                                          understanding of our
                                          business operations. To
                                          enhance quality of
                                          society in the future,
                                          we have been engaging in
                                          environmental
                                          development whether
                                          it&#8217;s involved in
                                          natural resources,
                                          energy, or quality of
                                          people&#8217;s lives in
                                          society.</span></p>

                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 33px">
                                    <span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>



                                  <a (click)="csr_page()" target="_self" style="font-size: 13px"
                                    class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                    <span class="qode-btn-text" style="color: white;">Find Out
                                      More</span><span class="qode-button-v2-icon-holder"
                                      style="font-size: 21px;color: white;"><span aria-hidden="true"
                                        class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                  </a>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="qode-elliptical-slide"
                        style="background-image:url(../../../../assets/templates/home/E-Book-B-1920x1080.jpg)">
                        <div class="qode-elliptical-slide-image-holder-wrapper" style="background-color:#2c3137">
                          <span class="qode-elliptical-slide-image-holder">
                            <img src="../../../../assets/templates/home/E-Book-B-1920x1080.jpg"
                              alt="qode-eliptic-slider" />
                          </span>
                        </div>
                        <div class="qode-elliptical-slide-content-holder"
                          style="background: -webkit-linear-gradient(left, #2c3137 50%, transparent 50%);background: linear-gradient(90deg, #2c3137 50%, transparent 50%);">
                          <div class="qode-elliptical-slide-content-holder-inner grid_section">
                            <div class="qode-elliptical-slide-content-wrapper section_inner">
                              <div class="qode-elliptical-slide-wrapper-inner">
                                <div class="qode-elliptical-slide-svg-holder">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="92.296px"
                                    height="485px" viewBox="0 0 92.296 492" enable-background="new 0 0 92.296 492"
                                    preserveAspectRatio="none">
                                    <path style="fill:#2c3137"
                                      d="M91.621,0H0v492h92.296C47.988,426.806,21,340.351,21,245.5C21,151.133,47.716,65.078,91.621,0z" />
                                  </svg>
                                </div>
                                <div class="qode-elliptical-slide-elements-holder">


                                  <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                      <!-- <h6><span style="color: #ffffff;">Our
                                                                                        Latest Activities</span>
                                                                                </h6> -->

                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 7px">
                                    <span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>


                                  <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                      <h2><span style="color: #ffffff;">Company
                                          E-Book</span></h2>

                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 24px">
                                    <span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>


                                  <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                      <p><span style="color: #ffffff;">Strengthening
                                          Relationships in
                                          Daicel&#8217;s
                                          Family.<br />
                                          E-book produced by
                                          Daicel Corporation has
                                          an objective to build
                                          strong relationships and
                                          connections within the
                                          company. We believe that
                                          successful operations
                                          are the result of
                                          unity.</span></p>

                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 33px">
                                    <span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>



                                  <a (click)="news_events()" target="_self" style="font-size: 13px"
                                    class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                                    <span class="qode-btn-text" style="color: white;">Find Out
                                      More</span><span class="qode-button-v2-icon-holder"
                                      style="font-size: 21px;color: white;"><span aria-hidden="true"
                                        class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                                  </a>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518013530449 grid_section"
          style=" text-align:center;">
          <div class=" section_inner clearfix">
            <div class="section_inner_margin clearfix">
              <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper">
                    <div class="wpb_text_column wpb_content_element ">
                      <div class="wpb_wrapper">
                        <h6>Daicel Group Products</h6>

                      </div>
                    </div>
                    <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                        <span class="empty_space_image"></span>
                      </span>
                    </div>


                    <div class="wpb_text_column wpb_content_element ">
                      <div class="wpb_wrapper">
                        <h2>New Applications</h2>

                      </div>
                    </div>
                    <div class="vc_empty_space" style="height: 68px"><span class="vc_empty_space_inner">
                        <span class="empty_space_image"></span>
                      </span>
                    </div>

                    <div class="vc_row wpb_row section vc_row-fluid vc_inner  grid_section" style=" text-align:center;">
                      <div class=" section_inner clearfix">
                        <div class="section_inner_margin clearfix">
                          <div class="wpb_column vc_column_container vc_col-sm-4">
                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div class="wpb_single_image wpb_content_element vc_align_center">
                                  <div class="wpb_wrapper">

                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                      <img width="150" height="150"
                                        src="../../../../assets/templates/home/Power-Disconnect-Device-1200x1200-150x150.png"
                                        class="vc_single_image-img attachment-thumbnail" alt=""
                                        srcset="
                                                                                    ../../../../assets/templates/home/Power-Disconnect-Device-1200x1200-150x150.png 150w,
                                                                                    ../../../../assets/templates/home/Power-Disconnect-Device-1200x1200-300x300.png 300w,
                                                                                    ../../../../assets/templates/home/Power-Disconnect-Device-1200x1200-768x768.png 768w,
                                                                                    ../../../../assets/templates/home/Power-Disconnect-Device-1200x1200-1024x1024.png 1024w,
                                                                                    ../../../../assets/templates/home/Power-Disconnect-Device-1200x1200.png 1200w,
                                                                                    ../../../../assets/templates/home/Power-Disconnect-Device-1200x1200-100x100.png 100w"
                                        sizes="(max-width: 150px) 100vw, 150px">
                                    </div>
                                  </div>
                                </div>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h6>POWER DISCONNECT DEVICE</h6>

                                  </div>
                                </div>
                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <p>This device provides an instant disconnection of an electrical harness for
                                      facilities or equipment that require an emergency electrical disconnection. It is
                                      used primarily with hybrid
                                      and electric vehicles to prevent leakage from a battery during a collision.
                                    </p>

                                  </div>
                                </div>
                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-4">
                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div class="wpb_single_image wpb_content_element vc_align_center">
                                  <div class="wpb_wrapper">

                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                      <img width="150" height="150"
                                        src="../../../../assets/templates/home/Pin-Thruster-1200x1200-150x150.png"
                                        class="vc_single_image-img attachment-thumbnail" alt=""
                                        srcset="../../../../assets/templates/home/Pin-Thruster-1200x1200-150x150.png 150w,
                                                                                     ../../../../assets/templates/home/Pin-Thruster-1200x1200-300x300.png 300w,
                                                                                     ../../../../assets/templates/home/Pin-Thruster-1200x1200-768x768.png 768w,
                                                                                     ../../../../assets/templates/home/Pin-Thruster-1200x1200-1024x1024.png 1024w,
                                                                                      ../../../../assets/templates/home/Pin-Thruster-1200x1200.png 1200w,
                                                                                      ../../../../assets/templates/home/Pin-Thruster-1200x1200-100x100.png 100w"
                                        sizes="(max-width: 150px) 100vw, 150px">
                                    </div>
                                  </div>
                                </div>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h6>PIN THRUSTER</h6>

                                  </div>
                                </div>
                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <p>This device instantly pushes out a pin. It is expected to be used as a hood
                                      lifter to protect people in the event of a car collision where it is necessary to
                                      push out a pin in an
                                      emergency situation.</p>

                                  </div>
                                </div>
                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-4">
                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div class="wpb_single_image wpb_content_element vc_align_center">
                                  <div class="wpb_wrapper">

                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                      <img width="150" height="150"
                                        src="../../../../assets/templates/home/Pin-Puller-1200x1200-150x150.png"
                                        class="vc_single_image-img attachment-thumbnail" alt=""
                                        srcset="../../../../assets/templates/home/Pin-Puller-1200x1200-150x150.png 150w,
                                                                                    ../../../../assets/templates/home/Pin-Puller-1200x1200-300x300.png 300w,
                                                                                    ../../../../assets/templates/home/Pin-Puller-1200x1200-768x768.png 768w,
                                                                                    ../../../../assets/templates/home/Pin-Puller-1200x1200-1024x1024.png 1024w,
                                                                                    ../../../../assets/templates/home/Pin-Puller-1200x1200.png 1200w,
                                                                                    ../../../../assets/templates/home/Pin-Puller-1200x1200-100x100.png 100w"
                                        sizes="(max-width: 150px) 100vw, 150px">
                                    </div>
                                  </div>
                                </div>

                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h6>PIN PULLER</h6>

                                  </div>
                                </div>
                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <p>This device instantly pulls a pin. It is used on devices that require&nbsp;a pin
                                      to be pulled in an emergency situation.</p>

                                  </div>
                                </div>
                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vc_empty_space" style="height: 68px"><span class="vc_empty_space_inner">
                        <span class="empty_space_image"></span>
                      </span>
                    </div>

                    <div class="testimonials_holder clearfix dark">
                      <div class="testimonials testimonials_carousel" data-show-navigation="yes"
                        data-animation-type="fade" data-animation-speed="" data-auto-rotate-slides="3"
                        data-number-per-slide="1">
                        <ul class="slides">
                          <li id="testimonials54" class="testimonial_content"
                            style="width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;">
                            <div class="testimonial_content_inner">
                              <div class="testimonial_text_holder">
                                <div class="testimonial_text_inner" style="">
                                  <p>Increasing safety and security worldwide through innovative technology.
                                  </p>
                                  <p class="testimonial_author" style="">- Daicel -
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li id="testimonials51" class="testimonial_content flex-active-slide"
                            style="width: 100%; float: left; margin-right: -100%; position: relative; opacity: 1; display: block; z-index: 2;">
                            <div class="testimonial_content_inner">
                              <div class="testimonial_text_holder">
                                <div class="testimonial_text_inner" style="">
                                  <p>“To Become #1 Highest Priority Safety &amp; Quality Products”</p>
                                  <p class="testimonial_author" style="">- Daicel -
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li id="testimonials52" class="testimonial_content"
                            style="width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;">
                            <div class="testimonial_content_inner">
                              <div class="testimonial_text_holder">
                                <div class="testimonial_text_inner" style="">
                                  <p>เราคือผู้ผลิตระบบ Airbags ที่มีความปลอดภัยและคุณภาพที่ดีที่สุด
                                  </p>
                                  <p class="testimonial_author" style="">- Daicel -
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li id="testimonials42" class="testimonial_content"
                            style="width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;">
                            <div class="testimonial_content_inner">
                              <div class="testimonial_text_holder">
                                <div class="testimonial_text_inner" style="">
                                  <p>To Meet All Performance And Quality Requirements For Airbags.</p>
                                  <p class="testimonial_author" style="">- Daicel -
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li id="testimonials47" class="testimonial_content"
                            style="width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;">
                            <div class="testimonial_content_inner">
                              <div class="testimonial_text_holder">
                                <div class="testimonial_text_inner" style="">
                                  <p>เรามุ่งเน้นสร้างผลิตภัณฑ์ที่รักษาความปลอดภัยให้กับชีวิต
                                    โดยไม่ก่อให้เกิดมลภาวะและเป็นมิตรกับสิ่งแวดล้อม
                                  </p>
                                  <p class="testimonial_author" style="">- Daicel -
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
          <div class=" section_inner clearfix">
            <div class="section_inner_margin clearfix">
              <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner vc_custom_1481530374674">
                  <div class="wpb_wrapper">
                    <div class="qode-blog-carousel-titled" data-posts-shown="4" style="opacity: 1;">
                      <div class="qode-bct-title-holder" style="background-color:#0071be;color:#ffffff">
                        <a class="qode-bct-caroufredsel-prev" style="display: block;">
                          <span class="qode-bct-caroufredsel-nav-inner">
                            <span class="qode-bct-caroufredsel-nav-icon-holder">
                              <span class="arrow_carrot-left"></span>
                            </span>
                          </span>
                        </a>
                        <h4 style="font-weight: 700!important;">
                          Latest Company News </h4>
                        <a class="qode-bct-caroufredsel-next" style="display: block;">
                          <span class="qode-bct-caroufredsel-nav-inner">
                            <span class="qode-bct-caroufredsel-nav-icon-holder">
                              <span class="arrow_carrot-right"></span>
                            </span>
                          </span>
                        </a>
                      </div>
                      <div class="qode-bct-posts-holder">
                        <div class="caroufredsel_wrapper" style="    position: relative;
                                                inset: auto;
                                                overflow: hidden;
                                                height: 419px;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;">


                          <div class="qode-bct-posts" *ngIf="mobile==false"
                            style="text-align: left; float: none; position: absolute; top: 0px; right: auto; bottom: auto; left: 0px; margin: 0px; width: 12236px;">

                            <div *ngFor="let item of lastest ; let i = index" class="qode-bct-post">
                              <div class="qode-bct-post-image">
                                <img style="width: 300px; height: 200px; object-fit: cover;" [src]="img[i]"
                                  class="attachment-full size-full wp-post-image" alt="">

                              </div>




                              <div class="qode-bct-post-text">
                                <h4 class="qode-bct-post-title entry_title" style="overflow: hidden;
                                                                    text-overflow: ellipsis;
                                                                    display: -webkit-box;
                                                                    line-height: 25px;
                                                                    max-height: 100px;
                                                                    -webkit-line-clamp: 2; /* number of lines to show */
                                                                    -webkit-box-orient: vertical;
                                                                    height: 60px;

                                                                    " itemprop="name">
                                  <a (click)="preview(item.event_id)">{{item.event_name_en}}
                                  </a>
                                </h4>
                                <p itemprop="description" class="qode-bct-post-excerpt" style="

                                                                            display: block;
                                                                            display: -webkit-box;
                                                                            max-width: 100%;
                                                                            height: 100%;
                                                                            margin: 0 auto;
                                                                            font-size: 14px;
                                                                            line-height: 1.5em;
                                                                            -webkit-line-clamp: 3;
                                                                            -webkit-box-orient: vertical;
                                                                            overflow: hidden;
                                                                            text-overflow: ellipsis;"
                                  [innerHTML]="item.content_detail_en">
                                </p>
                                <div class="qode-bct-post-date entry_date updated"
                                  style="margin-bottom: 20px; font-size: 14px;" itemprop="dateCreated">
                                  {{item.published_date |date:'longDate' }}
                                  <meta itemprop="interactionCount" content="UserComments: 0">
                                </div>
                              </div>
                            </div>




                          </div>

                          <div class="qode-bct-posts" *ngIf="mobile==true">

                            <div *ngFor="let item of lastest ; let i = index" class="qode-bct-post">
                              <div class="qode-bct-post-image">


                                <img width="230px" height="230px" [src]="img[i]"
                                  class="attachment-full size-full wp-post-image">

                              </div>
                              <div class="qode-bct-post-text">
                                <h4 class="qode-bct-post-title entry_title" style="
                                                                    word-wrap: break-word; width: 230px;
                                                                   " itemprop="name">
                                  <a (click)="preview(item.event_name_id)">{{item.event_name_en}}
                                  </a>
                                </h4>
                                <p style="
                                                                        word-wrap: break-word; width: 230px;
                                                                        height:60px;
        line-height:20px;
        overflow:hidden;
                                                                       " [innerHTML]="item.content_detail_en">

                                </p>
                                <div class="qode-bct-post-date entry_date updated" itemprop="dateCreated">
                                  {{item.published_date |date:'longDate' }}
                                  <meta itemprop="interactionCount" content="UserComments: 0">
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="vc_row wpb_row section vc_row-fluid  qode-lower-section-z-index vc_custom_1518013740121 grid_section"
          style=" text-align:center;">
          <div class=" section_inner clearfix">
            <div class="section_inner_margin clearfix">
              <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner vc_custom_1504225816522">
                  <div class="wpb_wrapper">
                    <div class="wpb_text_column wpb_content_element ">
                      <div class="wpb_wrapper">
                        <h6>Our Keys Of Success</h6>

                      </div>
                    </div>
                    <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                        <span class="empty_space_image"></span>
                      </span>
                    </div>


                    <div class="wpb_text_column wpb_content_element ">
                      <div class="wpb_wrapper">
                        <h2>Certifications</h2>

                      </div>
                    </div>
                    <div class="vc_empty_space" style="height: 28px"><span class="vc_empty_space_inner">
                        <span class="empty_space_image"></span>
                      </span>
                    </div>

                    <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518013774805"
                      style=" text-align:center;">
                      <div class=" full_section_inner clearfix">

                        <div class="wpb_column vc_column_container vc_col-sm-6">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="wpb_single_image wpb_content_element vc_align_center">
                                <div class="wpb_wrapper">

                                  <div class="vc_single_image-wrapper   vc_box_border_grey">
                                    <img width="300" height="300" src="../../../../assets/templates/home/ISO 14001.jpg"
                                      class="vc_single_image-img attachment-medium" alt=""
                                      srcset="../../../../assets/templates/home/ISO 14001.jpg 300w,
                                                                                ../../../../assets/templates/home/ISO 14001.jpg 150w,
                                                                                  ../../../../assets/templates/home/ISO 14001.jpg 100w,
                                                                                  ../../../../assets/templates/home/ISO 14001.jpg 600w"
                                      sizes="(max-width: 300px) 100vw, 300px">
                                  </div>
                                </div>
                              </div>

                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h6>Certificates</h6>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 3px">
                                <span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h4 style="font-weight: 700;font-size: 20px;">ISO 14001</h4>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 14px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <p>Due to our awareness of environment and society, our company is certified by ISO
                                    14001- regarding to environmental management.</p>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 40px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="wpb_column vc_column_container vc_col-sm-6">
                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="wpb_single_image wpb_content_element vc_align_center">
                                <div class="wpb_wrapper">

                                  <div class="vc_single_image-wrapper   vc_box_border_grey">
                                    <img width="300" height="300" src="../../../../assets/templates/home/IATF 16949.jpg"
                                      class="vc_single_image-img attachment-medium" alt=""
                                      srcset="../../../../assets/templates/home/IATF 16949.jpg 300w,
                                                                                ../../../../assets/templates/home/IATF 16949.jpg 150w,
                                                                                 ../../../../assets/templates/home/IATF 16949.jpg 100w,
                                                                                 ../../../../assets/templates/home/IATF 16949.jpg 600w"
                                      sizes="(max-width: 300px) 100vw, 300px">
                                  </div>
                                </div>
                              </div>

                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h6>Certificates</h6>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 3px">
                                <span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <h4 style="font-weight: 700;font-size: 20px;">IATF 16949</h4>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 14px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>


                              <div class="wpb_text_column wpb_content_element ">
                                <div class="wpb_wrapper">
                                  <p>IATF 16949- an ISO technical specification – certified DSST as a certified
                                    DSST as certified company having effective quality management system.
                                  </p>

                                </div>
                              </div>
                              <div class="vc_empty_space" style="height: 40px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                                </span>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
<div style="background-color: white;">
  <app-slide-footer></app-slide-footer>
</div>

<app-footer></app-footer>

