import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import Swal from 'sweetalert2'
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public aFormGroup: FormGroup;
  public recapcha : string;
  constructor(private formBuilder: FormBuilder,private mailService:ContentService) {
    this.createContactForm();
  }

  ngOnInit(): void {

  }

  contactForm: FormGroup;
  async onSubmit() {

   let a = await this.mailService.sendMail(this.contactForm.value).toPromise();
  // if(a=='success'){

    // this.recapcha = this.mailService.get_capcha()
    // if(this.recapcha=='success' && this.contactForm.value.fullName!='' && this.contactForm.value.email!=''&& this.contactForm.value.message!=''){
    Swal.fire({
      title: 'Send email successfully',
      text: 'Click close button to back to the Post page',
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: "Close",
      confirmButtonColor: "#3085d6",
    }).then(result => {
      this.mailService.remove_capcha()
     location.reload()
    });
  // }
  // }


}
  createContactForm(){
    this.contactForm = this.formBuilder.group({
      fullName: [''],
      email: [''],
      message: ['']
    });
  }

  term() {
    window.location.href = '/terms-of-use'

  }
  privacy() {
    window.location.href = '/privacy-policy'
  }
  career() {
    window.location.href = '/career'
  }
}
