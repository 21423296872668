<title>
  Daicel Thailand | Products</title>
  <header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
    <div class="header_inner clearfix">
        <div class="header_top_bottom_holder">
            <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
                <div class="container">
                    <div class="container_inner clearfix">
                        <div class="header_inner_left">
                            <div class="mobile_menu_button">
                                <span>
                                    <i class="fa fa-bars"></i> </span>
                            </div>
                            <div class="logo_wrapper">
                                <div class="q_logo">
                                    <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                        <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="light" src="../assets/templates/home/DSST-Logo-300x70.png"
                                            alt="Logo" style="height: 100%;"> <img itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> <img itemprop="image" class="sticky" src="../assets/templates/home/DSST-Logo-300x70.png"
                                            alt="Logo" style="height: 100%;"> <img itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;"> </a>
                                </div>
                            </div>
                            <div class="header_fixed_right_area">
                              <div class="textwidget custom-html-widget">
                                  <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                      </span>
                                  </div>

                                  <span style="display: inline-block;">
                                    <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                                        <div class="icon_holder " style="">
                                            <img itemprop="image" style="
                                            margin-top: 7px;
                                            margin-left: 5px;
                                        "
                                                src="../assets/templates/home/pr.png" alt="">
                                        </div>
                                        <div class="icon_text_holder" style="">
                                            <div class="icon_text_inner" style="">
                                              <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;
                                              font-family: 'Open Sans', sans-serif!important;
                                              "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                                </h6>
                                                <p style="color: #949494;line-height: 18px!important;
                                              font-weight: 400!important;
                                                ">  241 หมู่ 4, เขตอุตสาหกรรม 304 <br>
                                                  ปราจีนบุรี 25140 ประเทศไทย <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>

                                <span style="display: inline-block; margin-right: 15px;">
                                    <div class="q_icon_with_title medium custom_icon_image ">
                                        <div class="icon_holder " style=" "><img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                                src="../assets/templates/home/sb.png" alt=""></div>
                                        <div class="icon_text_holder" style="">
                                            <div class="icon_text_inner" style="">
                                              <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;               font-family: 'Open Sans', sans-serif!important;

                                              ">
                                                  +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                                </h6>
                                                <p style="color: #949494;line-height: 18px!important;
                                              font-weight: 400!important;
                                                ">  76 หมู่ 1, อำเภอพระพุทธบาท<br>
                                                สระบุรี 18120 ประเทศไทย <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>

                                <span style="display: inline-block;">
                                    <div class="q_icon_with_title medium custom_icon_image ">
                                        <div class="icon_holder " style=" "><img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                                src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                        <div class="icon_text_holder" style="">
                                            <div class="icon_text_inner" style="">
                                              <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;

                                              "> daicel-dsst.com<br>

                                                </h6>
                                                <p style="color: #949494;line-height: 18px!important;
                                                font-weight: 400!important;
                                                ">  วันจันทร์ - วันศุกร์ 08h - 17h<br>หยุดวันเสาร์ - อาทิตย์


                                                 <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>


                              </div>
                          </div>
                        </div>
                        <div class="header_menu_bottom">
                            <div class="header_menu_bottom_inner">
                                <div class="main_menu_header_inner_right_holder">
                                    <nav class="main_menu drop_down">
                                        <ul id="menu-main-menu" class="clearfix">
                                            <li id="nav-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="aboutUs()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>เกี่ยวกับเรา
                                                        <span class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="products()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>ผลิตภัณฑ์<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="daicel_group()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>ไดเซลกรุ๊ป<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="csr_page()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>กิจกรรม CSR<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="news_events()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>ข่าวสารและกิจกรรม<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>

                                            <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="career()" class=""><i
                                                                class="menu_icon blank fa"></i><span class='font'>ร่วมงานกับเรา<span
                                                                    class="underline_dash"></span></span><span
                                                                class="plus"></span></a></li>
                                            <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">

                                                <a (click)="contact_us()" class=""><i
                                                        class="menu_icon blank fa"></i><span class='font'>ติดต่อเรา
                                                        <span class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                                <a title="English"  class=""><i
                                                        class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                                                            src="../assets/templates/home/th.png" alt="en"
                                                            title="ไทย"><span class="wpml-ls-native" class='font'>ไทย</span><span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span><span class="qode-featured-icon "
                                                        aria-hidden="true"></span></a>
                                                <div class="second" style="height: 0px;">
                                                    <div class="inner">
                                                        <ul>
                                                            <li id="nav-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                                <a title="ไทย" (click)="th()" class=""><i
                                                                        class="menu_icon blank fa"></i><span><img
                                                                            class="wpml-ls-flag"
                                                                            src="../assets/templates/home/en.png"
                                                                            alt="th" title="English"><span
                                                                            class="wpml-ls-native">English</span></span><span
                                                                        class="plus"></span><span
                                                                        class="qode-featured-icon "
                                                                        aria-hidden="true"></span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div class="header_inner_right">
                                        <div class="side_menu_button_wrapper right">
                                            <div class="header_bottom_right_widget_holder">
                                                <div class="widget_text header_bottom_widget widget_custom_html">
                                                    <div class="textwidget custom-html-widget">
                                                        <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="side_menu_button">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav class="mobile_menu">
                            <ul id="menu-main-menu-1" class="">
                                <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="aboutUs()" class=""><span class='font'>เกี่ยวกับเรา
                                        </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="products()" class=""><span class='font'>ผลิตภัณฑ์</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="daicel_group()" class=""><span class='font'>ไดเซลกรุ๊ป
                                        </span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="csr_page()" class=""><span class='font'>กิจกรรม CSR</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="news_events()" class=""><span class='font'>ข่าวสารและกิจกรรม</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>

                                <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="career()" class=""><span class='font'>ร่วมงานกับเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                    class="fa fa-angle-down"></i></span></li>

                                <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="contact_us()" class=""><span class='font'>ติดต่อเรา</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                    <a title="English"  class=""><span><img class="wpml-ls-flag"
                                                src="../assets/templates/home/th.png" alt="en" title="ไทย"><span
                                                class="wpml-ls-native font" >ไทย</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span>
                                    <ul class="sub_menu">
                                        <li id="mobile-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                            <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                        src="../assets/templates/home/en.png" alt="th"
                                                        title="English"><span
                                                        class="wpml-ls-native">English</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                    class="fa fa-angle-down"></i></span></li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
    </div>

  </header>
<a id="back_to_top" href="#" class="off">
  <span class="fa-stack">
    <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>
<div class="content content_top_margin_none">
  <div class="content_inner  ">
    <div class="title_outer title_without_animation" data-height="250">
      <div class="title title_size_small  position_left  has_fixed_background "
        style="background-size:1920px auto;background-image:url(../../../../assets/templates/home/pgg_bg.png);height:250px;">
        <div class="image not_responsive"><img itemprop="image" src="../../../../assets/templates/home/pgg_bg.png"
            alt="&nbsp;" /> </div>
        <div class="title_holder" style="padding-top:0;height:250px;">
          <div class="container">
            <div class="container_inner clearfix">
              <div class="title_subtitle_holder">
                <div class="title_subtitle_holder_inner">
                  <h1><span>PGG</span></h1>

                  <div class="breadcrumb" style="background-color: transparent;">
                    <div class="breadcrumbs">
                      <div itemprop="breadcrumb" class="breadcrumbs_inner"><a (click)="home()">Home</a><span
                          class="delimiter">&nbsp;>&nbsp; Products </span> &nbsp;>&nbsp; <span
                          class="current">PGG</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full_width">
      <app-choose-product-th></app-choose-product-th>
      <div class="full_width_inner">

        <div class="full_width_inner">
          <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518017477874 grid_section"
            style=' text-align:left;'>
            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                      <br> <br> <br>


                      <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner">
                          <span class="empty_space_image"></span>
                        </span>
                      </div>
                      <div class="wpb_text_column wpb_content_element ">
                        <div class="wpb_wrapper">
                          <p style="text-align: center;"><span>Seatbelts serve the crucial function in all automobiles to protect the lives of drivers and passengers. <br>

                            “Gas generators for Seat Belt Pretensioners (PGG)” are necessary to fasten the passengers on to the seats safely and securely by retracting the seatbelt in case of collisions.

                         <br>   Daicel Safety Strategic Business Unit(SBU)s' pyrotechnic-activated seatbelt retraction device provides safety and reassurance to protect our customers under emergencies.</span></p>


                          <br>
                          <br>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br><br>
          </div>



          <section data-speed="1" class="parallax_section_holder  "
            style=" height:px; background-image:url('../../../../assets/templates/home/Products-Sample-A90-1920x1080.jpg');">
            <br><br><br>
            <div class="parallax_content_full_width center">
              <div class='parallax_section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner vc_custom_1518015406018">
                    <div class="wpb_wrapper">
                      <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner">
                          <span class="empty_space_image"></span>
                        </span>
                      </div>


                      <div class="wpb_text_column wpb_content_element ">
                        <div class="wpb_wrapper">
                          <h2><span style="color: #009be6;">Product lineup</span></h2>

                        </div>
                      </div>
                      <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner">
                          <span class="empty_space_image"></span>
                        </span>
                      </div>

                      <div class="vc_row wpb_row section vc_row-fluid vc_inner  grid_section"
                        style=' text-align:center'>
                        <div class=" section_inner clearfix">
                          <div class='section_inner_margin clearfix'>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                              <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                  <div
                                    class="qode-numbered-process-holder qode-numbered-process-holder-items-four qodef-np-padding-small qode-np-line-dashed">
                                    <div class="qode-numbered-process-inner">
                                      <div class="qode-np-item">
                                        <div class="qode-np-item-image-holder">
                                          <div class="qode-np-item-image-inner" style="border-color: #009be6">
                                            <div class="qode-np-item-image-table">
                                              <div class="qode-np-item-image-table-cell">
                                                <img src="../../../../assets/templates/home/lineup_pgg.png"
                                                  alt="qode-np-item" />
                                              </div>
                                            </div>

                                          </div>
                                          <span class="qode-np-line"></span>
                                        </div>
                                        <h4 class="qode-np-title" style="color: #000000">
                                          Gas generators for Seat Belt Pretensioners (PGG)</h4>

                                        <br>
                                        <p style="text-align: center;">
                                          This gas generator protects the occupant by retracting the seat belt during a collision.
                                          It utilizes the pyrotechnic method.


                                        </p>
                                        <br>


                                      </div>

                                    </div>
                                  </div>
                                  <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                      <span class="empty_space_image"></span>
                                    </span>
                                  </div>



                                </div>
                                <br><br><br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518014984044 grid_section"
            style=' text-align:left; background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>
            <br><br><br><br>

            <div class=" section_inner clearfix">
              <div class='section_inner_margin clearfix'>
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner ">
                    <div class="wpb_wrapper">

                      <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1518015575529"
                        style=' text-align:left;'>
                        <div class=" full_section_inner clearfix">
                          <div class="wpb_column vc_column_container vc_col-sm-7">
                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h6>Seat Belt Pretensioner Functions</h6>

                                  </div>
                                </div>
                                <div class="vc_empty_space" style="height: 7px">
                                  <span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">
                                    <h3><strong>Seat Belt Pretensioners and Pop up hood</strong></h3>

                                  </div>
                                </div>
                                <div class="vc_empty_space" style="height: 21px"><span class="vc_empty_space_inner">
                                    <span class="empty_space_image"></span>
                                  </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                  <div class="wpb_wrapper">


                                  </div>
                                </div>
                                <br>

                                <img src="{{imageSrc}}">

                                <!-- <img src="../../../../assets/templates/home/choice_driver_mv01.gif" alt=""> -->

                              </div>
                            </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-5">
                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                               <br><br>
                                <p><i class="fa fa-square" aria-hidden="true"></i> Click for more information.</p>
                                <div class="wpb_single_image wpb_content_element vc_align_center">
                                  <div class="row" >
                                    <div class="col-auto" >
                                      <button class="button-2" style="width: 120px;height: 100px" (click)="onClick('choice_pgg_mv01.gif')" >Seat Belt Pretensioners</button>
                                    </div>
                                    <div class="col-auto">
                                      <button   class="button-2"  style="width: 120px;height: 100px"  (click)="onClick('choice_pgg_mv02.gif')">Pop up  hood</button>
                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                          </div>





                        </div>

                        <div class="vc_empty_space" style="height: 80px">
                          <span class="vc_empty_space_inner">
                            <span class="empty_space_image"></span>
                          </span>
                        </div>

                      </div>






                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1510511606615 grid_section" style='background-image:url(../../../../assets/templates/home/Products-Sample-A90-1920x1080.jpg); text-align:center;'>
            <br><br><br>
            <div class=" section_inner clearfix">
                <div class='section_inner_margin clearfix'>
                    <div class="wpb_column vc_column_container vc_col-sm-12">
                        <div class="vc_column-inner ">
                            <div class="wpb_wrapper">

                                <div class="vc_empty_space" style="height: 7px"><span class="vc_empty_space_inner">
                                        <span class="empty_space_image"></span>
                                    </span>
                                </div>


                                <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                      <br>
                                        <h2 style="text-align: center;">Operating principle of Seat Belt Pretensioners
                                        </h2>
                                        <br>

                                    </div>
                                </div>
                                <div class="vc_empty_space" style="height: 30px"><span class="vc_empty_space_inner">
                                        <span class="empty_space_image"></span>
                                    </span>
                                </div>

                                <div class="vc_row wpb_row section vc_row-fluid vc_inner " style=' text-align:left;'>
                                    <div class=" full_section_inner clearfix">
                                        <div class="wpb_column vc_column_container vc_col-sm-3">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                        <div class="wpb_wrapper">

                                                            <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width="250" height="150" src="../../../../assets/templates/home/order_pgg1.png" class="vc_single_image-img attachment-thumbnail" alt="" srcset="../../../../assets/templates/home/../../../../assets/templates/home/order_pgg1.png 150w,
                                                                ../../../../assets/templates/home/order_pgg1.png 300w,
                                                                ../../../../assets/templates/home/order_pgg1.png 768w,
                                                                ../../../../assets/templates/home/order_pgg1.png 1024w,
                                                                ../../../../assets/templates/home/order_pgg1.png 1200w,
                                                                ../../../../assets/templates/home/order_pgg1.png100w" sizes="(max-width: 150px) 100vw, 150px" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="vc_empty_space" style="height: 7px">
                                                        <span class="vc_empty_space_inner">
                                                            <span class="empty_space_image"></span>
                                                        </span>
                                                    </div>


                                                    <div class="wpb_text_column wpb_content_element ">
                                                        <div class="wpb_wrapper">

                                                            <p style="text-align: left;">
                                                             <b >1. </b> During a collision, the seat belt remains locked while the pressure is dispersed maximally to the chest area.
                                                            </p>

                                                        </div>
                                                    </div>
                                                    <div class="vc_empty_space" style="height: 7px">
                                                        <span class="vc_empty_space_inner">
                                                            <span class="empty_space_image"></span>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-3">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                        <div class="wpb_wrapper">

                                                            <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width="250" height="150" src="../../../../assets/templates/home/order_pgg2.png" class="vc_single_image-img attachment-thumbnail" alt="" srcset="../../../../assets/templates/home/order_pgg2.png 150w,
                                                                ../../../../assets/templates/home/order_pgg2.png 300w,
                                                                ../../../../assets/templates/home/order_pgg2.png 768w,
                                                                ../../../../assets/templates/home/order_pgg2.png 1024w,
                                                                ../../../../assets/templates/home/order_pgg2.png 1200w,
                                                                ../../../../assets/templates/home/order_pgg2.png 100w" sizes="(max-width: 150px) 100vw, 150px" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="vc_empty_space" style="height: 7px">
                                                        <span class="vc_empty_space_inner">
                                                            <span class="empty_space_image"></span>
                                                        </span>
                                                    </div>


                                                    <div class="wpb_text_column wpb_content_element ">
                                                        <div class="wpb_wrapper">
                                                          <p style="text-align: left;">
                                                            <b>2. </b> After the collision, the PGG applies force to the piston, causing the pretensioner to instantly pull on the seat belt, restraining the occupant in the seat.
                                                           </p>


                                                        </div>
                                                    </div>
                                                    <div class="vc_empty_space" style="height: 7px">
                                                        <span class="vc_empty_space_inner">
                                                            <span class="empty_space_image"></span>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-3">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_single_image wpb_content_element vc_align_center">
                                                        <div class="wpb_wrapper">

                                                            <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width="250" height="150" src="../../../../assets/templates/home/order_pgg3.png" class="vc_single_image-img attachment-thumbnail" alt="" srcset="../../../../assets/templates/home/order_pgg3.png 150w,
                                                                ../../../../assets/templates/home/order_pgg3.png 300w,
                                                                ../../../../assets/templates/home/order_pgg3.png 768w,
                                                                ../../../../assets/templates/home/order_pgg3.png 1024w,
                                                                ../../../../assets/templates/home/order_pgg3.png 1200w,
                                                                ../../../../assets/templates/home/order_pgg3.png 100w" sizes="(max-width: 150px) 100vw, 150px" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="vc_empty_space" style="height: 7px">
                                                        <span class="vc_empty_space_inner">
                                                            <span class="empty_space_image"></span>
                                                        </span>
                                                    </div>


                                                    <div class="wpb_text_column wpb_content_element ">
                                                        <div class="wpb_wrapper">
                                                          <p style="text-align: left;">
                                                            <b>3. </b> After the collision, the inflation of the airbag reduces the force applied to the head and chest area.
                                                           </p>

                                                        </div>
                                                    </div>
                                                    <div class="vc_empty_space" style="height: 7px">
                                                        <span class="vc_empty_space_inner">
                                                            <span class="empty_space_image"></span>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-3">
                                          <div class="vc_column-inner ">
                                              <div class="wpb_wrapper">
                                                  <div class="wpb_single_image wpb_content_element vc_align_center">
                                                      <div class="wpb_wrapper">

                                                          <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                              <img width="250" height="150" src="../../../../assets/templates/home/order_pgg4.png" class="vc_single_image-img attachment-thumbnail" alt="" srcset="../../../../assets/templates/home/order_pgg4.png 150w,
                                                              ../../../../assets/templates/home/order_pgg4.png 300w,
                                                              ../../../../assets/templates/home/order_pgg4.png 768w,
                                                              ../../../../assets/templates/home/order_pgg4.png 1024w,
                                                              ../../../../assets/templates/home/order_pgg4.png 1200w,
                                                              ../../../../assets/templates/home/order_pgg4.png 100w" sizes="(max-width: 150px) 100vw, 150px" />
                                                          </div>
                                                      </div>
                                                  </div>


                                                  <div class="vc_empty_space" style="height: 7px">
                                                      <span class="vc_empty_space_inner">
                                                          <span class="empty_space_image"></span>
                                                      </span>
                                                  </div>


                                                  <div class="wpb_text_column wpb_content_element ">
                                                      <div class="wpb_wrapper">
                                                        <p style="text-align: left;">
                                                          <b>4. </b> At the same time, the seat belt tension is moderately eased by the activation of force limiters.
                                                         </p>

                                                      </div>
                                                  </div>
                                                  <div class="vc_empty_space" style="height: 7px">
                                                      <span class="vc_empty_space_inner">
                                                          <span class="empty_space_image"></span>
                                                      </span>
                                                  </div>

                                              </div>
                                          </div>
                                      </div>
                                    </div>

                                </div>

                                <div class="vc_empty_space" style="height: 30px"><span class="vc_empty_space_inner">
                                  <span class="empty_space_image"></span>
                              </span>
                          </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br><br><br>
        </div>

        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1510596881665 grid_section"
        style='background-color:#ffffff; text-align:right;'>
        <br>
        <div class=" section_inner clearfix">
          <div class='section_inner_margin clearfix'>
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div
                    class='q_elements_holder two_columns eh_two_columns_66_33 responsive_mode_from_768 alignment_one_column_center'>
                    <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                      <div class='q_elements_item_inner'>
                        <div class='q_elements_item_content q_elements_holder_custom_904884'>
                          <style type="text/css" data-type="q_elements_custom_padding" scoped>
                            @media only screen and (min-width: 600px) and (max-width: 768px) {
                              .q_elements_item_content.q_elements_holder_custom_904884 {
                                padding: 0 0 21px 0 !important;
                              }
                            }

                            @media only screen and (min-width: 480px) and (max-width: 600px) {
                              .q_elements_item_content.q_elements_holder_custom_904884 {
                                padding: 0 0 21px 0 !important;
                              }
                            }

                            @media only screen and (max-width: 480px) {
                              .q_elements_item_content.q_elements_holder_custom_904884 {
                                padding: 0 0 21px 0 !important;
                              }
                            }
                          </style>
                          <div class="wpb_text_column wpb_content_element ">
                            <div class="wpb_wrapper">
                              <h3>HAVE QUESTIONS ABOUT OUR PRODUCTS?</h3>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='q_elements_item ' data-animation='no' style='vertical-align:middle;'>
                      <div class='q_elements_item_inner'>
                        <div class='q_elements_item_content q_elements_holder_custom_972935'>
                          <style type="text/css" data-type="q_elements_custom_padding" scoped>
                            @media only screen and (min-width: 600px) and (max-width: 768px) {
                              .q_elements_item_content.q_elements_holder_custom_972935 {
                                padding: 0 0 21px 0 !important;
                              }
                            }

                            @media only screen and (min-width: 480px) and (max-width: 600px) {
                              .q_elements_item_content.q_elements_holder_custom_972935 {
                                padding: 0 0 21px 0 !important;
                              }
                            }

                            @media only screen and (max-width: 480px) {
                              .q_elements_item_content.q_elements_holder_custom_972935 {
                                padding: 0 0 21px 0 !important;
                              }
                            }
                          </style>

                          <a href="mailto:sales.dsst@dsst.daicel.com" target="_self"
                            style="font-size: 13px"
                            class="qode-btn qode-btn-medium qode-btn-solid qode-btn-icon qodef-btn-icon-square qode-btn-icon-rotate">
                            <span class="qode-btn-text">Get In
                              Touch</span><span class="qode-button-v2-icon-holder" style="font-size: 21px"><span
                                aria-hidden="true"
                                class="qode_icon_font_elegant arrow_carrot-right_alt2 qode-button-v2-icon-holder-inner"></span></span>
                          </a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
      </div>



        </div>
      </div>

    </div>
  </div>




  <app-footer></app-footer>
