import { formatDate } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { contentModel } from "src/app/models/content";
import { ContentService } from "src/app/services/content.service";
import { CollapseModule } from "angular-bootstrap-md";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from "@angular/forms";
import * as _ from "lodash";
@Component({
  selector: "app-career",
  templateUrl: "./career.component.html",
  styleUrls: ["./career.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class CareerComponent implements OnInit {
  cities = [
    { id: 1, name: "Prachinburi" },
    { id: 2, name: "Saraburi" },
  ];

  filterValues = {
    event_name_en: "",
  };

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  onChange($event: any) {
    if ($event != undefined) {
      let filteredData = _.filter(this.dataCareer, (item) => {
        return item.location.toLowerCase() == $event.name.toLowerCase();
      });
      this.dataSource = new MatTableDataSource(filteredData);
    } else {
      this.dataSource = new MatTableDataSource(this.dataCareer);
    }
  }

  data1: any = [];
  data2: any = [];
  mobile: boolean = false;
  vacant: any = [];
  location: any = [];
  dataCareer: any = [];
  Arr = Array;
  public content: contentModel;
  date_now = formatDate(new Date(), "yyyy-MM-dd", "en");

  displayedColumns: string[] = [
    "active",
    "event_name_en",
    "vacant",
    "location",
    "published_date",
  ];
  displayedColumnsMobile: string[] = ["active"];

  // displayedColumns: string[] = ['active','event_name_en','vacant','post_date'];

  expandedElement: PeriodicElement | null;
  dataSource = new MatTableDataSource(this.dataCareer);
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  public innerWidth: any;
  highlightedRows = [];
  constructor(private router: Router, private contentService: ContentService) {}

  selectedRowIndex = -1;

  async ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    let res = await this.contentService.findJob().toPromise();
    this.content = res;

    for (let i = 0; i < res.length; i++) {
      if (this.date_now >= res[i].published_date)
        this.dataCareer.push({
          active: res[i].active,
          event_name_en: res[i].event_name_en,
          post_date: res[i].post_date,
          published_date: res[i].published_date,
          event_id: res[i].event_id,
          event_name_th: res[i].event_name_th.split("<br>")[0],
          location: res[i].event_name_th.split("<br>")[2],
          vacant: parseInt(res[i].event_name_th.split("<br>")[1]),
          content_detail_en: res[i].content_detail_en,
          content_detail_th: res[i].content_detail_th,
        });
    }

    this.dataSource = new MatTableDataSource(this.dataCareer);

  }

  editItem(id) {
    window.location.href = "/career/detail/" + id;
  }

  aboutUs() {
    window.location.href = "/about-us";
  }
  products() {
    window.location.href = "/products";
  }
  daicel_group() {
    window.location.href = "/daicel-group";
  }
  csr_page() {
    window.location.href = "/csr";
  }
  news_events() {
    window.location.href = "/news-events";
  }
  contact_us() {
    window.location.href = "/contact-us";
  }
  career() {
    window.location.href = "/career";
  }
  home() {
    window.location.href = "/";
  }
  th() {
    window.location.href = "/th/career";
  }

  link(url) {
    let arrayUrl = url.split("<br>");
    window.open(arrayUrl[0], "_blank");
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}
