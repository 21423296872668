import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Router, ActivatedRoute, Route } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Location } from "@angular/common";
import Swal from "sweetalert2";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ContentService } from "src/app/services/content.service";
import { UserModel } from "../../models/user";
import { contentModel } from "../../models/content";
import { imagesModel } from "../../models/images";
import { ImagesService } from "src/app/services/images.service";
import { ThrowStmt } from "@angular/compiler";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-career-edit",
  templateUrl: "./career-edit.component.html",
  styleUrls: ["./career-edit.component.css"],
})
export class CareerEditComponent implements OnInit {
  public readonly USERLOGIN: UserModel =
    this.authenticationService.currentUserValue;
  public content: contentModel;
  form: any;

  words2 = [
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
  ];

  words1 = [
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
    { values: "" },
  ];

  node_static_url = `${environment.backendUrl}`;
  public imageSrc: any = null;

  mIsSubmitted = false;

  filename = "Choose file";

  activeColor: string = "green";
  baseColor: string = "#ccc";
  overlayColor: string = "rgba(255,255,255,0.5)";

  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;

  file: string = "";
  borderColor: string;
  iconColor: string;
  post_by: string;

  images: any = [];
  allfiles: any = [];
  headerImg;
  imagesName = [];
  imgName = [];
  AddNameImg = [];
  imgAll = [];
  allData: any[];
  imgHeader: string;

  imgname: string = "";
  size: string = "";
  type: number;

  titleEn: string = "";
  detailEn: string = "";
  titleTh: string = "";
  detailTh: string = "";
  dateActivity: string = "";
  datePublish: string = "";
  comNews: string = "";
  csr: number;
  event: string = "";
  event_id: string = "";
  id: 0;
  minDate = "";
  cities = [
    { id: 1, name: "Prachinburi" },
    { id: 2, name: "Saraburi" },
  ];
  j1: number = 1;
  j2: number = 1;
  uploadedFiles: Array<File>;
  name: string = "";
  radioItems: Array<string>;
  model = { option: "" };
  template: any;
  num_position = 1;
  active: any;
  submitted = false;
  selectedLocation : any;
  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private contentService: ContentService,
    private router: Router,
    private http: HttpClient,
    private location: Location,
    public _d: DomSanitizer,
    fb: FormBuilder
  ) {
    this.radioItems = ["Company news", "CSR", "Event"];

    this.form = new FormGroup({
      id: new FormControl("", Validators.required),
      titleTh: new FormControl("", Validators.required),
      titleEn: new FormControl("", Validators.required),
      detailTh: new FormControl("", Validators.required),
      detailEn: new FormControl("", Validators.required),
      dateActivity: new FormControl("", Validators.required),
      datePublish: new FormControl("", Validators.required),
      comNews: new FormControl("", Validators.required),
      csr: new FormControl("", Validators.required),
      event: new FormControl("", Validators.required),
      post_by: new FormControl("", Validators.required),
      num_position: new FormControl("", Validators.required),
      selectedLocation: new FormControl("", Validators.required),
    });
  }
  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.id = params["id"];
      this.content = await this.contentService
        .findJobById(this.id + "")
        .toPromise();
      this.event_id = this.content.event_id.toString();
      this.form.patchValue({
        titleTh: this.content.event_name_th.split("<br>")[0],
        titleEn: this.content.event_name_en,
        detailTh: this.content.content_detail_th,
        detailEn: this.content.content_detail_en,
        num_position: this.content.event_name_th.split("<br>")[1],
       selectedLocation: this.content.event_name_th.split("<br>")[2],
      });


      if (this.content.active == 3) {
        this.template = true;
      }
      if (this.content.active == 4) {
        this.template = false;
      }

      this.getValue();
    });
  }

  setValue() {


    if (this.form.value.selectedLocation.name != undefined) {
      this.selectedLocation = this.form.value.selectedLocation.name;
    } else if (this.form.value.selectedLocation.name == undefined) {
      this.selectedLocation = this.content.event_name_th.split("<br>")[2]
    }


    this.detailEn = null;
    this.detailTh = null;
    this.j1 = 1;
    this.j2 = 1;
    for (let i = 0; i < this.words1.length; i++) {
      if (this.words1[i]["values"] != "") {
        if (this.words1[i]["values"] != " ") {
          this.detailEn =
            this.detailEn + this.j1 + "." + this.words1[i]["values"] + "<br>";
          this.j1 += 1;
        }
      }
    }

    for (let i = 0; i < this.words2.length; i++) {
      if (this.words2[i]["values"] != "") {
        if (this.words2[i]["values"] != " ") {
          this.detailTh =
            this.detailTh + this.j2 + "." + this.words2[i]["values"] + "<br>";
          this.j2 += 1;
        }
      }
    }
  }

  getValue() {
    this.words2 = [
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
    ];

    this.words1 = [
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
      { values: "" },
    ];

    localStorage.removeItem("titleEn");
    localStorage.removeItem("titleTh");
    localStorage.removeItem("detailEn");
    localStorage.removeItem("detailTh");

    let num = this.content.content_detail_th.split("<br>").length - 1;
    let num1 = this.content.content_detail_en.split("<br>").length - 1;

    for (let i = 0; i < num; i++) {
      if (
        this.content.content_detail_th.split("<br>")[i].split(".")[1] != " "
      ) {
        this.words2[i]["values"] = this.content.content_detail_th
          .split("<br>")
          [i].split(".")[1];
      }
    }
    for (let i = 0; i < num1; i++) {
      if (
        this.content.content_detail_en.split("<br>")[i].split(".")[1] != " "
      ) {
        this.words1[i]["values"] = this.content.content_detail_en
          .split("<br>")
          [i].split(".")[1];
      }
    }
  }

  get f() {
    return this.form.controls;
  }

  change1() {
    if (this.template == true) {
      this.template = false;
    } else {
      this.template = true;
    }
  }

  async save_post() {
    this.setValue();

    if (this.form.value.titleTh != "" && this.form.value.titleEn != "") {
      if (this.template == true) {
        this.active = 3;
      } else if (this.template == false) {
        this.active = 4;
      }


      let formData = new FormData();
      formData.append("id", this.event_id);
      formData.append(
        "event_name_th",
        this.titleTh + "<br>" + this.form.value.num_position+ "<br>" + this.selectedLocation
      );
      formData.append("event_name_en", this.titleEn);
      formData.append("content_detail_th", this.detailTh);
      formData.append("content_detail_en", this.detailEn);
      formData.append("active", this.active + "");
      formData.append("post_by", "admin");
      formData.append("post_date", formatDate(new Date(), "yyyy-MM-dd", "en"));
      formData.append(
        "published_date",
        formatDate(new Date(), "yyyy-MM-dd", "en")
      );

      await this.contentService.updateContent(formData).toPromise();
      Swal.fire({
        title: "Update successfully",
        text: "Click close button to back to the Post page",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Close",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        this.location.back();
      });
    }

    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
  }

  async save() {

    this.setValue();

    if (this.form.value.titleTh != "" && this.form.value.titleEn != "") {
      if (this.template == true) {
        this.active = 3;
      } else if (this.template == false) {
        this.active = 4;
      }

      let formData = new FormData();
      formData.append("id", this.event_id);
      formData.append(
        "event_name_th",
        this.titleTh + "<br>" + this.form.value.num_position +"<br>" + this.selectedLocation
      );
      formData.append("event_name_en", this.titleEn);
      formData.append("content_detail_th", this.detailTh);
      formData.append("content_detail_en", this.detailEn);
      formData.append("active", this.active + "");
      formData.append("post_by", "admin");
      formData.append("post_date", formatDate(new Date(), "yyyy-MM-dd", "en"));
      formData.append(
        "published_date",
        formatDate(new Date(), "2500-01-01", "en")
      );
      await this.contentService.updateContent(formData).toPromise();
      Swal.fire({
        title: "Update successfully",
        text: "Click close button to back to the Post page",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Close",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        this.location.back();
      });
    }

    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
  }


  async previewEN() {
    let active = 0
    if (this.template == true) {
      active = 3;

    } else if (this.template == false) {
      active = 4;
    }

    this.setValue();
    this.titleEn = this.form.value.titleEn;
    this.titleTh = this.form.value.titleTh;
    this.num_position = this.form.value.num_position;
    this.type = active;

    localStorage.setItem("titleEn", this.titleEn);
    localStorage.setItem("titleTh", this.titleTh);
    localStorage.setItem("detailEn", this.detailEn);
    localStorage.setItem("detailTh", this.detailTh);

    window.open(
      "preview-career?type=" +
        active +
        "&num=" +
        this.num_position +
        "&id=" +
        this.event_id+"&location="+
        this.selectedLocation,
      "_blank" // <- This is what makes it open in a new window.
    );
  }
}
