
<div class="menu"  *ngIf="scrolled == 1 " >
  <div class="title"><p style="color: white">Products</p></div>
  <ul class="nav" style="    width: 200px;
  margin-left: -40px;">

    <li><a href="products/co-creation" class="btn-2 hover-opacity"><img style="width: 40px;" src="../../../assets/templates/home/product1.png" alt="Wire Cutter"> <label> Co-Creation</label></a></li>
    <li><a href="products/inflator" class="btn-2 hover-opacity"><img style="width: 40px;" src="../../../assets/templates/home/product_inflator.png" alt="Wire Cutter"> <label >Inflator</label> </a></li>
    <li><a href="products/pgg" class="btn-2 hover-opacity"><img style="width: 40px;" src="../../../assets/templates/home/product_pgg.png" alt="Wire Cutter">  <label >PGG</label></a></li>
    <li><a href="products/pyrofuse" class="btn-2 hover-opacity"><img style="width: 40px;" src="../../../assets/templates/home/product_pyro-fuse.png" alt="Wire Cutter"> <label >Pyro-Fuse</label> </a></li>

  </ul>
</div>


