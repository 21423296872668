<header class="has_header_fixed_right stick menu_bottom">
    <div class="header_inner clearfix">
        <div class="header_top_bottom_holder">
            <div class="header_bottom clearfix" style="background-color: rgba(255, 255, 255, 1);">
                <div class="container">
                    <div class="container_inner clearfix">
                        <div class="header_inner_left">
                            <div class="mobile_menu_button">
                                <span> <i class="fa fa-bars"></i> </span>
                            </div>
                            <div class="logo_wrapper">
                                <div class="q_logo">
                                    <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                        <img itemprop="image" class="normal" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;" />
                                        <img itemprop="image" class="light" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;" />
                                        <img itemprop="image" class="dark" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;" />
                                        <img itemprop="image" class="sticky" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;" />
                                        <img itemprop="image" class="mobile" src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo" style="height: 100%;" />
                                    </a>
                                </div>
                            </div>
                            <div class="header_fixed_right_area">
                              <div class="textwidget custom-html-widget">
                                  <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                              <span class="empty_space_image"></span>
                                      </span>
                                  </div>

                                  <span style="display: inline-block;">
                                    <div class="q_icon_with_title medium custom_icon_image " style="margin-right: 5px;">
                                        <div class="icon_holder " style="">
                                            <img itemprop="image" style="
                                            margin-top: 7px;
                                            margin-left: 5px;
                                        "
                                                src="../assets/templates/home/pr.png" alt="">
                                        </div>
                                        <div class="icon_text_holder" style="">
                                            <div class="icon_text_inner" style="">
                                              <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;
                                              font-family: 'Open Sans', sans-serif!important;
                                              "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                                </h6>
                                                <p style="color: #949494;line-height: 18px!important;
                                                font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                ">  241 Moo 4, 304 Ind Park<br>
                                                  Prachinburi 25140 Thailand <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>

                                <span style="display: inline-block; margin-right: 15px;">
                                    <div class="q_icon_with_title medium custom_icon_image ">
                                        <div class="icon_holder " style=" "><img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                                src="../assets/templates/home/sb.png" alt=""></div>
                                        <div class="icon_text_holder" style="">
                                            <div class="icon_text_inner" style="">
                                              <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;
                                              font-family: 'Open Sans', sans-serif!important;
                                              ">
                                                  +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                                </h6>
                                                <p style="color: #949494;line-height: 18px!important;
                                                font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                ">  76 Moo.1, A.Phra Phutthabat<br>
                                                 Saraburi 18120 Thailand <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>

                                <span style="display: inline-block;">
                                    <div class="q_icon_with_title medium custom_icon_image ">
                                        <div class="icon_holder " style=" "><img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      "
                                                src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                        <div class="icon_text_holder" style="">
                                            <div class="icon_text_inner" style="">
                                              <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;
                                              font-family: 'Open Sans', sans-serif!important;
                                              "> daicel-dsst.com<br>

                                                </h6>
                                                <p style="color: #949494;line-height: 18px!important;
                                                font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                ">  Monday - Friday 08h - 17h<br>Closed on Weekends


                                                 <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>


                              </div>
                          </div>
                        </div>





                        <div class="header_menu_bottom">
                            <div class="header_menu_bottom_inner">
                                <div class="main_menu_header_inner_right_holder">
                                    <nav class="main_menu drop_down">
                                        <ul id="menu-main-menu" class="clearfix">
                                            <li id="nav-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="aboutUs()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                        ">About
                                                        Us<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="products()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                        ">Products<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="daicel_group()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                        ">Daicel
                                                        Group<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="csr_page()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                        ">CSR<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="news_events()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                        ">News<span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="career()" class=""><i
                                                                class="menu_icon blank fa"></i><span style="
                                                                ">Career
                                                                <span class="underline_dash"></span></span><span
                                                                class="plus"></span></a></li>
                                            <li id="nav-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="contact_us()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                        ">Contact
                                                        Us<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a></li>
                                            <li id="nav-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                                <a title="English" class=""><i
                                                        class="menu_icon blank fa"></i><span><img class="wpml-ls-flag"
                                                            src="../assets/templates/home/en.png" alt="en"
                                                            title="English"><span
                                                            class="wpml-ls-native" style="
                                                            ">English</span><span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span><span class="qode-featured-icon "
                                                        aria-hidden="true"></span></a>
                                                <div class="second" style="height: 0px;">
                                                    <div class="inner">
                                                        <ul>
                                                            <li id="nav-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                                <a title="ไทย" (click)="th()" class=""><i
                                                                        class="menu_icon blank fa"></i><span><img
                                                                            class="wpml-ls-flag"
                                                                            src="../assets/templates/home/th.png"
                                                                            alt="th" title="ไทย"><span
                                                                            class="wpml-ls-native">ไทย</span></span><span
                                                                        class="plus"></span><span
                                                                        class="qode-featured-icon "
                                                                        aria-hidden="true"></span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div class="header_inner_right">
                                        <div class="side_menu_button_wrapper right">
                                            <div class="header_bottom_right_widget_holder">
                                                <div class="widget_text header_bottom_widget widget_custom_html">
                                                    <div class="textwidget custom-html-widget">
                                                        <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">
                                                            <div class=" full_section_inner clearfix">
                                                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                                                    <div class="vc_column-inner ">
                                                                        <div class="wpb_wrapper">


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="side_menu_button">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav class="mobile_menu">
                            <ul id="menu-main-menu-1" class="">
                                <li id="mobile-menu-item-1532" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="aboutUs()" class=""><span>About
                                            Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1530" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="products()" class=""><span>Products</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1526" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="daicel_group()" class=""><span>Daicel
                                            Group</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1527" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="csr_page()" class=""><span>CSR</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="news_events()" class=""><span>News</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1529" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="career()" class=""><span>Career</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                    class="fa fa-angle-down"></i></span></li>

                                <li id="mobile-menu-item-1531" class="menu-item menu-item-type-post_type menu-item-object-page "><a (click)="contact_us()" class=""><span>Contact
                                            Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-wpml-ls-12-en" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                    <a title="English" class=""><span><img class="wpml-ls-flag"
                                                src="../assets/templates/home/en.png" alt="en" title="English"><span
                                                class="wpml-ls-native">English</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span>
                                    <ul class="sub_menu">
                                        <li id="mobile-menu-item-wpml-ls-12-th" class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                            <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                        src="../assets/templates/home/th.png" alt="th" title="ไทย"><span
                                                        class="wpml-ls-native">ไทย</span></span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                    class="fa fa-angle-down"></i></span></li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
