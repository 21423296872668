<footer>
    <div class="footer_inner clearfix">
        <div class="footer_top_holder">
            <div class="footer_top">
                <div class="container" style="background-color: #2c3137;">
                    <div class="container_inner">
                        <div class="three_columns clearfix">
                            <div class="column1 footer_col1">
                                <div class="column_inner">
                                    <div id="text-2" class="widget widget_text">
                                        <div class="textwidget">
                                            <p><img src="../../../../assets/templates/home/Daicel-White-300x87.png"
                                                    alt="logo" /></p>
                                            <div class="vc_empty_space" style="height: 21px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <p class='font'>บริษัท ไดเซล เซฟตี้ ซีสเต็มส์ (ประเทศไทย) จำกัด (DSST)
                                                ได้รับการจัดตั้งขึ้นโดยมีจุดมุ่งหมายในการเป็นผู้ผลิตชั้นนำและบริษัท
                                                จำหน่ายเครื่องเติมอากาศสำหรับระบบถุงลมนิรภัย
                                                เครื่องกำเนิดไฟฟ้า แก๊สตัวเติมอากาศ (PGG)
                                                สำหรับเข็มขัดนิรภัยและชิ้นส่วนที่เกี่ยวข้อง (Initiators and Coolants)
                                                สำหรับระบบความปลอดภัยของรถยนต์</p>
                                            <div class="vc_empty_space" style="height: 30px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                        </div>
                                    </div><span class='q_social_icon_holder square_social' data-color=#ffffff
                                        data-hover-background-color=#0071be data-hover-color=#ffffff><a itemprop='url'
                                            href='https://www.facebook.com/' target='_blank'><span class='fa-stack '
                                                style='background-color: #42464b;border-radius: 1px;-webkit-border-radius: 1px;-moz-border-radius: 1px;border-width: 0px;margin: 0 3px 0 0;font-size: 20px;'><span
                                                    aria-hidden="true" class="qode_icon_font_elegant social_facebook "
                                                    style="color: #ffffff;font-size: 18px;"></span></span>
                                        </a>
                                    </span><span class='q_social_icon_holder square_social' data-color=#ffffff
                                        data-hover-background-color=#0071be data-hover-color=#ffffff><a itemprop='url'
                                            href='https://www.twitter.com/' target='_blank'><span class='fa-stack '
                                                style='background-color: #42464b;border-radius: 1px;-webkit-border-radius: 1px;-moz-border-radius: 1px;border-width: 0px;margin: 0 3px 0 0;font-size: 20px;'><span
                                                    aria-hidden="true" class="qode_icon_font_elegant social_twitter "
                                                    style="color: #ffffff;font-size: 18px;"></span></span>
                                        </a>
                                    </span><span class='q_social_icon_holder square_social' data-color=#ffffff
                                        data-hover-background-color=#0071be data-hover-color=#ffffff><a itemprop='url'
                                            href='https://plus.google.com/' target='_blank'><span class='fa-stack '
                                                style='background-color: #42464b;border-radius: 1px;-webkit-border-radius: 1px;-moz-border-radius: 1px;border-width: 0px;margin: 0 3px 0 0;font-size: 20px;'><span
                                                    aria-hidden="true" class="qode_icon_font_elegant social_googleplus "
                                                    style="color: #ffffff;font-size: 18px;"></span></span>
                                        </a>
                                    </span><span class='q_social_icon_holder square_social' data-color=#ffffff
                                        data-hover-background-color=#0071be data-hover-color=#ffffff><a itemprop='url'
                                            href='https://www.instagram.com/' target='_blank'><span class='fa-stack '
                                                style='background-color: #42464b;border-radius: 1px;-webkit-border-radius: 1px;-moz-border-radius: 1px;border-width: 0px;margin: 0 3px 0 0;font-size: 20px;'><span
                                                    aria-hidden="true" class="qode_icon_font_elegant social_instagram "
                                                    style="color: #ffffff;font-size: 18px;"></span></span>
                                        </a>
                                    </span><span class='q_social_icon_holder square_social' data-color=#ffffff
                                        data-hover-background-color=#0071be data-hover-color=#ffffff><a itemprop='url'
                                            href='https://www.linkedin.com/' target='_blank'><span class='fa-stack '
                                                style='background-color: #42464b;border-radius: 1px;-webkit-border-radius: 1px;-moz-border-radius: 1px;border-width: 0px;margin: 0 0 0 0;font-size: 20px;'><span
                                                    aria-hidden="true" class="qode_icon_font_elegant social_linkedin "
                                                    style="color: #ffffff;font-size: 18px;"></span></span>
                                        </a>

                                    </span>


                                </div>
                            </div>
                            <div class="column2 footer_col2">
                                <div class="column_inner">
                                    <div id="text-9" class="widget widget_text">
                                        <h5>Daicel Safety Systems Companies</h5>
                                        <div class="textwidget"></div>
                                    </div>
                                    <div id="nav_menu-2" class="widget widget_nav_menu">
                                        <div class="menu-footer-menu-thai-container">
                                            <ul id="menu-footer-menu-thai" class="menu">
                                                <li id="menu-item-1827"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1827">
                                                    <a target="_blank" href="https://www.daicel.com/msd/en/">Japan (HQ
                                                        of
                                                        Safety Systems Business/DSS)</a>
                                                </li>
                                                <li id="menu-item-1828"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1828">
                                                    <a target="_blank" href="http://daicelssa.com/">USA
                                                        (DSSA)</a>
                                                </li>

                                                <li id="menu-item-1830"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1830">
                                                    <a target="_blank" href="../../index.html">Thailand
                                                        (DSST)</a>
                                                </li>

                                                <li id="menu-item-1832"
                                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1832">
                                                    <a target="_blank" href="http://www.daicelsse.com/homepage">Europe
                                                        (DSSE)</a>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column3 footer_col3">
                                <div class="column_inner">
                                    <div id="text-6" class="widget widget_text">
                                        <h5 style="font-family: Sarabun;">ติดต่อเรา</h5>
                                        <div class="textwidget">
                                            <div class="vc_empty_space" style="height: 15px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <span
                                                style="display: inline-block; margin-right: 18px;     margin-left: 6px;">
                                                <div class="q_icon_with_title medium custom_icon_image ">
                                                    <div class="icon_holder " style=" "><img itemprop="image"
                                                            style="margin-top: 10px;"
                                                            src="../../../../assets/templates/home/pr.png" alt=""></div>
                                                    <div class="icon_text_holder" style="">
                                                        <div class="icon_text_inner" style="">
                                                            <h6 class="icon_title" style="color: #ffffff;line-height: 26px!important;
                                                                        font-weight: 400!important;
                                                                        font-family: 'Open Sans', sans-serif!important;
                                                                        ">+66 37270900 &nbsp; &nbsp;<b>( Head Office
                                                                    )</b></h6>
                                                            <p style="color: #ffffff;line-height: 26px!important;
                                                                        font-weight: 400!important;
                                                                        ">241 หมู่ 4, เขตอุตสาหกรรม 304 </p>
                                                            <p style="color: #ffffff;line-height: 26px!important;margin-top: -10px;
                                                                           font-weight: 400!important;
                                                                           ">ปราจีนบุรี 25140 ประเทศไทย</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>


                                            <span
                                                style="display: inline-block; margin-right: 18px;     margin-left: 6px;">
                                                <div class="q_icon_with_title medium custom_icon_image ">
                                                    <div class="icon_holder " style=" "><img itemprop="image"
                                                            style="margin-top: 10px;"
                                                            src="../../../../assets/templates/home/sb.png" alt=""></div>
                                                    <div class="icon_text_holder" style="">
                                                        <div class="icon_text_inner" style="">
                                                            <h6 class="icon_title" style="color: #ffffff;line-height: 26px!important;
                                                                        font-weight: 400!important;
                                                                        font-family: 'Open Sans', sans-serif!important;
                                                                        ">+66 36236318 &nbsp; &nbsp;<b>( Branch Office
                                                                    )</b></h6>
                                                            <p style="color: #ffffff;line-height: 26px!important;
                                                                        font-weight: 400!important;
                                                                        ">76 หมู่ 1, อำเภอพระพุทธบาท</p>
                                                            <p style="color: #ffffff;line-height: 26px!important;margin-top: -10px;
                                                                           font-weight: 400!important;
                                                                           ">สระบุรี 18120 ประเทศไทย</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>

                                            <span
                                                style="display: inline-block; margin-right: 18px;     margin-left: 6px;">
                                                <div class="q_icon_with_title medium custom_icon_image ">
                                                    <div class="icon_holder " style=" "><img itemprop="image" style=""
                                                            src="../../../../assets/templates/home/email-icon.png"
                                                            alt=""></div>
                                                    <div class="icon_text_holder" style="">
                                                        <div class="icon_text_inner" style="">
                                                            <h6 class="icon_title" style="color: #ffffff;line-height: 26px!important;
                                                                        font-weight: 400!important;

                                                                        ">dsstannouncement@dsst.daicel.com</h6>
                                                            <a href="mailto:dsstannouncement@dsst.daicel.com">
                                                                <p style="color: #ffffff;line-height: 26px!important;
                                                                        font-weight: 400!important;
                                                                        ">ส่งข้อความหาเรา</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>

                                            <span
                                                style="display: inline-block; margin-right: 18px;     margin-left: 6px;">
                                                <div class="q_icon_with_title medium custom_icon_image ">
                                                    <div class="icon_holder " style=" "><img itemprop="image" style=""
                                                            src="../../../../assets/templates/home/email-icon.png"
                                                            alt=""></div>
                                                    <div class="icon_text_holder" style="">
                                                        <div class="icon_text_inner" style="">
                                                            <h6 class="icon_title" style="color: #ffffff;line-height: 26px!important;
                                                                        font-weight: 400!important;
                                                                        font-family: 'Sarabun'', sans-serif!important;
                                                                        ">sales.dsst@dsst.daicel.com</h6>
                                                            <a href="mailto:sales.dsst@dsst.daicel.com">
                                                                <p style="color: #ffffff;line-height: 26px!important;
                                                                        font-family: 'Sarabun', sans-serif!important;font-weight: 400!important;
                                                                        ">ส่งข้อความหาเรา เกี่ยวกับผลิตภัณฑ์</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_bottom_holder">
            <div class="container" style="background-color: #0071be;">
                <div class="container_inner">
                    <div class="two_columns_50_50 footer_bottom_columns clearfix">
                        <div class="column1 footer_bottom_column">
                            <div class="column_inner">
                                <div class="footer_bottom">
                                    <div class="textwidget">
                                        <p>© 2018-2019 Daicel Safety Systems Thailand Co., Ltd. All Rights Reserved.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column2 footer_bottom_column">
                            <div class="column_inner">
                                <div class="footer_bottom">
                                    <div class="menu-bottom-footer-menu-thai-container">
                                        <ul id="menu-bottom-footer-menu-thai" class="menu">
                                            <!-- <li id="menu-item-1835" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1835">
                                                <a (click)="privacy()" class="font">นโยบายความเป็นส่วนตัว</a>
                                            </li> -->
                                            <li id="menu-item-1836"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1836">
                                                <a (click)="term()" class="font">ข้อกำหนดการใช้งาน</a>
                                            </li>
                                            <li id="menu-item-1837"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1837">
                                                <a (click)="career()" class="font">ร่วมงานกับเรา</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>