import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { contentModel } from '../../models/content';
import { ContentService } from 'src/app/services/content.service';
import { formatDate } from '@angular/common';
import Swal from "sweetalert2";
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-full-recruit',
  templateUrl: './full-recruit.component.html',
  styleUrls: ['./full-recruit.component.scss']
})
export class FullRecruitComponent implements OnInit {
  public career: contentModel;
  public benefitsAndContacts: contentModel;
  date_now  =  formatDate(new Date(), 'yyyy-MM-dd', 'en')

  @ViewChild("benefitModal") public benefitModal: ModalDirective;
  @ViewChild("contactsModal") public contactsModal: ModalDirective;
  @ViewChild("jobModal") public jobModal: ModalDirective;
  modalRef: BsModalRef;

  @ViewChild('editor') editor;

  constructor(
    private contentService: ContentService,
    public modalService: BsModalService
  ) { }

  async  ngOnInit() {
    alert('**')
    this.career = await this.contentService.findJob().toPromise();
    this.benefitsAndContacts = await this.contentService.findSetting().toPromise();
    this.description = this.benefitsAndContacts[0].content_detail_en
  }

  openModal(template: TemplateRef<any>) {
    // this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' ,ignoreBackdropClick : true})
    );

  }

  public ngAfterViewInit(): void {
    // this.input.nativeElement.focus();
    setTimeout(() => this.editor.nativeElement.focus(), 0);
  }
  description: string = "";

  onChange() {
    console.log(this.editor.nativeElement["innerHTML"]);
  }

  saveBenefits() {
    // alert(this.editor.nativeElement["innerHTML"])
  console.log(this.description)
    // console.log(this.editor.nativeElement["innerHTML"]);
  }


}
