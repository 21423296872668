import { CareerThDetailComponent } from "./views/th/career-th-detail/career-th-detail.component";
import { CoCreationThComponent } from "./views/th/co-creation-th/co-creation-th.component";
import { InflatorComponent } from "./views/en/inflator/inflator.component";
import { PggComponent } from "./views/en/pgg/pgg.component";
import { PyroFuseComponent } from "./views/en/pyro-fuse/pyro-fuse.component";
import { CoCreationComponent } from "./views/en/co-creation/co-creation.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageThComponent } from "./views/th/home-page-th/home-page-th.component";
import { HomePageEnComponent } from "./views/en/home-page-en/home-page-en.component";
import { PrivacyComponent } from "./views/en/privacy/privacy.component";
import { TermsOfUseComponent } from "./views/en/terms-of-use/terms-of-use.component";
import { AboutUsComponent } from "./views/en/about-us/about-us.component";
import { ProductsComponent } from "./views/en/products/products.component";
import { DaicelGroupComponent } from "./views/en/daicel-group/daicel-group.component";
import { CsrComponent } from "./views/en/csr/csr.component";
import { NewsComponent } from "./views/en/news/news.component";
import { ContactUsComponent } from "./views/en/contact-us/contact-us.component";
import { AboutUsThComponent } from "./views/th/about-us-th/about-us-th.component";
import { ProductsThComponent } from "./views/th/products-th/products-th.component";
import { DaicelGroupThComponent } from "./views/th/daicel-group-th/daicel-group-th.component";
import { CsrThComponent } from "./views/th/csr-th/csr-th.component";
import { NewsThComponent } from "./views/th/news-th/news-th.component";
import { ContactUsThComponent } from "./views/th/contact-us-th/contact-us-th.component";
import { PreviewEnComponent } from "./views/en/preview-en/preview-en.component";
import { PreviewThComponent } from "./views/th/preview-th/preview-th.component";
import { YearThComponent } from "./views/th/year-th/year-th.component";
import { YearComponent } from "./views/en/year/year.component";
import { SearchThComponent } from "./views/th/search-th/search-th.component";
import { SearchComponent } from "./views/en/search/search.component";
import { PrivacyThComponent } from "./views/th/privacy-th/privacy-th.component";
import { TermOfUseThComponent } from "./views/th/term-of-use-th/term-of-use-th.component";
import { LoginComponent } from "./admin/login/login.component";

import { ChooseComponent } from "./admin/choose/choose.component";
import { PreviewAdminThComponent } from "./admin/preview-admin-th/preview-admin-th.component";
import { AuthGuard } from "src/app/guards/auth-guard.service";
import { HeaderAdminComponent } from "./admin/header-admin/header-admin.component";

import { CareerComponent } from "./views/en/career/career.component";
import { CareerDetailComponent } from "./views/en/career-detail/career-detail.component";
import { PreviewCareerComponent } from "./admin/preview-career/preview-career.component";
import { CareerEditComponent } from "./admin/career-edit/career-edit.component";
import { PreviewAdminComponent } from "./admin/preview-admin/preview-admin.component";
import { CareerAllComponent } from "./admin/career-all/career-all.component";
import { CareerAddComponent } from "./admin/career-add/career-add.component";
import { CareerThComponent } from "./views/th/career-th/career-th.component";
import { PreloadingComponent } from "./views/preloading/preloading.component";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { FullRecruitComponent } from "./admin/full-recruit/full-recruit.component";
import { InflatorThComponent } from "./views/th/inflator-th/inflator-th.component";
import { PyroFuseThComponent } from "./views/th/pyro-fuse-th/pyro-fuse-th.component";
import { PggThComponent } from "./views/th/pgg-th/pgg-th.component";



const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: "", component: HomePageEnComponent },
  { path: "privacy-policy", component: PrivacyComponent },
  { path: "terms-of-use", component: TermsOfUseComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "products", component: ProductsComponent },
  { path: "products/co-creation", component: CoCreationComponent },
  { path: "products/pyrofuse", component: PyroFuseComponent },
  { path: "products/pgg", component: PggComponent },
  { path: "products/inflator", component: InflatorComponent },

  { path: "th/products/inflator", component: InflatorThComponent },
  { path: "th/products/co-creation", component: CoCreationThComponent },
  { path: "th/products/pyrofuse", component: PyroFuseThComponent },
  { path: "th/products/pgg", component: PggThComponent },

  //  { path: 'daici/actranza-pr', component: As },

  { path: "daicel-group", component: DaicelGroupComponent },
  { path: "csr", component: CsrComponent },
  { path: "news-events", component: NewsComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "career", component: CareerComponent },
  { path: "preview", component: PreviewEnComponent },
  { path: "th/career", component: CareerThComponent },
  { path: "th/preview", component: PreviewThComponent },
  { path: "th", component: HomePageThComponent },
  { path: "th/about-us", component: AboutUsThComponent },
  { path: "th/products", component: ProductsThComponent },
  { path: "th/daicel-group", component: DaicelGroupThComponent },
  { path: "th/csr", component: CsrThComponent },
  { path: "th/news-events", component: NewsThComponent },
  { path: "th/contact-us", component: ContactUsThComponent },
  { path: "th/year", component: YearThComponent },
  { path: "year", component: YearComponent },
  { path: "th/search", component: SearchThComponent },
  { path: "search", component: SearchComponent },
  { path: "th/privacy-policy", component: PrivacyThComponent },
  { path: "th/terms-of-use", component: TermOfUseThComponent },
  { path: "career/detail/:id", component: CareerDetailComponent },
  { path: "th/career/detail/:id", component: CareerThDetailComponent },
  { path: "preload", component: PreloadingComponent },

  {
    path: "career-all",
    component: CareerAllComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "career/create",
    component: CareerAddComponent,
    canActivate: [AuthGuard],
  },
  { path: "login", component: LoginComponent },

  {
    path: "post",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./../app/admin/ir/ir.module").then((m) => m.IrModule),
  },

  {
    path: "career/edit/:id",
    component: CareerEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "preview-save",
    component: PreviewAdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "th/preview-save",
    component: PreviewAdminThComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "preview-career",
    component: PreviewCareerComponent,
    canActivate: [AuthGuard],
  },



  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes),
    QuicklinkModule,
    RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy }),
  ],
  exports: [RouterModule, QuicklinkModule],
})
export class AppRoutingModule {}
