<title>Daicel Thailand | About Us</title>
<header class="has_header_fixed_right scroll_header_top_area  stick menu_bottom scrolled_not_transparent page_header">
    <div class="header_inner clearfix">
        <div class="header_top_bottom_holder">
            <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
                <div class="container">
                    <div class="container_inner clearfix">
                        <div class="header_inner_left">
                            <div class="mobile_menu_button">
                                <span>
                                    <i class="fa fa-bars"></i> </span>
                            </div>
                            <div class="logo_wrapper">
                                <div class="q_logo">
                                    <a itemprop="url" (click)="home()" style="height: 70px; visibility: visible;">
                                        <img itemprop="image" class="normal"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="light"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="dark"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="sticky"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> <img itemprop="image" class="mobile"
                                            src="../assets/templates/home/DSST-Logo-300x70.png" alt="Logo"
                                            style="height: 100%;"> </a>
                                </div>
                            </div>
                            <div class="header_fixed_right_area">
                                <div class="textwidget custom-html-widget">
                                    <div class="vc_empty_space" style="height: 10px"><span class="vc_empty_space_inner">
                                            <span class="empty_space_image"></span>
                                        </span>
                                    </div>

                                    <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image "
                                            style="margin-right: 5px;">
                                            <div class="icon_holder " style="">
                                                <img itemprop="image" style="
                                            margin-top: 7px;
                                            margin-left: 5px;
                                        " src="../assets/templates/home/pr.png" alt="">
                                            </div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                    <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;
                                              font-family: 'Open Sans', sans-serif!important;
                                              "> +66 37270900 &nbsp;<b> ( Head Office ) </b>
                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                "> 241 Moo 4, 304 Ind Park<br>
                                                        Prachinburi 25140 Thailand <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block; margin-right: 15px;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      " src="../assets/templates/home/sb.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                    <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;
                                              font-family: 'Open Sans', sans-serif!important;
                                              ">
                                                        +66 36236318 &nbsp;<b> ( Branch Office ) </b><br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                "> 76 Moo.1, A.Phra Phutthabat<br>
                                                        Saraburi 18120 Thailand <br> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>

                                    <span style="display: inline-block;">
                                        <div class="q_icon_with_title medium custom_icon_image ">
                                            <div class="icon_holder " style=" "><img itemprop="image" style="
                                          margin-top: 7px;
                                          margin-left: 5px;
                                      " src="../assets/templates/home/header-icon-3.png" alt=""></div>
                                            <div class="icon_text_holder" style="">
                                                <div class="icon_text_inner" style="">
                                                    <h6 class="icon_title" style="color: #424242;line-height: 26px!important;
                                              font-weight: 400!important;
                                              font-family: 'Open Sans', sans-serif!important;
                                              "> daicel-dsst.com<br>

                                                    </h6>
                                                    <p style="color: #949494;line-height: 18px!important;
                                                font-family: 'Open Sans', sans-serif!important;font-weight: 400!important;
                                                "> Monday - Friday 08h - 17h<br>Closed on Weekends


                                                        <br>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>


                                </div>
                            </div>
                        </div>
                        <div class="header_menu_bottom">
                            <div class="header_menu_bottom_inner">
                                <div class="main_menu_header_inner_right_holder">
                                    <nav class="main_menu drop_down">
                                        <ul id="menu-main-menu" class="clearfix">
                                            <li id="nav-menu-item-1532"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="aboutUs()" class=""><i class="menu_icon blank fa"></i><span
                                                        style="
                                                          ">About
                                                        Us<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1530"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="products()" class=""><i class="menu_icon blank fa"></i><span
                                                        style="
                                                          ">Products<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1526"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="daicel_group()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                          ">Daicel
                                                        Group<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1527"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="csr_page()" class=""><i class="menu_icon blank fa"></i><span
                                                        style="
                                                          ">CSR<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1529"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="news_events()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                          ">News<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1531"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="career()" class=""><i class="menu_icon blank fa"></i><span
                                                        style="
                                                                  ">Career
                                                        <span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-1531"
                                                class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                                                <a (click)="contact_us()" class=""><i
                                                        class="menu_icon blank fa"></i><span style="
                                                          ">Contact
                                                        Us<span class="underline_dash"></span></span><span
                                                        class="plus"></span></a>
                                            </li>
                                            <li id="nav-menu-item-wpml-ls-12-en"
                                                class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub narrow">
                                                <a title="English" class=""><i class="menu_icon blank fa"></i><span><img
                                                            class="wpml-ls-flag" src="../assets/templates/home/en.png"
                                                            alt="en" title="English"><span class="wpml-ls-native" style="
                                                              ">English</span><span
                                                            class="underline_dash"></span></span><span
                                                        class="plus"></span><span class="qode-featured-icon "
                                                        aria-hidden="true"></span></a>
                                                <div class="second" style="height: 0px;">
                                                    <div class="inner">
                                                        <ul>
                                                            <li id="nav-menu-item-wpml-ls-12-th"
                                                                class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                                                <a title="ไทย" (click)="th()" class=""><i
                                                                        class="menu_icon blank fa"></i><span><img
                                                                            class="wpml-ls-flag"
                                                                            src="../assets/templates/home/th.png"
                                                                            alt="th" title="ไทย"><span
                                                                            class="wpml-ls-native">ไทย</span></span><span
                                                                        class="plus"></span><span
                                                                        class="qode-featured-icon "
                                                                        aria-hidden="true"></span></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div class="header_inner_right">
                                        <div class="side_menu_button_wrapper right">
                                            <div class="header_bottom_right_widget_holder">
                                                <div class="widget_text header_bottom_widget widget_custom_html">
                                                    <div class="textwidget custom-html-widget">
                                                        <div class="vc_row wpb_row section vc_row-fluid "
                                                            style=" text-align:left;">
                                                            <div class=" full_section_inner clearfix">
                                                                <div
                                                                    class="wpb_column vc_column_container vc_col-sm-12">
                                                                    <div class="vc_column-inner ">
                                                                        <div class="wpb_wrapper">


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="side_menu_button">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav class="mobile_menu">
                            <ul id="menu-main-menu-1" class="">
                                <li id="mobile-menu-item-1532"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="aboutUs()" class=""><span>About
                                            Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1530"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="products()" class=""><span>Products</span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1526"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="daicel_group()" class=""><span>Daicel
                                            Group</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1527"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="csr_page()" class=""><span>CSR</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1529"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="news_events()" class=""><span>News</span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-1529"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="career()" class=""><span>Career</span></a><span class="mobile_arrow"><i
                                            class="fa fa-angle-right"></i><i class="fa fa-angle-down"></i></span></li>

                                <li id="mobile-menu-item-1531"
                                    class="menu-item menu-item-type-post_type menu-item-object-page "><a
                                        (click)="contact_us()" class=""><span>Contact
                                            Us</span></a><span class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span></li>
                                <li id="mobile-menu-item-wpml-ls-12-en"
                                    class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-menu-item wpml-ls-first-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item menu-item-has-children  has_sub">
                                    <a title="English" class=""><span><img class="wpml-ls-flag"
                                                src="../assets/templates/home/en.png" alt="en" title="English"><span
                                                class="wpml-ls-native">English</span></span></a><span
                                        class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                            class="fa fa-angle-down"></i></span>
                                    <ul class="sub_menu">
                                        <li id="mobile-menu-item-wpml-ls-12-th"
                                            class="menu-item wpml-ls-slot-12 wpml-ls-item wpml-ls-item-th wpml-ls-menu-item wpml-ls-last-item menu-item-type-wpml_ls_menu_item menu-item-object-wpml_ls_menu_item ">
                                            <a title="ไทย" (click)="th()" class=""><span><img class="wpml-ls-flag"
                                                        src="../assets/templates/home/th.png" alt="th" title="ไทย"><span
                                                        class="wpml-ls-native">ไทย</span></span></a><span
                                                class="mobile_arrow"><i class="fa fa-angle-right"></i><i
                                                    class="fa fa-angle-down"></i></span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>

</header>
<a id="back_to_top" href="#" class="off">
    <span class="fa-stack">
        <i class="qode_icon_font_awesome fa fa-arrow-up "></i> </span>
</a>


<div class="content content_top_margin_none">
    <div class="content_inner  ">
        <div class="title_outer title_without_animation" data-height="250">
            <div class="title title_size_small  position_left  has_fixed_background "
                style="background-size:1920px auto;background-image:url(../../../../assets/templates/home/BG-E-1920x600-B60.jpg);height:250px;">
                <div class="image not_responsive"><img itemprop="image"
                        src="../../../../assets/templates/home/BG-E-1920x600-B60.jpg" alt="&nbsp;" /> </div>
                <div class="title_holder" style="padding-top:0;height:250px;">
                    <div class="container">
                        <div class="container_inner clearfix">
                            <div class="title_subtitle_holder">
                                <div class="title_subtitle_holder_inner">
                                    <h1><span>About Us</span></h1>

                                    <div class="breadcrumb" style="background-color: transparent;">
                                        <div class="breadcrumbs">
                                            <div itemprop="breadcrumb"><a (click)="home()">Home</a><span
                                                    class="delimiter">&nbsp;>&nbsp;</span><span class="current">About
                                                    Us</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="full_width">
            <div class="full_width_inner">
                <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518017477874 grid_section"
                    style=' text-align:left;background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;'>
                    <div class=" section_inner clearfix">
                        <div class='section_inner_margin clearfix'>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">
                                            <div class="wpb_wrapper">
                                                <br><br><br><br><br>
                                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                    <img style="width:600 ;"
                                                        src="../../../../assets/templates/home/DSST-Logo-bg.png" alt="">
                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 32px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <p style="text-align: center;">Daicel Corporation is major
                                                        manufacturer of safety systems (Inflator and Pretensioner Gas
                                                        Generator (PGG))and related components like an initiator and
                                                        coolant in the world.</p>

                                                </div>
                                            </div>
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <p style="text-align: center;">We DSST, has been established as
                                                        second overseas business base for Inflator in 2002 and for
                                                        Initiator in 2005.
                                                        <br> We have market territory whole Asian except Japan, China
                                                        Taiwan and Korea in Daicel group.

                                                    </p>
                                                    <p style="text-align: center;">Our company have the
                                                        slogan<strong><span style="color: #0071be;">&#8220;We Save
                                                                Lives&#8221;</span></strong>, our team members are
                                                        endeavoring to supply the most competitive product with the
                                                        highest safety / quality in the market.
                                                        It is our great honor and pleasure to establish our business in
                                                        Thailand, and to experience the contributions of its highly
                                                        skilled
                                                        and motivated people as we achieve continued growth and
                                                        development through our environment-friendly product.
                                                    </p>

                                                </div>
                                            </div>

                                            <br>
                                            <div
                                                class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1510369127181">
                                                <div class="wpb_wrapper">

                                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                        <img width="1920" height="789"
                                                            src="../../../../assets/templates/home/About-Aerial-A-1920x780.jpg"
                                                            class="vc_single_image-img attachment-full" alt=""
                                                            srcset="../../../../assets/templates/home/About-Aerial-A-1920x780.jpg 1920w,
                                                    ../../../../assets/templates/home/About-Aerial-A-1920x780-300x123.jpg 300w,
                                                    ../../../../assets/templates/home/About-Aerial-A-1920x780-768x316.jpg 768w,
                                                    ../../../../assets/templates/home/About-Aerial-A-1920x780-1024x421.jpg 1024w"
                                                            sizes="(max-width: 1920px) 100vw, 1920px" />
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="vc_empty_space" style="height: 64px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                style=' text-align:left;'>
                                                <div class=" full_section_inner clearfix">
                                                    <div class="wpb_column vc_column_container vc_col-sm-4">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h6 style="text-align: center;">
                                                                            President Of DSST </h6>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 7px">
                                                                    <span class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h2 style="text-align: center;">
                                                                            President&#8217;s Message</h2>

                                                                    </div>
                                                                </div>
                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <p>Our Companies, Daicel Safety Systems
                                                                            (Thailand) Co., Ltd. (DSST) have been
                                                                            established with the aim of becoming a
                                                                            leading manufacturer
                                                                            and sales company of inflators for airbag
                                                                            systems, Pretensioner Gas Generators (PGG)
                                                                            for seat belts and related components
                                                                            (Initiators, Coolants and Gas Generant) for
                                                                            automotive safety
                                                                            systems.
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 7px">
                                                                    <span class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <p>With our slogan <span
                                                                                style="color: #0071be;"><strong>“We
                                                                                    Save Lives”</strong></span> our team
                                                                            members are endeavoring to supply the most
                                                                            competitive products with the highest
                                                                            quality in the market. It is our great honor
                                                                            and pleasure
                                                                            to establish our business in Thailand, and
                                                                            to experience the contributions of its
                                                                            highly skilled and motivated people as we
                                                                            achieve continuous growth and development
                                                                            through our environmentally
                                                                            friendly products.
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="wpb_column vc_column_container vc_col-sm-8">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="vc_empty_space" style="height: 48px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>

                                                                <div
                                                                    class="wpb_gallery wpb_content_element vc_clearfix">
                                                                    <div class="wpb_wrapper">
                                                                        <div class="wpb_gallery_slides drag_enabled wpb_flexslider flexslider_fade flexslider"
                                                                            data-interval="3" data-flex_fx="fade"
                                                                            data-direction="true" data-drag="true">
                                                                            <ul class="slides">
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic2.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic2.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic2-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic2-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic3.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic3.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic3-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic3-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic4.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic4.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic4-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic4-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic5.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic5.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic5-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic5-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic6.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic6.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic6-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic6-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/About-856x373-Pic7.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/About-856x373-Pic7.jpg 856w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic7-300x131.jpg 300w,
                                                                                ../../../../assets/templates/home/About-856x373-Pic7-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br>
                    </div>



                    <section data-speed="1" class="parallax_section_holder  "
                        style=" height:450px; background-image:url('../../../../assets/templates/home/BG-A-1920x1080-B70.jpg');">
                        <div class="parallax_content_full_width center">
                            <div class='parallax_section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="vc_empty_space" style="height: 128px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h2><span style="color: #ffffff;">Vision</span></h2>

                                                </div>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper" style="padding: 20px;">
                                                    <h2 style="text-align: center;"><span
                                                            style="color: #ffffff;"><em>&#8220;Our vision is to
                                                                be strong,<br />
                                                                reliable and recognizable provider of innovative
                                                                safety solutions.&#8221;</em></span></h2>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 128px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1507498988322 grid_section"
                        style='background-image:url(../../../../assets/templates/home/map.jpg); text-align:left;'>
                        <br><br>

                        <div class=" section_inner clearfix">
                            <div class='section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-6">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h6>Mission to Success</h6>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 7px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h2>We must profitably achieve our vision by:</h2>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 21px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class=ordered>

                                                <ol>
                                                    <li>Maintaining a safety and high quality, productive, enjoyable
                                                        work environment.</li>
                                                    <li>Attracting and retaining well-qualified employees.</li>
                                                    <li>Improving knowledge of manufacturing and technology to achieve
                                                        process standardization.</li>
                                                    <li>Developing and communicating a long term business plan that
                                                        aligns with the DC.</li>
                                                    <li>Acquiring ISO/TS16949 and ISO 14001 to strengthen our core
                                                        business and improving and expanding sales to targeted customers
                                                        in creating competitive advantages.
                                                    </li>
                                                </ol>
                                                <p>
                                            </div>
                                            <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                style=' text-align:left;'>
                                                <div class=" full_section_inner clearfix">
                                                    <div class="wpb_column vc_column_container vc_col-sm-6">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div
                                                                    class="wpb_single_image wpb_content_element vc_align_center">
                                                                    <div class="wpb_wrapper">

                                                                        <div
                                                                            class="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img width="600" height="600"
                                                                                src="../../../../assets/templates/home/ISO 14001.jpg"
                                                                                class="vc_single_image-img attachment-full"
                                                                                alt=""
                                                                                srcset="
                                                                                ../../../../assets/templates/home/ISO 14001.jpg 600w, 
                                                                                ../../../../assets/templates/home/ISO 14001.jpg 150w,
                                                                                ../../../../assets/templates/home/ISO 14001.jpg300w,
                                                                                ../../../../assets/templates/home/ISO 14001.jpg 100w"
                                                                                sizes="(max-width: 600px) 100vw, 600px" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="wpb_column vc_column_container vc_col-sm-6">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div
                                                                    class="wpb_single_image wpb_content_element vc_align_center">
                                                                    <div class="wpb_wrapper">

                                                                        <div
                                                                            class="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img width="600" height="600"
                                                                                src="../../../../assets/templates/home/IATF 16949.jpg"
                                                                                class="vc_single_image-img attachment-full"
                                                                                alt=""
                                                                                srcset="../../../../assets/templates/home/IATF 16949.jpg 600w,
                                                                                ../../../../assets/templates/home/IATF 16949.jpg 150w,
                                                                                ../../../../assets/templates/home/IATF 16949.jpg 300w, 
                                                                                ../../../../assets/templates/home/IATF 16949.jpg 100w"
                                                                                sizes="(max-width: 600px) 100vw, 600px" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="wpb_column vc_column_container vc_col-sm-6">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h6>Values</h6>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 7px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h2>We must achieve our critical success factors by following these
                                                        values</h2>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 21px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span class='progress_title'><span>Safety &amp; High
                                                            quality</span></span><span class='progress_number'
                                                        style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span class='progress_title'><span>Customer
                                                            Satisfaction</span></span><span class='progress_number'
                                                        style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span class='progress_title'><span>Continuous
                                                            Improvement</span></span><span class='progress_number'
                                                        style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span class='progress_title'><span>Corporate
                                                            Citizenship</span></span><span class='progress_number'
                                                        style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 13px;'>
                                                    <span
                                                        class='progress_title'><span>Empowerment/Accountability</span></span><span
                                                        class='progress_number' style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                            <div class='q_progress_bar'>
                                                <h5 class='progress_title_holder clearfix' style='font-size: 14px;'>
                                                    <span class='progress_title'><span>Mutual
                                                            Respect</span></span><span class='progress_number'
                                                        style=''><span>0</span>%</span>
                                                </h5>
                                                <div class='progress_content_outer' style=''>
                                                    <div data-percentage='100' class='progress_content' style=''></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br>
                    </div>



                    <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518017917058 grid_section"
                        style=' text-align:left; background-color: white; padding: 70px;'>
                        <div class=" section_inner clearfix">
                            <div class='section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                style=' text-align:left;'>
                                                <div class=" full_section_inner clearfix">
                                                    <div class="wpb_column vc_column_container vc_col-sm-4">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h6>Manufacturing Highlight</h6>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 7px">
                                                                    <span class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h2>Flexible Production System</h2>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 21px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <p>Flexible production system and its management
                                                                            system have been established to comply with
                                                                            the industry&#8217;s specific supply system
                                                                            and meet the customer&#8217;s diverse
                                                                            requirements.</p>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 32px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <h2>For Ultimate Reliability</h2>

                                                                    </div>
                                                                </div>
                                                                <div class="vc_empty_space" style="height: 21px"><span
                                                                        class="vc_empty_space_inner">
                                                                        <span class="empty_space_image"></span>
                                                                    </span>
                                                                </div>


                                                                <div class="wpb_text_column wpb_content_element ">
                                                                    <div class="wpb_wrapper">
                                                                        <p>Reliability tests under severe conditions are
                                                                            performed to verify the highest reliability
                                                                            required for the inflators : we serve
                                                                            customers through a total system from design
                                                                            to evaluation.</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="wpb_column vc_column_container vc_col-sm-8">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div
                                                                    class="wpb_gallery wpb_content_element vc_clearfix">
                                                                    <div class="wpb_wrapper">
                                                                        <div class="wpb_gallery_slides drag_enabled wpb_flexslider flexslider_fade flexslider"
                                                                            data-interval="3" data-flex_fx="fade"
                                                                            data-direction="true" data-drag="true">
                                                                            <ul class="slides">
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/6A-856x373.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/6A-856x373.jpg 856w,
                                                                                    ../../../../assets/templates/home/6A-856x373-300x131.jpg 300w,
                                                                                    ../../../../assets/templates/home/6A-856x373-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                                <li><img width="856" height="373"
                                                                                        src="../../../../assets/templates/home/5.2-856x373.jpg"
                                                                                        class="attachment-full" alt=""
                                                                                        srcset="../../../../assets/templates/home/5.2-856x373.jpg 856w,
                                                                                    ../../../../assets/templates/home/5.2-856x373-300x131.jpg 300w,
                                                                                    ../../../../assets/templates/home/5.2-856x373-768x335.jpg 768w"
                                                                                        sizes="(max-width: 856px) 100vw, 856px" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section data-speed="1" class="parallax_section_holder  "
                        style=" height:450px; background-image:url('../../../../assets/templates/home/BG-A-1920x1080-B70.jpg');">
                        <div class="parallax_content_full_width center">
                            <div class='parallax_section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="vc_empty_space" style="height: 128px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class="vc_row wpb_row section vc_row-fluid vc_inner  grid_section"
                                                style=' text-align:center;'>
                                                <div class=" section_inner clearfix">
                                                    <div class='section_inner_margin clearfix'>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="q_counter_holder  center" style=""><span
                                                                            class="counter  zero"
                                                                            style="color: #ffffff;font-size: 72px;">2002</span><span
                                                                            class="separator small" style=""></span>
                                                                        <p class="counter_text"
                                                                            style="font-size: 24px;text-transform: capitalize;color: #ffffff;">
                                                                            DSST Year<br /> Established
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="q_counter_holder  center" style=""><span
                                                                            class="counter  zero"
                                                                            style="color: #ffffff;font-size: 72px;">1300</span><span
                                                                            class="separator small" style=""></span>
                                                                        <p class="counter_text"
                                                                            style="font-size: 24px;text-transform: capitalize;color: #ffffff;">
                                                                            DSST Total Employees</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 128px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1518014855017 grid_section" style=' text-align:left;background-image: url(../../../../assets/templates/home/Daicel-History-BG-A20-1920x2160.jpg?id=1685) !important;
'>
                        <div class=" section_inner clearfix">
                            <div class='section_inner_margin clearfix'>
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner vc_custom_1507483902096">
                                        <div class="wpb_wrapper">
                                            <div class="vc_empty_space" style="height: 72px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h6 style="text-align: center;">DSST</h6>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 7px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>


                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h2 style="text-align: center;">History</h2>

                                                </div>
                                            </div>
                                            <div class="vc_empty_space" style="height: 16px"><span
                                                    class="vc_empty_space_inner">
                                                    <span class="empty_space_image"></span>
                                                </span>
                                            </div>

                                            <div class="qode-workflow  qode-workflow-animate">
                                                <span class="main-line" style="background-color:#009be6;"></span>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2001-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2001-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2001-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2001</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">JUN : First arrival in Thailand to survey
                                                                business opportunities of safety devices.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2002-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2002-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2002-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2002</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">MAY: Start of INFLATOR business., SEP:
                                                                Daicel Safety Systems (Thailand) Co., Ltd. (DSST)
                                                                established, (Bangkok office)., NOV: Ground Breaking
                                                                Ceremony in Prachinburi Province (304 Industrial Park).
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2003-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2003-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2003-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2003</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">MAY : DSST move from Bangkok to 304
                                                                Industrial Park., SEP : Process verification Operation
                                                                Test</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2004-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2004-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2004-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2004</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">MAR: Start of commercial operation of
                                                                INFLATOR line no.1 (T1/DSST)., MAY: Start of INITIATOR
                                                                business., SEP: Start of INFLATOR production line No.2
                                                                (T2/DSST).</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2005-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2005-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2005-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2005</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">JAN: DSST acquired ISO 9001., APR : DSST
                                                                acquired ISO/TS 16949., AUG : Daicel Safety Technologies
                                                                (Thailand) Co., Ltd. (DSTT) established INITIATOR
                                                                business</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2006-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2006-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2006-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2006</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">MAY: Start of commercial operation of
                                                                INITIATOR (DSTT)., JUN: Start of Coolant business
                                                                (DSST)., OCT: Start of INFLATOR additional 2 lines
                                                                (T3,T4/DSST)., DEC: DSTT acquired ISO 9001:2000.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2007-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2007-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2007-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2007</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">MAR: Acquired ISO 14001 (DSST/DSTT).
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2009-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2009-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2009-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2009</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">JUL: Start of PGG business (DSTT)., MAY:
                                                                DSTT acquired ISO/TS 16949 : 2002.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2012-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2012-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2012-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2012</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">JUN: Start of INFLATOR production line no.5
                                                                (T5/DSST)., MAY : Start of INITIATOR new Generation
                                                                (DG2) operation (DSTT).</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2013-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2013-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2013-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2013</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">JUN: Start of INFLATOR production line no.6
                                                                (T6/DSST)., JUL: Start of new Mixing #3 operation
                                                                (DSTT)., DEC: Start of INFLATOR production line no.7
                                                                (T7/DSST).</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner ">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2014-Left-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2014-Left-500x60.png 500w,
                                        ../../../../assets/templates/home/2014-Left-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2014</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text"> AUG: INFLATOR building expansion with
                                                                future support to production line increase (Inflator
                                                                Factory DSST).</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="qode-workflow-item">
                                                    <span class="line" style="background-color:#009be6;"></span>
                                                    <div class="qode-workflow-item-inner reverse">
                                                        <div class="qode-workflow-image left">
                                                            <img width="500" height="60"
                                                                src="../../../../assets/templates/home/2016-Right-500x60.png"
                                                                class="attachment-full size-full" alt="" srcset="../../../../assets/templates/home/2016-Right-500x60.png 500w,
                                        ../../../../assets/templates/home/2016-Right-500x60-300x36.png 300w"
                                                                sizes="(max-width: 500px) 100vw, 500px" />
                                                        </div>
                                                        <div class="qode-workflow-text">
                                                            <span class="circle"
                                                                style="background-color:#009be6;"></span>
                                                            <h3>2016</h3>
                                                            <h6 class="qode-workflow-subtitle">Histories</h6>
                                                            <p class="text">FEB: Start of INFLATOR production lines no.9
                                                                (T9/DSST)., MAR: Start of Gas Generant business (GGT1,
                                                                GGT2/DSST)., JUL: Inflator Factory #2 construction
                                                                (DSST)., AUG: Start of EL Inflator with production
                                                                lines no.8 as first global production site (T8/DSST).
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br><br><br>
                    </div>


                </div>
            </div>

        </div>
    </div>
    <div style="background-color: white;">
        <app-slide-footer></app-slide-footer>
    </div>

    <app-footer></app-footer>