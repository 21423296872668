<div *ngIf="showContent">
    <div id="source-frame">
        <ul class="source-list">

            <!-- 1. Rotating Plane -->
            <li>
                <textarea readonly class="html-code">
<div class="spinner"></div>
</textarea>
                <textarea readonly class="css-code">
.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg);
  };
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  };
}
</textarea>
            </li>


        </ul>
    </div>


    <div id="preview-area">
        <ul id="spinners">

            <li class="selected">
                <div class="rotating-plane"></div>
            </li>

        </ul>
    </div>

</div>