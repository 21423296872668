import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ContentService } from 'src/app/services/content.service';
import { LocalStorageService } from '../ir/local-storage.service';
import { contentModel } from "src/app/models/content";

@Component({
  selector: 'app-preview-admin',
  templateUrl: './preview-admin.component.html',
  styleUrls: ['./preview-admin.component.css']
})
export class PreviewAdminComponent implements OnInit {
  public content1: contentModel;
  data: [];
  imgHeader: string = '';
  imgAll: [];
  imgAll1: Array<object> = [];
  imgAll2: Array<object> = [];
  title: string = '';
  detail: string = '';
  cnum = 0;
  dateActivity: string = '';
  datePublish: string = '';
  comNews: string = '';
  csr: string = '';
  event: string = '';
  type: string = '';
  post_by: string = '';
  recent: [];
  node_static_url = environment.backendUrl;
  year: string[] = [];
  startMonth: number[] = [];
  endMonth: number[] = [];
  month: string[] = [];
  mlength: number[] = [];
  content: object = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private rest: ContentService,
    public storage: LocalStorageService,
  ) { }


  async ngOnInit() {
    this.content = {}
    this.content = this.storage.get("user_object");

    // this.route.queryParams.subscribe((params) => {
    //   this.type = params.type

    //   this.comNews = params.comNews
    //   this.csr = params.csr
    //   this.event = params.event
    //   this.dateActivity = params.dateActivity
    //   this.datePublish = params.datePublish
    //   this.imgHeader = params.imgHeader
    //   this.imgAll = params.imgAll
    //   this.post_by = 'admin'

    // })

    this.recent = await this.rest.getRecent().toPromise();
    // this.content.imgName.forEach((e) => {
    //   this.imgAll.push(this.node_static_url +e);
    // });

  const start = await this.rest.getStartYear().toPromise();
    const end = await this.rest.getEndYear().toPromise();
    var yearAll = parseInt(end[0]) - parseInt(start[0]) + 1;

    var m: string[];
    m = await this.rest.getMonth().toPromise();
    for (let i = 0; i < yearAll; i++) {
      this.year.push((parseInt(start[0]) + i).toString());
    }

    this.month.push("January");
    this.month.push("February");
    this.month.push("March");
    this.month.push("April");
    this.month.push("May");
    this.month.push("June");
    this.month.push("July");
    this.month.push("August");
    this.month.push("September");
    this.month.push("October ");
    this.month.push("November");
    this.month.push("December");


    // this.title = params.titleEn
    // this.detail = res.content_detail_en
    // this.datePublish = res.published_date
    // const v = await this.rest.getImageById(this.id).toPromise();
    // this.imgHeader = this.node_static_url + "images/" + v.img_header
    // this.comNews = res.com_news
    // this.csr = res.csr
    // this.event = res.events
    // this.imgAll = v.img_all

    this.recent = await this.rest.getRecent().toPromise();


  }

  aboutUs() {
    window.location.href = '/about-us';
  }
  products() {
    window.location.href = '/products';
  }
  daicel_group() {
    window.location.href = '/daicel-group';
  }
  csr_page() {
    window.location.href = '/csr';
  }
  news_events() {
    window.location.href = '/news-events';
  }
  contact_us() {
    window.location.href = '/contact-us';

  }
  career() {
    window.location.href = '/career';

  }

  home() {
    window.location.href = '/home';
  }

  preview(data) {

    window.location.href = '/preview?id=' + data;
  }

  async onSearch(keyword) {

    window.location.href = '/search?keyword=' + keyword;
  }

  go(j, item) {

    window.location.href = '/year?month=' + j + '&year=' + item


  }
  th() {
    window.location.href = "/th/preview-save";

  }


}
