
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContentService } from 'src/app/services/content.service';
import { Component, OnInit,ElementRef, ViewChild} from '@angular/core';
import { ReCaptcha2Component } from 'ngx-captcha';

import Swal from 'sweetalert2'
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  lat: number = 52.358;
  lng: number = 4.916;
   public recapcha : string;
   constructor(private formBuilder: FormBuilder,private mailService:ContentService) {
     this.createContactForm();
   }
 
   ngOnInit(): void {
 
   }
 
   contactForm: FormGroup;
   async onSubmit() {
 
    let a = await this.mailService.sendMail(this.contactForm.value).toPromise();
   // if(a=='success'){
     
    //  this.recapcha = this.mailService.get_capcha()
    //  if(this.recapcha=='success' && this.contactForm.value.fullName!='' && this.contactForm.value.email!=''&& this.contactForm.value.message!=''){
     Swal.fire({
       title: 'Send email successfully',
       text: 'Click close button to back to the Post page',
       icon: 'success',
       showCancelButton: false,
       confirmButtonText: "Close",
       confirmButtonColor: "#3085d6",
     }).then(result => {
       this.mailService.remove_capcha()
      location.reload()
     });
  //  }
   // }
   
 
 }
   createContactForm(){
     this.contactForm = this.formBuilder.group({
       fullName: [''],  
       email: [''],
       message: ['']
     });
   }
 


  career() {
    window.location.href = '/career';
   

  }
  aboutUs() {
    window.location.href='/about-us';  
   
  }
  products(){
    window.location.href='/products';  
   
  }
  daicel_group(){
    window.location.href='/daicel-group';
     
  }
  csr_page(){
    window.location.href='/csr'; 
       
  }
  news_events(){
    window.location.href='/news-events';  
   
  }
  contact_us(){
    window.location.href='/contact-us';
   

  }
  th(){
    window.location.href='/th/contact-us';
   

  }
  home () {
    window.location.href='/';
   
  }


}
