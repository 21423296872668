import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  selector: "app-choose-product",
  templateUrl: "./choose-product.component.html",
  styleUrls: ["./choose-product.component.scss"],
})
export class ChooseProductComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scrolled = 0;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event) {
    const numb = window.scrollY;
    if (numb >= 50) {
      this.scrolled = 1;
    } else {
      this.scrolled = 0;
    }
  }
}
