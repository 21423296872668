<title>Daicel Thailand | Add Career</title>
<app-header-admin></app-header-admin>
<br />
<div class="container-fluid">
    <div class="view view-cascade gradient-card-header blue-gradient narrower py-2 mx-6 mb-2 d-flex justify-content-between align-items-center" style="margin-left: 25px; width: 96%; height: 90px;">
        <div class="text-center"></div>
        <h1 class="white-text mx-1" style="    font-weight: 700;
    font-size: 25px;font-family: sarabun;">
            ADD NEW CAREER
        </h1>
        <div>
            <a (click)="previewEN()" class="btn btn-outline-white btn-sm" style="font-size: 14px !important; font-weight: 500;font-family: sarabun;">
                <i class="fa fa-eye" aria-hidden="true"></i> Preview
            </a>

            <button (click)="save()" type="button" class="btn btn-amber btn-sm" style="width: 120px; font-size: 14px !important; color: white; font-weight: 500;font-family: sarabun;">
        <i class="fa fa-save" aria-hidden="true"></i> SAVE
      </button>

            <button (click)="save_post()" type="button" class="btn btn-white btn-sm" style="color: #274c87;
      font-size: 14px;
      font-weight: 700;font-family: sarabun; margin-right: 20px;">
    <i class="fa fa-eye" aria-hidden="true"></i> SAVE & POST
    </button>
        </div>
    </div>

    <div class="px-4">
        <div [formGroup]="form">
            <div class="row">
                <div class="col-md-12">
                    <div class="card" style="font-size: 14px !important;">
                        <div class="card-header">
                            <div class="card-title" style="font-family: sarabun;    color: black;font-weight: bold;">ABOUT JOB</div>
                        </div>
                        <div class="card-body pb-0">
                            <div class="row" style="padding-left: 3%;">
                                <div class="col-1">
                                    <label for=""></label>
                                </div>
                                <div class="col-5">
                                    <label for="">POSITION</label>
                                </div>
                                <div class="col-1">
                                    <label for="">VACANT</label>
                                </div>

                                <div class="col-5">
                                    <label for="">APPLICATION LINK</label>
                                </div>

                            </div>

                            <div class="row" style="padding-left: 3%;">
                                <div class="col-1">
                                    <form>
                                        <div class="form-check">

                                            <input type="checkbox" style="    width: 20px;
                        height: 20px;" class="form-check-input" id="materialUnchecked" name="materialExampleRadios" [(ngModel)]="template" />
                                            <div style="font-weight: bold;color:red;font-size: 14px;
                     ">
                                                <span>Urgent</span>
                                            </div>
                                        </div>


                                    </form>
                                </div>
                                <div class="col-5">
                                    <input style="font-size: 14px !important;" type="text" class="form-control" name="titleEn" formControlName="titleEn" [ngClass]="{ 'is-invalid': submitted && f.titleEn.errors }" />
                                    <div *ngIf="submitted && f.titleEn.errors" class="invalid-feedback">
                                        <div *ngIf="f.titleEn.errors.required">Position is required</div>
                                    </div>


                                </div>
                                <div class="col-1">
                                    <input style="font-size: 14px !important" type="number" class="form-control" name="num_position" formControlName="num_position" [(ngModel)]="num_position" [ngClass]="{ 'is-invalid': submitted && f.num_position.errors }" />
                                    <div *ngIf="submitted && f.num_position.errors" class="invalid-feedback">
                                        <div *ngIf="f.num_position.errors.required">Vacant is required</div>
                                    </div>

                                </div>
                                <div class="col-5">
                                    <input style="font-size: 14px !important;" type="text" class="form-control" name="titleTh" formControlName="titleTh" [ngClass]="{ 'is-invalid': submitted && f.titleTh.errors }" />
                                    <div *ngIf="submitted && f.titleTh.errors" class="invalid-feedback">
                                        <div *ngIf="f.titleTh.errors.required">Application Link is required</div>
                                    </div>

                                </div>


                            </div>


                            <br>
                            <div class="row" style="padding-left: 3%;">
                              <div class="col-1">
                                <label for=""></label>
                            </div>
                              <div class="col-5">
                                <label for="">LOCATION</label>
                            </div>
                            </div>
                            <div class="row" style="padding-left: 3%;">
                              <div class="col-1">
                                <label for=""></label>
                            </div>
                              <div class="col-5">
                                <ng-select [items]="cities"
                                bindLabel="name"
                                placeholder="Select Location"
                                name="selectedLocation" formControlName="selectedLocation"
                               >
                     </ng-select>
                            </div>
                            </div>

                            <div class="separator-dashed"></div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="card" style="font-size: 14px !important;">
                        <div class="card-header">
                            <div class="card-title" style="font-family: sarabun;    color: black;font-weight: bold;">JOB DESCRIPTION</div>
                        </div>
                        <div class="card-body pb-0">
                            <form #formRef="ngForm">
                                <table mdbTable striped="true">

                                    <tbody>
                                        <tr mdbTableCol *ngFor="let word2 of words1; let in=index">
                                            <td scope="row">{{in + 1}}.</td>
                                            <td style="width: 100vh">
                                                <input type="text" [(ngModel)]="words1[in].values" name="name{{in}}" class="form-control" #name="ngModel" required />
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                            </form>

                        </div>



                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card" style="font-size: 14px !important;">
                        <div class="card-header">
                            <div class="card-title" style="font-family: sarabun;    color: black;font-weight: bold;">QUALIFICATIONS</div>
                        </div>
                        <div class="card-body pb-0">

                            <div class="row">
                                <form #formRef="ngForm">
                                    <table mdbTable striped="true">

                                        <tbody>
                                            <tr mdbTableCol *ngFor="let word2 of words2; let in=index">
                                                <td scope="row">{{in + 1}}.</td>
                                                <td style="width: 100vh;">
                                                    <input type="text" [(ngModel)]="words2[in].values" name="name{{in}}" class="form-control" #name="ngModel" required />
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>

                                </form>





                            </div>


                        </div>
                    </div>
                </div>





            </div>
        </div>
    </div>
</div>
