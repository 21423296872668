import { Component, OnInit } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  Route,
  NavigationExtras,
} from "@angular/router";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";
import { ContentService } from "src/app/services/content.service";
import { ImagesService } from "src/app/services/images.service";
@Component({
  selector: "app-preview-en",
  templateUrl: "./preview-en.component.html",
  styleUrls: ["./preview-en.component.css"],
})
export class PreviewEnComponent implements OnInit {
  data: [];
  id: string;
  imgHeader: string = "";
  imgAll: [];
  imgAll1: Array<object> = [];
  imgAll2: Array<object> = [];
  title: string = "";
  detail: string = "";
  typeContent: string = "";

  dateActivity: string = "";
  datePublish: string = "";
  comNews: string = "";
  csr: string = "";
  event: string = "";
  type: string = "";
  mobile = false;
  recent: [];
  node_static_url: string = environment.backendUrl;
  year: string[] = [];
  startMonth: number[] = [];
  endMonth: number[] = [];
  month: string[] = [];
  mlength: number[] = [];
  link = environment.path;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private rest: ContentService,
    private imageService: ImagesService
  ) {}

  async ngOnInit() {
    if (window.screen.width < 640) {
      // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    if (this.rest.get_reload() != null) {
      location.reload();
      this.rest.remove_reload();
    }
    window.scroll(0, 0);
    this.route.queryParams.subscribe(async (params) => {
      this.id = params.id;
    });
    const start = await this.rest.getStartYear().toPromise();
    const end = await this.rest.getEndYear().toPromise();
    var yearAll = parseInt(end[0]) - parseInt(start[0]) + 1;

    var m: string[];
    m = await this.rest.getMonth().toPromise();
    for (let i = 0; i < yearAll; i++) {
      this.year.push((parseInt(start[0]) + i).toString());
    }

    this.month.push("January");
    this.month.push("February");
    this.month.push("March");
    this.month.push("April");
    this.month.push("May");
    this.month.push("June");
    this.month.push("July");
    this.month.push("August");
    this.month.push("September");
    this.month.push("October ");
    this.month.push("November");
    this.month.push("December");

    const res = await this.rest.findById(this.id).toPromise();
    this.title = res.event_name_en;
    this.detail = res.content_detail_en;
    this.datePublish = res.published_date;
    const v = await this.imageService
      .findByIdHeader(res.event_id + "")
      .toPromise();
    this.imgHeader = this.node_static_url + v["img_header"];
    this.comNews = res.com_news + "";
    this.csr = res.csr + "";
    this.event = res.events + "";
    const z = await this.imageService
      .findByIdAll(res.event_id + "")
      .toPromise();
    this.imgAll = z["img_all"];

    this.recent = await this.rest.getRecent().toPromise();
    this.setTag();
    this.setData();
  }

  setData() {
    let devide = Math.ceil(this.imgAll.length / 2);
    let col1 = devide;
    let col2 = this.imgAll.length - devide;
    for (var i = 0; i < col1; i++) {
      this.imgAll1.push(this.imgAll[i]);
    }
    for (var i = col1; i < col1 + col2; i++) {
      this.imgAll2.push(this.imgAll[i]);
    }
  }
  setTag() {
    if (this.comNews == "1") {
      this.comNews = "NEWS ";
      this.typeContent = "NEWS ";
    }
    if (this.csr == "1") {
      this.csr = "CSR ";
      this.typeContent = "CSR ";
    }
    if (this.event == "1") {
      this.event = "EVENT ";
      this.typeContent = "EVENT ";
    }
    if (this.comNews == "0") {
      this.comNews = "";
    }
    if (this.csr == "0") {
      this.csr = "";
    }
    if (this.event == "0") {
      this.event = "";
    }
  }

  career() {
    window.location.href = "/career";
  }
  aboutUs() {
    window.location.href = "/about-us";
  }
  products() {
    window.location.href = "/products";
  }
  daicel_group() {
    window.location.href = "/daicel-group";
  }
  csr_page() {
    window.location.href = "/csr";
  }
  news_events() {
    window.location.href = "/news-events";
  }
  contact_us() {
    window.location.href = "/contact-us";
  }
  th() {
    window.location.href = "/th/preview?id=" + this.id;
  }
  home() {
    window.location.href = "/";
  }

  preview(data) {
    window.location.href = "/preview?id=" + data;
  }
  async onSearch(keyword) {
    window.location.href = "/search?keyword=" + keyword;
  }
  go(j, item) {
    window.location.href = "/year?month=" + j + "&year=" + item;
  }
}
