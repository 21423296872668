import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule, CarouselModule } from 'angular-bootstrap-md';
import { CookieService } from 'ngx-cookie-service';
import { ButtonsModule, InputsModule } from 'angular-bootstrap-md'
import { NavbarModule , WavesModule} from 'angular-bootstrap-md'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderThComponent } from './views/th/header-th/header-th.component';
import { NgImageSliderModule } from 'ng-image-slider';

import { FooterComponent } from './views/en/footer/footer.component';
import { PrivacyComponent } from './views/en/privacy/privacy.component';
import { TermsOfUseComponent } from './views/en/terms-of-use/terms-of-use.component';
import { DaicelGroupComponent } from './views/en/daicel-group/daicel-group.component';
import { AboutUsComponent } from './views/en/about-us/about-us.component';
import { ProductsComponent } from './views/en/products/products.component';
import { CsrComponent } from './views/en/csr/csr.component';
import { NewsComponent } from './views/en/news/news.component';
import { ContactUsComponent } from './views/en/contact-us/contact-us.component';
import { AboutUsThComponent } from './views/th/about-us-th/about-us-th.component';
import { ProductsThComponent } from './views/th/products-th/products-th.component';
import { DaicelGroupThComponent } from './views/th/daicel-group-th/daicel-group-th.component';
import { CsrThComponent } from './views/th/csr-th/csr-th.component';
import { NewsThComponent } from './views/th/news-th/news-th.component';
import { ContactUsThComponent } from './views/th/contact-us-th/contact-us-th.component';
import { SlideFooterComponent } from './views/en/slide-footer/slide-footer.component';
import { FooterThComponent } from './views/th/footer-th/footer-th.component';
import { PreviewEnComponent } from './views/en/preview-en/preview-en.component';
import { PreviewThComponent } from './views/th/preview-th/preview-th.component';
import { YearComponent } from './views/en/year/year.component';
import { YearThComponent } from './views/th/year-th/year-th.component';
import { SearchComponent } from './views/en/search/search.component';
import { SearchThComponent } from './views/th/search-th/search-th.component';
import { PrivacyThComponent } from './views/th/privacy-th/privacy-th.component';
import { TermOfUseThComponent } from './views/th/term-of-use-th/term-of-use-th.component';
import { ChooseComponent } from './admin/choose/choose.component';
import { LoginComponent } from './admin/login/login.component';
import { EditorModule } from "@tinymce/tinymce-angular";

import { PreviewAdminComponent } from './admin/preview-admin/preview-admin.component';
import { PreviewAdminThComponent } from './admin/preview-admin-th/preview-admin-th.component';
import { RouterModule } from '@angular/router';
import { HeaderAdminComponent } from './admin/header-admin/header-admin.component';
import { HomePageThComponent } from './views/th/home-page-th/home-page-th.component';
import { HomePageEnComponent } from './views/en/home-page-en/home-page-en.component';

import { CollapseModule } from 'angular-bootstrap-md';
import { CareerAllComponent } from './admin/career-all/career-all.component';
import { CareerAddComponent } from './admin/career-add/career-add.component';
import { CareerEditComponent } from './admin/career-edit/career-edit.component';
import { PreviewCareerComponent } from './admin/preview-career/preview-career.component';
import { CareerComponent } from './views/en/career/career.component';
import { CareerDetailComponent } from './views/en/career-detail/career-detail.component'
import { NgxCaptchaModule } from 'ngx-captcha';
// import { CapchaComponent } from './views/capcha/capcha.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { CareerThComponent } from './views/th/career-th/career-th.component';
import { CareerThDetailComponent } from './views/th/career-th-detail/career-th-detail.component';
import { HeaderComponent } from './views/en/header/header.component';
import { PreloadingComponent } from './views/preloading/preloading.component';

import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { QuillModule } from 'ngx-quill';


import {NgxImageCompressService} from 'ngx-image-compress';
import { PggComponent } from './views/en/pgg/pgg.component';
import { CoCreationComponent } from './views/en/co-creation/co-creation.component';
import { PyroFuseComponent } from './views/en/pyro-fuse/pyro-fuse.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProductLineupComponent } from './views/en/pyro-fuse/product-lineup/product-lineup.component';
import { InflatorComponent } from './views/en/inflator/inflator.component';
import { ChooseProductComponent } from './views/choose-product/choose-product.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FullRecruitComponent } from './admin/full-recruit/full-recruit.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService } from "ngx-bootstrap/modal";

import { MatTableModule } from '@angular/material/table';
import { InflatorThComponent } from './views/th/inflator-th/inflator-th.component';
import { PyroFuseThComponent } from './views/th/pyro-fuse-th/pyro-fuse-th.component';
import { PggThComponent } from './views/th/pgg-th/pgg-th.component';
import { CoCreationThComponent } from './views/th/co-creation-th/co-creation-th.component';
import { ProductLineupThComponent } from './views/th/pyro-fuse-th/product-lineup-th/product-lineup-th.component';
import { ChooseProductThComponent } from './views/choose-product-th/choose-product-th.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { BootstrapModalModule } from 'ngx-bs-modal';
import { AdminIrComponent } from './admin-ir/admin-ir.component';
import { IrModule } from './admin/ir/ir.module';

const cookieConfig:NgcCookieConsentConfig ={
  "cookie": {
    "domain": window.location.hostname
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#007bff",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  // "type": "opt-out",
  "type": "info",
  "content": {
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "dismiss": "Allow cookies",
    "deny": "Refuse cookies",
    "link": "Terms of use",

    "href": "/terms-of-use",
    "policy": "Cookie Policy"
  }
}

@NgModule({
  declarations: [
    AppComponent,

    HeaderThComponent,
    FooterComponent,
    PrivacyComponent,
    TermsOfUseComponent,
    DaicelGroupComponent,
    AboutUsComponent,
    ProductsComponent,
    CsrComponent,
    NewsComponent,
    ContactUsComponent,
    AboutUsThComponent,
    ProductsThComponent,
    DaicelGroupThComponent,
    CsrThComponent,
    NewsThComponent,
    ContactUsThComponent,
    SlideFooterComponent,
    FooterThComponent,
    PreviewEnComponent,
    PreviewThComponent,
    YearComponent,
    YearThComponent,
    SearchComponent,
    SearchThComponent,
    PrivacyThComponent,
    TermOfUseThComponent,
    ChooseComponent,
    LoginComponent,
    PreviewAdminComponent,
    PreviewAdminThComponent,
    HeaderAdminComponent,
    HomePageThComponent,
    HomePageEnComponent,
    CareerAllComponent,
    CareerAddComponent,
    CareerEditComponent,
    PreviewCareerComponent,
    CareerComponent,
    CareerDetailComponent,

    CareerThComponent,
    CareerThDetailComponent,
    HeaderComponent,
    PreloadingComponent,

    PggComponent,
    CoCreationComponent,
    PyroFuseComponent,
    ProductLineupComponent,
    ChooseProductComponent,
    InflatorComponent,
    FullRecruitComponent,
    InflatorThComponent,
    PyroFuseThComponent,
    PggThComponent,
    CoCreationThComponent,
    ProductLineupThComponent,
    ChooseProductThComponent,

    AdminIrComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ButtonsModule.forRoot(),
     InputsModule,
    NavbarModule, WavesModule,
    MDBBootstrapModule.forRoot(),
    NgImageSliderModule ,
    NgxCaptchaModule,
    RecaptchaModule ,
    QuicklinkModule,
    EditorModule,
    QuillModule.forRoot() ,
    TabsModule.forRoot(),
    NgSelectModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    MatTableModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatFormFieldModule,
    MatSelectModule,
    BootstrapModalModule,
    IrModule,
    EditorModule



  ],
  providers: [
    CookieService ,
    NgxImageCompressService,
    BsModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
