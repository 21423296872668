import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-co-creation-th',
  templateUrl: './co-creation-th.component.html',
  styleUrls: ['./co-creation-th.component.css']
})
export class CoCreationThComponent implements OnInit {

  imageSrc = '../../../../assets/templates/home/choice_driver_mv01.gif';

  constructor() { }

  ngOnInit(): void {
  }


  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }


  onClick(name) {
    this.imageSrc = '../../../../assets/templates/home/'+name
  }

  career() {
    window.location.href = "/th/career";
  }
  aboutUs() {
    window.location.href = "/th/about-us";
  }
  products() {
    window.location.href = "/th/products";
  }
  daicel_group() {
    window.location.href = "/th/daicel-group";
  }
  csr_page() {
    window.location.href = "/th/csr";
  }
  news_events() {
    window.location.href = "/th/news-events";
  }
  contact_us() {
    window.location.href = "/th/contact-us";
  }
  th() {
    window.location.href = "/products";
  }
  home() {
    window.location.href = "/th";
  }



}
