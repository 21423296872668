import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-th',
  templateUrl: './products-th.component.html',
  styleUrls: ['./products-th.component.css']
})
export class ProductsThComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  career() {
    window.location.href = '/th/career';


  }
  aboutUs() {
    window.location.href = '/th/about-us';

  }
  products() {
    window.location.href = '/th/products';

  }
  daicel_group() {
    window.location.href = '/th/daicel-group';

  }
  csr_page() {
    window.location.href = '/th/csr';

  }
  news_events() {
    window.location.href = '/th/news-events';

  }
  contact_us() {
    window.location.href = '/th/contact-us';


  }
  th() {
    window.location.href = '/products';


  }
  home() {
    window.location.href = '/th';


  }
  inflator() {
    window.location.href = "/th/products/inflator";
  }
  pgg() {
    window.location.href = "/th/products/pgg";
  }
  co_creation() {
    window.location.href = "/th/products/co-creation";
  }
  pyro_fuse() {
    window.location.href = "/th/products/pyrofuse";
  }

  out1() {
    window.location.href = "/th/products/pgg";
  }
  out2() {
    window.location.href = "/th/products/co-creation";
  }
  out3() {
    window.location.href = "/th/products/pyrofuse";
  }


  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

}
