<!-- DIRTY Responsive pricing table HTML -->

<article *ngIf="!mobile">

  <ul>
    <li class="bg-purple ">
      <button>PDD2</button>
    </li>
    <li class="bg-blue">
      <button>PDD3</button>
    </li>
    <li class="bg-blue active">
      <button>PDD5.0</button>
    </li>
    <li class="bg-blue">
      <button>PDD5.1</button>
    </li>
    <li class="bg-blue">
      <button>PDD6</button>
    </li>
  </ul>

  <table>
    <thead>
      <tr>
        <th class="bg-purple" style="text-align: center;">Model</th>
        <th class="bg-blue">PDD2</th>
        <th class="bg-blue">PDD3</th>
        <th class="bg-blue">PDD5.0</th>
        <th class="bg-blue default">PDD5.1</th>
        <th class="bg-blue">PDD6</th>
      </tr>
    </thead>
    <tbody style="background-color: white">
      <tr>
        <td style=" text-align: center;    font-weight: bold;">Appearance</td>
        <td style="display:revert;"><img src="../../../../assets/templates/home/lineup_pdd2_im01.png" alt="">
          <img style="width: 80px;
          height: 20px;
          opacity: 0.6;
          margin-top: 60px;

          transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">
        </td>
        <td><img src="../../../../assets/templates/home/lineup_pdd3_im01.png" alt="">
          <img style="width: 80px;
          height: 20px;
          opacity: 0.6;
          margin-top: 45px;

          transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">

        </td>
        <td><img src="../../../../assets/templates/home/lineup_pdd50_im01.png" alt="">
          <img style="width: 80px;
          height: 20px;
          opacity: 0.6;
          margin-top: 40px;

          transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">
        </td>
        <td class="default"><img src="../../../../assets/templates/home/lineup_pdd51_im01.png" alt="">
          <img style="width: 80px;
          height: 20px;
          opacity: 0.6;
          margin-top: 30px;

          transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">
        </td>
        <td><img src="../../../../assets/templates/home/lineup_pdd6_im01.png" alt="">
          <img style="width: 80px;
          height: 20px;
          opacity: 0.6;
          margin-top: 20px;

          transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">
        </td>
      </tr>
      <tr>
        <td style=" text-align: center;    font-weight: bold;">Breaking capacity</td>
        <td style="display:revert;">2,300A/48VDC/7µH</td>
        <td>3,000A/100VDC/10µH </td>
        <td>15,000A/500VDC/10µH <br>
          500A/600VDC/50µH</td>
        <td class="default">20,000A/500VDC/20µH<br>
          16,000A/920VDC/10µH<br>
          15,000A/900VDC/25µH</td>
        <td>25,000A/915VDC/10µH</td>
      </tr>

      <tr>
        <td style=" text-align: center;    font-weight: bold;">Operating temperature range </td>
        <td style="display:revert;">−40℃〜+85℃</td>
        <td>−40℃〜+85℃</td>
        <td>−40℃〜+85℃</td>
        <td class="default">−40℃〜+85℃</td>
        <td>−40℃〜+85℃</td>
      </tr>

      <tr>
        <td style=" text-align: center;    font-weight: bold;">Busbar resistance
          (pre-operation:+23℃)</td>
        <td style="display:revert;">＜0.1mΩ</td>
        <td>＜0.1mΩ</td>
        <td>＜0.1mΩ</td>
        <td class="default">＜0.1mΩ</td>
        <td>＜0.1mΩ</td>
      </tr>

      <tr>
        <td style=" text-align: center;    font-weight: bold;">Continuous current rating</td>
        <td style="display:revert;">200A</td>
        <td>200A</td>
        <td>350A</td>
        <td class="default">350A</td>
        <td>350A</td>
      </tr>

      <tr>
        <td style=" text-align: center;    font-weight: bold;">Function (breaking) time</td>
        <td style="display:revert;">＜2ms</td>
        <td>＜2ms</td>
        <td>＜2ms</td>
        <td class="default">＜2ms</td>
        <td>＜2ms</td>
      </tr>

      <tr>
        <td style=" text-align: center;    font-weight: bold;">Geometry
          (D×W×L:mm)</td>
        <td style="display:revert;">19×70×45</td>
        <td>15×60×61</td>
        <td>44×88×68</td>
        <td class="default">44×88×80</td>
        <td>62×112×120</td>
      </tr>

      <tr>
        <td style=" text-align: center;    font-weight: bold;">Unit weight (g)</td>
        <td style="display:revert;">40g</td>
        <td>60g</td>
        <td>190g</td>
        <td class="default">220g</td>
        <td>510g</td>
      </tr>

      <tr>
        <td style=" text-align: center;    font-weight: bold;">Outgassing</td>
        <td style="display:revert;">outgas</td>
        <td>outgas</td>
        <td>no outgas</td>
        <td class="default">no outgas</td>
        <td>no outgas</td>
      </tr>

      <tr>
        <td style=" text-align: center;    font-weight: bold;">Product Data Sheet
          （PDF）</td>
        <td style="display:revert;"><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a
            style="color:#009be6"
            (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD2_Rev01.pdf')">
            PDF:254KB</a> </td>
        <td><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a style="color:#009be6"
            (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD3_Rev01.pdf')">
            PDF:254KB</a> </td>
        <td><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a style="color:#009be6"
            (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD5.0_Rev04.pdf')">
            PDF:254KB</a></td>
        <td class="default"><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a
            style="color:#009be6"
            (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD5.1_Rev08.pdf')">
            PDF:254KB</a></td>
        <td><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a style="color:#009be6"
            (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD6_Rev02.pdf')">
            PDF:254KB</a></td>
      </tr>




    </tbody>
  </table>


</article>


<tabset [justified]="true" *ngIf="mobile">
  <tab heading="PDD2" id="tab1">
    <div style=' text-align:center;'>
      <br><br>
      <div class=" section_inner clearfix">
        <div class='section_inner_margin clearfix' style="width: 95%;
        margin-left: auto;
        margin-right: auto;">

          <tbody style="background-color: white">
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Appearance</td>
              <td style="display:revert;"><img src="../../../../assets/templates/home/lineup_pdd2_im01.png" alt="">
                <img style="width: 80px;
                  height: 20px;
                  opacity: 0.6;
                  margin-top: 60px;

                  transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">
              </td>

            </tr>
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Breaking capacity</td>
              <td class="default" style="display:revert;">2,300A/48VDC/7µH</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Operating temperature range </td>
              <td style="display:revert;" class="default">−40℃〜+85℃</td>

            </tr>

            <tr>
              <td class="default" style=" text-align: center;    font-weight: bold;">Busbar resistance
                (pre-operation:+23℃)</td>
              <td style="display:revert;">＜0.1mΩ</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Continuous current rating</td>
              <td style="display:revert;">200A</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Function (breaking) time</td>
              <td style="display:revert;">＜2ms</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Geometry
                (D×W×L:mm)</td>
              <td  style="display:revert;">19×70×45</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Unit weight (g)</td>
              <td style="display:revert;">40g</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Outgassing</td>
              <td style="display:revert;">outgas</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Product Data Sheet
                （PDF）</td>
              <td class="default" style="display:revert;"><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a
                style="color:#009be6"
                (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD2_Rev01.pdf')">
                PDF:254KB</a>  </td>

            </tr>




          </tbody>


        </div>
      </div>
    </div>




  </tab>
  <tab heading="PDD3" >
    <div style=' text-align:center;'>
      <br><br>
      <div class=" section_inner clearfix">
        <div class='section_inner_margin clearfix' style="width: 95%;
        margin-left: auto;
        margin-right: auto;">

          <tbody style="background-color: white">
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Appearance</td>
              <td style="display:revert;">
                <img src="../../../../assets/templates/home/lineup_pdd3_im01.png" alt="">
                <img style="width: 80px;
                height: 20px;
                opacity: 0.6;
                margin-top: 45px;

                transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">

</td>

            </tr>
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Breaking capacity</td>
              <td class="default" style="display:revert;">3,000A/100VDC/10µH </td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Operating temperature range </td>
              <td style="display:revert;" class="default">−40℃〜+85℃</td>

            </tr>

            <tr>
              <td class="default" style=" text-align: center;    font-weight: bold;">Busbar resistance
                (pre-operation:+23℃)</td>
              <td style="display:revert;">＜0.1mΩ</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Continuous current rating</td>
              <td style="display:revert;">200A</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Function (breaking) time</td>
              <td style="display:revert;">＜2ms</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Geometry
                (D×W×L:mm)</td>
              <td  style="display:revert;">15×60×61</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Unit weight (g)</td>
              <td style="display:revert;">60g</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Outgassing</td>
              <td style="display:revert;">outgas</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Product Data Sheet
                （PDF）</td>
              <td class="default" style="display:revert;"><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a style="color:#009be6"
                (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD3_Rev01.pdf')">
                PDF:254KB</a>  </td>

            </tr>




          </tbody>


        </div>
      </div>
    </div>




  </tab>
  <tab heading="PDD5.0" >
    <div style=' text-align:center;'>
      <br><br>
      <div class=" section_inner clearfix">
        <div class='section_inner_margin clearfix' style="width: 95%;
        margin-left: auto;
        margin-right: auto;">

          <tbody style="background-color: white">
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Appearance</td>
              <td style="display:revert;">
                <img src="../../../../assets/templates/home/lineup_pdd50_im01.png" alt="">
                <img style="width: 80px;
                height: 20px;
                opacity: 0.6;
                margin-top: 40px;

                transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">

</td>

            </tr>
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Breaking capacity</td>
              <td class="default" style="display:revert;">15,000A/500VDC/10µH <br>
                500A/600VDC/50µH</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Operating temperature range </td>
              <td style="display:revert;" class="default">−40℃〜+85℃</td>

            </tr>

            <tr>
              <td class="default" style=" text-align: center;    font-weight: bold;">Busbar resistance
                (pre-operation:+23℃)</td>
              <td style="display:revert;">＜0.1mΩ</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Continuous current rating</td>
              <td style="display:revert;">350A</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Function (breaking) time</td>
              <td style="display:revert;">＜2ms</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Geometry
                (D×W×L:mm)</td>
              <td  style="display:revert;">44×88×68</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Unit weight (g)</td>
              <td style="display:revert;">190g</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Outgassing</td>
              <td style="display:revert;">no outgas</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Product Data Sheet
                （PDF）</td>
              <td class="default" style="display:revert;"><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a style="color:#009be6"
                (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD5.0_Rev04.pdf')">
                PDF:254KB</a></td>

            </tr>




          </tbody>


        </div>
      </div>
    </div>




  </tab>
  <tab heading="PDD5.1" >
    <div style=' text-align:center;'>
      <br><br>
      <div class=" section_inner clearfix">
        <div class='section_inner_margin clearfix' style="width: 95%;
        margin-left: auto;
        margin-right: auto;">

          <tbody style="background-color: white">
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Appearance</td>
              <td style="display:revert;">
                <img src="../../../../assets/templates/home/lineup_pdd51_im01.png" alt="">
          <img style="width: 80px;
          height: 20px;
          opacity: 0.6;
          margin-top: 30px;

          transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">

</td>

            </tr>
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Breaking capacity</td>
              <td class="default" style="display:revert;">20,000A/500VDC/20µH<br>
                16,000A/920VDC/10µH<br>
                15,000A/900VDC/25µH</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Operating temperature range </td>
              <td style="display:revert;" class="default">−40℃〜+85℃</td>

            </tr>

            <tr>
              <td class="default" style=" text-align: center;    font-weight: bold;">Busbar resistance
                (pre-operation:+23℃)</td>
              <td style="display:revert;">＜0.1mΩ</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Continuous current rating</td>
              <td style="display:revert;">350A</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Function (breaking) time</td>
              <td style="display:revert;">＜2ms</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Geometry
                (D×W×L:mm)</td>
              <td  style="display:revert;">44×88×80</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Unit weight (g)</td>
              <td style="display:revert;">220g</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Outgassing</td>
              <td style="display:revert;">no outgas</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Product Data Sheet
                （PDF）</td>
              <td class="default" style="display:revert;"><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a
                style="color:#009be6"
                (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD5.1_Rev08.pdf')">
                PDF:254KB</a></td>

            </tr>




          </tbody>


        </div>
      </div>
    </div>




  </tab>
  <tab heading="PDD6.0">
    <div style=' text-align:center;'>
      <br><br>
      <div class=" section_inner clearfix">
        <div class='section_inner_margin clearfix' style="width: 95%;
        margin-left: auto;
        margin-right: auto;">

          <tbody style="background-color: white">
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Appearance</td>
              <td style="display:revert;">
                <img src="../../../../assets/templates/home/lineup_pdd6_im01.png" alt="">
                <img style="width: 80px;
                height: 20px;
                opacity: 0.6;
                margin-top: 20px;

                transform: rotate(-8deg);" src="../../../../assets/templates/home/patented_ic01.svg" alt="">

</td>

            </tr>
            <tr>
              <td style=" text-align: center;    font-weight: bold;">Breaking capacity</td>
              <td class="default" style="display:revert;">25,000A/915VDC/10µH</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Operating temperature range </td>
              <td style="display:revert;" class="default">−40℃〜+85℃</td>

            </tr>

            <tr>
              <td class="default" style=" text-align: center;    font-weight: bold;">Busbar resistance
                (pre-operation:+23℃)</td>
              <td style="display:revert;">＜0.1mΩ</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Continuous current rating</td>
              <td style="display:revert;">350A</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Function (breaking) time</td>
              <td style="display:revert;">＜2ms</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Geometry
                (D×W×L:mm)</td>
              <td  style="display:revert;">62×112×120</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Unit weight (g)</td>
              <td style="display:revert;">510g</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Outgassing</td>
              <td style="display:revert;">no outgas</td>

            </tr>

            <tr>
              <td style=" text-align: center;    font-weight: bold;">Product Data Sheet
                （PDF）</td>
              <td class="default" style="display:revert;"><i class="fa fa-file-pdf-o" aria-hidden="true" style="color: red"></i><a style="color:#009be6"
                (click)="goToLink('https://www.daicel.com/safety/pyrofuse/pdf/ProductDataSheet_PDD6_Rev02.pdf')">
                PDF:254KB</a> </td>

            </tr>




          </tbody>


        </div>
      </div>
    </div>




  </tab>
</tabset>
