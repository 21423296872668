import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-header-th',
  templateUrl: './header-th.component.html',
  styleUrls: ['./header-th.component.css']
})
export class HeaderThComponent implements OnInit {
  @Input() id: string;
  constructor(private router: Router,private rest: ContentService) { }

  ngOnInit(): void {
    if(this.rest.get_reload()!=null){
      location.reload()
     this.rest.remove_reload()
  }
  }
  
  career() {
    window.location.href = '/th/career';
   

  }
  aboutUs() {
    window.location.href = '/th/about-us';
   
  }
  products() {
    window.location.href = '/th/products';
   
  }
  daicel_group() {
    window.location.href = '/th/daicel-group';
   
  }
  csr_page() {
    window.location.href = '/th/csr';
   
  }
  news_events() {
    window.location.href = '/th/news-events';
   
  }
  contact_us() {
    window.location.href = '/th/contact-us';
   

  }
  th() {
    window.location.href = '/preview?id=' + this.id;
   

  }
  home() {
    window.location.href = '/th';
   
  }

  preview(data) {

    window.location.href = '/th/preview?id=' + data;
   
  }
  async onSearch(keyword) {

    window.location.href = '/th/search?keyword=' + keyword;
   
  }
  go(j, item) {

    window.location.href = '/th/year?month=' + j + '&year=' + item
   

  }



}
