<!doctype html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta http-equiv="Content-Language" content="en">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>Daicel Thailand | Admin</title>
  <meta name="viewport"
    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no" />
  <meta name="description" content="This is an example dashboard created using build-in elements and components.">
  <meta name="msapplication-tap-highlight" content="no">
  <link href="https://demo.dashboardpack.com/architectui-html-free/main.css" rel="stylesheet">
</head>

<body>
  <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
    <div class="app-header header-shadow">
      <div class="app-header__logo">
        <img src="../assets/templates/home/DSST-Logo-500x146.png" style="height: 45px;width:120px ;margin-left:20px ;">

      </div>
      <div class="app-header__mobile-menu">
        <div>
          <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div class="app-header__menu">
        <span>
          <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
            <span class="btn-icon-wrapper">
              <i class="fa fa-ellipsis-v fa-w-6"></i>
            </span>
          </button>
        </span>
      </div>

      <div class="app-header__content">
        <div class="app-header-left">
          <!-- <div class="search-wrapper">
            <div class="input-holder">
              <input type="text" class="search-input" placeholder="Type to search">
              <button class="search-icon"><span></span></button>
            </div>
            <button class="close"></button>
          </div> -->
          <ul class="header-menu nav">

            <!-- <li class="btn-group nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon fa fa-edit"></i>
                                Projects
                            </a>
                        </li>
                        <li class="dropdown nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon fa fa-cog"></i>
                                Settings
                            </a>
                        </li> -->
          </ul>
        </div>
        <div class="app-header-right">
          <div class="header-btn-lg pr-0">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">

                <div class="widget-content-left  ml-3 header-user-info">
                  <div class="widget-heading">
                    Admin
                  </div>
                  <div class="widget-subheading">
                    Daicel Thailand
                  </div>
                </div>
                <div class="widget-content-right header-user-info ml-3">
                  <button (click)="onClickSignOut()" type="button"
                    class="btn-shadow p-1 btn btn-dark btn-sm show-toastr-example">
                    <i class="fas fa-sign-out-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="app-main">
      <div class="app-main__outer" style="padding-left: 0px;">
        <div class="app-main__inner">
          <div class="app-page-title">
            <div class="page-title-wrapper">
              <div class="page-title-heading">
                <div class="page-title-icon">
                  <i class="fas fa-clipboard-list"></i>
                </div>
                <div>Dashboard

                </div>
              </div>
              <div class="page-title-actions">

                <div class="d-inline-block dropdown">
                  <button (click)="openModal(template)" type="button" class="btn btn-primary btn-lg btn-block"> <i
                      class="fas fa-plus"></i> Add new post</button>

                </div>
              </div>
            </div>
          </div>



          <div class="row">
            <div class="col-md-12">
              <div class="main-card mb-3 card">
                <div class="card-header">All post
                  <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                      <button class="active btn btn-focus" style="width: 150px;">Search</button>

                      <input [(ngModel)]="fields.event_name_en" (ngModelChange)="updateFilters()" mdbInput type="text"
                        class="form-control pl-0 rounded-0" id="inlineFormInputGroupMD" placeholder="Title EN" />
                      <button class="btn btn-focus" style="width: 200px;">Publish Date</button>
                      <input [(ngModel)]="fields.published_date" (ngModelChange)="updateFilters()" style="width: 200px;"
                        mdbInput type="date" class="form-control pl-0 rounded-0" id="inlineFormInputGroupMD" value="" />
                      <button class="btn btn-focus" style="width: 200px;">Type</button>
                      <select [(ngModel)]="selected" name="valueCheck" (change)="valueChange($event)">
                        <option [ngValue]="undefined" disabled>Select Any </option>
                        <option *ngFor="let obj of stud" [ngValue]="obj.id" [selected]="obj.id == selected">
                          {{obj.name}} </option>
                      </select>


                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">No.</th>
                        <th></th>
                        <th class="text-center">Title TH</th>
                        <th class="text-center">Title EN</th>
                        <th class="text-center">Publish Date</th>
                        <th class="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of content1| myFilter :filter; let i = index; ">
                        <!-- <tr *ngFor="let item of pagedItems; let i = index"> -->
                        <td class="text-center text-muted">{{((currentPage - 1) * itemsPerPage) + i + 1}}</td>
                        <td>
                          <span class="badge badge-danger badge-danger-alt"
                            *ngIf="(item.com_news==0 &&item.csr==0&&item.events==0 )">Copy Post</span>
                          <span class="badge badge-primary badge-primary-alt" *ngIf="item.com_news==1">Company
                            News</span>
                          <span class="badge badge-warning badge-warning-alt" *ngIf="item.csr==1">CSR</span>
                          <span class="badge badge-success badge-success-alt" *ngIf="item.events==1">Events</span>
                          <br>
                          <div id="thumbwrap">
                            <a class="thumb" (click)="showImage(item.img_header)">
                              <img src={{item.img_header}} alt="" style="width: 45px; height: 45px" class="rounded" />

                            </a>
                          </div>



                        </td>
                        <td style="width: 25%;"> {{item.event_name_th}}</td>
                        <td style="width: 25%;"> {{item.event_name_en}}</td>
                        <td>
                          <p *ngIf="item.published_date =='2500-01-01'">-</p>
                          <p *ngIf="item.published_date !='2500-01-01'">{{item.published_date | date: "dd/MM/yyyy" }}
                          </p>

                        </td>
                        <td class="text-center">
                          <button type="button" id="PopoverCustomT-1" class="btn btn-outline-primary  btn-sm"
                            (click)="copy(item.event_id)"><i class="far fa-copy"></i> </button>
                          <button (click)="edit(template,item.event_id)" type="button" id="PopoverCustomT-1" class="btn btn-primary btn-sm"><i
                              class="fas fa-pencil-alt"></i> Edit</button>
                          <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm"
                            (click)="deleteItem(item.event_id)"><i class="fas fa-trash-alt"></i></button>
                        </td>
                      </tr>

                    </tbody>


                  </table>
                </div>
                <div class="d-block text-center card-footer">
                  <div class="row">
                    <div class="col">
                      <!-- <pagination
                                          [totalItems]="content1.length"
                                          [itemsPerPage]="itemsPerPage"
                                          [boundaryLinks]="true"
                                          [directionLinks]="true"
                                          [maxSize]="5"
                                          [(ngModel)]="currentPage"
                                          name="currentPage"
                                          (pageChanged)="pageChanged($event)"
                                          (pageBoundsCorrection)="pageChanged($event)"></pagination> -->
                    </div>
                    <div class="col">
                      <div class="pull-right mt-2 text-muted d-none d-sm-block justify-content-center"
                        style="float: right;margin-right: 10px;">
                        Total: <b>{{content1.length}}</b> Records
                      </div>

                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="app-wrapper-footer">
          <div class="app-footer">
            <div class="app-footer__inner">
              <div class="app-footer-left">
                <ul class="nav">
                  <li class="nav-item">
                    <a href="javascript:void(0);" class="nav-link">
                      <p>Daicel Safety Systems ( Thailand ) Co., Ltd. </p>
                    </a>
                  </li>

                </ul>
              </div>
              <div class="app-footer-right">
                <ul class="nav">

                  <li class="nav-item">
                    <a href="javascript:void(0);" class="nav-link">
                      <div class="badge badge-success mr-1 ml-0">
                        <small>NEW</small>
                      </div>
                      Admin
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="http://maps.google.com/maps/api/js?sensor=true"></script>
    </div>
  </div>
  <script type="text/javascript"
    src="https://demo.dashboardpack.com/architectui-html-free/assets/scripts/main.js"></script>
</body>

</html>


<ng-template #template >
  <div class="modal-header">
    <h4 class="modal-title pull-left">Post</h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form id="content-form"  #contentForm="ngForm" novalidate>
      <div [hidden]="contentForm.submitted">
        <div class="row" >
          <div class="col-3" style="margin-top: -15px;">

            <div class="group-gap">
              <div class="d-block my-3">
                <label for="boiProject-name">Type</label>
                <div class="custom-control custom-radio">
                  <input (change)="checkRadio()" id="com_news" type="radio" class="custom-control-input"
                  [(ngModel)]="content.type"
                  name="type" #type="ngModel" [value]="0" required>
                  <label class="custom-control-label" for="com_news">Company News</label>
                </div>

                <div class="custom-control custom-radio">
                  <input (change)="checkRadio()" id="csr" type="radio" class="custom-control-input"
                  [(ngModel)]="content.type"
                  name="type" #type="ngModel" [value]="1" required
                  >
                  <label class="custom-control-label" for="csr">CSR</label>
                </div>

                <div class="custom-control custom-radio">
                  <input (change)="checkRadio()" id="events" type="radio" class="custom-control-input"
                  [(ngModel)]="content.type"
                  name="type" #type="ngModel" [value]="2"
                    required>
                  <label class="custom-control-label" for="events">Events</label>
                </div>


                <div *ngIf="valid1">
                  <p style="color:red">Please select type</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-9">
            <label for="boiProject-name">Image Header</label>
            <br>
            <button class="btn btn-outline-primary" type="button" (click)="fileHeader1.click()">
              <i class="fas fa-cloud-upload-alt"></i> Upload Image
            </button>

            <input type="file"  #fileHeader1 (change)="fileHeader($event)" class="custom-file-input" style="font-size: 14px !important;" accept="image/*" class="btn btn-default">

            <img [src]="imageSrc">

          </div>
        </div>


        <hr>
        <div class="row">

          <div class="col-12">
            <h4><b>Thai Content</b></h4>
            <div class="form-group">
              <label for="boiProject-name">Title</label>

               <input  (keyup)="checkTitleTh()" class="form-control" [(ngModel)]="content.event_name_th"  name="event_name_th" #event_name_th="ngModel"   type="text" placeholder="ระบุหัวข้อ TH"
               required>


              <div *ngIf="valid2">
                <p style="color:red">Please Input Thai Title</p>
              </div>


            </div>
          </div>
          <div class="col-12">
            <div class="editControls">
              <div class='btn-group'>
                <button type="button" class="btn" (click)="setStyle('bold')" style="    border: 1px solid;">
                  <b>B</b>
                </button>
                <button type="button" class="btn" (click)="setStyle('italic')" style="    border: 1px solid;">
                  <em><b>I</b></em>
                </button>
                <button type="button" class="btn" (click)="setStyle('underline')" style="    border: 1px solid;">
                  <u><b>U</b></u>
                </button>
                <button type="button" class="btn" (click)="setStyle('strikeThrough')" style="    border: 1px solid;">
                  <s>abc</s>
                </button>
              </div>

            </div>
            <div #editorTh class="editor" [(innerHtml)]="description" (input)="onChange($event)" contenteditable></div>
            <!-- <div #editor class="editor" [(innerHtml)]="description" (input)="onChange($event.target.innerHTML) "
              contenteditable></div> -->
          </div>


        </div>
        <hr>
        <br>
        <div class="row">
          <div class="col-12">

            <div class="form-group">
              <h4><b>English Content</b></h4>
              <label for="boiProject-name">Title</label>
              <input (keyup)="checkTitleEn()"  class="form-control" [(ngModel)]="content.event_name_en"  name="event_name_en" #event_name_en="ngModel"   type="text" placeholder="ระบุหัวข้อ EN"
              required>

              <div *ngIf="valid3">
                <p style="color:red">Please Input English Title</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="editControls">
              <div class='btn-group'>
                <button type="button" class="btn" (click)="setStyle('bold')" style="    border: 1px solid;">
                  <b>B</b>
                </button>
                <button type="button" class="btn" (click)="setStyle('italic')" style="    border: 1px solid;">
                  <em><b>I</b></em>
                </button>
                <button type="button" class="btn" (click)="setStyle('underline')" style="    border: 1px solid;">
                  <u><b>U</b></u>
                </button>
                <button type="button" class="btn" (click)="setStyle('strikeThrough')" style="    border: 1px solid;">
                  <s>abc</s>
                </button>
              </div>

            </div>


         

            <div #editorEn class="editor" [(innerHtml)]="description1" (input)="onChange1($event)" contenteditable></div>

       
          </div>





        </div>
        <hr>
        <br>
        <div class="row">
          <div class="col-12">
            <h4><b>All Image</b></h4>
            <button class="btn btn-outline-primary" type="button" (click)="filePicker.click()">
              <i class="fas fa-cloud-upload-alt"></i> Upload Image
            </button>
            <input type="file" #filePicker (change)="fileuploads($event)"  multiple/>
          </div>


          <div class="row">
            <div class="column" *ngFor="let image of images">
              <div class="container">
                <img [src]="image.url" style="width: 100%;" />
                <div class="middle">
                  <button href="javascript:void(0)" (click)="deleteImage(image)" class="btn btn-danger btn-sm" style="border-radius:50%"><i class="fas fa-trash-alt"
                      aria-hidden="true" ></i></button>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>


    </form>


  </div>
  <div class="modal-footer" style="margin-top: 20px">
    <button type="button" class="btn btn-danger " (click)="cancel()"><i class="fas fa-times"></i> Close</button>
    <button type="button" class="btn btn-success " (click)="submitForm()" style="float:right"><i
        class="far fa-save"></i>
      Save</button>
    <button type="button" class="btn btn-primary " (click)="previewEN()" style="float:right"><i
        class="fas fa-eye"></i>
      Preview EN </button>
    <button type="button" class="btn btn-primary  " (click)="previewTH()" style="float:right"><i
        class="fas fa-eye"></i>
      Preview TH</button>

  </div>
</ng-template>
